import { VehicleCheckHistorySingleComponent } from './vehicle-check-history-single/vehicle-check-history-single.component';
import { AllWashingRegisterComponent } from './all-washing-register/all-washing-register.component';
import { DashboardCombinedComponent } from './dashboard-combined/dashboard-combined.component';
import { DashboardFuelConsumptionComponent } from './dashboard-fuel-consumption/dashboard-fuel-consumption.component';
import { DashboardGpsComponent } from './dashboard-gps/dashboard-gps.component';
import { DisposableProductsComponent } from './disposable-products/disposable-products.component';
import { DashboardCustomerComponent } from './dashboard-customer/dashboard-customer.component';
import { ApprovePendingApprovalComponent } from './approve-pending-approval/approve-pending-approval.component';
import { OrderMastersComponent } from './order-masters/order-masters.component';
import { VehicleCategoriesComponent } from './vehicle-categories/vehicle-categories.component';
import { AllUpcomingBookingsComponent } from './all-upcoming-bookings/all-upcoming-bookings.component';
import { AllVehiclesComponent } from './all-vehicles/all-vehicles.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { AllSurveyorsComponent } from './all-surveyors/all-surveyors.component';
import { DashboardWorkshopComponent } from './dashboard-workshop/dashboard-workshop.component';
import { DashboardInventoryComponent } from './dashboard-inventory/dashboard-inventory.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AllJobsComponent } from './all-jobs/all-jobs.component';
import { ProductSingleComponent } from './product-single/product-single.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AllComponentsComponent } from './all-components/all-components.component';
import { AllProductsComponent } from './all-products/all-products.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { SignupPageComponent } from './signup-page/signup-page.component';
import { MasterdataComponent } from './masterdata/masterdata.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { OrderSingleComponent } from './order-single/order-single.component';
import { AllGeneralServicingComponent } from './all-general-servicing/all-general-servicing.component';
import { GeneralServicingNewComponent } from './general-servicing-new/general-servicing-new.component';
import { GeneralServicingSingleComponent } from './general-servicing-single/general-servicing-single.component';
import { InventoryTransactionsComponent } from './inventory-transactions/inventory-transactions.component';
import { AllCustomersComponent } from './all-customers/all-customers.component';
import { CustomerSingleComponent } from './customer-single/customer-single.component';
import { AllInvoiceComponent } from './all-invoice/all-invoice.component';
import { InvoiceSingleComponent } from './invoice-single/invoice-single.component';
import { AllAccidentalClaimsComponent } from './all-accidental-claims/all-accidental-claims.component';
import { AccidentalClaimComponent } from './accidental-claim/accidental-claim.component';
import { AccidentalClaimSingleComponent } from './accidental-claim-single/accidental-claim-single.component';
import { AllReportingOnlyComponent } from './all-reporting-only/all-reporting-only.component';
import { ReportingOnlyNewComponent } from './reporting-only-new/reporting-only-new.component';
import { DashboardFinanceComponent } from './dashboard-finance/dashboard-finance.component';
import { DashboardLabourComponent } from './dashboard-labour/dashboard-labour.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { WashingFormComponent } from './washing-form/washing-form.component';
import { VehicleCheckHistoryComponent } from './vehicle-check-history/vehicle-check-history.component';
import { VpcMasterComponent } from './vpc-master/vpc-master.component';


const appRoutes: Routes = [
    {path: '', component: LoginPageComponent},

    {path: 'login', component: LoginPageComponent},
    {path: 'all-components', component: AllComponentsComponent},
    {path: 'sign-up', component: SignupPageComponent},
    {path: 'all-products', component: AllProductsComponent},
    {path: 'product-single', component: ProductSingleComponent},
    {path: 'masterdata', component: MasterdataComponent},
    {path: 'all-orders', component: AllOrdersComponent},
    {path: 'purchase-orders', component: PurchaseOrdersComponent},
    {path: 'order-single', component: OrderSingleComponent},
    {path: 'all-general-servicing', component: AllGeneralServicingComponent},
    {path: 'general-servicing-single', component: GeneralServicingSingleComponent},
    {path: 'general-servicing', component: GeneralServicingNewComponent},
    {path: 'orders-masterdata', component: OrderMastersComponent},
    {path: 'inventory-transactions', component: InventoryTransactionsComponent},
    {path: 'disposable-products', component: DisposableProductsComponent},
    {path: 'all-customers', component: AllCustomersComponent},
    {path: 'all-vehicles', component: AllVehiclesComponent},
    {path: 'all-upcoming-bookings', component: AllUpcomingBookingsComponent},
    {path: 'vehicle-categories', component: VehicleCategoriesComponent},
    {path: 'customer-single', component: CustomerSingleComponent},
    {path: 'vehicle-check-history/:vehicle_id', component: VehicleCheckHistoryComponent},
    {path: 'vehicle-check-history/:vehicle_id/:form_type', component: VehicleCheckHistorySingleComponent},
    {path: 'vehicle-check-history/:vehicle_id/:form_type/edit/:id', component: VehicleCheckHistorySingleComponent},
    {path: 'all-invoices', component: AllInvoiceComponent},
    {path: 'invoice-single', component: InvoiceSingleComponent},
    {path: 'all-accidental-claims', component: AllAccidentalClaimsComponent},
    {path: 'accidental-claim', component: AccidentalClaimComponent},
    {path: 'accidental-claim-single', component: AccidentalClaimSingleComponent},
    {path: 'all-surveyors', component: AllSurveyorsComponent},
    {path: 'all-jobs', component: AllJobsComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'combined-dashboard', component: DashboardCombinedComponent},
    {path: 'inventory-dashboard', component: DashboardInventoryComponent},
    {path: 'customer-dashboard', component: DashboardCustomerComponent},
    {path: 'finance-dashboard', component: DashboardFinanceComponent},
    {path: 'labour-dashboard', component: DashboardLabourComponent},
    {path: 'workshop-dashboard', component: DashboardWorkshopComponent},
    {path: 'gps-dashboard', component: DashboardGpsComponent},
    {path: 'fuel-consumption-dashboard', component: DashboardFuelConsumptionComponent},
    
    {path: 'all-reporting-only', component: AllReportingOnlyComponent},
    {path: 'wash-register', component: AllWashingRegisterComponent},
    {path: 'wash-register-new', component: WashingFormComponent},
    {path: 'reporting-only-new', component: ReportingOnlyNewComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'settings', component: SettingsComponent},
    {path: 'approvals', component: ApprovalsComponent},
    {path: 'pending-approvals/approve/:id', component: ApprovePendingApprovalComponent},

    {path: 'vpc-master', component: VpcMasterComponent},


];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { 
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled' 
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}

// AIzaSyBoU1nrGyraL_EDKoD40rM8Dc74rkJoekc