import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, OnDestroy, OnInit } from '@angular/core';
import axios from 'axios';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-approve-pending-approval',
  templateUrl: './approve-pending-approval.component.html',
  styleUrls: ['./approve-pending-approval.component.css']
})
export class ApprovePendingApprovalComponent implements OnInit, OnDestroy {

  pending_approval_id = 0;
  private sub: any;

  constructor(private route: ActivatedRoute, private msg: NzMessageService, private router: Router) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.pending_approval_id = +params.id;
      this.verifyApprovalOtp();
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  verifyApprovalOtp() {
    const data = {
      email_verify: true,
      pending_approval_id: this.pending_approval_id
    }

    axios.post('/api/verify-approval-otp', data, {}).then((response)=>{
      this.msg.success("Pending approval approved successfully!");
      this.router.navigate(['/approvals']);
    }).catch(() => {
      this.msg.error("Something went wrong!");
    })
  }

}
