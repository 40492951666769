import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sort-by-component',
  templateUrl: './sort-by-component.component.html',
  styleUrls: ['./sort-by-component.component.css']
})
export class SortByComponentComponent implements OnInit {

  @Input()
  asc: boolean = false;
  @Input()
  isActive: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
