import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders-navbar',
  templateUrl: './orders-navbar.component.html',
  styleUrls: ['./orders-navbar.component.css']
})
export class OrdersNavbarComponent implements OnInit {

  pathType = '';

  ngOnInit(): void {
    this.setPathType();
  }

  setPathType() {
    let path = window.location.pathname;

    if(path == "/all-general-servicing") {
      this.pathType = 'servicing';
    } 

    if(path == "/all-accidental-claims") {
      this.pathType = 'accident';
    } 

    if(path == "/all-reporting-only") {
      this.pathType = 'report';
    } 

  }

}
