import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-dashboard-workshop',
  templateUrl: './dashboard-workshop.component.html',
  styleUrls: ['./dashboard-workshop.component.css'],
})
export class DashboardWorkshopComponent implements OnInit {
  selectedValue = null;

  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;

  constructor(
    private msg: NzMessageService,
    private themeService: ThemeConstantService
  ) {}

  activeTab = 0;
  date = [moment().subtract(1, 'M').toDate(), moment().toDate()];
  revenueChartFormat: string = 'revenueMonth';

  revenueChartData: Array<any> = [
    {
      data: [30, 60, 40, 50, 40, 55, 85, 65, 75, 50, 70],
      label: 'Series A',
    },
  ];
  currentrevenueChartLabelsIdx = 1;
  revenueChartLabels: Array<any> = [
    '16th',
    '17th',
    '18th',
    '19th',
    '20th',
    '21th',
    '22th',
    '23th',
    '24th',
    '25th',
    '26th',
  ];
  revenueChartOptions: any = {
    maintainAspectRatio: false,
    responsive: true,
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    tooltips: {
      mode: 'index',
    },
    scales: {
      xAxes: [
        {
          gridLines: [
            {
              display: false,
            },
          ],
          ticks: {
            display: true,
            fontColor: '#77838f',
            fontSize: 13,
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            drawTicks: false,
            borderDash: [3, 4],
            zeroLineWidth: 1,
            zeroLineBorderDash: [3, 4],
          },
          ticks: {
            display: true,
            stepSize: 20,
            fontColor: '#77838f',
            fontSize: 13,
            padding: 10,
          },
        },
      ],
    },
  };
  revenueChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      borderColor: '#05c9a7',
      pointBackgroundColor: '#05c9a7',
      pointBorderColor: '#ffffff',
      pointHoverBackgroundColor: 'rgba(0, 201, 167, 0.1)',
      pointHoverBorderColor: 'rgba(0, 201, 167, 0.1)',
    },
  ];
  revenueChartType = 'line';

  chartDataLoaded = false;

  intro = {
    quotation_open: 0,
    open_accidental_claims: 0,
    open_servicing_orders: 0,
    total_amount: 0,
  };

  open_orders = [];
  orders_stuck_in_last_10_days = [];

  quotation_open_visible = false;
  open_accidental_claims_visible = false;
  open_servicing_orders_visible = false;
  total_amount_visible = false;

  quotation_open_data = [];
  open_accidental_claims_data = [];
  open_servicing_orders_data = [];
  total_amount_data = [];

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.getDashboardWorkshop();
  }

  getDashboardWorkshop() {
    let url = '/api/get-dashboard-workshop';

    let data = {
      start_date: moment(this.date[0]).format('YYYY-MM-DD'),
      end_date: moment(this.date[1]).format('YYYY-MM-DD'),
    };

    axios.post(url, data, {}).then((response) => {

        this.intro = response.data.intro;
        this.open_orders = response.data.open_orders;
        this.orders_stuck_in_last_10_days = response.data.orders_stuck_in_last_10_days;

      this.revenueChartData = [
        { 
          data: response.data.revenue_chart_data,
          label: 'Orders',
        },
      ];

      this.revenueChartLabels = response.data.revenue_chart_labels;
      this.chartDataLoaded = true;
    });
  }

  onOk(result: Date | Date[] | null): void {
    this.getDashboardWorkshop();
  }

  getDashboardWorkshopDetails(type: string) {

    let url = '/api/get-dashboard-workshop-details';

    let data = {
        type: type,
        start_date: moment(this.date[0]).format('YYYY-MM-DD'),
        end_date: moment(this.date[1]).format('YYYY-MM-DD')
    };

    axios.post(url, data, {}).then(response => {
        if(type == 'quotation_open') {
            this.quotation_open_data = response.data.data;
            this.quotation_open_visible = true;
        } else if (type == 'open_accidental_claims') {
            this.open_accidental_claims_data = response.data.data;
            this.open_accidental_claims_visible = true;
        } else if (type == 'open_servicing_orders') {
            this.open_servicing_orders_data = response.data.data;
            this.open_servicing_orders_visible = true;
        } else if (type == 'total_amount') {
            this.total_amount_data = response.data.data;
            this.total_amount_visible = true;
        }

    })
  }
}
