import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';
import { differenceInCalendarDays } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit {


  listOfRemarks = [];
  check_in_date = new Date();
  remarks = [];
  all_customers = [];
  customer_id = -1;
  customer_name = '';
  phone = '';
  customer_vehicles = [];
  vehicle_id = -1;
  make = '';
  model = '';
  mfg_date = null;
  order_id = 0;
  isEdit = false;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  name:'';
  email:'';
  labour_day_price:'';
  max_booking:'';
  openOtpModal=false;
  inputValue='';
  otp='';

  pending_approval_id = 0;

  approvals = [];

  showApproved = false;
  showArchived = false;
  showRejected = false;

  reject_reason = '';
  rejectReasonModal=false;

  intro = {
    pending_po: 0,
    pending_credit_limit: 0,
    total_reject_count: 0,
    total_accept_count: 0,
  }

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;

  constructor(private msg: NzMessageService, private route: ActivatedRoute, private router: Router, private themeService: ThemeConstantService,private modal: NzModalService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    
    this.getApprovals();
  }

  getApprovals() {
    var url = '/api/pending-approvals'

    var data = {
      show_approved: this.showApproved,
      show_archived: this.showArchived,
      show_rejected: this.showRejected
    };

    axios.post(url, data, {}).then(response => {
      this.approvals = [...response.data.pending_approvals];
    });
    this.pendingApprovalsStats();
  }

  pendingApprovalsStats() {
    var url = '/api/pending-approvals-stats'

    axios.post(url, {}).then(response => {
      this.intro = response.data.data;
    });
  }

  sendApprovalOtp() {
    var url = '/api/send-approval-otp'

    var data = {
      pending_approval_id: this.pending_approval_id
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success("Otp sent successfully");
    })
  }

  approveRequest() {
    var url = '/api/verify-approval-otp'

    var data = {
      otp: this.otp
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success) {
        this.msg.success("Request approved successfully");
        this.openOtpModal=false;
        this.getApprovals()
        this.clearPrevData();
      } else {
        this.msg.error("Invalid otp");
      }
    })
    
  }

  archiveApproval(id: number) {
    var url = '/api/archive-approval'

    var data = {
      id: id,
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success("Request archived successfully");
      this.getApprovals();
    })
  }

  rejectApproval() {

    if(this.reject_reason == '') {
      this.msg.error("Please enter reject reason");
      return;
    }

    var url = '/api/reject-approval'

    var data = {
      id: this.pending_approval_id,
      reject_reason: this.reject_reason
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success("Request rejected successfully");
      this.rejectReasonModal=false;
      this.reject_reason = '';
      this.getApprovals();
    })
  }

  clearPrevData() {
    this.pending_approval_id = 0;
  }

}
