<div nz-row class="main-login-signup-container">

  <div class="main-login-container" nz-col="24">

    <div class="blur-filter"></div>  

    
    <img src="https://images.unsplash.com/photo-1559297434-fae8a1916a79?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" style="width:100vw; object-fit: cover; height:100vh; position: absolute; z-index:1;" alt="">

    
    <div class="signup-details-container">
    <div class="new-here-lang-container">
      <div class="new-here-sign-up-text">

        
        <div class="logo-container">
          <img src="assets/BigFoot/bigfoot.png" alt="" style="width:185px; height:auto;">
        </div>

        
        <div class="sign-up-text">Sign Up</div>
      </div>
    

    </div>

    <div class="sign-up-sub-text">Sign up and get access to all the information regarding your vehicle service</div>

    <div class="sign-up-separator"></div>  

    <div class="full-name-details">
      <div class="full-name-text">
        Full Name
      </div>

      <div>
        <nz-input-group [nzPrefix]="prefixIconSearch"> 
          <input style="margin-left: 15px;" [(ngModel)]="name" type="text" nz-input placeholder="Full Name" />
        </nz-input-group>

        <ng-template #prefixIconSearch>
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4141 10.9313C11.1194 10.2331 10.6916 9.59891 10.1547 9.06406C9.61947 8.52767 8.9854 8.10001 8.28754 7.80469C8.28129 7.80156 8.27504 7.8 8.26879 7.79688C9.24223 7.09375 9.87504 5.94844 9.87504 4.65625C9.87504 2.51563 8.14066 0.78125 6.00004 0.78125C3.85941 0.78125 2.12504 2.51563 2.12504 4.65625C2.12504 5.94844 2.75785 7.09375 3.73129 7.79844C3.72504 7.80156 3.71879 7.80313 3.71254 7.80625C3.01254 8.10156 2.38441 8.525 1.84535 9.06563C1.30896 9.60089 0.881296 10.235 0.585977 10.9328C0.295855 11.616 0.139386 12.3485 0.125039 13.0906C0.124622 13.1073 0.127547 13.1239 0.133642 13.1394C0.139737 13.155 0.148878 13.1691 0.160527 13.181C0.172176 13.193 0.186098 13.2025 0.201471 13.2089C0.216844 13.2154 0.233358 13.2188 0.250039 13.2188H1.18754C1.25629 13.2188 1.31098 13.1641 1.31254 13.0969C1.34379 11.8906 1.82816 10.7609 2.68441 9.90469C3.57035 9.01875 4.74691 8.53125 6.00004 8.53125C7.25316 8.53125 8.42973 9.01875 9.31566 9.90469C10.1719 10.7609 10.6563 11.8906 10.6875 13.0969C10.6891 13.1656 10.7438 13.2188 10.8125 13.2188H11.75C11.7667 13.2188 11.7832 13.2154 11.7986 13.2089C11.814 13.2025 11.8279 13.193 11.8396 13.181C11.8512 13.1691 11.8603 13.155 11.8664 13.1394C11.8725 13.1239 11.8755 13.1073 11.875 13.0906C11.8594 12.3438 11.7047 11.6172 11.4141 10.9313ZM6.00004 7.34375C5.28285 7.34375 4.60785 7.06406 4.10004 6.55625C3.59223 6.04844 3.31254 5.37344 3.31254 4.65625C3.31254 3.93906 3.59223 3.26406 4.10004 2.75625C4.60785 2.24844 5.28285 1.96875 6.00004 1.96875C6.71723 1.96875 7.39223 2.24844 7.90004 2.75625C8.40785 3.26406 8.68754 3.93906 8.68754 4.65625C8.68754 5.37344 8.40785 6.04844 7.90004 6.55625C7.39223 7.06406 6.71723 7.34375 6.00004 7.34375Z" fill="#5B5B5B"/>
            </svg>
            
        </ng-template>

      </div>
    </div>  

    <div class="full-name-details">
      <div class="full-name-text">
        Email
      </div>

      <div>
        <nz-input-group [nzPrefix]="prefixIconEmail"> 
          <input style="margin-left: 15px;" type="text" [(ngModel)]="email" nz-input placeholder="email" />
        </nz-input-group>

        <ng-template #prefixIconEmail>
          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.49994 12H16.5C17.3272 12 18 11.3273 18 10.5V1.5C18 0.672752 17.3272 0 16.5 0H1.49994C0.672691 0 -6.10352e-05 0.672752 -6.10352e-05 1.5V10.5C-6.10352e-05 11.3273 0.672691 12 1.49994 12ZM16.5 0.750002C16.9136 0.750002 17.25 1.08638 17.25 1.5V10.5C17.25 10.9137 16.9136 11.25 16.5 11.25H1.49994C1.08632 11.25 0.749943 10.9137 0.749943 10.5V1.5C0.749943 1.08638 1.08632 0.750002 1.49994 0.750002H16.5Z" fill="#5B5B5B"/>
            <path d="M8.99994 7.94663L15.5786 3.68999C15.7526 3.57712 15.8025 3.34537 15.69 3.17136C15.5775 2.99736 15.3453 2.94786 15.1713 3.05999L8.99994 7.05338L2.82855 3.05999C2.65455 2.94786 2.42242 2.99736 2.30992 3.17136C2.19742 3.34537 2.2473 3.57712 2.4213 3.68999L8.99994 7.94663Z" fill="#5B5B5B"/>
            <path d="M15.7497 9.75023C15.8708 9.75023 15.99 9.69173 16.062 9.58335C16.1772 9.41085 16.1307 9.17835 15.9582 9.06322L12.5831 6.81322C12.4106 6.69809 12.1778 6.74459 12.063 6.91709C11.9479 7.08959 11.9944 7.32209 12.1669 7.43722L15.5419 9.68723C15.606 9.7296 15.6784 9.75023 15.7497 9.75023Z" fill="#5B5B5B"/>
            <path d="M2.25027 9.75023C2.32152 9.75023 2.3939 9.7296 2.45802 9.68723L5.83303 7.43722C6.00553 7.32209 6.05203 7.08959 5.93691 6.91709C5.82216 6.74459 5.58966 6.69809 5.41678 6.81322L2.04177 9.06322C1.86927 9.17835 1.82277 9.41085 1.93789 9.58335C2.00989 9.69173 2.12914 9.75023 2.25027 9.75023Z" fill="#5B5B5B"/>
            </svg>
            
            
        </ng-template>

      </div>
    </div> 

    <div class="full-name-details">
      <div class="full-name-text">
        Create Password
      </div>

      <div>
        <nz-input-group [nzPrefix]="prefixIconPassword"> 
          <input style="margin-left: 15px;" [(ngModel)]="password" type="password" nz-input placeholder="Password" />
        </nz-input-group>

        <ng-template #prefixIconPassword>
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 6.25H9.9375V2.75C9.9375 1.64531 9.04219 0.75 7.9375 0.75H4.0625C2.95781 0.75 2.0625 1.64531 2.0625 2.75V6.25H1C0.723438 6.25 0.5 6.47344 0.5 6.75V12.75C0.5 13.0266 0.723438 13.25 1 13.25H11C11.2766 13.25 11.5 13.0266 11.5 12.75V6.75C11.5 6.47344 11.2766 6.25 11 6.25ZM3.1875 2.75C3.1875 2.26719 3.57969 1.875 4.0625 1.875H7.9375C8.42031 1.875 8.8125 2.26719 8.8125 2.75V6.25H3.1875V2.75ZM10.375 12.125H1.625V7.375H10.375V12.125ZM5.5625 9.95313V10.7813C5.5625 10.85 5.61875 10.9063 5.6875 10.9063H6.3125C6.38125 10.9063 6.4375 10.85 6.4375 10.7813V9.95313C6.56648 9.86052 6.66275 9.72939 6.71245 9.57859C6.76216 9.42779 6.76273 9.26511 6.71409 9.11396C6.66545 8.96282 6.57011 8.831 6.44179 8.73749C6.31346 8.64398 6.15878 8.5936 6 8.5936C5.84122 8.5936 5.68654 8.64398 5.55821 8.73749C5.42989 8.831 5.33455 8.96282 5.28591 9.11396C5.23727 9.26511 5.23784 9.42779 5.28755 9.57859C5.33725 9.72939 5.43352 9.86052 5.5625 9.95313Z" fill="#5B5B5B"/>
            </svg>
            
            
        </ng-template>

      </div>
    </div> 


    <button nz-button nzType="primary" nzDanger style="margin-top: 55px;" (click)="signupFn()">
      Sign Up
   </button>

   <div class="already-member">
    
    <div class="already-member-text">Already a member?</div>
    <div class="sign-in-text" routerLink="/login" style="cursor: pointer;">SIGN IN</div>

   </div>

    
    </div>
    
  </div>



</div> 