<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">

            <div class="row mb-4" style="width: 100%">
                <div class="col-12 d-flex justify-content-end">
                    <nz-range-picker [(ngModel)]="date" (nzOnOk)="onOk($event)"></nz-range-picker>
                </div> 
            </div>

            <div class="row" style="width: 100%">
                <div class="col-md-6 col-lg-3">
                    <nz-card class="pointer" (click)="getDashboardFinanceDetails('quotations_closed')">
                        <div class="media align-items-center">
                            <div>
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33" cy="33" r="33" fill="#3F87F5" fill-opacity="0.19"/>
                                    <path d="M16.3099 14C15.5865 14 15 14.5926 15 15.3237C15 16.0547 15.5865 16.6473 16.3099 16.6473H45.1273V47.402L40.5606 44.6331C40.1463 44.3825 39.6292 44.3825 39.2149 44.6331L33.3384 48.1957L27.4618 44.6331C27.0475 44.3825 26.5304 44.3825 26.1161 44.6331L21.5494 47.402V20.6184C21.5494 19.8873 20.963 19.2947 20.2395 19.2947C19.5161 19.2947 18.9296 19.8873 18.9296 20.6184V49.7391C18.9304 50.767 20.0396 51.4019 20.9124 50.874L26.7889 47.3115L32.6655 50.874C33.0798 51.1247 33.5969 51.1247 34.0112 50.874L39.8878 47.3115L45.7643 50.874C46.6371 51.4019 47.7463 50.767 47.7471 49.7391V16.6473H50.3668C51.0902 16.6473 51.6767 16.0547 51.6767 15.3237C51.6767 14.5926 51.0902 14 50.3668 14C39.0145 14 27.6622 14 16.3099 14Z" fill="#3F87F5"/>
                                    <path d="M25.625 32.8182C25.625 32.8182 28.5341 27 33.625 27C38.7159 27 41.625 32.8182 41.625 32.8182C41.625 32.8182 38.7159 38.6364 33.625 38.6364C28.5341 38.6364 25.625 32.8182 25.625 32.8182Z" stroke="#3F87F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M33.6252 34.9999C34.8302 34.9999 35.807 34.023 35.807 32.818C35.807 31.6131 34.8302 30.6362 33.6252 30.6362C32.4202 30.6362 31.4434 31.6131 31.4434 32.818C31.4434 34.023 32.4202 34.9999 33.6252 34.9999Z" stroke="#3F87F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">{{intro.quotations_closed}}</h2>
                                <p class="m-b-0 text-muted">Quotations Closed</p>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-md-6 col-lg-3">
                    <nz-card class="pointer" (click)="getDashboardFinanceDetails('quotations_open')">
                        <div class="media align-items-center">
                            <div>
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33" cy="33" r="33" fill="#CBFFF6"/>
                                    <path d="M16.3099 14C15.5865 14 15 14.5926 15 15.3237C15 16.0547 15.5865 16.6473 16.3099 16.6473H45.1273V47.402L40.5606 44.6331C40.1463 44.3825 39.6292 44.3825 39.2149 44.6331L33.3384 48.1957L27.4618 44.6331C27.0475 44.3825 26.5304 44.3825 26.1161 44.6331L21.5494 47.402V20.6184C21.5494 19.8873 20.963 19.2947 20.2395 19.2947C19.5161 19.2947 18.9296 19.8873 18.9296 20.6184V49.7391C18.9304 50.767 20.0396 51.4019 20.9124 50.874L26.7889 47.3115L32.6655 50.874C33.0798 51.1247 33.5969 51.1247 34.0112 50.874L39.8878 47.3115L45.7643 50.874C46.6371 51.4019 47.7463 50.767 47.7471 49.7391V16.6473H50.3668C51.0902 16.6473 51.6767 16.0547 51.6767 15.3237C51.6767 14.5926 51.0902 14 50.3668 14C39.0145 14 27.6622 14 16.3099 14Z" fill="#00C9A7"/>
                                    <path d="M40 27L31.0625 36L27 31.9091" stroke="#00C9A7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">{{intro.quotations_open}}</h2>
                                <p class="m-b-0 text-muted">Quotations Open</p>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-md-6 col-lg-3">
                    <nz-card class="pointer" (click)="getDashboardFinanceDetails('total_collection')">
                        <div class="media align-items-center">
                            <div>
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33" cy="33" r="33" fill="#FFF9E6"/>
                                    <path d="M16.3099 14C15.5865 14 15 14.5926 15 15.3237C15 16.0547 15.5865 16.6473 16.3099 16.6473H45.1273V47.402L40.5606 44.6331C40.1463 44.3825 39.6292 44.3825 39.2149 44.6331L33.3384 48.1957L27.4618 44.6331C27.0475 44.3825 26.5304 44.3825 26.1161 44.6331L21.5494 47.402V20.6184C21.5494 19.8873 20.963 19.2947 20.2395 19.2947C19.5161 19.2947 18.9296 19.8873 18.9296 20.6184V49.7391C18.9304 50.767 20.0396 51.4019 20.9124 50.874L26.7889 47.3115L32.6655 50.874C33.0798 51.1247 33.5969 51.1247 34.0112 50.874L39.8878 47.3115L45.7643 50.874C46.6371 51.4019 47.7463 50.767 47.7471 49.7391V16.6473H50.3668C51.0902 16.6473 51.6767 16.0547 51.6767 15.3237C51.6767 14.5926 51.0902 14 50.3668 14C39.0145 14 27.6622 14 16.3099 14Z" fill="#FFC107"/>
                                    <path d="M33.3842 20.6182C32.6607 20.6182 32.0743 21.2108 32.0743 21.9418V23.8188C30.862 24.0643 30.0277 25.1396 29.5696 25.9413C29.2388 26.6009 29.0931 27.3371 29.1526 28.0742C29.2121 28.8112 29.4731 29.5147 29.9022 30.114C30.6846 31.1603 31.7667 31.6892 32.8367 32.221C33.7459 32.6439 34.4825 33.0426 34.822 33.4748C35.4971 34.3643 34.7595 36.0394 33.4865 36.0394C32.3748 36.1321 31.5316 35.3311 31.5217 34.496C31.4804 33.7655 30.8605 33.2073 30.1376 33.2499C29.4157 33.2916 28.8637 33.9164 28.9045 34.646C28.9485 35.4263 29.2141 36.1744 29.6617 36.8124C30.3641 37.7468 31.1799 38.2281 32.0743 38.511V40.4112C32.0743 41.1422 32.6607 41.7348 33.3842 41.7348C34.1076 41.7348 34.694 41.1422 34.694 40.4112V38.4825C35.1024 38.3565 35.4932 38.1785 35.853 37.9396C36.5012 37.5094 37.0246 36.916 37.3624 36.2127C37.7003 35.5093 37.8337 34.7271 37.7462 33.9505C37.6585 33.174 37.355 32.4408 36.8738 31.828C36.0058 30.7228 34.8399 30.2391 33.9316 29.8166C32.985 29.328 31.8552 28.8221 31.7647 27.8596C31.6771 26.8895 32.4418 26.3128 33.2844 26.2515C34.1824 26.183 34.9661 26.7631 35.1187 27.6036C35.199 28.3305 35.8475 28.8538 36.5668 28.7722C37.2839 28.6897 37.7999 28.0365 37.7206 27.3115C37.6392 26.5766 37.3581 25.8815 36.9121 25.295C36.2629 24.5064 35.551 24.0583 34.694 23.7774V21.9418C34.694 21.2108 34.1076 20.6182 33.3842 20.6182Z" fill="#FFC107"/>
                                    </svg>
                                    
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0"> 
                                    ${{intro.total_collection | number: '1.2-2'}}
                                </h2>
                                <p class="m-b-0 text-muted">Total Collection</p>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-md-6 col-lg-3">
                    <nz-card class="pointer" (click)="getDashboardFinanceDetails('to_be_collected')">
                        <div class="media align-items-center">
                            <div>
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33" cy="33" r="33" fill="#F3F0FF"/>
                                    <path d="M16.3099 14C15.5865 14 15 14.5926 15 15.3237C15 16.0547 15.5865 16.6473 16.3099 16.6473H45.1273V47.402L40.5606 44.6331C40.1463 44.3825 39.6292 44.3825 39.2149 44.6331L33.3384 48.1957L27.4618 44.6331C27.0475 44.3825 26.5304 44.3825 26.1161 44.6331L21.5494 47.402V20.6184C21.5494 19.8873 20.963 19.2947 20.2395 19.2947C19.5161 19.2947 18.9296 19.8873 18.9296 20.6184V49.7391C18.9304 50.767 20.0396 51.4019 20.9124 50.874L26.7889 47.3115L32.6655 50.874C33.0798 51.1247 33.5969 51.1247 34.0112 50.874L39.8878 47.3115L45.7643 50.874C46.6371 51.4019 47.7463 50.767 47.7471 49.7391V16.6473H50.3668C51.0902 16.6473 51.6767 16.0547 51.6767 15.3237C51.6767 14.5926 51.0902 14 50.3668 14C39.0145 14 27.6622 14 16.3099 14Z" fill="#886CFF"/>
                                    <path d="M33 39V25" stroke="#886CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M26 32L33 25L40 32" stroke="#886CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">
                                    ${{intro.to_be_collected | number: '1.2-2'}}
                                </h2>
                                <p class="m-b-0 text-muted">To be Collected</p>
                            </div>
                        </div>
                    </nz-card>
                </div>
            </div>

            <div class="row" style="width: 100%">
                <div class="col-12">
                    <nz-card>
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 style="color:#004A7F;">Finance Trend</h5>      
                        </div>

                        <div class="d-flex justify-content-between align-items-center" style="margin-bottom: -20px; margin-top: 20px;">
                            <h6 style="color:#000000;">Collection</h6>
                        </div>
            
                        <div class="m-t-50" style="height: 290px" *ngIf="chartDataLoaded">
                            <canvas ngChartjs 
                                #myCanvas 
                                [datasets]="revenueChartData" 
                                [labels]="revenueChartLabels"
                                [options]="revenueChartOptions" 
                                [colors]="revenueChartColors" 
                                [legend]="false"
                                [chartType]="revenueChartType">
                            </canvas>
                        </div>
                    </nz-card>
                </div>
            </div>

            <div class="row" style="width: 100%;display: none;">
                <div class="col-12">
                    <nz-card>
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 style="color:#004A7F;">Credit Trend</h5>
                        </div>

                         <div class="d-flex justify-content-between align-items-center" style="margin-bottom: -20px; margin-top: 20px;">
                            <h6 style="color:#000000;">Collection</h6>
                        </div>
            
                        <div class="m-t-50" style="height: 290px">
                            <canvas ngChartjs 
                                #myCanvas 
                                [datasets]="revenueChartData" 
                                [labels]="revenueChartLabels"
                                [options]="revenueChartOptions" 
                                [colors]="revenueChartColors" 
                                [legend]="false"
                                [chartType]="revenueChartType">
                            </canvas>
                        </div>
                    </nz-card>
                </div>
            </div>

            <div class="row" style="width: 100%">
                <div class="col-12">
                    <nz-card>
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 style="color:#004A7F;">Open Credits</h5>
                        </div>
                        <div class="m-t-30">
                            <nz-table #openCreditsTable [nzData]="open_credits"  [nzShowPagination]="false" class="no-border-last">
                                <thead>
                                    <tr>
                                        <th>Customer ID</th>
                                        <th>Name</th>
                                        <th>Credit</th>
                                        <th>Credit Limit</th>
                                        <th>Last Paid</th>
                                        <th>Last Paid On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of openCreditsTable.data">
                                        <td>#{{item.id}}</td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <nz-avatar [nzSize]="30" nzIcon="user"></nz-avatar>
                                                <h6 class="m-l-10 m-b-0">{{item.customer_name}}</h6>
                                            </div>
                                        </td>
                                        <td>${{item.balance | number : '1.2-2'}}</td>
                                        <td>${{item.credit_limit | number : '1.2-2'}}</td>
                                        <td>${{item.last_paid | number : '1.2-2'}}</td>
                                        <td>{{item.last_paid_on | date}}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-card>
                </div>
            </div>

            <div class="row" style="width: 100%">
                <div class="col-12">
                    <nz-card>
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 style="color:#004A7F;">Recent Payments</h5>
                        </div> 
                        <div class="m-t-30">
                            <nz-table #recentPaymentsTable [nzData]="recent_payments"  [nzShowPagination]="false" class="no-border-last">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Invoice ID</th>
                                        <th>Invoice Amt.</th>
                                        <th>Paid Amt.</th>
                                        <th>Date</th>
                                        <th>Payment Mode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of recentPaymentsTable.data">
                                        <td>#{{item.id}}</td>
                                        <td>
                                            #{{item.invoice_id}}
                                        </td>
                                        <td>${{item.invoice_total | number : '1.2-2'}}</td>
                                        <td>${{item.amount | number : '1.2-2'}}</td>
                                        <td>{{item.payment_date | date}}</td>
                                        <td>{{item.payment_method}}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </nz-card>
                </div>
            </div>

        </div>
    </div>
</div>

<nz-modal [nzWidth]="920" [(nzVisible)]="quotations_closed_visible" (nzOnCancel)="quotations_closed_visible=false">
    <ng-container *nzModalContent>
        <nz-table #quotationsClosedTable [nzData]="quotations_closed_data">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of quotationsClosedTable.data">
                <td>
                    <a *ngIf="data.order_type==0" href="#" routerLink="/general-servicing-single" [queryParams]="{ order_id: data.order_id}">#{{ data.order_id }}</a>
                    <a *ngIf="data.order_type==1" href="#" routerLink="/accidental-claim-single" [queryParams]="{ order_id: data.order_id}">#{{ data.order_id }}</a>
                </td>
                <td>{{data.date | date}}</td>
                <td>${{data.price | number: '1.2-2'}}</td>
                <td>{{data.remark}}</td>
              </tr>
            </tbody>
          </nz-table>
    </ng-container>
</nz-modal>

<nz-modal [nzWidth]="920" [(nzVisible)]="quotations_open_visible" (nzOnCancel)="quotations_open_visible=false">
    <ng-container *nzModalContent>
        <nz-table #quotationsOpenTable [nzData]="quotations_open_data">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of quotationsOpenTable.data">
                <td>
                    <a *ngIf="data.order_type==0" href="#" routerLink="/general-servicing-single" [queryParams]="{ order_id: data.order_id}">#{{ data.order_id }}</a>
                    <a *ngIf="data.order_type==1" href="#" routerLink="/accidental-claim-single" [queryParams]="{ order_id: data.order_id}">#{{ data.order_id }}</a>
                </td>
                <td>{{data.date | date}}</td>
                <td>${{data.price | number: '1.2-2'}}</td>
                <td>{{data.remark}}</td>
              </tr>
            </tbody>
          </nz-table>
    </ng-container>
</nz-modal>

<nz-modal [nzWidth]="920" [(nzVisible)]="total_collection_visible" (nzOnCancel)="total_collection_visible=false">
    <ng-container *nzModalContent>
        <nz-table #totalCollectionTable [nzData]="total_collection_data">
            <thead>
              <tr>
                <th>Invoice Id</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Payment Method</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of totalCollectionTable.data">
                <td>
                    <a href="#" routerLink="/invoice-single" [queryParams]="{ invoice_id: data.invoice_id}">#{{ data.invoice_id }}</a>
                </td>
                <td>{{data.payment_date | date}}</td>
                <td>${{data.amount | number: '1.2-2'}}</td>
                <td>{{data.payment_method}}</td>
                <td>{{data.remark}}</td>
              </tr>
            </tbody>
          </nz-table>
    </ng-container>
</nz-modal>

<nz-modal [nzWidth]="920" [(nzVisible)]="to_be_collected_visible" (nzOnCancel)="to_be_collected_visible=false">
    <ng-container *nzModalContent>
        <nz-table #toBeCollectedTable [nzData]="to_be_collected_data">
            <thead>
              <tr>
                <th>Customer Id</th>
                <th>Customer Name</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of toBeCollectedTable.data">
                <td>
                    <a href="#" routerLink="/customer-single" [queryParams]="{ customer_id: data.id}">
                        #{{data.id}}
                    </a>
                </td>
                <td>{{data.customer_name}}</td>
                <td>${{data.balance | number: '1.2-2'}}</td>
              </tr>
            </tbody>
          </nz-table>
    </ng-container>
</nz-modal>