import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-masterdata',
  templateUrl: './masterdata.component.html',
  styleUrls: ['./masterdata.component.css']
})
export class MasterdataComponent implements OnInit {

  isClicked:boolean=false;
  activeTab=0;
  listOfData = [];

  listOfVendors = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };

  isLoadingVendors = false;

  listOfCategories = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };



  isLoadingCategories = false;

  openAddCategoryModal = false;
  openAddVendorModal = false;
  editLabourPriceModal = false;

  category_id = 0;
  category_name = '';
  description = '';
  isCategoryEdit = false;

  vendor_id = 0;
  vendor_name = '';
  contact_number = '';
  whatsapp_number = '';
  uen_number = '';
  email = '';
  contact_person = '';
  address = '';
  total_purchasses = 0;
  isVendorEdit = false;

  category_search = '';
  vendor_search = '';
  categoriesTimer = null;
  vendorsTimer = null;
  
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  
  isLoadingLabourPrices: boolean = false;

  labour_prices = [];
  all_labour_prices =[];
  labour_price_id = 0;

  vehicle_type ='';
  cost_per_day ='';
  updated_by_name ='';
  labour_price='';
  vendor_id_search = 0;

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);  
    this.route.queryParams.subscribe((params) => {
      this.vendor_id_search = params.vendor_id;
      this.filterMethodVendors();
    });
    this.filterMethodCategories();
    this.filterMethodLabourPrices();
  }

  changeCategoriesFn() {
    this.categoriesTimer = setTimeout(() => {
      this.filterMethodCategories();
     clearTimeout(this.categoriesTimer); 
    }, 500);
  }

  changeVendorsFn() {
    this.vendorsTimer = setTimeout(() => {
      this.filterMethodVendors();
     clearTimeout(this.vendorsTimer); 
    }, 500);
  }

  filterMethodCategories(page_index=1, page_size=10) {
    this.isLoadingCategories = true;

    var url = '/api/all-categories';

    var data = {
      search: this.category_search,
      page_index,
      page_size,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfCategories = response.data.all_categories;
      this.isLoadingCategories = false;
    })
  }

  onCategoriesChange($event) {
    this.filterMethodCategories($event.pageIndex, $event.pageSize);
  }

  downloadCsvCategories() {
    const { parse } = require('json2csv');
    // let Categories = [...this.listOfCategories.data];
  
    // Categories.forEach((_, index) => {
    //   Categories[index].sr_no = index + 1;
    // });

    const fields = [{
      label: 'Sr. No.',
      value: (row:any) => row.sr_no,
    }, {
      label: 'Category',
      value: (row:any) => row.category_name,
    }, {
      label: 'Description',
      value: (row:any) => row.description,
    }, {
      label: 'Created On',
      value: (row:any) => moment(row.created_at).format('DD/MM/YYYY'),
    }, {
      label: 'Created By',
      value: (row:any) => row.created_by_name,
    }];

    var url = '/api/all-categories';

    var data = {
      search: this.category_search,
      page_index:1,
      page_size:this.listOfCategories.meta.total,
    };

    axios.post(url, data, {}).then(response => {
      // this.listOfCategories = response.data.all_categories;
      let listOfCategories = response.data.all_categories;
      let Categories = [...listOfCategories.data];
  
      Categories.forEach((_, index) => {
        Categories[index].sr_no = index + 1;
      });
      const csv = parse(Categories, { fields });
      require("downloadjs")(csv, 'Categories.csv');
    })
     
    // const csv = parse(Categories, { fields });
    // require("downloadjs")(csv, 'Categories.csv');

  }

  newCategory() {

    if(this.category_name=='') {
      this.msg.error("Please enter category name!")
      return false;
    }

    if(this.description=='') {
      this.msg.error("Please enter category description!")
      return false;
    }

    if(this.category_name.length>50) {
      this.msg.error("Category name should be less than 50 characters!")
      return false;
    }

    if(this.description.length>500) {
      this.msg.error("Category description should be less than 500 characters!")
      return false;
    }

    let url = '';

    if(this.isCategoryEdit) {
      url = '/api/update-category';
    } else {
      url = '/api/new-category';
    }

    let data = {
       category_name: this.category_name,
       category_id: this.category_id,
       description: this.description,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodCategories();
        this.openAddCategoryModal = false;
        this.isCategoryEdit = false;
        this.clearPreviousData();
      } else if(response.data.success==false) {
        if(response.data.message=='Category name already exist') {
          this.msg.error("Category name already exist!");
        } else {
          this.msg.error("You can't edit category name if there are some products that belong to the category!");
        }
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  editCategory(data) {
    this.category_name = data.category_name;
    this.category_id = data.id;
    this.description = data.description;
    
    this.isCategoryEdit = true;
    this.openAddCategoryModal=true;
  }

  deleteCategory(data) {
    var url = '/api/delete-category';

    var data2 = {
       category_id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodCategories();
        this.openAddCategoryModal=false;
        this.msg.success("Category deleted successfully!")
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  filterMethodVendors(page_index=1, page_size=10) {
    this.isLoadingVendors = true;

    var url = '/api/all-vendors';

    var data = {
      search: this.vendor_search,
      page_index,
      page_size,
      vendor_id: this.vendor_id_search,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfVendors = response.data.all_vendors;
      this.isLoadingVendors = false;
    })
  }

  onVendorsChange($event) {
    this.filterMethodVendors($event.pageIndex, $event.pageSize);
  }

  downloadCsvVendor() {

    const { parse } = require('json2csv');
    // let Vendors = [...this.listOfVendors.data];
  
    // Vendors.forEach((_, index) => {
    //   Vendors[index].sr_no = index + 1;
    // });

    const fields = [{
      label: 'Sr. No.',
      value: (row:any) => row.sr_no,
    }, {
      label: 'Company Name',
      value: (row:any) => row.vendor_name,
    }, {
      label: 'Contact person',
      value: (row:any) => row.contact_person,
    }, {
      label: 'Contact',
      value: (row:any) => row.contact_number,
    }, {
      label: 'Address',
      value: (row:any) => row.address,
    }, {
      label: 'Total Business',
      value: (row:any) => row.total_purchasses,
    }, {
      label: 'Created On',
      value: (row:any) => moment(row.created_at).format('DD/MM/YYYY'),
    }, {
      label: 'Created By',
      value: (row:any) => row.created_by_name,
    }];
    
    var url = '/api/all-vendors';

    var data = {
      search: this.vendor_search,
      page_index:1,
      page_size:this.listOfVendors.meta.total,
      vendor_id: this.vendor_id_search,
    };

    axios.post(url, data, {}).then(response => {
      // this.listOfVendors = response.data.all_vendors;
      let listOfVendors = response.data.all_vendors;
      let Vendors = [...listOfVendors.data];
  
      Vendors.forEach((_, index) => {
        Vendors[index].sr_no = index + 1;
      });
      const csv = parse(Vendors, { fields });
      require("downloadjs")(csv, 'Vendors.csv');
    })
    // const csv = parse(Vendors, { fields });
    // require("downloadjs")(csv, 'Vendors.csv');
  }

  newVendor() {

    if(this.vendor_name=='') {
      this.msg.error("Please enter vendor name!");
      return false;
    }

    if(this.contact_number=='') {
      this.msg.error("Please enter contact number!");
      return false;
    }

    if(!this.contact_number.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
      this.msg.error("Please enter valid singapore mobile number!");
      return false;
    }

    if(this.whatsapp_number=='') {
      this.msg.error("Please enter whatsapp number!");
      return false;
    }

    if(this.uen_number=='') {
      this.msg.error("Please enter UEN number!");
      return false;
    }

    if(this.email=='') {
      this.msg.error("Please enter email address!");
      return false;
    }

    if(this.email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )==null) {
      this.msg.error('Please enter a valid email');
      return;
    }

    if(this.contact_person=='') {
      this.msg.error("Please enter contact person!");
      return false;
    }

    if(this.address=='') {
      this.msg.error("Please enter address!");
      return false;
    }

    let url = '';

    if(this.isVendorEdit) {
      url = '/api/update-vendor';
    } else {
      url = '/api/new-vendor';
    }

    let data = {
       vendor_name: this.vendor_name,
       vendor_id: this.vendor_id,
       contact_number: this.contact_number,
       whatsapp_number: this.whatsapp_number,
       uen_number: this.uen_number,
       email: this.email,
       contact_person: this.contact_person,
       address: this.address,
       total_purchasses: this.total_purchasses,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodVendors();
        this.openAddVendorModal = false;
        this.isVendorEdit = false;
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  editVendor(data) {
    this.vendor_name = data.vendor_name;
    this.vendor_id = data.id;
    this.contact_number = data.contact_number;
    this.whatsapp_number = data.whatsapp_number;
    this.uen_number = data.uen_number;
    this.email = data.email;
    this.contact_person = data.contact_person;
    this.address = data.address;
    this.total_purchasses = data.total_purchasses;

    this.isVendorEdit = true;
    this.openAddVendorModal=true;
  }

  deleteVendor(data) {
    var url = '/api/delete-vendor';

    var data2 = {
       vendor_id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodVendors();
        this.openAddVendorModal=false;
        this.isVendorEdit = false;
        this.msg.success("Vendor deleted successfully!")
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  filterMethodLabourPrices(page_index=1, page_size=10) {
    this.isLoadingLabourPrices = true;

    var url = '/api/all-labour-prices';

    var data = {
      page_index,
      page_size,
    };

    axios.post(url, data, {}).then(response => {
      this.labour_prices = response.data.all_labour_prices;
      this.isLoadingLabourPrices = false;
    })
  }

  editLabourPrice(data) {
    this.vehicle_type = data.vehicle_type
    this.cost_per_day = data.cost_per_day;
    this.labour_price_id = data.id;
    
    this.editLabourPriceModal=true;
  }

  saveLabourPrice() {
    var url = '/api/update-labour-prices';

    let data = {
      vehicle_type: this.vehicle_type,
      cost_per_day: this.cost_per_day,
    }

    axios.post(url, data, {}).then(response => {

      this.msg.success("Labour price edited successfully!")
      this.editLabourPriceModal=false;
      this.clearPreviousData();
      this.filterMethodLabourPrices();
    }) 

  }

  clearPreviousData() {
    this.vendor_name = '';
    this.vendor_id = 0;
    this.contact_number = '';
    this.contact_person = '';
    this.address = '';
    this.total_purchasses = 0;
    this.isVendorEdit = false;
    this.whatsapp_number = '';
    this.uen_number = '';
    this.email = '';
  
    this.category_name = '';
    this.category_id = 0;
    this.description = '';
    this.cost_per_day = '';
    this.labour_price_id = 0;
    this.isCategoryEdit = false;
  }

  clearSearches() {
    this.category_search = '';
    this.vendor_search = '';
  } 
}
