import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-all-vehicles',
  templateUrl: './all-vehicles.component.html',
  styleUrls: ['./all-vehicles.component.css']
})
export class AllVehiclesComponent implements OnInit {
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  vehicle_search = '';

  page_index = 1;
  page_size = 10;
  sort_by_field = 'created_at';
  sort_by_type = 'desc';

  filterDrawerVisible = false;
  openAddVehicleModal = false;
  isLoadingVehicles = false;

  product_filters = [
    {
      label: 'Customer Name',
      key: 'customer_name',
      type: 'string',
      value: null,
      condition: "Contains",
    },
    {
      label: 'Category',
      key: 'vehicles.category',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Make',
      key: 'vehicles.make',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Model',
      key: 'vehicles.model',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Vehicle Number',
      key: 'vehicles.vehicle_number',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'COE expiry date',
      key: 'COEExpiryDate',
      type: 'date',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Registration Date',
      key: 'mfg_date',
      type: 'date',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Ownership Date',
      key: 'effectiveDateOfOwnership',
      type: 'date',
      value: null,
      condition: "Equals"
    },
    {
      label: 'GPS',
      key: 'isGPS',
      type: "options",
      value: null,
      condition: "Equals",
      options: [{
        label: 'Yes',
        value: 'true'
      },{
        label: 'No',
        value: 'false'
      }]
    },
    {
      label: 'Seasonal Parking',
      key: 'isSeasonParking',
      type: "options",
      value: null,
      condition: "Equals",
      options: [{
        label: 'Yes',
        value: 'true'
      },{
        label: 'No',
        value: 'false'
      }]
    },
    {
      label: 'Vehicle on Lease',
      key: 'is_vehicle_on_lease',
      type: "options",
      value: null,
      condition: "Equals",
      options: [{
        label: 'Yes',
        value: 1
      },{
        label: 'No',
        value: 0
      }]
    }
  ];

  listOfVehicles = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };

  uploadingFile = false;

  vehicle_refills = [];
  displayRefillPreview = false;
  uploadingRefillData = false;
  base_url = 'http://localhost:3333';

  downloadVehicleCSV = false;

  vehicle_id_search = 0;

  allChecked = false;
  allCheckedIndeterminate = false;
  setOfCheckedId = [];

  printVehiclesData = [];
  showPrintModal = false;

  show_scrapped = false;

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);  

    this.route.queryParams.subscribe((params) => {
      this.vehicle_id_search = params.vehicle_id;
      this.filterMethodVehicles();
    });

    if(window.location.hostname=="localhost") {
      this.base_url = 'http://localhost:3333'; 
    } else if(window.location.hostname.includes("reddotapps")) {
      this.base_url = 'https://bigfoot.reddotapps.com.sg'; 
    } else {
      this.base_url = 'https://vmo.bigfoot.com.sg'; 
    }
  }

  downloadCsvVehicles(): void {
    this.downloadVehicleCSV = true;
    this.filterMethodVehicles();
  }

  filterMethodVehicles() {
    this.isLoadingVehicles = true;

    var url = '/api/all-vehicles-with-filters';

    const last_page_size = this.page_size;

    if(this.downloadVehicleCSV) {
      this.page_size = 1000000;
    }

    var data = {
      search: this.vehicle_search,
      page_index: this.page_index,
      page_size: this.page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters: [],
      vehicle_id: this.vehicle_id_search,
      show_scrapped: this.show_scrapped
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          
          if(element.condition === 'Range') {
            if(element.value.length>0) {
              return {
                key: element.key,
                value: [moment(element.value[0]).format('YYYY-MM-DD'), moment(element.value[1]).format('YYYY-MM-DD')],
                condition: element.condition
              }
            }
          } else {
            return {
              key: element.key,
              value: moment(element.value).format('YYYY-MM-DD'),
              condition: element.condition
            }
          }

        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      this.listOfVehicles = response.data.all_vehicles;
      this.isLoadingVehicles = false;
      this.filterDrawerVisible = false;

      if(this.downloadVehicleCSV) {
        const { parse } = require('json2csv');
        const Vehicles = this.listOfVehicles.data;
        const fields = [{
          label: 'Customer',
          value: (row:any) => row.customer_name,
        }, {
          label: 'Vehicle Number',
          value: (row:any) => row.vehicle_number,
        }, {
          label: 'Category',
          value: (row:any) => row.category,
        }, {
          label: 'Make',
          value: (row:any) => row.make,
        }, {
          label: 'Model',
          value: (row:any) => row.model,
        }, {
          label: 'Mfg Date',
          value: (row:any) => row.mfg_date ? moment(row.mfg_date).format('DD/MM/YYYY') : '',
        }, {
          label: 'Last Serviced On',
          value: (row:any) => row.last_serviced_on ? moment(row.last_serviced_on).format('DD/MM/YYYY') : ''
        }, {
          label: 'Last Service ID',
          value: (row:any) => row.last_service_id,
        }, {
          label: 'Log Card',
          value: (row:any) => row.logcard,
        }];
    
        const csv = parse(Vehicles, { fields });
        require("downloadjs")(csv, 'Vehicles.csv');
        this.downloadVehicleCSV = false;
        this.page_size = last_page_size;
        this.filterMethodVehicles();
      }
    })
  }

  onParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(item => item.value !== null);
    this.sort_by_field = (currentSort && currentSort.key) || 'created_at';
    this.sort_by_type = (currentSort && currentSort.value) || 'desc';
    this.page_index = pageIndex;
    this.page_size = pageSize;
    this.filterMethodVehicles();
  }

  clearFilters() {
    this.product_filters.forEach(element => {
      element.value = null;
    });
    this.filterMethodVehicles();
  }

  handleChange(info: NzUploadChangeParam) {
    this.uploadingFile = true;
    if (info.file.status === 'done') {
      this.uploadingFile = false;
      if (info.file.response) {
        if(info.file.response.success) {
          this.vehicle_refills = info.file.response.vehicle_refills;
          this.displayRefillPreview = true;
        } else {
          this.msg.error(info.file.response.message, {nzDuration: 10000});
        }
      }
    } else if (info.file.status === 'error') {
      this.uploadingFile = false;
    }
  }

  uploadVehicleRefills() {
    this.uploadingRefillData = true;
    var url = '/api/upload-vehicle-refills';
    var data = {
      vehicle_refills: this.vehicle_refills
    };

    axios.post(url, data, {}).then(response => {
      this.uploadingRefillData = false;
      this.msg.success("Data uploaded successfully");
      this.displayRefillPreview = false;
      this.vehicle_refills = [];
    })
    .catch(error => {
      this.uploadingRefillData = false;
      this.msg.error("Error uploading data");
    });
  }

  onAllChecked(checked: boolean): void {
    this.listOfVehicles.data
      .forEach(({ id }) => ()=>{
        if(checked) {
          this.setOfCheckedId = [...this.setOfCheckedId, id];
        } else {
          this.setOfCheckedId = this.setOfCheckedId.filter(item => item !== id);
        }
      });
    this.refreshCheckedStatus();
  }
  
  refreshCheckedStatus() {
    const listOfEnabledData = this.listOfVehicles.data;
    this.allChecked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.includes(id));
    this.allCheckedIndeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.includes(id)) && !this.allChecked;
  }

  onItemChecked(id: number, checked: boolean): void {
    if(checked) {
      this.setOfCheckedId = [...this.setOfCheckedId, id];
    } else {
      this.setOfCheckedId = this.setOfCheckedId.filter(item => item !== id);
    }
  }

  printSelected() {
    let vehicles = this.listOfVehicles.data.filter(element => this.setOfCheckedId.includes(element.id));
    this.printVehiclesData = vehicles;
    this.showPrintModal = true;
  }

  finalPrint() {
    const printJS = require('print-js')
    printJS({
      printable: 'print-section', 
      type: 'html',
      css: [
        'https://cdnjs.cloudflare.com/ajax/libs/antd/4.20.7/antd.min.css'
      ],  
    })
  }
}
