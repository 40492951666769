<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
          <nz-card>

            <nz-tabset>
              <nz-tab nzTitle="Surveyors">
                  <div class="breadcrumbs-add-btn-bar mt-4">
                      <div class="title-breadrumbs-container">
                        <div class="title">Surveyors</div>
                      </div>
                      <div class="doc-search-btn">
                        <div class="icon-download">
                            <div style="margin-right: 10px;">
                              <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                                <path d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                                <path d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                                <path d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                                </svg>
                                    
                            </div>
                            <div  class="download-text" (click)="downloadCsvSurveyor()">
                                Download CSV
                            </div>
                        </div>
            
                        <div class="search-box">
                            <nz-input-group [nzPrefix]="prefixIconSearch" style="min-height: 42px;">
                                <input type="text" nz-input (ngModelChange)="changeSurveyorsFn()" [(ngModel)]="surveyor_search" placeholder="Search..." />
                              </nz-input-group>
            
                              <ng-template #prefixIconSearch>
                                <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right:5px;">
                                    <path d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                              </ng-template>
                        </div>
            
                        <button (click)="openAddSurveyorModal=true" nz-button nzType="primary">Add Surveyor</button>
            
                        <nz-modal [(nzVisible)]="openAddSurveyorModal" nzTitle="Add New Surveyor" 
                        (nzOnCancel)="openAddSurveyorModal=false;clearPreviousData()" style="justify-content: center; align-items: center;">
                          
                        <div class="input-group">
                            <div class="input-label">Surveyor Name</div>
                            <input nz-input placeholder="Surveyor Name" [(ngModel)]="surveyor_name" />
                        </div>
            
                        <div class="input-group">
                            <div class="input-label">Contact Number</div>
                            <input nz-input placeholder="Contact Number" [(ngModel)]="surveyor_contact_no" />
                        </div>

                        <div class="input-group">
                            <div class="input-label">Email</div>
                            <input nz-input placeholder="Email" [(ngModel)]="surveyor_email" />
                        </div>

                        <div class="input-label">Company</div>
            
                        <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="insurance_company_id">
                            <nz-option *ngFor="let insurance_company of listOfInsuranceCompanies;" 
                            [nzValue]="insurance_company.id" [nzLabel]="insurance_company.company_name"></nz-option>
                        </nz-select>
            
                        <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary" (click)="newSurveyor()" >SAVE</button>
                        
                        </nz-modal>
            
                    </div>
                  </div>

                  <div class='stats__table'>
                    <nz-table  #surveyorsTable 
                    [nzData]="listOfSurveyors.data" 
                    nzTableLayout="fixed"
                    nzShowSizeChanger
                    [nzLoading]="isLoadingSurveyors"
                    [nzFrontPagination]="false"
                    [nzTotal]="listOfSurveyors.meta.total"
                    [nzPageSize]="listOfSurveyors.meta.per_page"
                    [nzPageIndex]="listOfSurveyors.meta.current_page"
                    (nzQueryParams)="onSurveyorsChange($event)"
                    >
                      <thead>
                        <tr>
                          <th nzLeft>Sr. No</th>
                          <th nzLeft>Surveyor Name</th>
                          <th>Surveyor Company</th>
                          <th>Contact No</th>
                          <th>Created On</th>
                          <th nzRight></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of surveyorsTable.data; let i=index;">
                          <td nzLeft>{{ i+1 }}</td>
                          <td nzLeft>{{ data.surveyor_name }}</td>
                          <td>{{ data.surveyor_company }}</td>
                          <td>{{ data.surveyor_contact_no }}</td>
                          <td>{{ data.created_at | date: 'mediumDate'}}</td>

                          <td nzRight>
                            <div style="display: flex;">
                              <div style="margin-right: 15px;cursor: pointer;" (click)="editSurveyor(data)">
                                <svg width="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </div>
                
                              <div style="cursor: pointer;" 
                              nz-popconfirm
                              nzPopconfirmTitle="Are you sure delete this surveyor?"
                              (nzOnConfirm)="deleteSurveyor(data)">
                                <svg style="position: relative;top: 2px;" width="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M7 7L13 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M13 7L7 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </div>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </nz-table>
                  </div>
              </nz-tab>
              <nz-tab nzTitle="Insurance Companies">
                <div class="breadcrumbs-add-btn-bar mt-4">
                  <div class="title-breadrumbs-container">
                    <div class="title">Insurance Companies</div>
                  </div>
                  <div class="doc-search-btn">
                    <div class="icon-download">
                        <div style="margin-right: 10px;">
                          <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                            <path d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                            <path d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                            <path d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                            </svg>
                                
                        </div>
                        <div  class="download-text" (click)="downloadCsvInsuranceCompanies()">
                            Download CSV
                        </div>
                    </div>
        
                    <div class="search-box">
                        <nz-input-group [nzPrefix]="prefixIconSearch" style="min-height: 42px;">
                            <input type="text" nz-input (ngModelChange)="changeInsuranceCompanyFn()" [(ngModel)]="surveyor_search" placeholder="Search..." />
                          </nz-input-group>
        
                          <ng-template #prefixIconSearch>
                            <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right:5px;">
                                <path d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                          </ng-template>
                    </div>
        
                    <button (click)="openInsuranceCompanyModal=true" nz-button nzType="primary">Add Insurance Company</button>
        
                    <nz-modal [(nzVisible)]="openInsuranceCompanyModal" nzTitle="New Insurance Company" 
                    (nzOnCancel)="openInsuranceCompanyModal=false;clearPreviousData()" style="justify-content: center; align-items: center;">
                      
                    <div class="input-group">
                        <div class="input-label">Company Name</div>
                        <input nz-input placeholder="Company Name" [(ngModel)]="company_name" />
                    </div>
        
                    <div class="input-group">
                        <div class="input-label">Contact Person</div>
                        <input nz-input placeholder="Contact Person" [(ngModel)]="contact_person" />
                    </div>
        
                    <div class="input-group">
                        <div class="input-label">Contact Number</div>
                        <input nz-input placeholder="Contact Number" [(ngModel)]="contact_number" />
                    </div>

                    <div class="input-group">
                        <div class="input-label">Email</div>
                        <input nz-input placeholder="Email" [(ngModel)]="email" />
                    </div>
        
                    <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary" (click)="newInsuranceCompany()" >SAVE</button>
                    
                    </nz-modal>
        
                </div>
                </div>

                <div class='stats__table'>
                  <nz-table  #insuranceCompanyTable 
                  [nzData]="listOfInsuranceCompanies" 
                  nzTableLayout="fixed"
                  nzShowSizeChanger
                  [nzLoading]="isLoadingInsuranceCompanies"
                  [nzFrontPagination]="false"
                  >
                    <thead>
                      <tr>
                        <th nzLeft>Sr. No</th>
                        <th>Company Name</th>
                        <th>Contact Person</th>
                        <th>Contact No</th>
                        <th>Email</th>
                        <th>Created On</th>
                        <th>Created By</th>
                        <th nzRight></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of insuranceCompanyTable.data;let i = index;">
                        <td nzLeft>{{ i+1 }}</td>
                        <td>{{ data.company_name }}</td>
                        <td>{{ data.contact_person }}</td>
                        <td>{{ data.contact_number }}</td>
                        <td>{{ data.email }}</td>
                        <td>{{ data.created_at | date: 'mediumDate'}}</td>
                        <td>{{ data.created_by_name }}</td>

                        <td nzRight>
                          <div style="display: flex;">
                            <div style="margin-right: 15px;cursor: pointer;" (click)="editInsuranceCompany(data)">
                              <svg width="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
              
                            <div style="cursor: pointer;" 
                            nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this insurance company?"
                            (nzOnConfirm)="deleteInsuranceCompany(data)">
                              <svg style="position: relative;top: 2px;" width="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7 7L13 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13 7L7 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                          </div>
                        </td>

                      </tr>
                    </tbody>
                  </nz-table>
                </div>
              </nz-tab>
            </nz-tabset>

          </nz-card>
        </div>
      </div>
  </div>
