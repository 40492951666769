import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzGridModule } from 'ng-zorro-antd/grid';
import axios from 'axios';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

declare const gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    name = '';
    username = '';
    email = '';
    password_confirmation = '';
    password = '';
    page = 1;
    remember_password=false;
    showForgerPassword = false;
    isLoggedIn = false;

    constructor(private message: NzMessageService, private router: Router) {
      axios.interceptors.response.use((response) => {
        return response;
      }, (error) => {
          if(error.response.status=="500") {
            if(error.response.data.message) {
              if(error.response.data.message.includes("foreign key")) {
                this.createErrorMessage("You are not allowed to create or delete this record, foreign key constraint failed");
              }
            }
          }
          if(error.response.status=="403") {
            this.createErrorMessage("You do not have enough permissions to perform this action!");
          }
          if(error.response.status=="401") {
            localStorage.removeItem("token");
            //this.createErrorMessage("Unauthorized/Expired Access, please login again to continue!");
            this.router.navigate(['/login']);
          }
        return Promise.reject(error);
      });

      console.log(window.location.hostname);

      if(window.location.hostname=="localhost") {
        //  axios.defaults.baseURL = 'http://localhost:3333';
        axios.defaults.baseURL = 'https://hc-api.reddotapps.com.sg/';
      } else if(window.location.hostname.includes("reddotapps")) {
        axios.defaults.baseURL = 'https://hc-api.reddotapps.com.sg/'; 
      } else {
        axios.defaults.baseURL = 'https://hc-api.reddotapps.com.sg/'; 
      }

      axios.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.getItem('token');

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
         gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
         })
         const user_id = localStorage.getItem('id');
         if(user_id) {
          gtag('config', 'GA_MEASUREMENT_ID', {
            'user_id': user_id
          });
          gtag('set', {'user_id': user_id});
        }
      })
    }

    createSuccessMessage(msg: string): void {
        this.message.create('success', msg);
    }
    
    createErrorMessage(msg: string): void {
        this.message.create('error', msg);
    }

    ngOnInit () {
      if(localStorage.getItem("token")) {
        this.requestPermission();
        this.listen();
      }
    }

    
    requestPermission() {
      const messaging = getMessaging();
      getToken(messaging, 
       { vapidKey: environment.firebase.vapidKey}).then(
         (currentToken) => {
           if (currentToken) {
            axios.post('/api/assign-fcm-token', {
              fcm_token: currentToken,
              device_type: "web_vmo"
            }).then(response => {
              localStorage.setItem("fcm_token", currentToken);
              console.log(response);
            }).catch(error => {
              console.log(error);
            });
           } else {
             console.log('No registration token available. Request permission to generate one.');
           }
       }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
      });
    }
    listen() {
      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
      });
    }
}