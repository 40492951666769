<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
          <nz-card>

            <div class="breadcrumbs-add-btn-bar">

                <div class="title-breadrumbs-container">
                    <div class="title">Disposed Items</div>
                </div>

                <div class="doc-search-btn">
                    <div class="icon-download">
                        <div style="margin-right: 10px;">
                          <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                            <path d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                            <path d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                            <path d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                            </svg>
                                
                        </div>
                        <div class="download-text" (click)="downloadCsvTransactions()">
                            Download CSV
                        </div>
                    </div>

                    <div class="search-box">
                      <nz-range-picker [(ngModel)]="dates" (ngModelChange)="onDateChange($event)"></nz-range-picker>
                    </div>

                </div>

            </div>

            <div class='stats__table'>
                <nz-table  #sortTable 
                [nzData]="listOfTransactions.data" 
                nzTableLayout="fixed"
                nzShowSizeChanger
                [nzLoading]="isLoadingTransactions"
                [nzFrontPagination]="false"
                [nzTotal]="listOfTransactions.meta.total"
                [nzPageSize]="listOfTransactions.meta.per_page"
                [nzPageIndex]="listOfTransactions.meta.current_page"
                (nzQueryParams)="onTransactionsChange($event)"
                 >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th nzLeft>Image</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of sortTable.data">
                      <td>{{ data.created_at | date: 'mediumDate' }}</td>
                      <td nzLeft><img src="{{ data.product_image }}" style="max-height: 30px;"/></td>
                      <td>{{ data.product_name }}</td>
                      <td>
                        {{ data.quantity }}
                      </td>
                      <td>{{ data.price | number : '1.2-2' }}</td>
                      <td>{{ data.quantity*data.price | number : '1.2-2' }}</td>
                    </tr>
                  </tbody>
                </nz-table>
            </div>

          </nz-card>
      </div>
  </div>
</div>