import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-order-masters',
  templateUrl: './order-masters.component.html',
  styleUrls: ['./order-masters.component.css']
})
export class OrderMastersComponent implements OnInit {

  isClicked:boolean=false;
  activeTab=0;

  listOfSubType = [];

  isLoadingSubType = false;
  openAddSubTypeModal = false;

  id = 0;
  name = '';
  iSubTypeEdit = false;

  sub_type_search = '';
  vendor_search = '';
  categoriesTimer = null;
  vendorsTimer = null;
  
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);  
    this.filterMethodSubType();
  }

  changeCategoriesFn() {
    this.categoriesTimer = setTimeout(() => {
      this.filterMethodSubType();
     clearTimeout(this.categoriesTimer); 
    }, 500);
  }

  filterMethodSubType(page_index=1, page_size=10) {
    this.isLoadingSubType = true;

    var url = '/api/all-order-sub-types';

    var data = {
      search: this.sub_type_search,
      page_index,
      page_size,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfSubType = response.data.all_order_sub_types;
      this.isLoadingSubType = false;
    })
  }

  downloadCsvCategories() {
    const { parse } = require('json2csv');
    // let Categories = [...this.listOfSubType];
  
    // Categories.forEach((_, index) => {
    //   Categories[index].sr_no = index + 1;
    // });

    const fields = [{
      label: 'Sr. No.',
      value: (row:any) => row.sr_no,
    }, {
      label: 'Name',
      value: (row:any) => row.name,
    }, {
      label: 'Created On',
      value: (row:any) => moment(row.created_at).format('DD/MM/YYYY'),
    }, {
      label: 'Created By',
      value: (row:any) => row.created_by_name,
    }];

    var url = '/api/all-order-sub-types';

    var data = {
      search: this.sub_type_search,
      page_index: 1,
      page_size: this.listOfSubType.length,
    };

    axios.post(url, data, {}).then(response => {
      // this.listOfSubType = response.data.all_order_sub_types;
      let listOfSubType = response.data.all_order_sub_types;
      let Categories = [...listOfSubType];
  
      Categories.forEach((_, index) => {
        Categories[index].sr_no = index + 1;
      });
      const csv = parse(Categories, { fields });
      require("downloadjs")(csv, 'Categories.csv');
    })
     
    // const csv = parse(Categories, { fields });
    // require("downloadjs")(csv, 'Categories.csv');

  }

  newSubType() {

    if(this.name=='') {
      this.msg.error("Please enter category name!")
      return false;
    }

    let url = '';

    if(this.iSubTypeEdit) {
      url = '/api/update-order-sub-type';
    } else {
      url = '/api/new-order-sub-type';
    }

    let data = {
       name: this.name,
       id: this.id
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodSubType();
        this.openAddSubTypeModal = false;
        this.iSubTypeEdit = false;
        this.clearPreviousData();
      } else if(response.data.success==false) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  editSubType(data) {
    this.name = data.name;
    this.id = data.id;
    
    this.iSubTypeEdit = true;
    this.openAddSubTypeModal=true;
  }

  deleteSubType(data) {
    var url = '/api/delete-order-sub-type';

    var data2 = {
       id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodSubType();
        this.openAddSubTypeModal=false;
        this.msg.success("Category deleted successfully!")
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  clearPreviousData() {
    this.name = '';
    this.id = 0;
  }

  clearSearches() {
    this.sub_type_search = '';
  } 
}
