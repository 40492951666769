import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-disposable-products',
  templateUrl: './disposable-products.component.html',
  styleUrls: ['./disposable-products.component.css']
})
export class DisposableProductsComponent implements OnInit {


  createNewPOModal = false;
  listOfData = [];
  dates = [
    moment().subtract(1, 'month').toDate(),
    moment().toDate()
  ]

  constructor(private themeService: ThemeConstantService) { }

  listOfTransactions = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  isLoadingTransactions = false;
  transactions_sort_by_field = 'created_at';
  transactions_sort_by_type = 'desc';
  transaction_search = '';
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);   
    this.filterMethodTransactions();
  }

  filterMethodTransactions(page_index=1, page_size=10) {
    this.isLoadingTransactions = true;

    var url = '/api/all_disposable_inventories';

    var data = {
      search: this.transaction_search,
      page_index: page_index,
      page_size: page_size,
      start_date: moment(this.dates[0]).format('YYYY-MM-DD'),
      end_date: moment(this.dates[1]).format('YYYY-MM-DD'),
    };

    axios.post(url, data, {}).then(response => {
      this.listOfTransactions = response.data.all_disposable_inventories;
      this.isLoadingTransactions = false;
    })
  }

  onDateChange(e) {
    this.filterMethodTransactions();
  }

  onTransactionsChange($event) {
    this.filterMethodTransactions($event.pageIndex, $event.pageSize);
  }

  downloadCsvTransactions() {
    const { Parser } = require('json2csv');
    // const myCars = this.listOfTransactions.data;
    const json2csvParser = new Parser();
    var url = '/api/all_disposable_inventories';

    var data = {
      search: this.transaction_search,
      page_index: 1,
      page_size: this.listOfTransactions.meta.total,
      start_date: moment(this.dates[0]).format('YYYY-MM-DD'),
      end_date: moment(this.dates[1]).format('YYYY-MM-DD'),
    };

    axios.post(url, data, {}).then(response => {
      // this.listOfTransactions = response.data.all_disposable_inventories;
      var listOfTransactions = response.data.all_disposable_inventories;

      if(listOfTransactions.data.length > 0){
        const myCars = listOfTransactions.data;
        const csv = json2csvParser.parse(myCars);
        require("downloadjs")(csv, 'diposable-inventory.csv');
      } else {
        console.log("Download not possible for empty data!");
      }
    })
    // const csv = json2csvParser.parse(myCars);
    // require("downloadjs")(csv, 'diposable-inventory.csv');
  }

}
