import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  selectedValue = null

  constructor() { }
  activeTab = 0;
  date = null;
  revenueChartFormat: string = 'revenueMonth';

  revenueChartData: Array<any> = [{ 
      data: [30, 60, 40, 50, 40, 55, 85, 65, 75, 50, 70],
      label: 'Series A' 
  }];
  currentrevenueChartLabelsIdx = 1;
  revenueChartLabels:Array<any> = ["16th", "17th", "18th", "19th", "20th", "21th", "22th", "23th", "24th", "25th", "26th"];
  revenueChartOptions: any = {
      maintainAspectRatio: false,
      responsive: true,
      hover: {
          mode: 'nearest',
          intersect: true
      },
      tooltips: {
          mode: 'index'
      },
      scales: {
          xAxes: [{ 
              gridLines: [{
                  display: false,
              }],
              ticks: {
                  display: true,
                  fontColor: '#77838f',
                  fontSize: 13,
                  padding: 10
              }
          }],
          yAxes: [{
              gridLines: {
                  drawBorder: false,
                  drawTicks: false,
                  borderDash: [3, 4],
                  zeroLineWidth: 1,
                  zeroLineBorderDash: [3, 4]  
              },
              ticks: {
                  display: true,
                  max: 100,                            
                  stepSize: 20,
                  fontColor: '#77838f',
                  fontSize: 13,
                  padding: 10
              }  
          }],
      }
  };
  revenueChartColors: Array<any> = [
      { 
          backgroundColor: 'rgba(255, 255, 255, 0)',
          borderColor: "#05c9a7",
          pointBackgroundColor: "#05c9a7",
          pointBorderColor: '#ffffff',
          pointHoverBackgroundColor: 'rgba(0, 201, 167, 0.1)',
          pointHoverBorderColor: 'rgba(0, 201, 167, 0.1)'
      }
  ];
  revenueChartType = 'line';

  
  ordersList = [
    {
        id: 5331,
        name: 'Erin Gonzales',
        avatar: 'assets/images/avatars/thumb-1.jpg',
        date: '8 May 2019',
        amount: 137,
        status: 'In Stock',
        checked : false
    },
    {
        id: 5375,
        name: 'Darryl Day',
        avatar: 'assets/images/avatars/thumb-2.jpg',
        date: '6 May 2019',
        amount: 322,
        status: 'Out of Stock',
        checked : false
    },
    {
        id: 5762,
        name: 'Marshall Nichols',
        avatar: 'assets/images/avatars/thumb-3.jpg',
        date: '1 May 2019',
        amount: 543,
        status: 'Out of Stock',
        checked : false
    },
    {
        id: 5865,
        name: 'Virgil Gonzales',
        avatar: 'assets/images/avatars/thumb-4.jpg',
        date: '28 April 2019',
        amount: 876,
        status: 'In Stock',
        checked : false
    },
    {
        id: 5213,
        name: 'Nicole Wyne',
        avatar: 'assets/images/avatars/thumb-5.jpg',
        date: '28 April 2019',
        amount: 241,
        status: 'In Stock',
        checked : false
    }
]   


  ngOnInit(): void {
  }

  onChange(result: Date[]): void {
    console.log('onChange: ', result);
  }

}
