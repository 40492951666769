<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
          <nz-card>

            <div class="breadcrumbs-add-btn-bar">

                <div class="title-breadrumbs-container">

                    <div class="title">All Invoices</div>
        
                    <!-- <div class="breadcrumbs">
                        <div class="breadcrumbs-text" style="margin-right: 10px;">Invoices</div>
                        <div>
                            <svg width="6" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 10px;position:relative;top:1px;">
                                <path d="M5.97304 4.14618L0.690235 0.0200133C0.676431 0.00914556 0.659841 0.00239175 0.642372 0.00052741C0.624902 -0.00133693 0.607261 0.00176383 0.591474 0.00947365C0.575687 0.0171835 0.562396 0.0291897 0.553125 0.0441133C0.543854 0.0590369 0.53898 0.0762728 0.539064 0.0938414V0.999701C0.539064 1.05712 0.566017 1.1122 0.610548 1.14736L4.82929 4.4415L0.610548 7.73564C0.564845 7.77079 0.539064 7.82587 0.539064 7.88329V8.78915C0.539064 8.86767 0.629298 8.91103 0.690235 8.86298L5.97304 4.73681C6.01794 4.70179 6.05427 4.65698 6.07925 4.60581C6.10423 4.55464 6.11722 4.49844 6.11722 4.4415C6.11722 4.38455 6.10423 4.32836 6.07925 4.27718C6.05427 4.22601 6.01794 4.18121 5.97304 4.14618Z" fill="#979797"/>
                              </svg>
                        </div>
                        <div class="breadcrumbs-text" style="margin-right: 10px;">All Invoices</div>
                    </div>  -->

                </div>

                <div class="doc-search-btn">
                    <div class="icon-download">
                        <div style="margin-right: 10px;">
                          <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                            <path d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                            <path d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                            <path d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                            </svg>
                                
                        </div>
                        <div class="download-text" (click)="downloadCsvInvoices()">
                            Download CSV
                        </div>
                    </div>

                    <div class="search-box">
                        <nz-input-group [nzPrefix]="prefixIconSearch" >
                            <input type="text" nz-input placeholder="Search..." [(ngModel)]="invoice_search" 
                            (ngModelChange)="filterMethodInvoices()" />
                          </nz-input-group>

                          <ng-template #prefixIconSearch>
                            <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right:5px;">
                                <path d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> 
                          </ng-template>
                    </div>
                    <button nz-button class="filter-btn" (click)="filterDrawerVisible=true">
                      <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="841"><path d="M912.83888 168.576q9.728 23.424-8 40l-281.728 281.728 0 424q0 24-22.272 33.728-7.424 2.848-14.272 2.848-15.424 0-25.728-10.848l-146.272-146.272q-10.848-10.848-10.848-25.728l0-277.728-281.728-281.728q-17.728-16.576-8-40 9.728-22.272 33.728-22.272l731.424 0q24 0 33.728 22.272z" p-id="842"></path></svg>
                    </button>

                    <nz-drawer
                      [nzBodyStyle]="{ overflow: 'auto' }"
                      [nzWidth]="550"
                      [nzVisible]="filterDrawerVisible"
                      nzTitle="Apply Filters"
                      [nzFooter]="footerTpl"
                      (nzOnClose)="filterDrawerVisible=false;"
                    >
                      <div *nzDrawerContent class="pb-5">
                        
                        <div class="input-group" *ngFor="let product_filter of product_filters">

                          <div class="input-label">{{product_filter.label}}</div>

                          <div class="d-flex">

                            <nz-select *ngIf="product_filter.type=='string'" class="mr-3 w-50" nzShowSearch nzPlaceHolder="Operators" [(ngModel)]="product_filter.condition">
                              <nz-option nzLabel="Contains" nzValue="Contains"></nz-option>
                              <nz-option nzLabel="Equals" nzValue="Equals"></nz-option>
                              <nz-option nzLabel="Starts With" nzValue="Starts With"></nz-option>
                              <nz-option nzLabel="End With" nzValue="End With"></nz-option>
                            </nz-select>

                            <nz-select *ngIf="product_filter.type=='number'" class="mr-3 w-50" nzShowSearch nzPlaceHolder="Operators" [(ngModel)]="product_filter.condition">
                              <nz-option nzLabel="Equals" nzValue="Equals"></nz-option>
                              <nz-option nzLabel="More Than" nzValue="More Than"></nz-option>
                              <nz-option nzLabel="Less Than" nzValue="Less Than"></nz-option>
                            </nz-select>

                            <nz-select *ngIf="product_filter.type=='date'" class="mr-3 w-50" nzShowSearch nzPlaceHolder="Operators" [(ngModel)]="product_filter.condition">
                              <nz-option nzLabel="Equals" nzValue="Equals"></nz-option>
                              <nz-option nzLabel="More Than" nzValue="More Than"></nz-option>
                              <nz-option nzLabel="Less Than" nzValue="Less Than"></nz-option>
                            </nz-select>

                            <nz-select nzAllowClear *ngIf="product_filter.type=='options'" class="w-100" nzShowSearch nzPlaceHolder="Options" [(ngModel)]="product_filter.value">
                              <nz-option *ngFor="let option of product_filter.options" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                            </nz-select>

                            <nz-date-picker [nzFormat]="'dd-MM-yyyy'" class="w-100" *ngIf="product_filter.type=='date'" [(ngModel)]="product_filter.value"></nz-date-picker>

                            <input nz-input 
                            *ngIf="product_filter.type=='string'" 
                            [placeholder]="product_filter.label" 
                            [(ngModel)]="product_filter.value"
                            (ngModelChange)="product_filter.value=='' ? product_filter.value=null : null"
                            />

                            <nz-input-number 
                            class="w-100"
                            *ngIf="product_filter.type=='number'" 
                            [nzPlaceHolder]="product_filter.label" 
                            [(ngModel)]="product_filter.value"
                            (ngModelChange)="product_filter.value==='' ? product_filter.value=null : null"
                            [nzStep]="100"></nz-input-number>

                          </div>
                        </div>

                      </div>

                      <ng-template #footerTpl>
                        <div style="float: right">
                          <button nz-button style="margin-right: 8px;" (click)="clearFilters()">Clear</button>
                          <button nz-button nzType="primary" (click)="filterMethodInvoices()">Apply</button>
                        </div>
                      </ng-template>
                    </nz-drawer>
                    <div class="add-product-btn">
                      <button (click)="openAddInvoiceModal=true" nz-button nzType="primary">Add New</button>
        
                      <nz-modal [nzWidth]="800" [(nzVisible)]="openAddInvoiceModal" nzTitle="Add New Invoice" 
                      (nzOnCancel)="openAddInvoiceModal=false;clearPrevData();" style="justify-content: center; align-items: center;">
                        
                        <div nz-row [nzGutter]="[12,36]">
                          <div nz-col nzSpan="12">
                            <div class="input-group">
                              <div class="input-label">Order ID</div>
                              
                              <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Order ID" [nzDisabled]="isInvoiceEdit" [(ngModel)]="new_invoice_order_id" style="width: 100%;" (ngModelChange)="loadOrderDetail($event)">
                                <nz-option *ngFor="let order of orders;" nzLabel="#{{order.id}}" [nzValue]="order.id"></nz-option>
                              </nz-select>

                            </div>

                      
          
                          <div class="input-group">
                            <div class="input-label">Invoice Date</div>
                            <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;" [(ngModel)]="invoice_date"></nz-date-picker>
                        </div>

                      
                          </div>

                          <div nz-col nzSpan="12">

                            <div class="input-group">
                                <div class="input-label">Vehicle #</div>
                                <input nz-input placeholder="(Auto Generated)" [disabled]="isInvoiceEdit" [(ngModel)]="vehicle_number" />
                              </div>
                          
                          
                              <div class="input-group">
                                <div class="input-label">Due Date</div>
                                <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;" [(ngModel)]="due_date"></nz-date-picker>
                            </div>
                          
                          </div>
                        </div>

                        <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary" (click)="newInvoice()" >SAVE</button>
                        
                      </nz-modal>

                    </div>
                </div>

            </div>
            
            <div class='stats__table'>
              <nz-table #productTable 
              [nzData]="listOfInvoices.data" 
              nzTableLayout="fixed"
              nzShowSizeChanger
              [nzPageSizeOptions]="[10, 50, 100, 1000, 50000, 100000]"
              [nzLoading]="isLoadingInvoices"
              [nzFrontPagination]="false"
              [nzTotal]="listOfInvoices.meta.total"
              [nzPageSize]="listOfInvoices.meta.per_page"
              [nzPageIndex]="listOfInvoices.meta.current_page"
              (nzQueryParams)="onParamsChange($event)"
              >
                <thead>
                  <tr>
                    <th nzLeft [nzSortFn]="true" nzColumnKey="invoices.id">Invoice Id </th>
                    <th [nzSortFn]="true" nzColumnKey="orders.type">Order Type</th>
                    <th [nzSortFn]="true" nzColumnKey="invoices.order_id">Order ID</th>
                    <th [nzSortFn]="true" nzColumnKey="vehicle_number">Vehicle #</th>
                    <th [nzSortFn]="true" nzColumnKey="customer_name">Customer Name</th>
                    <th [nzSortFn]="true" nzColumnKey="invoice_date">Invoice Date</th>
                    <th [nzSortFn]="true" nzColumnKey="due_date">Due Date</th>
                    <th [nzSortFn]="true" nzColumnKey="invoices.balance">Due Amt.</th>
                    <th [nzSortFn]="true" nzColumnKey="orders.status">Status</th>
                    <th>Amt. Paid</th>
                    <th nzRight></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of productTable.data">
                    <td nzLeft>{{ data.id }}</td>
                    <td>
                      {{data.type==0 ? "General Servicing" : ''}}
                      {{data.type==1 ? "Accidental Claim" : ''}}
                    </td>
                    <td>{{ data.order_id }}</td>
                    <td>{{ data.vehicle_number }}</td>
                    <td>{{ data.customer_name }}</td>
                    <td>{{ data.invoice_date | date: 'mediumDate' }}</td>
                    <td>{{ data.due_date | date: 'mediumDate'}}</td>
                    <td>{{ data.balance | number : '1.2-2'}}</td>
                    <td>
                      <nz-badge *ngIf="data.balance==0" nzStatus="success"></nz-badge>
                      <nz-badge *ngIf="data.balance==data.total" nzStatus="error"></nz-badge>
                      <nz-badge *ngIf="data.balance>0 && data.balance<data.total" nzStatus="processing"></nz-badge>
                      {{ data.balance==0 ? 'Paid' : data.balance==data.total ? 'Unpaid' : 'Partially Paid' }}
                    </td>
                    <td>{{ data.total - data.balance | number : '1.2-2'}}</td>
                    <td nzRight>
                      <button nz-button nz-dropdown [nzTrigger]="'click'" [nzDropdownMenu]="menu4">
                        Action
                        <i nz-icon nzType="down"></i>
                      </button>

                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                          <li routerLink="/invoice-single" [queryParams]="{ invoice_id: data.id}" nz-menu-item style="display: flex; flex-direction: row; align-items: center;"> 
                            <div>
                            View  
                            </div>
                          </li>

                          <li nz-menu-item style="display: flex; flex-direction: row; align-items: center;" (click)="openPaymentModal=true;invoice_id=data.id;payment_amount=data.balance;"> 
                            <div>
                            Payment  
                            </div>
                          </li>

                          <li nz-menu-item style="display: flex; flex-direction: row; align-items: center;" 
                          (click)="printPDF(data.id)">
                            <div>
                            Print PDF 
                            </div>
                          </li>

                          <li nz-menu-item style="display: flex; flex-direction: row; align-items: center;" 
                          (click)="downloadPDF(data.id)">
                            <div>
                            Download PDF 
                            </div>
                          </li>
                          
                          <li nz-menu-item style="display: flex; flex-direction: row; align-items: center;" (click)="editInvoice(data)">
                            <div>
                            Edit 
                            </div>
                          </li>

                          <li
                          nz-popconfirm
                          nzPopconfirmTitle="Are you sure delete this invoice?"
                          nzPopconfirmPlacement="bottom"
                          (nzOnConfirm)="deleteInvoice(data)"
                          nz-menu-item style="display: flex; flex-direction: row; align-items: center;">
                            <div style="color: rgb(177, 21, 21);">
                              Delete 
                            </div>
                          </li>


                        </ul>
                      </nz-dropdown-menu>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>

            <nz-modal [nzWidth]="800" [(nzVisible)]="openPaymentModal" nzTitle="Payment" 
        (nzOnCancel)="openPaymentModal=false;clearPrevData();" style="justify-content: center; align-items: center;">
          
          <div nz-row [nzGutter]="[12,36]">

            <div nz-col nzSpan="8">
              <div class="input-group">
                <div class="input-label">Payment Date</div>

                <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;" [(ngModel)]="payment_date"></nz-date-picker>
              </div>
        
            </div>

            <div nz-col nzSpan="8">

              <div class="input-group">
                <div class="input-label">Payment Method</div>
                <nz-select nzShowSearch [(ngModel)]="payment_method" style="width: 100%;">
                  <nz-option nzValue="Cash" nzLabel="Cash"></nz-option>
                  <nz-option nzValue="Bank" nzLabel="Bank"></nz-option>
                </nz-select>
              </div>

            
            </div>

            <div nz-col nzSpan="8">
              
              <div class="input-group">
                <div class="input-label">Amount Collected</div>
                <nz-input-group nzAddOnBefore="$">
                  <nz-input-number nz-input [nzMin]="0" [nzStep]="100" [(ngModel)]="payment_amount" style="width: 100%;"></nz-input-number>
                </nz-input-group>
              </div>
              
            
            </div>

            <div style="width: 100%; margin-bottom: 15px;">
              <div class="input-label">Remarks</div>

              <textarea rows="4" nz-input style="width: 100%;" [(ngModel)]="payment_remarks"></textarea>
            </div>

          </div>

          <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary" (click)="newPayment()" >SAVE</button>
          
            </nz-modal>

          </nz-card>
        </div>
    </div>
</div>




