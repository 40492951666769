<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
          <nz-card>

            <nz-tabset>
              <nz-tab nzTitle="Wash Register">
                <div class="breadcrumbs-add-btn-bar" style="margin-top: 0px;">

                  <div class="title" style="margin-bottom: 5px;">Wash Register</div>
      
                    <div class="doc-search-btn" (click)="downloadCsvRegister()">
                      <div class="icon-download">
                        <div style="margin-right: 10px;">
                            <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                              <path d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                              <path d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                              <path d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                              </svg>
                                  
                          </div>
                          <div class="download-text">
                              Download CSV
                          </div>
                      </div>
                        <button nz-button nzType="primary" routerLink='/wash-register-new'>New Wash</button>
                    </div>
      
                  </div>
      
                  <div class='stats__table'>
                      <nz-table  #sortTable 
                      [nzData]="listOfOrders.data" 
                      nzTableLayout="fixed"
                      nzShowSizeChanger
                      [nzLoading]="isLoadingOrders"
                      [nzFrontPagination]="false"
                      [nzTotal]="listOfOrders.meta.total"
                      [nzPageSize]="listOfOrders.meta.per_page"
                      [nzPageIndex]="listOfOrders.meta.current_page"
                      (nzQueryParams)="onOrdersChange($event)"
                      >
                        <thead>
                          <tr>
                            <th nzLeft [nzSortFn]="true" nzColumnKey="washing_register.id">Id </th>
                            <th nzLeft [nzSortFn]="true" nzColumnKey="customer_name">Customer</th>
                            <th [nzSortFn]="true" nzColumnKey="vehicle_number">Vehicle No. </th>
                            <th [nzSortFn]="true" nzColumnKey="vehicle_department">Vehicle Department </th>
                            <th [nzSortFn]="true" nzColumnKey="time">Reporting Date </th>
                            <th [nzSortFn]="true" nzColumnKey="time">Start Time </th>
                            <th [nzSortFn]="true" nzColumnKey="time">End Time </th>
                            <th [nzSortFn]="true" nzColumnKey="users.name">Created By </th>
                            <th>Pictures </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of sortTable.data">
                            <td nzLeft>{{ data.id }}</td>
                            <td nzLeft>{{ data.customer_name }}</td>
                            <td>{{ data.vehicle_number }}</td>
                            <td>{{ data.vehicle_department }}</td>
                            <td>{{ data.time | date: 'mediumDate' }}</td>
                            <td>{{ data.start_time }}</td>
                            <td>{{ data.end_time }}</td>
                            <td>{{ data.created_by_name }}</td>
                            <td>
                              <button nz-button [nzSize]="'small'" nz-dropdown [nzDropdownMenu]="menu" [nzPlacement]="'bottomRight'">
                                <svg t="1664868039105" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2523" width="16"><path d="M128 256h768v85.333333H128V256m0 213.333333h768v85.333334H128v-85.333334m0 213.333334h768v85.333333H128v-85.333333z" p-id="2524"></path></svg>
                              </button>
                              <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item 
                                  routerLink="/wash-register-new" [queryParams]="{ wash_register_id: data.id}"
                                  >Edit Entry</li>
                                  <li nz-menu-item (click)="viewOrder(data)">View Pictures</li>
                                </ul>
                              </nz-dropdown-menu>
                            </td>
                          </tr>
                        </tbody>
                      </nz-table>
                      
                      <nz-modal 
                      [nzWidth]="'80vw'"
                      [(nzVisible)]="pictureModal" nzTitle="Pictures" (nzOnCancel)="pictureModal=false;">
                          <ng-container *nzModalContent>
                              <div class="modal__pictures">
                                  <div class="d-flex flex-wrap flex-row">
                                      <img
                                          *ngFor="let picture of pictures"
                                          nz-image
                                          width="200px"
                                          height="200px"
                                          style="object-fit: cover;margin-right: 5px;cursor: zoom-in;"
                                          [nzSrc]="picture.url"
                                          alt=""
                                          />
                                  </div>
                              </div>
                          </ng-container>
                      </nz-modal>
                  </div>
              </nz-tab>
              <nz-tab nzTitle="Wash Schedule">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <nz-alert nzType="info" nzMessage="Booking schedule on the calendar below will be displayed for the coming two months only, and due date of the booking will be updated every day at 8:00 AM based on interval days."></nz-alert>
                  </div>
                </div>

                <div nz-row class="mt-4">
                  <div nz-col nzSpan="8">
                      <nz-button-group>
                      <button
                        nz-button
                        mwlCalendarPreviousView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()"
                      >
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70679 0.293031C5.89426 0.480558 5.99957 0.734866 5.99957 1.00003C5.99957 1.26519 5.89426 1.5195 5.70679 1.70703L2.41379 5.00003L5.70679 8.29303C5.88894 8.48163 5.98974 8.73423 5.98746 8.99643C5.98518 9.25863 5.88001 9.50944 5.6946 9.69485C5.5092 9.88026 5.25838 9.98543 4.99619 9.9877C4.73399 9.98998 4.48139 9.88919 4.29279 9.70703L0.292787 5.70703C0.105316 5.5195 0 5.26519 0 5.00003C0 4.73487 0.105316 4.48056 0.292787 4.29303L4.29279 0.293031C4.48031 0.10556 4.73462 0.000244141 4.99979 0.000244141C5.26495 0.000244141 5.51926 0.10556 5.70679 0.293031Z" fill="#18181B"/>
                          </svg>                            
                      </button>
                      <button
                        nz-button
                        mwlCalendarToday
                        [(viewDate)]="viewDate"
                      >
                        Today
                      </button>
                      <button
                        nz-button
                        mwlCalendarNextView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()"
                      >
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292787 9.70692C0.105316 9.51939 0 9.26508 0 8.99992C0 8.73475 0.105316 8.48045 0.292787 8.29292L3.58579 4.99992L0.292787 1.70692C0.110629 1.51832 0.00983372 1.26571 0.0121121 1.00352C0.0143906 0.741321 0.11956 0.490509 0.304968 0.305101C0.490376 0.119692 0.741189 0.0145233 1.00339 0.0122448C1.26558 0.00996641 1.51818 0.110761 1.70679 0.292919L5.70679 4.29292C5.89426 4.48045 5.99957 4.73475 5.99957 4.99992C5.99957 5.26508 5.89426 5.51939 5.70679 5.70692L1.70679 9.70692C1.51926 9.89439 1.26495 9.99971 0.999786 9.99971C0.734622 9.99971 0.480314 9.89439 0.292787 9.70692Z" fill="#18181B"/>
                          </svg>                            
                      </button>
                    </nz-button-group>
                  </div>
                  <div nz-col nzSpan="8" style="display: flex;justify-content: flex-end;">
                      <nz-button-group>
                          <button
                            nz-button
                            (click)="setView(CalendarView.Month)"
                            [class.active]="view === CalendarView.Month"
                          >
                            Month
                          </button>
                          <button
                            nz-button
                            (click)="setView(CalendarView.Week)"
                            [class.active]="view === CalendarView.Week"
                          >
                            Week
                          </button>
                          <button
                            nz-button
                            (click)="setView(CalendarView.Day)"
                            [class.active]="view === CalendarView.Day"
                          >
                            Day
                          </button>
                        </nz-button-group>
                  </div>
                  <div nz-col nzSpan="8" style="display: flex;justify-content: flex-end;">
                      <button (click)="openBookingModal=true;" nz-button nzType="primary" style="display: flex;align-items: center;justify-content: center;margin-left: 10px;">
                        New Wash Booking
                    </button>
                    <nz-drawer [nzWidth]="600" [(nzVisible)]="openBookingModal" nzTitle="Add New Wash Booking" 
                    (nzOnClose)="openBookingModal=false;clearPreviousData()">

                      <div class="input-group">
                        <div class="input-label">Vehicle</div>
                          <nz-select nzShowSearch style="width: 100%;" nzPlaceHolder="Select vehicle" [(ngModel)]="service_booking_vehicle_id" (ngModelChange)="bookingVehicleChanged($event)">
                            <nz-option *ngFor="let vehicle of customer_vehicles" [nzLabel]="vehicle.vehicle_number" [nzValue]="vehicle.id"></nz-option>
                          </nz-select>
                      </div>

                      <div class="input-group">
                        <div class="input-label">Department</div>
                        <input nz-input placeholder="Department" [(ngModel)]="service_booking_department" readonly />
                      </div>

                      <div class="input-group">
                        <div class="input-label">Vehicle Type</div>
                        <input nz-input placeholder="Vehicle Type" [(ngModel)]="service_booking_vehicle_type" readonly />
                      </div>

                      <div class="input-group">
                        <div class="input-label">Due Date</div>
                        <nz-date-picker 
                        nzFormat="yyyy-MM-dd"
                        style="width: 100%;" 
                        [(ngModel)]="service_booking_due_date"></nz-date-picker>
                      </div>

                      <div class="d-flex flex-column mb-3">
                        <label nz-checkbox [(ngModel)]="is_recurring_booking">IS RECURRING BOOKING?</label>

                        <div *ngIf="is_recurring_booking" class="input-label mt-3">Recurring after how many days?</div>
                        <nz-input-number *ngIf="is_recurring_booking" class="mt-2 w-100" [(ngModel)]="recurring_in" [nzMin]="1" [nzMax]="120" [nzStep]="1"></nz-input-number>

                      </div>

                      <div class="input-group" *ngIf="is_recurring_booking">
                        <div class="input-label">Last Recurring Date</div>
                        <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;" [(ngModel)]="last_recurring_date"></nz-date-picker>
                      </div>

                      <div style="display: flex;justify-content: space-between;align-items: center;flex-direction: row;margin-top: 25px;">
                        <div>
                          <button (click)="newBooking()" style="margin-right: 15px;" nz-button nzType="primary">SAVE</button>
                        </div>
                      </div>

                    </nz-drawer>
                  </div>
                </div>
                <br />
                <div [ngSwitch]="view">
                  <mwl-calendar-month-view
                    *ngSwitchCase="CalendarView.Month"
                    [viewDate]="viewDate"
                    [events]="events"
                    [refresh]="refresh"
                    [activeDayIsOpen]="activeDayIsOpen"
                    (dayClicked)="dayClicked($event.day)"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                    (eventTimesChanged)="eventTimesChanged($event)"
                  >
                  </mwl-calendar-month-view>
                  <mwl-calendar-week-view
                    *ngSwitchCase="CalendarView.Week"
                    [viewDate]="viewDate"
                    [events]="events"
                    [refresh]="refresh"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                    (eventTimesChanged)="eventTimesChanged($event)"
                  >
                  </mwl-calendar-week-view>
                  <mwl-calendar-day-view
                    *ngSwitchCase="CalendarView.Day"
                    [viewDate]="viewDate"
                    [events]="events"
                    [refresh]="refresh"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                    (eventTimesChanged)="eventTimesChanged($event)"
                  >
                  </mwl-calendar-day-view>
                </div>
              </nz-tab>
            </nz-tabset>

          </nz-card>
        </div>
      </div>
  </div>