import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventory-navbar',
  templateUrl: './inventory-navbar.component.html',
  styleUrls: ['./inventory-navbar.component.css']
})
export class InventoryNavbarComponent implements OnInit {

  constructor() { }
  
  pathType = '';

  ngOnInit(): void {
    this.setPathType();
  }

  setPathType() {
    let path = window.location.pathname;

    if(path == "") {
      this.pathType = 'dashboard';
    } 

    if(path == "/masterdata") {
      this.pathType = 'masterdata';
    } 

    if(path == "/all-orders") {
      this.pathType = 'stockout';
    } 

    if(path == "/purchase-orders") {
      this.pathType = 'purchase-orders';
    } 

    if(path == "/all-products") {
      this.pathType = 'products';
    } 

    if(path == "/product-single") {
      this.pathType = 'products';
    } 

    if(path == "/inventory-transactions") {
      this.pathType = 'inventory-transactions';
    } 
    
  }

}
