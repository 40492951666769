import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-customfooter',
  templateUrl: './customfooter.component.html',
  styleUrls: ['./customfooter.component.scss']
})
export class CustomfooterComponent implements OnInit {
  path: string = '';

  constructor() {

   }

    changeLang(lang) {

    let tag = `googtrans(${lang})`;

    let url = window.location.href;
    let final_url = url+"#"+tag;

    window.location.href = final_url;
    window.location.reload();

  }

  ngOnInit(): void {
    this.path = window.location.pathname;
  }

}
