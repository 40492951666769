import { Component, Input, OnInit } from '@angular/core';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-sidebar-dashboard',
  templateUrl: './sidebar-dashboard.component.html',
  styleUrls: ['./sidebar-dashboard.component.css']
})
export class SidebarDashboardComponent implements OnInit {

  public menuItems: any[] = [{
    icon: `<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0.75H0.75V6H6V0.75Z" stroke="#565656" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.25 0.75H9V6H14.25V0.75Z" stroke="#565656" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.25 9H9V14.25H14.25V9Z" stroke="#565656" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 9H0.75V14.25H6V9Z" stroke="#565656" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    title: 'Dashboard',
    path: '/dashboard',
    submenu:[],
  },{
    title: 'Orders',
    path: '/all-general-servicing',
    submenu:[{
      title: 'General Servicing & Repairs',
      path: '/all-general-servicing',
      submenu:[],
    },{
      title: 'Accidental Claims',
      path: '/all-accidental-claims',
      submenu:[],
    },{
      title: 'Reporting Only',
      path: '/all-reporting-only',
      submenu:[],
    }],
  },{
    title: 'Inventory',
    path: '/all-products',
    submenu:[{
      title: 'Products',
      path: '/all-products',
      submenu:[],
    },{
      title: 'Purchase Orders',
      path: '/all-reporting-only',
      submenu:[],
    },{
      title: 'Stock Out',
      path: '/all-reporting-only',
      submenu:[],
    },{
      title: 'Transactions',
      path: '/all-reporting-only',
      submenu:[],
    }],
  },{
    title: 'Invoices',
    path: '/all-invoices',
    submenu:[],
  },{
    title: 'Customers',
    path: '/all-customers',
    submenu:[],
  },{
    title: 'Jobs Schedule',
    path: '/all-jobs',
    submenu:[],
  }];
  isFolded : boolean;
  isSideNavDark : boolean;
  isExpand : boolean;

  isDashboardPath = false;

  name:String = '';

  isInventoryDashboard  = false;
  isFinanceDashboard = false;
  isLabourDashboard = false;
  isWorkshopDashboard = false;
  isCustomerDashboard = false;
  isGPSDashboard = false;
  isFCDashboard = false;

  isGSPath = false;
  isACPath = false;
  isROPath = false;
  isOMDPath = false;

  isProductsPath = false;
  isPOPath = false;
  isSOPath = false;
  isTRPath = false;
  isMDPath = false;
  isDPPath = false;

  isInvoicePath = false;
  isCustomerPath = false;
  isVehiclePath = false;
  isJobsPath = false;
  isVpcMasterPath = false;
  isUpcomingBookingsPath = false;
  isVehicleCategoryPath = false;
  isVehicleDepartmentPath = false;
  isCombinedDashboard = false;
  isWRPath = false;

  role = 0;
  isVPCPath: boolean;
  
  constructor(private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.role = parseInt(localStorage.getItem('role'));
    this.themeService.isMenuFoldedChanges.subscribe(isFolded =>  {
     localStorage.setItem('isFolded', ""+isFolded);
     return this.isFolded = isFolded
    });
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);     
    this.setActivePath();

    const isFolded = localStorage.getItem('isFolded');
    this.isFolded = isFolded=='true' ? true: false;
  }

  setActivePath() {
    const path = window.location.pathname;
    console.log(path);
    
    if(path=='/inventory-dashboard') {
      this.isInventoryDashboard = true;
    }  

    if(path=='/combined-dashboard') {
      this.isCombinedDashboard = true;
    }  

    if(path=='/finance-dashboard') {
      this.isFinanceDashboard = true;
    }  
    if(path=='/labour-dashboard') {
      this.isLabourDashboard = true;
    }  
    if(path=='/workshop-dashboard') {
      this.isWorkshopDashboard = true;
    }  

    if(path=='/customer-dashboard') {
      this.isCustomerDashboard = true;
    }  

    if(path=='/gps-dashboard') {
      this.isGPSDashboard = true;
    }  

    if(path=='/fuel-consumption-dashboard') {
      this.isFCDashboard = true;
    }  

    if(path=='/all-general-servicing'||path=='/general-servicing-single'||path=='/general-servicing') {
      this.isGSPath = true;
    }  
    if(path=='/all-accidental-claims'||path=='/accidental-claim-single'||path=='/accidental-claim') {
      this.isACPath = true;
    }  
    if(path=='/all-reporting-only'||path=='/reporting-only-new') {
      this.isROPath = true;
    } 
    if(path=='/wash-register'||path=='/wash-register-new') {
      this.isWRPath = true;
    }



    if(path=='/orders-masterdata') {
      this.isOMDPath = true;
    }

    if(path=='/all-products'||path=='/product-single') {
      this.isProductsPath = true;
    } 
    if(path=='/purchase-orders') {
      this.isPOPath = true;
    } 
    if(path=='/all-orders'||path=='/order-single') {
      this.isSOPath = true;
    } 
    if(path=='/inventory-transactions') {
      this.isTRPath = true;
    } 
    if(path=='/disposable-products') {
      this.isDPPath = true;
    } 
    if(path=='/masterdata') {
      this.isMDPath = true;
    } 
    
    if(path=='/all-invoices'||path=='/invoice-single') {
      this.isInvoicePath = true;
    }  
    if(path=='/all-customers'||path=='/customer-single') {
      this.isCustomerPath = true;
    } 
    if(path=='/all-vehicles') {
      this.isVehiclePath = true;
    } 
    if(path=='/vpc-master') {
      this.isVPCPath = true;
    } 
    if(path=='/all-upcoming-bookings') {
      this.isUpcomingBookingsPath = true;
    } 
    if(path=='/all-jobs') {
      this.isJobsPath = true;
    }  

    if(path=='/vpc-master') {
      this.isVpcMasterPath = true;
    } 

    if(path=='/vehicle-categories') {
      this.isVehicleCategoryPath = true;
    }  

    if(path=='/vehicle-departments') {
      this.isVehicleDepartmentPath = true;
    }  
  }

}