import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { ThemeConstantService } from '../services/theme-constant.service';
import { ActivatedRoute } from '@angular/router';
import { NzTableQueryParams } from 'ng-zorro-antd/table';


@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.css']
})
export class PurchaseOrdersComponent implements OnInit {

  createNewPOModal = false;
  listOfData = [];
  listOfPurchaseOrders = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };

  vendor_id = 0;
  product_id = 0;
  quotation_id = 0;
  price = 0;
  quantity = 0;

  vendors = [];
  quotations = [];
  products = [];
  purchase_order_name = '';
  po_doc = '';
  isLoadingPurchaseOrders = false;
  purchase_order_search = '';
  purchase_order_id = 0;
  isPurchaseOrderEdit = false;
  purchase_order_status = 0;
  old_po_status = -2;
  date = new Date();
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  show_quotation: boolean = true;
  fullLoader: boolean = false;
  search_po_id = 0;

  sort_by_field = '';
  sort_by_type = '';
  page_index = 1;
  page_size = 10;
  filterDrawerVisible = false;

  product_filters = [{
    label: 'Product',
    key: 'product_name',
    type: "string",
    value: null,
    condition: "Contains",
  },
  {
    label: 'Vendor Name',
    key: 'vendor_name',
    type: "string",
    value: null,
    condition: "Contains",
  },
  { 
    label: 'Cost/Unit',
    key: 'purchase_orders.price',
    type: "number",
    value: null,
    condition: "Equals",
  },
  {
    label: 'Quantity',
    key: 'purchase_orders.quantity',
    type: "number",
    value: null,
    condition: "Equals",
  },
  {
    label: 'Total Cost',
    key: 'total_cost',
    type: "number",
    value: null,
    condition: "Equals",
  },
  {
    label: 'Petty Cash Purchase',
    key: 'purchase_orders.petty_cash_purchase',
    type: "options",
    value: null,
    condition: "Equals",
    options: [
      {
        value: 1,
        label: 'Yes'
      },
      {
        value: 0,
        label: 'No'
      }] 
  },
  {
    label: 'Date',
    key: 'purchase_orders.date',
    type: "date",
    value: null,
    condition: "Equals",
  },
  {
    label: 'Status',
    key: 'purchase_orders.status',
    type: "options",
    value: null,
    condition: "Equals",
    options: [
      {
        value: -1,
        label: 'Pending Approval'
      },
      {
        value: 0,
        label: 'Sent'
      },
      {
        value: 1,
        label: 'Stock Received'
      },
      {
        value: 2,
        label: 'Rejected'
      },
      {
        value: 3,
        label: 'Approved'
    }] 
  },
  {
    label: 'Created By',
    key: 'created_by_name',
    type: "string",
    value: null,
    condition: "Equals",
  },
  {
    label: 'Quotation',
    key: 'quotation_id',
    type: "string",
    value: null,
    condition: "Equals",
  }];

  quotations_all = [];

  last_purchase_date = '';
  GSTQuotePrice: number = 0;

  petty_cash_purchase = false;

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);     
    this.getPurchaseOrderCreateData();
    this.filterMethodPurchaseOrders();

    this.route.queryParams
      .subscribe(params => {
        if(params.id) {
          this.search_po_id = params.id;
          this.filterMethodPurchaseOrders();
        }
        if(params.po_status){
          const index = this.product_filters.findIndex(el => el.key == 'purchase_orders.status');
          this.product_filters[index].value = params.po_status;
          this.filterMethodPurchaseOrders();
        }
        if(params.start_date && params.end_date) {
          const index = this.product_filters.findIndex(el => el.key == 'purchase_orders.date');
          this.product_filters[index].value = moment(params.start_date).subtract(1, 'days')
          this.product_filters[index].condition = "More Than";
          this.product_filters.push({
            label: 'End Date',
            key: 'purchase_orders.date',
            type: "date",
            value: moment(params.end_date).add(1, 'days'),
            condition: "Less Than",
          });
          this.filterMethodPurchaseOrders();
        }

      }
    ); 
  }

  calcGSTQuotePrice() {
    this.GSTQuotePrice = this.price * 8 / 100;
   }

  getPurchaseOrderCreateData() {
    var url = '/api/get-purchase-order-create-data';

    var data = {};

    axios.post(url, data, {}).then(response => {
      this.vendors = response.data.vendors;
      this.quotations_all = response.data.quotations;
      this.quotations = response.data.quotations;
      this.products = response.data.products;
    })
  }

  handleChangePO(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        // Component will show file.url as link
        this.po_doc = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  filterQuotations(e) {
    if(e) {
      this.quotations = this.quotations_all.filter((el)=>el.vendor_id==e);
    } else {
      this.quotations = this.quotations_all;
    }
  }

  quoteSelected(e) {
    const selectedQuotation = this.quotations_all.filter((el)=>el.id==e)[0];
    this.vendor_id = selectedQuotation.vendor_id;
    this.price = selectedQuotation.price;
    this.product_id = selectedQuotation.product_id;
  }

  filterMethodPurchaseOrders() {
    
    this.isLoadingPurchaseOrders = true;

    var url = '/api/all-purchase-orders';

    var data = {
      product_id: 0,
      search: this.purchase_order_search,
      page_index: this.page_index,
      page_size: this.page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      search_po_id: this.search_po_id,
      filters: []
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      this.listOfPurchaseOrders = response.data.all_purchase_orders;
      this.isLoadingPurchaseOrders = false;
      this.filterDrawerVisible=false;
    })
  }

  clearFilters() {
    this.product_filters.forEach(element => {
      element.value = null;
    });
    this.filterMethodPurchaseOrders();
  }

  lastPurchaseDate(product_id) {
    var url = '/api/last-purchase-date';

    var data = {
      product_id: product_id
    };

    axios.post(url, data, {}).then(response => {
      this.last_purchase_date = response.data.date;
    })
  }

  onPurchaseOrdersChange(params: NzTableQueryParams) {
    const currentSort = params.sort.find(item => item.value !== null);
    this.sort_by_field = (currentSort && currentSort.key) || null;
    this.sort_by_type = (currentSort && currentSort.value) || null;

    this.page_index = params.pageIndex;
    this.page_size = params.pageSize;
    this.filterMethodPurchaseOrders();
  }

  newPurchaseOrder() {

    if(this.quantity==0) {
      this.msg.error("Please enter quantity!");
      return false;
    }

    if(this.quotation_id==0) {
      this.msg.error("Please select a quotation!");
      return false;
    }

    let url = '';

    if(this.isPurchaseOrderEdit) {
      url = '/api/update-purchase-order';
    } else {
      url = '/api/new-purchase-order';
    }

    let data = {
       purchase_order_id: this.purchase_order_id,
       purchase_order_name: this.purchase_order_name,
       po_doc: this.po_doc,
       quotation_id: this.quotation_id,
       vendor_id: this.vendor_id,
       product_id: this.product_id,
       quantity: this.quantity,
       price: this.price,
       status: this.purchase_order_status,
       date: moment(this.date).format('YYYY-MM-DD'),
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {

        if(this.isPurchaseOrderEdit) {
          this.msg.success("Purchase order updated!");
        } else {
          this.msg.success("Purchase order created!");
        }
        
        this.createNewPOModal=false;
        this.isPurchaseOrderEdit=false;
        this.filterMethodPurchaseOrders();
        this.clearPreviousData();
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  editPurchaseOrder(data) {
    this.purchase_order_name = data.purchase_order_name;
    this.po_doc = data.po_doc;
    this.quotation_id = data.quotation_id;
    this.vendor_id = data.vendor_id;
    this.product_id = data.product_id;
    this.quantity = data.quantity;
    this.price = data.price;
    this.purchase_order_id = data.id;
    this.purchase_order_status = data.status;
    this.old_po_status = data.status;
    this.date = new Date(data.date);
    this.show_quotation = this.quotations.filter(el => el.id==data.quotation_id).length!=0;
    
    this.isPurchaseOrderEdit = true;
    this.createNewPOModal=true;
  }

  deletePurchaseOrder() {
    var url = '/api/delete-purchase-order';

    var data2 = {
       purchase_order_id: this.purchase_order_id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.createNewPOModal=false;
        this.filterMethodPurchaseOrders();
        this.msg.success("Purchase Order deleted successfully!")
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  downloadCsvPurchaseOrders() {

    const { parse } = require('json2csv');
    // const PurchaseOrders = this.listOfPurchaseOrders.data;
    const fields = [{
      label: 'PO #', 
      value: (row:any) => row.id,
    }, {
      label: 'Product',
      value: (row:any) => row.product_name,
    }, {
      label: 'Vendor Name',
      value: (row:any) => row.vendor_name,
    }, {
      label: 'Cost/Unit',
      value: (row:any) => row.price,
    }, {
      label: 'Quantity',
      value: (row:any) => row.quantity,
    }, {
      label: 'Total Cost',
      value: (row:any) => row.price * row.quantity,
    }, {
      label: 'Date',
      value: (row:any) => moment(row.date).format('DD/MM/YYYY'),
    }, {
      label: 'Status',
      value: (row:any) => row.status==3 ? 'Approved' : row.status==-1 ? 'Pending Approval' : row.status==0 ? 'Sent' : row.status==1 ? 'Stock Received' : 'Rejected',
    }, {
      label: 'Created By',
      value: (row:any) => row.created_by_name,
    }, {
      label: 'Quotation',
      value: (row:any) => row.quotation_id,
    }];

    var url = '/api/all-purchase-orders';

    var data = {
      product_id: 0,
      search: this.purchase_order_search,
      page_index: 1,
      page_size: this.listOfPurchaseOrders.meta.total,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      search_po_id: this.search_po_id,
      filters: []
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      // this.listOfPurchaseOrders = response.data.all_purchase_orders;
      let listOfPurchaseOrders = response.data.all_purchase_orders;
      const PurchaseOrders = listOfPurchaseOrders.data;

      const csv = parse(PurchaseOrders, { fields });
      require("downloadjs")(csv, 'Purchase Orders.csv');
    })

    // const csv = parse(PurchaseOrders, { fields });
    // require("downloadjs")(csv, 'Purchase Orders.csv');
  }

  printPO(id) {
    this.fullLoader = true;
    var url = '/api/generate-po';

    var data = {
      po_id: id,
    };

    axios.post(url, data, {}).then(response => {
      this.fullLoader = false;
      const printJS = require('print-js')
      printJS({ printable: response.data.url})
    }).catch(error => {
      this.fullLoader = false;
      this.msg.error("Something Went Wrong!");
    })
  }

  clearPreviousData() {
    this.isPurchaseOrderEdit = false;
    this.purchase_order_name = '';
    this.po_doc = '';
    this.purchase_order_search = '';
    this.purchase_order_id = 0;
    this.purchase_order_status = 0;
    this.old_po_status = -2;
    this.isPurchaseOrderEdit = false;
    this.date = new Date();
    this.vendor_id = 0;
    this.quotation_id = 0;
    this.product_id = 0;
    this.price = 0;
    this.quantity = 0;
    this.quotations = this.quotations_all;
    this.last_purchase_date = '';
  }

}

