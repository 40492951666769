<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
            <nz-card>

                <div class="breadcrumbs-add-btn-bar">

                    <div class="title-breadrumbs-container d-flex">

                        <div class="d-flex">
                            <div class="title mr-3">REPORTING</div>

                            <div><nz-switch nzSize="small" [(ngModel)]="is_incident_only"></nz-switch> Incident Only</div>
                        </div>
 

                    </div>

                    <div class="doc-search-btn">
                        <button *ngIf="!isEdit" nz-button nzType="primary" (click)="createNewOrder()">CREATE</button>
                        <button *ngIf="isEdit" nz-button nzType="primary" (click)="updateOrder()">SAVE</button>
                    </div>

                </div>

                <div *ngIf="is_incident_only" class="customer-details-container" style="padding-bottom: 30px;"> 
                    <div style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;">Incident Details</div>
                    <nz-divider ></nz-divider>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Incident No
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="incident_number">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Employee name
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="employee_name">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Dept Name
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="dept_name">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Event Type
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="event_type">
                            </div>
                        </div>
                    </div>

                    <div class="mt-3" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Date
                            </div>
                            <div>
                                <nz-date-picker class="w-100"  nzFormat="yyyy-MM-dd" [(ngModel)]="date"></nz-date-picker>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Recorded By
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="recorded_by">
                            </div>
                        </div>


                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Status
                            </div>
                            <div>
                                <nz-select nzShowSearch class="w-100" [(ngModel)]="status">
                                    <nz-option nzValue="Open" nzLabel="Open"></nz-option>
                                    <nz-option nzValue="Closed" nzLabel="Closed"></nz-option>
                                </nz-select>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            
                        </div>
                        
                    </div>

                    <div class="mt-3" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Details of Incident
                            </div>
                            <div>
                                <textarea nz-input [(ngModel)]="details_of_incident"></textarea>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="customer-details-container" style="padding-bottom: 30px;"> 
                    <div style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;">Customer Details</div>
                    <nz-divider ></nz-divider>
                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer ID
                            </div>
                                <div>
                                    <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="customer_id" (ngModelChange)="loadCustomerDetails($event)">
                                        <nz-option *ngFor="let customer of all_customers;" 
                                        [nzValue]="customer.id" [nzLabel]="customer.customer_name+' #'+customer.id"></nz-option>
                                    </nz-select>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer Name
                            </div>
                                <div>
                                    <input nz-input [(ngModel)]="customer_name">
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Reg. No.
                            </div>
                                <div>
                                    <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="vehicle_id" (ngModelChange)="loadVehicleDetails($event)">
                                        <nz-option *ngFor="let vehicle of customer_vehicles;" 
                                        [nzValue]="vehicle.id" [nzLabel]="vehicle.vehicle_number"></nz-option>
                                    </nz-select>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer Contact No.
                            </div>
                                <div>
                                    <input nz-input type="text" [(ngModel)]="phone"/>
                                </div>
                        </div>
                    
                    </div>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top: 15px;">
                    
                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Make
                            </div>
                                <div>
                                    <input nz-input type="text" [(ngModel)]="make" />
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Model
                            </div>
                                <div>
                                    <input nz-input type="text" [(ngModel)]="model" />
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Registration Year
                            </div>
                            <div>
                                <nz-input-group>
                                <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;" [(ngModel)]="mfg_date"></nz-date-picker>
                                </nz-input-group>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Settlement Type
                            </div>
                            <div>
                                <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="settlement_type">
                                    <nz-option [nzValue]="'Reporting Only'" [nzLabel]="'Reporting Only'"></nz-option>
                                    <nz-option [nzValue]="'Private Settlement – At Fault'" [nzLabel]="'Private Settlement – At Fault'"></nz-option>
                                    <nz-option [nzValue]="'Private Settlement – Not At Fault'" [nzLabel]="'Private Settlement – Not At Fault'"></nz-option>
                                </nz-select>
                            </div>
                        </div>
                    
                    </div>
                </div>


                <div style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;justify-content: flex-start; align-items: center;">Files</div>
                <nz-divider ></nz-divider>

                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top: 15px;">
                    
                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            GIA report
                        </div>
                        <div
                        class="d-flex flex-column "
                        [class.reporting-only-uploads]="gia_report != ''">
                            <a 
                            *ngIf="gia_report!=''"
                            class="mb-2 uppercase" [href]="gia_report" target="_blank">
                                Download
                            </a>
                            <nz-upload
                                [nzAccept]="'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                text/plain, application/pdf, image/*, video/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload-gia-report"
                                (nzChange)="handleChangeGIAReport($event)"
                            >
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="upload" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                    {{gia_report=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                        
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Quotation
                        </div>
                        <div
                        class="d-flex flex-column "
                        [class.reporting-only-uploads]="quotation_report != ''">
                            <a 
                            *ngIf="quotation_report!=''"
                            class="mb-2 uppercase" [href]="quotation_report" target="_blank">
                                Download
                            </a>
                            <nz-upload
                                [nzAccept]="'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                text/plain, application/pdf, image/*, video/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload-quotation-reporting"
                                (nzChange)="handleChangeQuotationReporting($event)"
                            >
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="upload" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                    {{quotation_report=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            PTE Settlement Form
                        </div>
                        <div
                         class="d-flex flex-column "
                         [class.reporting-only-uploads]="pte_settlement_form != ''"
                        >
                            <a 
                            *ngIf="pte_settlement_form!=''"
                            class="mb-2 uppercase" [href]="pte_settlement_form" target="_blank">
                                Download
                            </a>
                                
                            <nz-upload
                                [nzAccept]="'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                text/plain, application/pdf, image/*, video/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload-pte-settlement-form"
                                (nzChange)="handleChangePTE($event)"
                            >
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="upload" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                    {{pte_settlement_form=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            NRIC/EP/SP/WP
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="nric_number" />
                        </div>
                    </div>
                </div>

                <div class="mt-4" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top: 15px;">
                    
                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Certificate of insurance 
                        </div>
                        <div
                        class="d-flex flex-column "
                        [class.reporting-only-uploads]="ci_file != ''">
                            <a 
                            *ngIf="ci_file!=''"
                            class="mb-2 uppercase" [href]="ci_file" target="_blank">
                                Download
                            </a>
                            <nz-upload
                                [nzAccept]="'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                text/plain, application/pdf, image/*, video/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload_ci_file"
                                (nzChange)="handleChangeCIFile($event)"
                            >
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="upload" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                    {{ci_file=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div>

                    <!-- <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Pictures
                        </div>
                        <div
                        class="d-flex flex-column "
                        [class.reporting-only-uploads]="ci_file != ''">
                            <a 
                            *ngIf="ci_file!=''"
                            class="mb-2 uppercase" [href]="ci_file" target="_blank">
                                Download
                            </a>
                            <nz-upload
                                [nzAccept]="'image/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload_ci_file"
                                (nzChange)="handleChangePictures($event)"
                                [(nzFileList)]="pictures"
                                [nzShowButton]="pictures.length < 8"
                            >
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="upload" aria-hidden="true"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                    {{ci_file=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div> -->

                </div>


                <div style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 45px;justify-content: flex-start; align-items: center;">Other Details</div>
                <nz-divider ></nz-divider>

                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Driver
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="driver" />
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Accident Date
                        </div>
                        <div>
                            <nz-date-picker
                                class="w-100"
                                [nzFormat]="'dd-MM-yyyy'"
                                [(ngModel)]="accident_date"
                            ></nz-date-picker>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Accident Time
                        </div>
                        <div>
                            <nz-time-picker [(ngModel)]="accident_time" class="w-100" [nzFormat]="'HH:mm:ss'"></nz-time-picker>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Location
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="location" />
                        </div>
                    </div>

                </div>

                <!-- <div class="mt-3"  style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            NRIC number
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="nric_number" />    
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            EP
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="ep_number" />
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            SP
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="sp_number" />
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            WP
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="wp_number" />
                        </div>
                    </div>

                </div> -->

                <div class="mt-3" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Damage Status
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="damage_status" />
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Third Party Vehicle Number
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="third_party_vehicle_number" />
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Cost of Repair
                        </div>
                        <div>
                            <nz-input-number [(ngModel)]="cost_of_repair" class="w-100" [nzMin]="0" [nzStep]="100"></nz-input-number>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Received Amount
                        </div>
                        <div>
                            <nz-input-number [(ngModel)]="received_amount" class="w-100" [nzMin]="0" [nzStep]="100"></nz-input-number>
                        </div>
                    </div>

                </div>

                <div class="mt-3" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Details of Accident
                        </div>
                        <div>
                            <textarea nz-input [(ngModel)]="details_of_accident" ></textarea>
                        </div>
                    </div>
                </div>

                <div class="mt-3" style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;justify-content: flex-start; align-items: center;">Customer Remarks</div>
                <nz-divider ></nz-divider>

                <div class='stats__table'>
                    <nz-table #productTable [nzData]="listOfRemarks" [nzNoResult]="null" [nzShowPagination]="false">
                    <thead>
                        <tr>
                        <th style="max-width:50px">ID</th>
                        <th style="width:100%">Remarks</th>
                        <th style="max-width:50px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of listOfRemarks;let i = index;">
                        <td>{{ i+1 }}</td>
                        <td>
                            <input placeholder="Customer Remark.." nz-input [(ngModel)]="data.text" />
                        </td>
                        <td>
                            <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;cursor: pointer;">
                                <div (click)="removeRemarks(i)">
                                    <svg width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5286 1H3.06607C1.92501 1 1 1.89543 1 3V17C1 18.1046 1.92501 19 3.06607 19H17.5286C18.6696 19 19.5947 18.1046 19.5947 17V3C19.5947 1.89543 18.6696 1 17.5286 1Z" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.19824 7L13.3965 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.3965 7L7.19824 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </td>
                        
                        </tr>
                    </tbody>
                    </nz-table>
                </div>

                <button nz-button style="margin-bottom: 25px;" (click)="addRemarks()">Add Remark</button>

            </nz-card>
        </div>
    </div>
</div>