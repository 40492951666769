import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

function getBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

@Component({
  selector: 'app-washing-form',
  templateUrl: './washing-form.component.html',
  styleUrls: ['./washing-form.component.css']
})
export class WashingFormComponent implements OnInit {

  listOfRemarks = [];
  check_in_date = new Date();
  remarks = [];
  all_customers = [];
  customer_id = -1;
  customer_name = '';
  phone = '';
  customer_vehicles = [];
  vehicle_id = -1;
  make = '';
  model = '';
  mfg_date = null;
  order_id = 0;
  isEdit = false;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  

  settlement_type = '';

  gia_report = '';
  quotation_report = '';
  pte_settlement_form = '';

  driver = '';
  accident_date = null;
  accident_time = null;
  details_of_accident = '';
  location = '';
  claim_type = '';
  damage_status = '';
  third_party_vehicle_number = '';
  cost_of_repair = '';
  received_amount = '';
  nric_number = '';
  ep_number = '';
  sp_number = '';
  wp_number = '';
  ci_file = '';
  pictures = [];

  is_incident_only = false;
  
  incident_number = '';
  employee_name = '';
  dept_name = '';
  event_type = '';
  date = null;
  recorded_by = '';
  details_of_incident = '';
  status = '';
  previewVisible = false;
  previewImage = '';

  wash_date_time = new Date();
  wash_end_time = null;

  vehicle_department = '';
  vehicle_type = '';

  driver_name = '';
  washer_name = '';

  listOfBookings = [];

  service_booking_id = 0;

  wash_register_id = 0;

  constructor(private msg: NzMessageService, private route: ActivatedRoute, private router: Router, private themeService: ThemeConstantService ) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.route.queryParams
    .subscribe(params => {
        this.wash_register_id = params.wash_register_id;
        if(this.wash_register_id) {
          this.isEdit = true;
          this.getWashRegister();
        }
        this.getGeneralServicingPrefs();
      }
    );
  }

  getGeneralServicingPrefs() {
    var url = '/api/all-customers-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.all_customers = response.data.all_customers;
    })
  }

  loadVehicleDetails(e) {
    const index = this.customer_vehicles.findIndex((el)=>el.id==e);
    const element = this.customer_vehicles[index];

    this.vehicle_type = element.vehicle_type;
    this.vehicle_department = element.vehicle_department;

    this.getBookings();
  }

  getBookings() {
    var url = '/api/all-service-bookings';

    var data = {
      customer_id: 1,
      search: '',
      type: 'WASH',
      vehicle_department: this.vehicle_department,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfBookings = response.data.service_bookings;
    })
  }

  getWashRegister() {
    var url = '/api/get-washing-register-single';

    var data = {
      id: this.wash_register_id
    };

    axios.post(url, data, {}).then(response => {
      this.customer_id = response.data.washing_register.customer_id;
      this.loadCustomerDetails(this.customer_id);
      this.customer_name = response.data.washing_register.customer_name;
      this.vehicle_id = response.data.washing_register.vehicle_id;
      this.make = response.data.washing_register.make;
      this.model = response.data.washing_register.model;
      this.mfg_date = response.data.washing_register.mfg_date;
      this.vehicle_department = response.data.washing_register.vehicle_department;
      this.vehicle_type = response.data.washing_register.vehicle_type;
      this.service_booking_id = response.data.washing_register.service_booking_id;
      this.wash_date_time = response.data.washing_register.time ? moment(response.data.washing_register.time).toDate() : null;
      this.wash_end_time = response.data.washing_register.end_time ? moment(response.data.washing_register.end_time, 'HH:mm:ss').toDate() : null;
      this.driver_name = response.data.washing_register.driver_name;
      this.washer_name = response.data.washing_register.washer_name;
      this.pictures = response.data.washing_register.pictures;
    })
  }

  createNewOrder() {
    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return;
    }

    if(this.wash_date_time==null) {
      this.msg.error("Please select wash date!");
      return;
    }

    var url = '/api/new-washing-register';

    if(this.isEdit) {
      url = '/api/update-washing-register';
    }

    var data = {
      id: this.wash_register_id,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      pictures: JSON.stringify(this.pictures),
      time: moment(this.wash_date_time).format('YYYY-MM-DD HH:mm:ss'),
      start_time: moment(this.wash_date_time).format('HH:mm:ss'),
      end_time: this.wash_end_time ? moment(this.wash_end_time).format('HH:mm:ss') : null,
      driver_name: this.driver_name,
      washer_name: this.washer_name,
      service_booking_id: this.service_booking_id,
    };

    axios.post(url, data, {}).then(response => {

      if(response.data.success==false) {
        this.msg.error(response.data.message);
      } else {
        this.msg.success("Wash Entry Created Successfully!");
        this.router.navigate(['/wash-register']);
      }

    })
  }

  loadCustomerDetails(e, loadVehicleDetails=false) {
    var url = '/api/get-customer-single';

    var data = {
      customer_id:e
    };

    axios.post(url, data, {}).then(response => {
      this.customer_id = response.data.id;
      this.customer_name = response.data.customer_name;
      this.phone = response.data.phone;
    })

    var url = '/api/customer-vehicles';

    var data2 = {
      customer_id:e
    };

    axios.post(url, data2, {}).then(response => {
      this.customer_vehicles = response.data.customer_vehicles;
      if(loadVehicleDetails) {
        this.loadVehicleDetails(this.vehicle_id);
      }
    })
  }

  allImagesSave(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.saveAllImagesFiles();
    }
  }

  saveAllImagesFiles() {
    var pictures = [];
    this.pictures.forEach((element) => {
      if (element.response) {
        var other_images_obj = {
          url: element.response.url,
        };
        pictures.push(other_images_obj);
      } else if(element.url) {
        var other_images_obj = {
          url: element.url,
        };
        pictures.push(other_images_obj);
      }
    });
    this.pictures = pictures;
  }

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

}
