import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-accidental-claim',
  templateUrl: './accidental-claim.component.html',
  styleUrls: ['./accidental-claim.component.css']
})
export class AccidentalClaimComponent implements OnInit {

  listOfRemarks = [];
  check_in_date = new Date();
  remarks = [];
  all_customers = [];
  customer_id = -1;
  customer_name = '';
  phone = '';
  customer_vehicles = [];
  vehicle_id = -1;
  make = '';
  model = '';
  mfg_date = null;
  order_id = 0;
  isEdit = false;

  all_surveyors = [];
  all_insurance_companies = [];
  filtered_surveyors = [];
  surveyor_id = -1;
  insurance_company_id = -1;
  surveyor_company_name ='';
  surveyor_name ='';
  surveyor_phone = '';
  survey_date_time = null;
  survey_time = null;
  send_server_mail = false;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  settlement_type = '';
  insurancing_company = '';

  incident_number = '';
  accident_date = null;
  accident_time = null;
  driver = '';
  type_of_accident = '';
  area_of_focus = '';
  details_of_accident = '';
  location = '';
  claim_type = '';
  cor = '';
  deduction = '';
  vehicle_repaired_status = '';
  str_date = null;
  sir_status = '';
  accident_remarks = '';

  listOfThirdParty = [];

  third_party_modal = false;

  third_party = {
    name: '',
    nric: '',
    contact: '',
    vehicle_no: '',
    company: '',
    pic_name: '',
    pic_contact: ''
  }

  gears_report_date = null;
  gears_report_id = "";
  gears_report__document = "";

  pre_payment_required = "";
  private_settlement_form = "";
  private_settlement_invoice = "";
  hr_deduction_form = "";


  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;

  constructor(private msg: NzMessageService, private route: ActivatedRoute, private router: Router, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);   
    
    this.route.queryParams
    .subscribe(params => {
        if(params.order_id) {
          this.order_id = params.order_id;
          this.isEdit = true;
          this.getOrderSingle();
        }
        this.getGeneralServicingPrefs();
        this.getSurveyors();
      }
    );
    
  }

  getOrderSingle() {
    var url = '/api/get-order-single';

    var data = {
      order_id: this.order_id
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.order) {
        this.customer_id = response.data.order.customer_id;
        this.vehicle_id = response.data.order.vehicle_id;
        this.settlement_type = response.data.order.settlement_type;
        this.insurancing_company = response.data.order.insurancing_company;
        this.remarks = JSON.parse(response.data.order.remarks);
        this.listOfRemarks = JSON.parse(response.data.order.remarks);
        this.insurance_company_id = response.data.order.insurance_company_id;
        this.surveyor_id = response.data.order.surveyor_id;
        this.survey_date_time = new Date(response.data.order.survey_date_time);
        this.check_in_date = new Date(response.data.order.check_in_date);

        if(response.data.order.other_details) {
          const other_details = JSON.parse(response.data.order.other_details);
          this.incident_number = other_details.incident_number;
          const accident_date = other_details.accident_date;
          this.accident_date = accident_date ? moment(accident_date, 'YYYY-MM-DD').toDate() : null;
          const accident_time = other_details.accident_time;
          this.accident_time = accident_time ? moment(accident_time, 'HH:mm:ss').toDate() : null;
          this.driver = other_details.driver;
          this.type_of_accident = other_details.type_of_accident;
          this.area_of_focus = other_details.area_of_focus;
          this.details_of_accident = other_details.details_of_accident;
          this.location = other_details.location;
          this.claim_type = other_details.claim_type;
          this.cor = other_details.cor;
          this.deduction = other_details.deduction;
          this.vehicle_repaired_status = other_details.vehicle_repaired_status;
          const str_date = other_details.str_date;
          this.str_date =  str_date ? moment(str_date, 'YYYY-MM-DD').toDate() : null;
          this.sir_status = other_details.sir_status;
          this.accident_remarks = other_details.accident_remarks;
          this.listOfThirdParty = other_details.third_parties;

          this.gears_report_date = other_details.gears_report_date ? moment(other_details.gears_report_date, 'YYYY-MM-DD').toDate() : null;

          this.gears_report_id = other_details.gears_report_id;
          this.gears_report__document = other_details.gears_report__document;

          this.pre_payment_required = other_details.pre_payment_required;
          this.private_settlement_form = other_details.private_settlement_form;
          this.private_settlement_invoice = other_details.private_settlement_invoice;
          this.hr_deduction_form = other_details.hr_deduction_form;
        }

        setTimeout(() => {
          this.filtered_surveyors = this.all_surveyors.filter(el => el.insurance_company_id == response.data.order.insurance_company_id);

          const selectedSurveyor = this.all_surveyors.filter(el => el.id == response.data.order.surveyor_id);
          this.surveyor_company_name = selectedSurveyor[0].surveyor_company;
          this.surveyor_name = selectedSurveyor[0].surveyor_name;
          this.surveyor_phone = selectedSurveyor[0].surveyor_contact_no;
        }, 500);

      }
      if(response.data.customer) {
        this.customer_name = response.data.customer.customer_name;
        this.phone = response.data.customer.phone;
      }
      if(response.data.vehicle) {
        this.make = response.data.vehicle.make;
        this.model = response.data.vehicle.model;
        this.mfg_date = response.data.vehicle.mfg_date;
      }
      this.loadCustomerDetails(response.data.order.customer_id);
      setTimeout(() => {
        this.loadVehicleDetails(response.data.order.vehicle_id);
      }, 500);
    })
  }

  getGeneralServicingPrefs() {
    var url = '/api/all-customers-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.all_customers = response.data.all_customers;
    })
  }

  getSurveyors() {
    var url = '/api/get-surveyors-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.all_surveyors = response.data.all_surveyors;
      this.all_insurance_companies = response.data.all_insurance_companies;
    })
  }

  insuranceCompanySelected(e) {
    this.filtered_surveyors = this.all_surveyors.filter(el => el.insurance_company_id == e);
    this.surveyor_id = -1;
    this.surveyor_company_name = '';
    this.surveyor_name = '';
    this.surveyor_phone = '';
  }

  surveyorSelected(e) {
    const selectedSurveyor = this.all_surveyors.filter(el => el.id == e);
    this.surveyor_company_name = selectedSurveyor[0].surveyor_company;
    this.surveyor_name = selectedSurveyor[0].surveyor_name;
    this.surveyor_phone = selectedSurveyor[0].surveyor_contact_no;
  }

  loadVehicleDetails(e) {
    const index = this.customer_vehicles.findIndex((el)=>el.id==e);
    const element = this.customer_vehicles[index];

    this.make = element.make;
    this.model = element.model;
    this.mfg_date = element.mfg_date;
    if(!this.isEdit) {
      this.checkOrderExists(e);
    }
  }

  createNewOrder() {
    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return false;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return false;
    }

    if(this.listOfRemarks.length==0) {
      this.msg.error("Please enter customer remarks!");
      return false;
    }

    let empty_remark_count = 0;

    for (let index = 0; index < this.listOfRemarks.length; index++) {
      const element = this.listOfRemarks[index];
      if(element.text=="") {
        empty_remark_count++;
      }
    }

    if(empty_remark_count!=0) {
      this.msg.error("Please enter value in all the remarks!");
      return false;
    }

    if(this.settlement_type=='') {
      this.msg.error("Please select settlement type!");
      return false;
    }

    var url = '/api/new-order';

    const other_details = {
      incident_number: this.incident_number,
      accident_date: this.accident_date ? moment(this.accident_date).format('YYYY-MM-DD') : null,
      accident_time: this.accident_time ? moment(this.accident_time).format('HH:mm:ss') : null,
      driver: this.driver,
      type_of_accident: this.type_of_accident,
      area_of_focus: this.area_of_focus,
      details_of_accident: this.details_of_accident,
      location: this.location,
      claim_type: this.claim_type,
      cor: this.cor,
      deduction: this.deduction,
      vehicle_repaired_status: this.vehicle_repaired_status,
      str_date: this.str_date ? moment(this.str_date).format('YYYY-MM-DD') : null,
      sir_status: this.sir_status,
      accident_remarks: this.accident_remarks,
      third_parties: this.listOfThirdParty,
      gears_report_date: this.gears_report_date ? moment(this.gears_report_date).format('YYYY-MM-DD') : null,
      gears_report_id: this.gears_report_id,
      gears_report__document: this.gears_report__document,
      pre_payment_required: this.pre_payment_required,
      private_settlement_form: this.private_settlement_form,
      private_settlement_invoice: this.private_settlement_invoice,
      hr_deduction_form: this.hr_deduction_form,
    }

    var data = {
      type: 1,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      settlement_type: this.settlement_type,
      insurancing_company: this.surveyor_company_name,
      surveyor_id: this.surveyor_id,
      insurance_company_id: this.insurance_company_id,
      survey_date_time: this.survey_date_time ? moment(this.survey_date_time).format('YYYY-MM-DD HH:mm:ss') : null,
      booking_date: moment().format('YYYY-MM-DD'),
      check_in_date: moment(this.check_in_date).format('YYYY-MM-DD'),
      status: 0,
      remarks: JSON.stringify(this.listOfRemarks),
      other_details: JSON.stringify(other_details),
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==false) {
        if(response.data.prev_order_id) {
          this.msg.error(response.data.message);
        } else {
          this.msg.error(response.data.message);
        }
      } else {
        this.msg.success("Order created successfully!")
        this.router.navigate(['/accidental-claim-single'], { queryParams: { order_id: response.data }});
        //this.router.navigate(['/all-accidental-claims']);
      }
    })
  }

  checkOrderExists(vehicle_id: number) {
    // var url = '/api/check-order-exists';
    // var data = {
    //   vehicle_id: vehicle_id,
    // }

    // axios.post(url, data, {}).then(response => {
    //   if(response.data.success==false) {
    //     this.msg.error(response.data.message);
    //   } 
    // })
  }

  updateOrder() {
    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return false;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return false;
    }

    if(this.listOfRemarks.length==0) {
      this.msg.error("Please enter customer remarks!");
      return false;
    }

    let empty_remark_count = 0;

    for (let index = 0; index < this.listOfRemarks.length; index++) {
      const element = this.listOfRemarks[index];
      if(element.text=="") {
        empty_remark_count++;
      }
    }

    if(empty_remark_count!=0) {
      this.msg.error("Please enter value in all the remarks!");
      return false;
    }

    if(this.settlement_type=='') {
      this.msg.error("Please select settlement type!");
      return false;
    }

    if(this.survey_date_time==null) {
      this.msg.error("Please select survey date and time!");
      return false;
    }

    // if(this.surveyor_id==-1) {
    //   this.msg.error("Please select surveyor!");
    //   return false;
    // }

    var url = '/api/update-order';

    const other_details = {
      incident_number: this.incident_number,
      accident_date: this.accident_date ? moment(this.accident_date).format('YYYY-MM-DD') : null,
      accident_time: this.accident_time ? moment(this.accident_time).format('HH:mm:ss') : null,
      driver: this.driver,
      type_of_accident: this.type_of_accident,
      area_of_focus: this.area_of_focus,
      details_of_accident: this.details_of_accident,
      location: this.location,
      claim_type: this.claim_type,
      cor: this.cor,
      deduction: this.deduction,
      vehicle_repaired_status: this.vehicle_repaired_status,
      str_date: this.str_date ? moment(this.str_date).format('YYYY-MM-DD') : null,
      sir_status: this.sir_status,
      accident_remarks: this.accident_remarks,
      third_parties: this.listOfThirdParty,
      gears_report_date: this.gears_report_date ? moment(this.gears_report_date).format('YYYY-MM-DD') : null,
      gears_report_id: this.gears_report_id,
      gears_report__document: this.gears_report__document,
      pre_payment_required: this.pre_payment_required,
      private_settlement_form: this.private_settlement_form,
      private_settlement_invoice: this.private_settlement_invoice,
      hr_deduction_form: this.hr_deduction_form,
    }

    var data = {
      order_id: this.order_id,
      type: 1,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      settlement_type: this.settlement_type,
      insurancing_company: this.surveyor_company_name,
      surveyor_id: this.surveyor_id,
      insurance_company_id: this.insurance_company_id,
      survey_date_time: moment(this.survey_date_time).format('YYYY-MM-DD HH:mm:ss'),
      booking_date: moment().format('YYYY-MM-DD'),
      check_in_date: moment(this.check_in_date).format('YYYY-MM-DD'),
      remarks: JSON.stringify(this.listOfRemarks),
      other_details: JSON.stringify(other_details),
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success("Order updated successfully!");
      this.router.navigate(['/all-accidental-claims']);
    })
  }


  loadCustomerDetails(e) {
    var url = '/api/get-customer-single';

    var data = {
      customer_id:e
    };

    axios.post(url, data, {}).then(response => {
      this.customer_id = response.data.id;
      this.customer_name = response.data.customer_name;
      this.phone = response.data.phone;
    })

    var url = '/api/customer-vehicles';

    var data2 = {
      customer_id:e
    };

    axios.post(url, data2, {}).then(response => {
      this.customer_vehicles = response.data.customer_vehicles;
    })
  }

  removeRemarks(i) {
    const listOfRemarks = [...this.listOfRemarks];
    listOfRemarks.splice(i, 1);
    this.listOfRemarks = listOfRemarks;
  }

  addRemarks() {
    const listOfRemarks = [...this.listOfRemarks];
    listOfRemarks.push({text:""});
    this.listOfRemarks = listOfRemarks;
  }
  
  addThirdParty() {

    if(this.third_party.name=='') {
      this.msg.error("Please enter name!");
      return false;
    }

    if(this.third_party.nric=='') {
      this.msg.error("Please enter NRIC!");
      return false;
    }

    if(this.third_party.contact=='') {
      this.msg.error("Please enter contact!");
      return false;
    }

    if(this.third_party.vehicle_no=='') {
      this.msg.error("Please enter vehicle no!");
      return false;
    }

    let listOfThirdParty = [...this.listOfThirdParty];
    listOfThirdParty.push(this.third_party);
    this.listOfThirdParty = listOfThirdParty;
    console.log(this.listOfThirdParty);
    
    this.clearThirdParty();
  }

  removeThirdParty(i) {
    let listOfThirdParty = [...this.listOfThirdParty];
    listOfThirdParty.splice(i, 1);
    this.listOfThirdParty = listOfThirdParty;
  }

  clearThirdParty() {
    this.third_party_modal = false;
    this.third_party = {
      name: '',
      nric: '',
      contact: '',
      vehicle_no: '',
      company: '',
      pic_name: '',
      pic_contact: ''
    }
  }

  handleChange(info: NzUploadChangeParam, name) {
    if (info.file.status !== 'uploading') {
      this[name] = '';
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        this[name] = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }
}
