<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
            <nz-card>

                <div class="breadcrumbs-add-btn-bar">

                    <div class="title-breadrumbs-container">
                        <div class="title">MY PROFILE</div>
                    </div>

                    <!-- <div class="doc-search-btn">
                        <button *ngIf="!isEdit" nz-button nzType="primary" (click)="createNewOrder()">CREATE</button>
                        <button *ngIf="isEdit" nz-button nzType="primary" (click)="updateOrder()">SAVE</button>
                    </div> -->

                </div>

                <div class="customer-details-container" style="padding-bottom: 30px;"> 

                    <div style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;">Profile Details</div>
                    <nz-divider ></nz-divider>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Name
                            </div>
                                <div>
                                    <div>
                                        <input nz-input [(ngModel)]="name">
                                    </div>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                               Email
                            </div>
                                <div>
                                    <input nz-input [(ngModel)]="email" >
                                </div>
                        </div>
                    </div>


                </div>         
                <button nz-button style="margin-bottom: 25px;" (click)="updateProfile()">Save</button>

            </nz-card>

            <nz-card>

                <div class="breadcrumbs-add-btn-bar">

                    <div class="title-breadrumbs-container">
                        <div class="title">PASSWORD</div>
                    </div>

                </div>

                <div class="customer-details-container" style="padding-bottom: 30px;"> 

                    <div style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;">Change Password</div>
                    <nz-divider ></nz-divider>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                               New Password
                            </div>
                                <div>
                                    <div>
                                        <input nz-input [(ngModel)]="password" type="password">
                                    </div>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                               Confirm Password
                            </div>
                                <div>
                                    <input nz-input [(ngModel)]="confirm_password" type="password">
                                </div>
                        </div>
                    </div>
                </div>   
                
                <button nz-button style="margin-bottom: 25px;" (click)="changePassword()">Change Password</button>

            </nz-card>
        </div>
    </div>
</div>