import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  listOfRemarks = [];
  check_in_date = new Date();
  remarks = [];
  all_customers = [];
  customer_id = -1;
  customer_name = '';
  phone = '';
  customer_vehicles = [];
  vehicle_id = -1;
  make = '';
  model = '';
  mfg_date = null;
  order_id = 0;
  isEdit = false;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  name:string = '';
  email:string = '';

  password = '';
  confirm_password = '';

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;

  constructor(private msg: NzMessageService, private route: ActivatedRoute, private router: Router, private themeService: ThemeConstantService ) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    
    this.getProfile();
    
  }

  getProfile() {
    var url = '/api/app/get-profile';

    var data = {

    };

    axios.post(url, data, {}).then(response => {
      this.name = response.data.name;
      this.email = response.data.email;
    })
  }

  updateProfile() {

    if(this.name == '') {
      this.msg.error('Please enter your name');
      return;
    }

    if(this.email == '') {
      this.msg.error('Please enter your email');
      return;
    }

    if(this.email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )==null) {
      this.msg.error('Please enter a valid email');
      return;
    }

    var url = '/api/app/update-profile';

    var data = {
      name: this.name,
      email: this.email
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success('Profile updated successfully');
      localStorage.setItem('name', this.name);
      localStorage.setItem('email', this.email);
      window.location.reload();
    })
  }

  changePassword() {

    if(this.password == '') {
      this.msg.error('Please enter your password');
      return;
    }

    if(this.confirm_password == '') {
      this.msg.error('Please enter your confirm password');
      return;
    }

    if(this.password != this.confirm_password) {
      this.msg.error('Password and confirm password do not match');
      return;
    }

    var url = '/api/app/update-password';

    var data = {
      password: this.password,
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success('Password updated successfully');
    })
  }

}
