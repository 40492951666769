import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-product-single',
  templateUrl: './product-single.component.html',
  styleUrls: ['./product-single.component.css']
})
export class ProductSingleComponent implements OnInit {


  isClicked:boolean=false;
  options: string[] = [
    'products',
    'purchase orders',
    'stock out',
    'dashboard',
    'master data',
    'transactions',
  ];

  createNewQuote = false;
  editPOModal=false;
  activeTab = 0;

  po_sort_by_field = 'vendor_name';
  po_sort_by_type = 'desc';

  listOfColumn = [
    {
      title: 'ID',
  
    },
    {
      title: 'VENDOR NAME',
    },
    {
      title: 'CONTACT',
    },
    {
      title: 'ADDRESS',
    },
    {
      title:'TOTAL BUSINESS'
    },
    {
      title:'CREATED ON'
    },
    {
      title:'CREATED BY'
    },
    {
      title:'CONTACT PERSON'
    }
  ];

  listOfData = [];

  createNewPOModal = false;
  listOfPurchaseOrders = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };

  vendor_id = 0;
  product_id = 0;
  quotation_id = 0;
  price = 0;
  quantity = 0;

  vendors = [];
  quotations = [];
  products = [];


  openAddProductModal = false;

  product_name = '';
  product_image = '';
  make = '';
  model = '';
  category_id = 0;
  type = '';
  recyclable_amount = 0;
  category_name = '';
  quote_price = 0;
  selling_price = 0;
  initial_stock = 0;
  reorder_level = 0;
  location = '';
  stock_in_hand = 0;
  status = 0;
  labour_charges = 0;
  categories = [];
  isProductEdit = true;
  isLoadingProducts = false;
  product_search = '';
  listOfProducts = [];
  newQuoteModal=false;

  purchase_order_name = '';
  po_doc = '';
  isLoadingPurchaseOrders = false;
  purchase_order_search = '';
  purchase_order_id = 0;
  purchase_order_status = 0;
  old_po_status = -2;
  isPurchaseOrderEdit = false;
  date = new Date();

  quotation_date = new Date();
  quotation_doc = '';
  quotation_vendor_id = 0;
  quotation_price = 0;
  quotation_expiry_date = null;
  ref_no = '';
  activeQuotes = 0;

  quotation_search = '';
  quotation_sort_by_field = 'created_at';
  quotation_sort_by_type = 'desc';
  listOfQuotations = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  isLoadingQuotation = false;

  isLoadingPriceList = false;
  listOfPriceList = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  pl_sort_by_field = 'created_at';
  pl_sort_by_type = 'desc';

  listOfTransactions = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  isLoadingTransactions = false;
  transactions_sort_by_field = 'created_at';
  transactions_sort_by_type = 'desc';

  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  fullLoader: boolean = false;

  quotations_all = [];

  last_purchase_date = '';

  formatterDollar = (value: number): string => `$ ${value}`;
  parserDollar = (value: string): string => value.replace('$ ', '');
  GSTQuotePrice: number = 0;

  petty_cash_purchase = false;
  price_chart_search: any;
  
  constructor(private msg: NzMessageService, private route: ActivatedRoute, private themeService: ThemeConstantService) { }

  ngOnInit(): void {

    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.route.queryParams
      .subscribe(params => {
        this.product_id = +params.product_id;
        this.getCategories();
        this.getProductSingle();
        this.getPurchaseOrderCreateData();
        this.filterMethodPurchaseOrders();
        this.filterMethodQuotations();
        this.filterMethodPriceList();
        this.filterMethodTransactions();
        this.lastPurchaseDate(+params.product_id);
      }
    );
  }

  lastPurchaseDate(product_id) {
    var url = '/api/last-purchase-date';

    var data = {
      product_id: product_id
    };

    axios.post(url, data, {}).then(response => {
      this.last_purchase_date = response.data.date;
    })
  }

  getCategories() {
    var url = '/api/categories-dropdown';

    var data = {};

    axios.post(url, data, {}).then(response => {
      this.categories = response.data.categories;
    })
  }

  getProductSingle() {
    this.isLoadingProducts = true;

    var url = '/api/get-product-single';

    var data = {
      product_id: this.product_id
    };

    axios.post(url, data, {}).then(response => {
      this.product_name = response.data.product_name;
      this.product_image = response.data.product_image;
      this.make = response.data.make;
      this.model = response.data.model;
      this.category_id = response.data.category_id;
      this.category_name = response.data.category_name;
      this.type = response.data.type;
      this.recyclable_amount = response.data.recyclable_amount;
      this.price = response.data.price;
      this.selling_price = response.data.selling_price;
      this.initial_stock = response.data.initial_stock;
      this.reorder_level = response.data.reorder_level;
      this.labour_charges = response.data.labour_charges;
      this.stock_in_hand = response.data.stock_in_hand;
      this.location = response.data.location;
      this.status = response.data.status;
    })
  }

  calcGSTQuotePrice() {
   this.GSTQuotePrice = this.quote_price * 8 / 100;
  }

  newProduct() {
    let url = '';

    if (this.product_name == '') {
      this.msg.error('Please enter product name!');
      return false;
    }

    if (this.product_image == '') {
      this.msg.error('Please upload product image!');
      return false;
    }

    if (this.make == '') {
      this.msg.error('Please enter product make!');
      return false;
    }

    if (this.model == '') {
      this.msg.error('Please enter product model!');
      return false;
    }

    if (this.location == '') {
      this.msg.error('Please enter location!');
      return false;
    }

    if (this.type == '') {
      this.msg.error('Please select product type!');
      return false;
    }

    if(!this.selling_price) {
      this.msg.error('Please enter selling price!');
      return false;
    }

    if(this.initial_stock<0) {
      this.msg.error('Please enter valid initial stock!');
      return false;
    }

    if(isNaN(this.initial_stock)) {
      this.msg.error('Please enter valid initial stock!');
      return false;
    }

    if(this.isProductEdit) {
      url = '/api/update-product';
    } else {
      url = '/api/new-product';
    }

    let data = {
       product_name: this.product_name,
       product_image: this.product_image,
       make: this.make,
       model: this.model,
       product_id: this.product_id,
       category_id: this.category_id,
       price: this.price,
       selling_price: this.selling_price,
       initial_stock: this.initial_stock,
       reorder_level: this.reorder_level,
       labour_charges: this.labour_charges,
       location: this.location,
       status: this.status,
       type: this.type,
       recyclable_amount: this.recyclable_amount,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.openAddProductModal = false;
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }



  getPurchaseOrderCreateData() {
    var url = '/api/get-purchase-order-create-data';

    var data = {};

    axios.post(url, data, {}).then(response => {
      this.vendors = response.data.vendors;
      this.quotations_all = response.data.quotations;
      this.quotations = response.data.quotations;
      this.products = response.data.products;
    })
  }

  handleChange(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        // Component will show file.url as link
        this.product_image = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  handleChangePO(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        // Component will show file.url as link
        this.po_doc = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  compareDates(expiry_date) {
    return new Date().getTime() <= new Date(expiry_date).getTime() ? true : false
  }

  filterQuotations(e) {
    if(e) {
      this.quotations = this.quotations_all.filter((el)=>el.vendor_id==e);
    } else {
      this.quotations = this.quotations_all;
    }
  }

  printPO(id) {
    this.fullLoader = true;
    var url = '/api/generate-po';

    var data = {
      po_id: id,
    };

    axios.post(url, data, {}).then(response => {
      this.fullLoader = false;
      const printJS = require('print-js')
      printJS({ printable: response.data.url})
    }).catch(error => {
      this.fullLoader = false;
      this.msg.error("Something Went Wrong!");
    })
  }

  handleChangeQuatation(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        // Component will show file.url as link
        this.quotation_doc = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  quoteSelected(e) {
    const selectedQuotation = this.quotations_all.filter((el)=>el.id==e)[0];
    this.vendor_id = selectedQuotation.vendor_id;
    this.quote_price = selectedQuotation.price;
    this.calcGSTQuotePrice();
  }

  filterMethodPurchaseOrders(page_index=1, page_size=10) {
    
    this.isLoadingPurchaseOrders = true;

    var url = '/api/all-purchase-orders';

    var data = {
      product_id: this.product_id,
      search: this.purchase_order_search,
      page_index: page_index,
      page_size: page_size,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfPurchaseOrders = response.data.all_purchase_orders;
      this.isLoadingPurchaseOrders=false;
    })
  }

  onPurchaseOrdersChange($event) {
    this.filterMethodPurchaseOrders($event.pageIndex, $event.pageSize);
  }


  filterMethodTransactions(page_index=1, page_size=10) {
    this.isLoadingTransactions = true;

    var url = '/api/all_transactions';

    var data = {
      product_id: this.product_id,
      search: this.purchase_order_search,
      page_index: page_index,
      page_size: page_size,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfTransactions = response.data.all_transactions;
      this.isLoadingTransactions = false;
    })
  }

  onTransactionsChange($event) {
    this.filterMethodTransactions($event.pageIndex, $event.pageSize);
  }

  filterMethodPriceList(page_index=1, page_size=10) {
    this.isLoadingPriceList = true;

    var url = '/api/all_price_chart';

    var data = {
      product_id: this.product_id,
      search: this.price_chart_search,
      page_index: page_index,
      page_size: page_size,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfPriceList = response.data.all_price_chart;
      this.isLoadingPriceList = false;
  })
  }

  onPriceListChange($event) {
    this.filterMethodPriceList($event.pageIndex, $event.pageSize);
  }


  filterMethodQuotations(page_index=1, page_size=10) {
    this.isLoadingQuotation = true;

    var url = '/api/all-quotations';

    var data = {
      search: this.quotation_search,
      page_index: page_index,
      page_size: page_size,
      product_id: this.product_id,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfQuotations = response.data.all_quotations;
      this.isLoadingQuotation = false;
    })
  }

  onQuotationsChange($event) {
    this.filterMethodQuotations($event.pageIndex, $event.pageSize);
  }

  newPurchaseOrder() {

    if(this.quantity==0) {
      this.msg.error("Please enter quantity!");
      return false;
    }

    if(this.quotation_id==0) {
      this.msg.error("Please select a quotation!");
      return false;
    }

    let url = '';

    if(this.isPurchaseOrderEdit) {
      url = '/api/update-purchase-order';
    } else {
      url = '/api/new-purchase-order';
    }

    let data = {
       purchase_order_id: this.purchase_order_id,
       purchase_order_name: this.purchase_order_name,
       po_doc: this.po_doc,
       quotation_id: this.quotation_id,
       vendor_id: this.vendor_id,
       product_id: this.product_id,
       quantity: this.quantity,
       status: this.purchase_order_status,
       price: this.quote_price,
       date: moment(this.date).format('YYYY-MM-DD'),
       petty_cash_purchase: this.petty_cash_purchase,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        if(this.isPurchaseOrderEdit) {
          this.msg.success("Purchase order updated!");
        } else {
          this.msg.success("Purchase order created!");
        }
        this.createNewPOModal=false;
        this.isPurchaseOrderEdit=false;
        this.filterMethodPurchaseOrders();
        this.clearPreviousData();
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  editPurchaseOrder(data) {
    this.purchase_order_name = data.purchase_order_name;
    this.po_doc = data.po_doc;
    this.quotation_id = data.quotation_id;
    this.vendor_id = data.vendor_id;
    this.quantity = data.quantity;
    this.quote_price = data.price;
    this.calcGSTQuotePrice();
    this.purchase_order_id = data.id;
    this.purchase_order_status = data.status;
    this.old_po_status = data.status;
    this.date = new Date(data.date);
    this.petty_cash_purchase = data.petty_cash_purchase;
    
    this.isPurchaseOrderEdit = true;
    this.createNewPOModal=true;
  }

  newPurchaseOrderByQuote(data) {
    this.quotation_id = data.id;
    this.vendor_id = data.vendor_id;
    this.quote_price = data.price;
    this.calcGSTQuotePrice();
    this.createNewPOModal=true;
  }

  deletePurchaseOrder() {
    var url = '/api/delete-purchase-order';

    var data2 = {
       purchase_order_id: this.purchase_order_id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.createNewPOModal=false;
        this.filterMethodPurchaseOrders();
        this.msg.success("Purchase Order deleted successfully!")
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }


  newQuote() {

    if(this.quote_price==0) {
      this.msg.error("Please enter price!");
      return false;
    }

    if(this.quotation_vendor_id==0) {
      this.msg.error("Please select a vendor!");
      return false;
    }

    if(this.quotation_expiry_date==null) {
      this.msg.error("Please select expiry date!");
      return false;
    }

    if(this.quotation_doc=='') {
      this.msg.error("Please upload Quotation Document!");
      return false;
    }

    
    if(this.ref_no=='') {
      this.msg.error("Please enter ref no!");
      return false;
    }

    if(this.quotation_expiry_date<this.quotation_date) {
      this.msg.error("Expiry date should be greater than date!");
      return false; 
    }

    let url = '/api/new-quotation';;

    let data = {
       ref_no: this.ref_no,
       quotation_id: this.quotation_id,
       quotation_doc: this.quotation_doc,
       vendor_id: this.quotation_vendor_id,
       product_id: this.product_id,
       price: this.quote_price,
       date: moment(this.quotation_date).format('YYYY-MM-DD'),
       expiry_date: moment(this.quotation_expiry_date).format('YYYY-MM-DD'),
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.msg.success("Quotation created!");
        this.newQuoteModal=false;
        this.filterMethodQuotations();
        this.getPurchaseOrderCreateData();
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else  {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  GenerateProductName() {

    if(this.categories.filter((el)=>el.id==this.category_id).length>0) {
      const category_name = this.categories.filter((el)=>el.id==this.category_id)[0].category_name;
      this.product_name = category_name+"-"+this.make+"-"+this.model;
    } else {
      this.product_name = ""+"-"+this.make+"-"+this.model;
    }

  }

  downloadCsvPriceList() {
    const { Parser } = require('json2csv');
    const myCars = this.listOfPriceList;
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(myCars);
    require("downloadjs")(csv, 'price_list.csv');
  }

  downloadCsvTransactions() {
    const { Parser } = require('json2csv');
    const myCars = this.listOfTransactions;
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(myCars);
    require("downloadjs")(csv, 'transactions.csv');
  }

  downloadCsvPurchaseOrders() {
    const { parse } = require('json2csv');
    const PurchaseOrders = this.listOfPurchaseOrders.data;
    const fields = [{
      label: 'PO #', 
      value: (row:any) => row.id,
    }, {
      label: 'Product',
      value: (row:any) => row.product_name,
    }, {
      label: 'Vendor Name',
      value: (row:any) => row.vendor_name,
    }, {
      label: 'Cost/Unit',
      value: (row:any) => row.price,
    }, {
      label: 'Quantity',
      value: (row:any) => row.quantity,
    }, {
      label: 'Total Cost',
      value: (row:any) => row.price * row.quantity,
    }, {
      label: 'Date',
      value: (row:any) => moment(row.date).format('DD/MM/YYYY'),
    }, {
      label: 'Status',
      value: (row:any) => row.status==3 ? 'Approved' : row.status==-1 ? 'Pending Approval' : row.status==0 ? 'Sent' : row.status==1 ? 'Stock Received' : 'Rejected',
    }, {
      label: 'Created By',
      value: (row:any) => row.created_by_name,
    }, {
      label: 'Quotation',
      value: (row:any) => row.quotation_id,
    }];

    const csv = parse(PurchaseOrders, { fields });
    require("downloadjs")(csv, 'Purchase Orders.csv');
  }

  downloadCsvQuotations() {
    const { parse } = require('json2csv');
    const Quotations = this.listOfQuotations.data;
    const fields = [{
      label: 'Quotation #', 
      value: (row:any) => row.id,
    }, {
      label: 'Product',
      value: (row:any) => row.product_name,
    }, {
      label: 'Vendor Name',
      value: (row:any) => row.vendor_name,
    }, {
      label: 'Cost/Unit',
      value: (row:any) => row.price,
    }, {
      label: 'Date',
      value: (row:any) => moment(row.date).format('DD/MM/YYYY'),
    }, {
      label: 'Expiry Date',
      value: (row:any) => moment(row.expiry_date).format('DD/MM/YYYY'),
    }, {
      label: 'Status',
      value: (row:any) => row.status,
    }, {
      label: 'Created By',
      value: (row:any) => row.created_by_name,
    }];

    const csv = parse(Quotations, { fields });
    require("downloadjs")(csv, 'Quotations.csv');
  }

  clearPreviousData() {
    this.isPurchaseOrderEdit = false;
    this.purchase_order_name = '';
    this.po_doc = '';
    this.purchase_order_search = '';
    this.purchase_order_id = 0;
    this.purchase_order_status = 0;
    this.old_po_status = -2;
    this.isPurchaseOrderEdit = false;
    this.date = new Date();
    this.vendor_id = 0;
    this.quotation_id = 0;
    this.quote_price = 0;
    this.quantity = 0;
    this.GSTQuotePrice = 0;

    this.petty_cash_purchase = false;
    
    this.quotation_date = new Date();
    this.quotation_doc = '';
    this.quotation_vendor_id = 0;
    this.quotation_price = 0;
    this.quotation_expiry_date = new Date();
    this.ref_no = '';
    this.quotations = this.quotations_all
  }
}
