import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-order-single',
  templateUrl: './order-single.component.html',
  styleUrls: ['./order-single.component.css']
})
export class OrderSingleComponent implements OnInit {

  listOfData = [];
  stock_out_id = 0;
  order_id = 0;
  requested_by = 0;
  date = '';
  status = 0;
  collected_on = '';
  materials = [];
  total_qt = 0;
  created_at = '';

  returnItemModal = false;
  collectItemModal = false;
  adjustItemModal = false;

  product_id = 0;
  product_name = '';
  product_quantity = 0;
  product_type = '';
  recyclable_amount = 0;

  max_collection_allowed = 0;
  max_return_allowed = 0;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  constructor(private msg: NzMessageService, private route: ActivatedRoute, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

    this.route.queryParams
      .subscribe(params => {
        this.stock_out_id = params.stock_out_id;
        this.getStockOutDetails();
      });
  }

  getStockOutDetails() {
    var url = '/api/get-stock-out-details';

    var data = {stock_out_id:this.stock_out_id};

    axios.post(url, data, {}).then(response => {
      this.listOfData = response.data.stock_out_materials;
      if(response.data.stock_out) {
        this.order_id = response.data.stock_out.order_id;
        this.requested_by = response.data.stock_out.requested_by_name;
        this.date = response.data.stock_out.date;
        this.status = response.data.stock_out.status;
        this.collected_on = response.data.stock_out.collected_on;
        this.materials = JSON.parse(response.data.stock_out.materials);
        this.total_qt = response.data.stock_out.total_qt;
        this.created_at = response.data.stock_out.created_at;
      }
    })
  }

  collectItem() {

    if(this.product_quantity==0) {
      return false;
    }

    var url = '/api/mark-stock-out-collected';

    var data = {
      stock_out_id:this.stock_out_id,
      product_id: this.product_id,
      product_name: this.product_name,
      product_quantity: this.product_quantity,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success) {
        this.getStockOutDetails()
        this.msg.success("Material marked as collected!");

        this.collectItemModal=false;
        this.product_id=0;
        this.product_name='';
        this.product_quantity=0;
        
      } else if(response.data.quantity_error) {
        this.msg.error("Quantity is low!");
      } else {
        this.msg.error("Something went wrong!");
      }
    })
  }

  returnItem() {

    if(this.product_quantity==0) {
      return false;
    }

    var url = '/api/mark-stock-out-returned';

    var data = {
      stock_out_id:this.stock_out_id,
      product_id: this.product_id,
      product_name: this.product_name,
      product_quantity: this.product_quantity,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success) {
        this.getStockOutDetails()
        this.msg.success("Material returned!");
      } else if(response.data.quantity_error) {
        this.msg.error("Material must be collected before it is returned!");
      } else {
        this.msg.error("Something went wrong!");
      }
    })
  }

  getLastAdjustedQuantity(product_id) {
    var url = '/api/get-last-adjusted-quantity';

    var data = {
      stock_out_id:this.stock_out_id,
      product_id: product_id,
    };

    axios.post(url, data, {}).then(response => {
      this.product_quantity = response.data.quantity;
      this.recyclable_amount = response.data.price;
    })
  }

  adjustItem() {
      
      if(this.product_quantity==0) {
        return false;
      }
  
      var url = '/api/adjust-stock-out-quantity';
  
      var data = {
        stock_out_id:this.stock_out_id,
        product_id: this.product_id,
        product_quantity: this.product_quantity,
        product_type: this.product_type,
        recyclable_amount: this.recyclable_amount,
      };
  
      axios.post(url, data, {}).then(response => {
        if(response.data.success) {
          this.getStockOutDetails()
          this.msg.success("Material adjusted!");
        } else if(response.data.message) {
          this.msg.error(response.data.message);
        } else {
          this.msg.error("Something went wrong!");
        }
      })  
  }

}
