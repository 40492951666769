// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCRwBiZy5zua3MTBcWkcQARImIUrVlTvl0",
    authDomain: "bigfoot-1c50a.firebaseapp.com",
    projectId: "bigfoot-1c50a",
    storageBucket: "bigfoot-1c50a.appspot.com",
    messagingSenderId: "308936556395",
    appId: "1:308936556395:web:ae500d5318088a9969562e",
    measurementId: "G-1P588Q4SFL",
    vapidKey: "BCfqoMG60l_RZxB06CTsdXT6XcNj3TLOapnNtjMxFd94776dZ40zAoAGjo3SP5BKXeJyvuzf-00yA2-2yZitfms"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
