import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-dashboard-combined',
  templateUrl: './dashboard-combined.component.html',
  styleUrls: ['./dashboard-combined.component.css']
})
export class DashboardCombinedComponent implements OnInit {
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  overall_fleet = 0;
  bf_assets = 0;
  owned_and_rental_assets = 0;
  lay_up_assets = 0;
  leased_assets = 0;

  total_customers = 0;
  total_departments = 0;
  total_vehicles = 0;

  total_accidents = 0;
  accident_change_percentage = 0;
  total_incidents = 0;
  incident_change_percentage = 0;

  total_vpc = 0;
  total_vpc_locations = 0;
  expiring_in_15_days = 0;

  accidental_chart_data = [
    {
      label: 'My First dataset',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],
    },
  ];
  accidental_chart_labels = [];

  job_card = {
    open_sm: 0,
    open_ba: 0,
    on_progress: 0,
    closed: 0,
    delayed: 0,
  };

  labour_data = {
    mechanic_count: 0,
    qc_count: 0,
    washer_count: 0,
    supervisor_count: 0,
  };

  fuel_consumption_data = [];
  fuel_consumption_labels = [];

  lineChartData: Array<any> = [
    {
      label: 'My First dataset',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ];
  lineChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    annotation: {
      annotations: [
        {
          drawTime: 'afterDraw',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 70,
          borderColor: '#000000',
          borderWidth: 2,
          label: {
            backgroundColor: 'red',
            content: 'global plugin',
            enabled: true,
            position: 'center',
          }
        }
      ]
    },
    legend: {
        position: 'right',
        fontSize: 10
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      }
    }
  };
  lineChartOptions2: (ChartOptions & { annotation: any }) = {
    onClick: this.chartClick,
    responsive: true,
    annotation: {
      annotations: [
        {
          drawTime: 'afterDraw',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 70,
          borderColor: '#000000',
          borderWidth: 2,
          label: {
            backgroundColor: 'red',
            content: 'global plugin',
            enabled: true,
            position: 'center',
          }
        }
      ]
    },
    legend: {
      display: true,
      position: 'right',
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      }
    }
  };
  lineChartLegend = true;
  lineChartType = 'line';
  inlinePlugin: any;
  textPlugin: any;

  loadCharts = false;
  expiring_in_15_days_modal = false;
  loading_expiring_vehicles = false;
  VPC_data_modal = false;
  isLoadingVpcCustomer = false;
  expiring_in_15_days_data = [];
  listOfVpcCustomer = [];
  search = '';

  id = 0;
  openAddVpcCustomerModel = false;
  customer_id = 0;
  customer_name = '';
  name = '';
  expiry_date = '';
  start_date = '';
  all_customers: any = [];
  isVpcCustomerEdit = false;

  constructor(private themeService: ThemeConstantService, private message: NzMessageService) { 

  }

  chartClick(e, array): void {

  }

  loadExpiringVehicles() {
    this.expiring_in_15_days_modal = true;
    this.loading_expiring_vehicles = true;
      axios.post('/api/get-expiring-in-15-days')
      .then(response => {
        this.loading_expiring_vehicles = false;
        this.expiring_in_15_days_data = response.data.data;
      })
  }

  loadVPCdata() {
    this.VPC_data_modal = true;
    this.isLoadingVpcCustomer = true;
    var url = '/api/get-vpc-master';
    var data = {
      search: this.search
    }
    axios.post(url, data, {}).then(response => {
      this.listOfVpcCustomer = response.data.vpc_masters;
      this.isLoadingVpcCustomer = false;
    })
  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);   
    this.combinedDashboard();
    this.isFolded = true;
    this.isExpand = false;
    this.themeService.toggleFold(this.isFolded)
    this.getGeneralServicingPrefs();
  }

  getGeneralServicingPrefs() {
    var url = '/api/all-customers-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.all_customers = response.data.all_customers;
    })
  }

  combinedDashboard() {
    let url = '/api/get-combined-dashboard';

    let data = {

    };

    axios.post(url, data, {}).then(response => {
      this.overall_fleet = response.data.overall_fleet;
      this.bf_assets = response.data.bf_assets;
      this.owned_and_rental_assets = response.data.owned_and_rental_assets;
      this.lay_up_assets = response.data.lay_up_assets;
      this.leased_assets = response.data.leased_assets;

      this.total_customers = response.data.total_customers;
      this.total_departments = response.data.total_departments;
      this.total_vehicles = response.data.total_vehicles;
      this.total_accidents = response.data.total_accidents;

      this.accident_change_percentage = response.data.accident_change_percentage;
      this.total_incidents = response.data.total_incidents;

      this.incident_change_percentage = response.data.incident_change_percentage;

      this.total_vpc = response.data.total_vpc;
      this.total_vpc_locations = response.data.total_vpc_locations;
      this.expiring_in_15_days = response.data.expiring_in_15_days;

      this.job_card = response.data.job_card;
      this.labour_data = response.data.labour_data;

      this.fuel_consumption_data = response.data.fuel_consumption_data;
      this.fuel_consumption_labels = response.data.fuel_consumption_labels;

      this.accidental_chart_data[0].data = response.data.accidental_chart_data;
      this.accidental_chart_labels = response.data.accidental_chart_labels;

      this.loadCharts = true;
    })
  }

  newAddVpcCustomer() {
    if(!this.customer_id) {
      this.message.error("Please select customer!");
      return false;
    }

    if(!this.name) {
      this.message.error("Please select location!");
      return false;
    }

    if(!this.start_date) {
      this.message.error("Please select start date!");
      return false;
    }

    if(!this.expiry_date) {
      this.message.error("Please select expiry date!");
      return false;
    }

    if(this.start_date > this.expiry_date) {
      this.message.error("Expiry date should be greater than start date!");
      return false;
    }

    let url = '';

    if(this.isVpcCustomerEdit) {
      url = '/api/update-vpc-master';
    } else {
      url = '/api/new-vpc-master';
    }

    let data = {
      id: this.id,
      customer_id: this.customer_id,
      name: this.name,
      expiry_date: moment(this.expiry_date).format('YYYY-MM-DD'),
      start_date: moment(this.start_date).format('YYYY-MM-DD'),
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.loadVPCdata();
        this.clearPreviousData();
      } else if(response.data.success==false) {
        this.message.error(response.data.message);
      } else {
        this.message.error("Something Went Wrong!");
      }
    })
  }

  editVpcCustomer(data) {
    this.customer_id = data.customer_id;
    this.name = data.name;
    this.start_date = data.start_date;
    this.expiry_date = data.expiry_date;
    this.id = data.id;

    this.isVpcCustomerEdit = true;
    this.openAddVpcCustomerModel=true;
  }

  deleteVpcCustomer(data) {
    var url = '/api/delete-vpc-master';

    var data2 = {
       id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.loadVPCdata();
        this.openAddVpcCustomerModel=false;
        this.message.success("VPC Customer deleted successfully!")
      } else if(response.data.message) {
        this.message.error(response.data.message);
      } else {
        this.message.error("Something Went Wrong!");
      }
    })
  }

  clearPreviousData() {
    this.name = '';
    this.id = 0;
    this.customer_id = 0;
    this.start_date = '';
    this.expiry_date = '';
    this.isVpcCustomerEdit = false;
    this.openAddVpcCustomerModel = false;
  }

}
