import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-dashboard-gps',
  templateUrl: './dashboard-gps.component.html',
  styleUrls: ['./dashboard-gps.component.css']
})
export class DashboardGpsComponent implements OnInit {
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  lat = 22.2736308;
  long = 70.7512555;

  devices = [];
  gps_token = localStorage.getItem('gps_token');

  constructor(private themeService: ThemeConstantService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);   

    if(this.gps_token) {
      this.getDevices();
    } else {
      this.getToken();
    }
  }

  getToken() {

    let formDate = new FormData();
    formDate.append('name', 'Testuser');
    formDate.append('password', 'Bfl*123');
    formDate.append('lang', 'en');
    formDate.append('timeZoneSecond', "19800");

    axios.post('https://www.whatsgps.com/user/login.do', formDate, {})
    .then(response => {
      if(response.data.ret) {
        localStorage.setItem('gps_token', response.data.data.token);
        this.gps_token = response.data.data.token;
        this.getDevices();
      } else {
        this.message.error("Unable to get token from whatsgps.com");
      }
    })
    .catch(error => {
      this.message.error("Unable to get token from whatsgps.com");
    });

  }

  getDevices() {
      
    this.devices.push({
      id: 1,
      name: 'Device 1',
      lat: 22.2736308,
      long: 70.7512555,
      speed: 0,
      time: moment().format('hh:mm:ss'),
      date: moment().format('DD/MM/YYYY'),
      status: 'Online',
      icon: {
        url: 'assets/map_images/blue_1.png',
        scaledSize: {
            width: 30,
            height: 30
        }
      }
    })

    this.devices.push({
      id: 1,
      name: 'Device 2',
      lat: 22.3736308,
      long: 70.4512555,
      speed: 0,
      time: moment().format('hh:mm:ss'),
      date: moment().format('DD/MM/YYYY'),
      status: 'Online',
      icon: {
        url: 'assets/map_images/blue_1.png',
        scaledSize: {
            width: 30,
            height: 30
        }
      }
    })
  }

}
