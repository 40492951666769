import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';


@Component({
  selector: 'app-all-reporting-only',
  templateUrl: './all-reporting-only.component.html',
  styleUrls: ['./all-reporting-only.component.css']
})
export class AllReportingOnlyComponent implements OnInit {

  openNewOrderModal=false;
  listOfOrders = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  isLoadingOrders = false;
  customer_search = '';
  sort_by_field = '';
  sort_by_type = ''; 
  
  compareId = (a, b) => a.id - b.id;
  compareName = (a, b) => a.customer_name.localeCompare(b.customer_name);
  compareVehicleNumber = (a, b) => a.vehicle_number.localeCompare(b.vehicle_number);
  compareCategory = (a, b) => a.category.localeCompare(b.category);
  compareBookingDate = (a, b) => a.booking_date - b.booking_date;
  compareCreatedBy = (a, b) => a.created_by - b.created_by;
  compareAmount = (a, b) => a.amount - b.amount;
  compareStatus = (a, b) => a.status - b.status;

  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  filterDrawerVisible = false;
  product_filters = [{
    label: 'Customer Name',
    key: 'customer_name',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Vehicle Number',
    key: 'vehicle_number',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Vehicles Type',
    key: 'vehicles.category',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Reporting Date',
    key: 'booking_date',
    type: "date",
    value: null,
    condition: "Equals",
  },{
    label: 'Created By',
    key: 'orders.created_by',
    type: "options",
    value: null,
    condition: "Equals",
    options: []
  }]

  // search = ""

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);     
    this.filterMethodOrders();
  }

  filterMethodOrders(page_index=1, page_size=10) {
    this.isLoadingOrders = true;

    var url = '/api/all-orders';

    var data = {
      type: 2,
      search: this.customer_search,
      page_index: page_index,
      page_size: page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      this.listOfOrders = response.data.all_orders;
      const all_users = response.data.all_users;

      const index = this.product_filters.findIndex(item => item.key == 'orders.created_by')
      this.product_filters[index].options = all_users.map(item => {
        return {value: item.id,label: item.name}
      });

      this.isLoadingOrders = false;
      this.filterDrawerVisible=false;
    })
  }

  clearFilters() {
    this.product_filters.forEach(element => {
      element.value = null;
    });
    this.filterMethodOrders();
  }

  downloadCsvOrders() {
    const { Parser } = require('json2csv');
    // const data = this.listOfOrders.data;

    var url = '/api/all-orders';

    var data = {
      type: 2,
      search: this.customer_search,
      page_index: 1,
      page_size: this.listOfOrders.meta.total,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      let listOfOrders = response.data.all_orders;
      const data = listOfOrders.data;
      
      const json2csvParser = new Parser();
      const csv = json2csvParser.parse(data);
      require("downloadjs")(csv, 'orders.csv');
    })

    // const json2csvParser = new Parser();
    // const csv = json2csvParser.parse(data);
    // require("downloadjs")(csv, 'orders.csv');
  }

  onOrdersChange($event) {
    this.filterMethodOrders($event.pageIndex, $event.pageSize);
  }

  deleteOrder(data) {
    var url = '/api/delete-order';

    var data2 = {
       order_id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodOrders();
        this.msg.success("Order deleted successfully!")
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

}
