import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-all-surveyors',
  templateUrl: './all-surveyors.component.html',
  styleUrls: ['./all-surveyors.component.css']
})
export class AllSurveyorsComponent implements OnInit {

  listOfSurveyors = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };

  listOfInsuranceCompanies = [];
  
  isLoadingSurveyors = false;
  isLoadingInsuranceCompanies = false;

  openAddSurveyorModal = false;
  openInsuranceCompanyModal = false;

  surveyor_id = 0;
  surveyor_name = '';
  surveyor_company = '';
  surveyor_contact_no = '';
  surveyor_email = '';

  insurance_company_id = 0;
  company_name = '';
  contact_person = '';
  email = '';
  contact_number = '';

  isSurveyorEdit = false;
  isInsuranceCompaniesEdit = false;

  category_search = '';
  surveyor_search = '';
  insurance_companies_search = '';

  categoriesTimer = null;
  surveyorsTimer = null;
  insuranceCompanyTimer = null;
  
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.filterMethodSurveyors();
    this.filterMethodInsuranceCompanies();
  }

  changeSurveyorsFn() {
    this.surveyorsTimer = setTimeout(() => {
      this.filterMethodSurveyors();
     clearTimeout(this.surveyorsTimer); 
    }, 500);
  }

  changeInsuranceCompanyFn() {
    this.insuranceCompanyTimer = setTimeout(() => {
      this.filterMethodInsuranceCompanies();
     clearTimeout(this.insuranceCompanyTimer); 
    }, 500);
  }

  filterMethodSurveyors(page_index=1, page_size=10) {
    this.isLoadingSurveyors = true;

    var url = '/api/all-surveyors';

    var data = {
      search: this.surveyor_search,
      page_index,
      page_size,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfSurveyors = response.data.all_surveyors;
      this.isLoadingSurveyors = false;
    })
  }

  filterMethodInsuranceCompanies() {
    this.isLoadingInsuranceCompanies = true;

    var url = '/api/all-insurance-companies';

    var data = {
      search: this.category_search,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfInsuranceCompanies = response.data.all_insurance_companies;
      this.isLoadingInsuranceCompanies = false;
    })
  }

  onSurveyorsChange($event) {
    this.filterMethodSurveyors($event.pageIndex, $event.pageSize);
  }

  downloadCsvSurveyor() {

    const { parse } = require('json2csv');
    let Surveyors = [...this.listOfSurveyors.data];

    Surveyors.forEach((element, index) => {
      element.sr_no = index + 1;
    });

    const fields = [{
      label: 'Sr. No',
      value: (row:any) => row.sr_no,
    }, {
      label: 'Surveyor Name',
      value: (row:any) => row.surveyor_name,
    }, {
      label: 'Surveyor Company',
      value: (row:any) => row.surveyor_company,
    }, {
      label: 'Contact No',
      value: (row:any) => row.surveyor_contact_no,
    }, {
      label: 'Created On',
      value: (row:any) => moment(row.created_at).format('DD/MM/YYYY'),
    }];
    const csv = parse(Surveyors, { fields });
    require("downloadjs")(csv, 'Surveyors.csv');

  }

  downloadCsvInsuranceCompanies() {
    const { parse } = require('json2csv');
    let InsuranceCompanies = [...this.listOfInsuranceCompanies];

    InsuranceCompanies.forEach((element, index) => {
      element.sr_no = index + 1;
    });

    const fields = [{
      label: 'Sr. No',
      value: (row:any) => row.sr_no,
    }, {
      label: 'Company Name',
      value: (row:any) => row.company_name,
    }, {
      label: 'Contact Person',
      value: (row:any) => row.contact_person,
    }, {
      label: 'Contact No',
      value: (row:any) => row.contact_number,
    }, {
      label: 'Email',
      value: (row:any) => row.email,
    }, {
      label: 'Created On',
      value: (row:any) => moment(row.created_at).format('DD/MM/YYYY'),
    }, {
      label: 'Created By',
      value: (row:any) => row.created_by_name,
    }];
     
    const csv = parse(InsuranceCompanies, { fields });
    require("downloadjs")(csv, 'Insurance Companies.csv');
  }

  newSurveyor() {

    if(this.surveyor_name=='') {
      this.msg.error("Please enter surveyor name!");
      return false;
    }

    if(this.surveyor_email=='') {
      this.msg.error("Please enter email!");
      return;
    }

    if(this.surveyor_email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )==null) {
      this.msg.error('Please enter a valid email');
      return;
    }

    let url = '';

    if(this.isSurveyorEdit) {
      url = '/api/update-surveyor';
    } else {
      url = '/api/new-surveyor';
    }

    const selected_company = this.listOfInsuranceCompanies.filter(item => item.id == this.insurance_company_id);

    let data = {
       surveyor_name: this.surveyor_name,
       surveyor_id: this.surveyor_id,
       surveyor_company: selected_company[0].company_name,
       insurance_company_id: this.insurance_company_id,
       surveyor_contact_no: this.surveyor_contact_no,
       surveyor_email: this.surveyor_email,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodSurveyors();
        this.openAddSurveyorModal = false;
        this.isSurveyorEdit = false;
        this.clearPreviousData();
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  editSurveyor(data) {
    this.surveyor_name = data.surveyor_name;
    this.surveyor_id = data.id;
    this.surveyor_contact_no = data.surveyor_contact_no;
    this.surveyor_company = data.surveyor_company;
    this.insurance_company_id = data.insurance_company_id;
    this.surveyor_email = data.surveyor_email;

    this.isSurveyorEdit = true;
    this.openAddSurveyorModal=true;
  }

  deleteSurveyor(data) {
    var url = '/api/delete-surveyor';

    var data2 = {
       surveyor_id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodSurveyors();
        this.openAddSurveyorModal=false;
        this.msg.success("Surveyor deleted successfully!")
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  newInsuranceCompany () {

    if(this.company_name=='') {
      this.msg.error("Please enter company name!");
      return;
    }

    if(this.contact_person=='') {
      this.msg.error("Please enter contact person!");
      return;
    }

    if(this.email=='') {
      this.msg.error("Please enter email!");
      return;
    }

    if(this.email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )==null) {
      this.msg.error('Please enter a valid email');
      return;
    }

    let url = '';

    if(this.isInsuranceCompaniesEdit) {
      url = '/api/update-insurance-company';
    } else {
      url = '/api/new-insurance-company';
    }

    let data = {
      insurance_company_id: this.insurance_company_id,
      company_name: this.company_name,
      contact_person: this.contact_person,
      email: this.email,
      contact_number: this.contact_number,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodInsuranceCompanies();
        this.openInsuranceCompanyModal = false;
        this.isInsuranceCompaniesEdit = false;
        this.clearPreviousData();
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  editInsuranceCompany(data) {
    this.insurance_company_id = data.id;
    this.company_name = data.company_name;
    this.contact_person = data.contact_person;
    this.email = data.email;
    this.contact_number = data.contact_number;

    this.isInsuranceCompaniesEdit = true;
    this.openInsuranceCompanyModal=true;
  }

  deleteInsuranceCompany(data) {
    var url = '/api/delete-insurance-company';

    var data2 = {
      insurance_company_id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodInsuranceCompanies();
        this.openInsuranceCompanyModal = false;
        this.isInsuranceCompaniesEdit = false;
        this.clearPreviousData();
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  clearPreviousData() {
    this.surveyor_name = '';
    this.surveyor_id = 0;
    this.surveyor_contact_no = '';
    this.surveyor_company = '';
    this.surveyor_email = '';
    this.isSurveyorEdit = false;
    this.insurance_company_id = 0;
    this.company_name = '';
    this.contact_person = '';
    this.email = '';
    this.contact_number = '';
    this.isInsuranceCompaniesEdit = false;
  }

}
