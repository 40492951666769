<app-customheader></app-customheader>

<section>
  <div class="lgx-banner lgx-banner-inner">
      <div class="lgx-page-inner">
          <!-- //.CONTAINER -->
      </div><!-- //.INNER -->
  </div>
</section>

<div id="notfound">
    <div class="notfound">
    <div class="notfound-404">
    <h1>Oops!</h1>
    <h2>404 - The Page can't be found</h2>
    </div>


    <div class="section-btn-area schedule-btn-area">
        <a class="lgx-btn lgx-btn-big" href="#" routerLink="/"><span>Proceed to Book</span></a>
    </div>

    </div>
</div>

<app-customfooter></app-customfooter>