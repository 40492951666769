import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-dashboard-fuel-consumption',
  templateUrl: './dashboard-fuel-consumption.component.html',
  styleUrls: ['./dashboard-fuel-consumption.component.css']
})
export class DashboardFuelConsumptionComponent implements OnInit {
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  department_id = null;
  departments = [];
  department_data = [];
  vehicle_data = [];

  lineChartData: Array<any> = [
    {
      label: 'My First dataset',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ];
  lineChartLabels: Array<any> = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  lineChartOptions: any = {
    responsive: true,
    annotation: {
      annotations: [
        {
          drawTime: 'afterDraw',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 70,
          borderColor: '#000000',
          borderWidth: 2,
          label: {
            backgroundColor: 'red',
            content: 'global plugin',
            enabled: true,
            position: 'center',
          }
        }
      ]
    }
  };
  lineChartLegend = true;
  lineChartType = 'line';
  inlinePlugin: any;
  textPlugin: any;

  constructor(private themeService: ThemeConstantService, private message: NzMessageService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);   
    this.getDepartments();
    this.getDashboardFuelConsumption();

    this.textPlugin = [{
      id: 'textPlugin',
    }];

    this.inlinePlugin = this.textPlugin;
  }

  departmentChange($event) {
    this.getDashboardFuelConsumption();
  }

  getDepartments() {
    var url = '/api/all-vehicle-departments';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.departments = response.data.vehicle_departments;
    })
  }

  getDashboardFuelConsumption() {
    let url = '/api/get-dashboard-fuel-consumption';

    let data = {
        department_id: this.department_id || 0,
    };

    axios.post(url, data, {}).then(response => {
        this.department_data = response.data.department_data;
        this.vehicle_data = response.data.vehicle_data;
    })
  }

}
