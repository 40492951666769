<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">

            <nz-card>

                <div class="breadcrumbs-add-btn-bar">

                    

                    <div class="title-breadrumbs-container">
                        <div class="title">APPROVALS</div>
                    </div>

                </div>

                <div class="customer-details-container" style="padding-bottom: 30px;"> 
                    <nz-divider ></nz-divider>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                              Purchase Order
                            </div>

                            <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                <div style="margin-right: 15px;">
                                    <div class="doc-search-btn">
                                        <div class="icon-download">
                                            <svg t="1641377690759" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2211" width="15" height="15"><path d="M507.418 804.036c-303.906 0-495.422-215.584-495.422-272.772 0-57.186 191.516-309.548 495.422-309.548s508.58 235.68 508.58 309.548C1015.998 605.134 811.324 804.036 507.418 804.036zM493.75 382.34c-44.352 0-80.306 35.902-80.306 80.188 0 44.294 35.954 80.194 80.306 80.194 44.348 0 80.304-35.902 80.304-80.194C574.052 418.242 538.098 382.34 493.75 382.34zM734.876 342.196c29.32 38.928 49.212 78.41 49.212 130.836 0 128.644-104.602 232.928-233.636 232.928-129.03 0-233.63-104.284-233.63-232.928 0-79.488 15.73-128.964 76.694-170.998-192.558 28.644-313.89 183.282-313.89 225.584 0 46.318 159.466 215.118 424.322 215.118s438.268-155.286 438.268-215.118C942.216 488.736 896.93 407.214 734.876 342.196z" p-id="2212" fill="#1e5799"></path></svg>

                                            <div class="download-text" style="color: #1e5799;font-size: 11px;text-transform: uppercase;letter-spacing: 0.8px;font-weight: 600; margin-left: 10px;">
                                                View
                                            </div>
                                        </div>
                    
                                    </div>   

                                </div>

                                <div>
                                 <button nz-button (click)="openOtpModal=true">Approve</button>
                                </div> 
                                
                                <nz-modal [nzWidth]="500" [(nzVisible)]="openOtpModal" nzTitle="Enter OTP" (nzOnCancel)="openOtpModal=false" style="justify-content: center; align-items: center;">
                                  
                                
                                  <input type="text" nz-input [(ngModel)]="inputValue" />

                                  <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary">SUBMIT</button>
                                  
                                </nz-modal>

                            </div>

                        </div>

                     

                    
                    </div>

                    <div class="record-separator" style="background-color: #f1f1f1; height: 1px; width: 100%; margin: 10px 0px;"></div>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                              Credit Limit
                            </div>

                            <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                <div style="margin-right: 15px;">
                                    <div class="doc-search-btn">
                                        <div class="icon-download">
                                            <svg t="1641377690759" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2211" width="15" height="15"><path d="M507.418 804.036c-303.906 0-495.422-215.584-495.422-272.772 0-57.186 191.516-309.548 495.422-309.548s508.58 235.68 508.58 309.548C1015.998 605.134 811.324 804.036 507.418 804.036zM493.75 382.34c-44.352 0-80.306 35.902-80.306 80.188 0 44.294 35.954 80.194 80.306 80.194 44.348 0 80.304-35.902 80.304-80.194C574.052 418.242 538.098 382.34 493.75 382.34zM734.876 342.196c29.32 38.928 49.212 78.41 49.212 130.836 0 128.644-104.602 232.928-233.636 232.928-129.03 0-233.63-104.284-233.63-232.928 0-79.488 15.73-128.964 76.694-170.998-192.558 28.644-313.89 183.282-313.89 225.584 0 46.318 159.466 215.118 424.322 215.118s438.268-155.286 438.268-215.118C942.216 488.736 896.93 407.214 734.876 342.196z" p-id="2212" fill="#1e5799"></path></svg>

                                            <div class="download-text" style="color: #1e5799;font-size: 11px;text-transform: uppercase;letter-spacing: 0.8px;font-weight: 600; margin-left: 10px;">
                                                View
                                            </div>
                                        </div>
                    
                                    </div>   

                                </div>

                                <div>
                                 <button nz-button (click)="openOtpModal=true">Approve</button>
                                </div> 
                                
                                <nz-modal [nzWidth]="500" [(nzVisible)]="openOtpModal" nzTitle="Enter OTP" (nzOnCancel)="openOtpModal=false" style="justify-content: center; align-items: center;">
                                  
                                <div style="justify-content: center; align-items: center; display: flex; flex-direction: column;">
                                    
                                  <div><input type="text" nz-input [(ngModel)]="otp" /></div>

                                  <div>
                                  <button style="margin-right:5px;" nz-button (click)="sendApprovalOtp()">SEND OTP</button>
                                 
                                    
                                  <button style="margin-top: 25px; margin-left:5px; min-height: auto;" nz-button nzType="primary" (click)="approveRequest()">APPROVE</button>
                                </div>
                                
                                </div>
                                  
                                </nz-modal>

                            </div>

                        </div>

                     

                    
                    </div>

                    <div class="record-separator" style="background-color: #f1f1f1; height: 1px; width: 100%; margin: 10px 0px;"></div>


                </div>  
                

            </nz-card>

            <nz-card>

                <div class="breadcrumbs-add-btn-bar">

                    

                    <div class="title-breadrumbs-container">
                        <div class="title">SERVICE SETTINGS</div>
                    </div>

                    <!-- <div class="doc-search-btn">
                        <button *ngIf="!isEdit" nz-button nzType="primary" (click)="createNewOrder()">CREATE</button>
                        <button *ngIf="isEdit" nz-button nzType="primary" (click)="updateOrder()">SAVE</button>
                    </div> -->

                </div>

                <div class="customer-details-container" style="padding-bottom: 30px;"> 
                    <nz-divider ></nz-divider>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                        <div style="display: flex; flex-direction: column; width: 50%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Labour Day Price
                            </div>
                                <div>
                                    <div>
                                        <input nz-input [(ngModel)]="labour_day_price">
                                    </div>
                                </div>
                        </div>

                        <!-- <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                               Email
                            </div>
                                <div>
                                    <input nz-input [(ngModel)]="email" >
                                </div>
                        </div> -->
                    </div>
                </div>  
                
                <button nz-button style="margin-bottom: 25px;" (click)="addRemarks()">Save</button>

            </nz-card>

            <nz-card>

                <div class="breadcrumbs-add-btn-bar">

                    <div class="title-breadrumbs-container">
                        <div class="title">BOOKING SETTINGS</div>
                    </div>

                </div>

                <div class="customer-details-container" style="padding-bottom: 30px;"> 

                    <nz-divider ></nz-divider>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                        <div style="display: flex; flex-direction: column; width: 50%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Max no. of Bookings
                            </div>
                                <div>
                                    <div>
                                        <input nz-input [(ngModel)]="max_booking">
                                    </div>
                                </div>
                        </div>

                        <!-- <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                               New Password
                            </div>
                                <div>
                                    <input nz-input [(ngModel)]="email" >
                                </div>
                        </div> -->
                    </div>
                </div>   
                
                <button nz-button style="margin-bottom: 25px;" (click)="addRemarks()">Save</button>

            </nz-card>
        </div>
    </div>
</div>