import { Component, OnInit, ChangeDetectionStrategy,  ViewChild,  TemplateRef, } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
// import bootstrap from '@fullcalendar/bootstrap';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/list';
import moment from 'moment';
// import 'bootstrap/dist/css/bootstrap.css';
// import '@fortawesome/fontawesome-free/css/all.css';
import { Router } from '@angular/router';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import axios from 'axios';
import { ThemeConstantService } from '../services/theme-constant.service';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};


@Component({
  selector: 'app-all-jobs',
  templateUrl: './all-jobs.component.html',
  styleUrls: ['./all-jobs.component.css']
})
export class AllJobsComponent implements OnInit {

  // @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  isLoadingJobs = false;
  job_search = '';

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;
  filterDrawerVisible = false;

  role = -1;
  users = [];
  filterUsers = [];

  modelContent = {
    title: '',
    start: '',
    end: '',
    type: '',
    order_id: '',
    order_type: '',
    order_link: '',
  }

  showJobDetailModal = false;

  constructor(private themeService: ThemeConstantService, private router: Router) {}

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event): void {
    console.log({ event, action });

    let order_link = '';

    if(event.order_type==0) {
      // this.router.navigate(['/general-servicing-single'], { queryParams: { order_id: event.order_id} });
      order_link = '/general-servicing-single?order_id=' + event.order_id;
    } else {
      // this.router.navigate(['/accidental-claim-single'], { queryParams: { order_id: event.order_id} });
      order_link = '/accidental-claim-single?order_id=' + event.order_id;
    }

    this.modelContent = {
      title: event.title,
      start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(event.end).format('YYYY-MM-DD HH:mm:ss'),
      type: event.type,
      order_id: event.order_id,
      order_type: event.order_type,
      order_link: order_link,
    }

    this.showJobDetailModal = true;
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);     
    this.filterMethodJobs();
    this.getAllUsers();
  }

  getAllUsers() {
    var url = '/api/all-users-for-job-filter';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      const users = response.data.users.map(user => {
        user.is_selected = true;
        return user;
      });

      this.users = users;
      this.filterUsers = users;
    })
  }

  clearSelections() {
    this.filterUsers = this.users.map(user => {
      user.is_selected = false;
      return user;
    });
  }

  filterByRole(role) {

    let filterUsers = [];

    for (let index = 0; index < this.users.length; index++) {
      const element = this.users[index];
      if(role==-1) {
        filterUsers.push(element);
      } else if(element.role == role) {
        filterUsers.push(element);
      }
    }

    this.filterUsers = filterUsers;
  }

  selectAll() {
    this.filterUsers = this.users.map(user => {
      if(this.role==-1) {
        user.is_selected = true;
      } else if(this.role==0) {
        if(user.role==0) {
          user.is_selected = true;
        }
      } else if(this.role==1) {
        if(user.role==1) {
          user.is_selected = true;
        }
      } else if(this.role==2) {
        if(user.role==2) {
          user.is_selected = true;
        }
      } else if(this.role==3) {
        if(user.role==3) {
          user.is_selected = true;
        }
      } else if(this.role==4) {
        if(user.role==4) {
          user.is_selected = true;
        }
      }

      return user;
    });
  }

  filterMethodJobs() {
    this.isLoadingJobs = true;

    var url = '/api/all-jobs';

    var data = {
      search: this.job_search,
      user_ids: this.filterUsers.filter(user => user.is_selected).map(user => user.id)
    };

    axios.post(url, data, {}).then(response => {
      let mechanic_color = {primary:'#e93b3b', secondary:'#ffffff'};
      let washer_color = {primary:'#3498db', secondary:'#ffffff'};
      let qc_color = {primary:'#9b59b6', secondary:'#ffffff'};
      let supervisior_color = {primary:'#f39c12', secondary:'#ffffff'};

      let events = [];
      const all_jobs = response.data.all_jobs;
      for (let index = 0; index < all_jobs.length; index++) {
        const element = all_jobs[index];
        events.push({
          order_id: element.order_id,
          order_type: element.order_type,
          type: element.type,
          start: new Date(element.start_time),
          end: new Date(element.end_time),
          title: element.user_name + " - "+ element.job_desc,
          color: element.type==1 ? mechanic_color : element.type==2 ? washer_color : element.type==3 ? qc_color : supervisior_color,
          resizable: {
            beforeStart: false,
            afterEnd: false,
          },
          draggable: false,
        });
      }
      this.events = events;
    })
  }

}
