<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">

            <div class="row mb-4" style="width: 100%">
                <div class="col-12 d-flex justify-content-end">
                    <nz-range-picker [(ngModel)]="date" (nzOnOk)="onOk($event)"></nz-range-picker>
                </div> 
            </div>

            <div class="row" style="width: 100%">
                <div class="col-md-6 col-lg-3">
                    <nz-card class="pointer" (click)="getDashboardWorkshopDetails('quotation_open')">
                        <div class="media align-items-center">
                           <div>
                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="33" cy="33" r="33" fill="#3F87F5" fill-opacity="0.19"/>
                                <path d="M19.0666 17C18.4603 17 17.9688 17.4967 17.9688 18.1094C17.9688 18.7221 18.4603 19.2188 19.0666 19.2188H43.219V44.9948L39.3916 42.6742C39.0443 42.4641 38.6109 42.4641 38.2637 42.6742L33.3384 45.66L28.4132 42.6742C28.066 42.4641 27.6326 42.4641 27.2853 42.6742L23.4579 44.9948V22.547C23.4579 21.9343 22.9664 21.4376 22.3601 21.4376C21.7538 21.4376 21.2623 21.9343 21.2623 22.547V46.9536C21.2629 47.8151 22.1925 48.3472 22.924 47.9048L27.8493 44.919L32.7745 47.9048C33.1217 48.1148 33.5552 48.1148 33.9024 47.9048L38.8276 44.919L43.7529 47.9048C44.4844 48.3472 45.414 47.8151 45.4146 46.9536V19.2188H47.6103C48.2166 19.2188 48.7081 18.7221 48.7081 18.1094C48.7081 17.4967 48.2166 17 47.6103 17C38.0957 17 28.5812 17 19.0666 17Z" fill="#3F87F5"/>
                                <path d="M26.873 32.7718C26.873 32.7718 29.3112 27.8955 33.578 27.8955C37.8448 27.8955 40.2829 32.7718 40.2829 32.7718C40.2829 32.7718 37.8448 37.6482 33.578 37.6482C29.3112 37.6482 26.873 32.7718 26.873 32.7718Z" stroke="#3F87F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M33.5786 34.6006C34.5885 34.6006 35.4072 33.7819 35.4072 32.772C35.4072 31.7621 34.5885 30.9434 33.5786 30.9434C32.5687 30.9434 31.75 31.7621 31.75 32.772C31.75 33.7819 32.5687 34.6006 33.5786 34.6006Z" stroke="#3F87F5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                           </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">{{intro.quotation_open}}</h2>
                                <p class="m-b-0 text-muted">Quotation Open</p>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-md-6 col-lg-3">
                    <nz-card class="pointer" (click)="getDashboardWorkshopDetails('open_accidental_claims')">
                        <div class="media align-items-center">
                           <div>
                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="33" cy="33" r="33" fill="#CBFFF6"/>
                                <path d="M19.1071 17C18.4957 17 18 17.4957 18 18.1071C18 18.7185 18.4957 19.2142 19.1071 19.2142H43.4643V44.9372L39.6044 42.6213C39.2543 42.4117 38.8172 42.4117 38.467 42.6213L33.5 45.601L28.533 42.6213C28.1828 42.4117 27.7457 42.4117 27.3956 42.6213L23.5357 44.9372V22.5355C23.5357 21.9241 23.04 21.4284 22.4286 21.4284C21.8171 21.4284 21.3214 21.9241 21.3214 22.5355V46.8919C21.3221 47.7516 22.2596 48.2826 22.9973 47.8412L27.9643 44.8615L32.9313 47.8412C33.2815 48.0508 33.7185 48.0508 34.0687 47.8412L39.0357 44.8615L44.0027 47.8412C44.7404 48.2826 45.6779 47.7516 45.6786 46.8919V19.2142H47.8929C48.5043 19.2142 49 18.7185 49 18.1071C49 17.4957 48.5043 17 47.8929 17C38.2976 17 28.7024 17 19.1071 17Z" fill="#00C9A7"/>
                                <path d="M39 28L31.4375 35L28 31.8182" stroke="#00C9A7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                           </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">{{intro.open_accidental_claims}}</h2>
                                <p class="m-b-0 text-muted">Open Accidental Claims</p>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-md-6 col-lg-3">
                    <nz-card class="pointer" (click)="getDashboardWorkshopDetails('open_servicing_orders')">
                        <div class="media align-items-center">
                            <div>
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33" cy="33" r="33" fill="#FFF9E6"/>
                                    <path d="M19.0666 17C18.4603 17 17.9688 17.4967 17.9688 18.1094C17.9688 18.7221 18.4603 19.2188 19.0666 19.2188H43.219V44.9948L39.3916 42.6742C39.0443 42.4641 38.6109 42.4641 38.2637 42.6742L33.3384 45.66L28.4132 42.6742C28.066 42.4641 27.6326 42.4641 27.2853 42.6742L23.4579 44.9948V22.547C23.4579 21.9343 22.9664 21.4376 22.3601 21.4376C21.7538 21.4376 21.2623 21.9343 21.2623 22.547V46.9536C21.2629 47.8151 22.1925 48.3472 22.924 47.9048L27.8493 44.919L32.7745 47.9048C33.1217 48.1148 33.5552 48.1148 33.9024 47.9048L38.8276 44.919L43.7529 47.9048C44.4844 48.3472 45.414 47.8151 45.4146 46.9536V19.2188H47.6103C48.2166 19.2188 48.7081 18.7221 48.7081 18.1094C48.7081 17.4967 48.2166 17 47.6103 17C38.0957 17 28.5812 17 19.0666 17Z" fill="#FFC107"/>
                                    <path d="M33.0547 37.9529V26.2192" stroke="#FFC107" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M27.1875 32.0861L33.0543 26.2192L38.9211 32.0861" stroke="#FFC107" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">{{intro.open_servicing_orders}}</h2>
                                <p class="m-b-0 text-muted">Open Servicing Orders</p>
                            </div>
                        </div>
                    </nz-card>

                </div>
                <div class="col-md-6 col-lg-3">
                    <nz-card class="pointer" (click)="getDashboardWorkshopDetails('total_amount')">
                        <div class="media align-items-center">
                           <div>
                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="33" cy="33" r="33" fill="#F3F0FF"/>
                                <path d="M18.1786 16C17.5277 16 17 16.5277 17 17.1785C17 17.8294 17.5277 18.3571 18.1786 18.3571H44.1071V45.7396L39.9983 43.2743C39.6255 43.0512 39.1602 43.0512 38.7875 43.2743L33.5 46.4462L28.2125 43.2743C27.8398 43.0512 27.3745 43.0512 27.0017 43.2743L22.8929 45.7396V21.8927C22.8929 21.2418 22.3652 20.7141 21.7143 20.7141C21.0634 20.7141 20.5357 21.2418 20.5357 21.8927V47.8204C20.5364 48.7356 21.5344 49.3009 22.3197 48.8309L27.6071 45.659L32.8946 48.8309C33.2674 49.054 33.7326 49.054 34.1054 48.8309L39.3929 45.659L44.6803 48.8309C45.4656 49.3009 46.4636 48.7356 46.4643 47.8204V18.3571H48.8214C49.4723 18.3571 50 17.8294 50 17.1785C50 16.5277 49.4723 16 48.8214 16C38.6071 16 28.3929 16 18.1786 16Z" fill="#886CFF"/>
                                <path d="M33.0422 22C32.3898 22 31.8608 22.5332 31.8608 23.191V24.8798C30.7674 25.1007 30.0149 26.0682 29.6018 26.7895C29.3035 27.3831 29.1721 28.0454 29.2257 28.7086C29.2794 29.3718 29.5148 30.0048 29.9018 30.544C30.6074 31.4855 31.5834 31.9613 32.5484 32.4398C33.3685 32.8203 34.0329 33.179 34.339 33.5679C34.948 34.3683 34.2827 35.8755 33.1345 35.8755C32.1319 35.9589 31.3713 35.2382 31.3624 34.4868C31.3252 33.8294 30.7661 33.3272 30.1141 33.3656C29.463 33.4031 28.9652 33.9653 29.0019 34.6217C29.0416 35.3238 29.2811 35.9969 29.6849 36.571C30.3184 37.4117 31.0542 37.8448 31.8608 38.0993V39.809C31.8608 40.4668 32.3898 41 33.0422 41C33.6947 41 34.2236 40.4668 34.2236 39.809V38.0737C34.592 37.9603 34.9444 37.8001 35.2689 37.5852C35.8535 37.1981 36.3256 36.6642 36.6303 36.0313C36.935 35.3985 37.0554 34.6947 36.9764 33.996C36.8974 33.2972 36.6236 32.6376 36.1896 32.0862C35.4068 31.0918 34.3552 30.6566 33.536 30.2764C32.6822 29.8367 31.6632 29.3816 31.5816 28.5155C31.5026 27.6427 32.1923 27.1239 32.9522 27.0687C33.7622 27.007 34.469 27.529 34.6067 28.2853C34.6791 28.9393 35.264 29.4102 35.9127 29.3367C36.5595 29.2625 37.0249 28.6747 36.9533 28.0224C36.88 27.3611 36.6264 26.7358 36.2242 26.208C35.6386 25.4984 34.9966 25.0953 34.2236 24.8426V23.191C34.2236 22.5332 33.6947 22 33.0422 22V22Z" fill="#886CFF"/>
                                </svg>
                                
                           </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">{{intro.total_amount}}</h2>
                                <p class="m-b-0 text-muted">Total Amount</p>
                            </div>
                        </div>
                    </nz-card>
                </div>

                <!-- <div class="row" style="width: 100%">
                    <div class="col-12">
                        <nz-card>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 style="color:#004A7F;">Quotation v/s Revenue</h5>      
                            </div>
    
                            <div class="d-flex justify-content-between align-items-center" style="margin-bottom: -20px; margin-top: 20px;">
                                <h6 style="color:#000000;">Sum Amount</h6>
                                <div>
                                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; position: relative; top: 4px;">
                                        <div style="margin-right: 15px;">
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <ellipse cx="6" cy="6.5" rx="6" ry="6.5" fill="#FF6060"/>
                                                </svg>   
                                        </div>
                                        <div>Labours</div>
                                    </div>
                                    
                                </div>
                            </div>
                
                            <div class="m-t-50" style="height: 290px">
                                <canvas ngChartjs 
                                    #myCanvas 
                                    [datasets]="revenueChartData" 
                                    [labels]="revenueChartLabels"
                                    [options]="revenueChartOptions" 
                                    [colors]="revenueChartColors" 
                                    [legend]="false"
                                    [chartType]="revenueChartType">
                                </canvas>
                            </div>
                        </nz-card>
                    </div>
                </div> -->

                <div class="row" style="width: 100%">
                    <div class="col-12">
                        <nz-card>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 style="color:#004A7F;">Number of Orders</h5>      
                            </div>
    
                            <div class="d-flex justify-content-between align-items-center" style="margin-bottom: -20px; margin-top: 20px;">
                                <h6 style="color:#000000;">Orders</h6>
                                <div>
                                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; position: relative; top: 4px;">
                                        <div style="margin-right: 15px;">
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <ellipse cx="6" cy="6.5" rx="6" ry="6.5" fill="#FF6060"/>
                                                </svg>   
                                        </div>
                                        <div>Labours</div>
                                    </div>
                                    
                                </div>
                            </div>
                
                            <div class="m-t-50" style="height: 290px">
                                <canvas ngChartjs 
                                    #myCanvas 
                                    [datasets]="revenueChartData" 
                                    [labels]="revenueChartLabels"
                                    [options]="revenueChartOptions" 
                                    [colors]="revenueChartColors" 
                                    [legend]="false"
                                    [chartType]="revenueChartType">
                                </canvas>
                            </div>
                        </nz-card>
                    </div>
                </div>
                
                <div class="row" style="width: 100%">
                    <div class="col-12">
                        <nz-card>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 style="color:#004A7F;">Open Orders</h5>
                            </div> 
                            <div class="m-t-30">
                                <nz-table #ordersListTable [nzData]="open_orders"  [nzShowPagination]="false" class="no-border-last">
                                    <thead>
                                        <tr>
                                            <th>Customer ID</th>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Approved Amt.</th>
                                            <th>Booking Date</th>
                                            <th>Check in Date</th>
                                            <th>Total Days of Workshop</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of ordersListTable.data">
                                            <td>#{{item.id}}</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <nz-avatar [nzSize]="30" nzIcon="user" [nzSrc]="item.avatar"></nz-avatar>
                                                    <h6 class="m-l-10 m-b-0">{{item.name}}</h6>
                                                </div>
                                            </td>
                                            <td>Servicing</td>
                                            <td>$800.00</td>
                                            <td>18 Oct 21</td>
                                            <td>18 Oct 21</td>
                                            <td>3</td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </div>
                        </nz-card>
                    </div>
                </div> 

                <div class="row" style="width: 100%">
                    <div class="col-12">
                        <nz-card>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 style="color:#004A7F;">Orders Stuck in last 10 Days</h5>
                            </div> 
                            <div class="m-t-30">
                                <nz-table #ordersListTable [nzData]="orders_stuck_in_last_10_days"  [nzShowPagination]="false" class="no-border-last">
                                    <thead>
                                        <tr>
                                            <th>Order ID</th>
                                            <th>Vehicle Number</th>
                                            <th>Type</th>
                                            <th>Order Type</th>
                                            <th>Paid by</th>
                                            <th>Booking Date</th>
                                            <th>last Updated On</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of ordersListTable.data">
                                            <td>#{{item.id}}</td>
                                            <td>
                                               #121654
                                            </td>
                                            <td>Van</td>
                                            <td>Servicing</td>
                                            <td>Customer</td>
                                            <td>18 Oct 21</td>
                                            <td>18 Oct 21</td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </div>
                        </nz-card>
                    </div>
                </div> 

            </div>
            
        </div>
    </div>
</div>

<nz-modal [nzWidth]="920" [(nzVisible)]="quotation_open_visible" (nzOnCancel)="quotation_open_visible=false">
    <ng-container *nzModalContent>
        <!-- <nz-table #quotationOpenTable [nzData]="quotation_open_data">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Remark</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of quotationOpenTable.data">
                <td>{{data.date | date}}</td>
                <td>${{data.price | number: '1.2-2'}}</td>
                <td>{{data.remark}}</td>
                <td>
                    <a *ngIf="data.order_type==0" href="#" routerLink="/general-servicing-single" [queryParams]="{ order_id: data.order_id}">#{{ data.order_id }}</a>
                    <a *ngIf="data.order_type==1" href="#" routerLink="/accidental-claim-single" [queryParams]="{ order_id: data.order_id}">#{{ data.order_id }}</a>
                </td>
              </tr>
            </tbody>
          </nz-table> -->
    </ng-container>
</nz-modal>

<nz-modal [nzWidth]="920" [(nzVisible)]="open_accidental_claims_visible" (nzOnCancel)="open_accidental_claims_visible=false">
    <ng-container *nzModalContent>
        <nz-table #openAccidentalClaimsTable [nzData]="open_accidental_claims_data">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Booking Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of openAccidentalClaimsTable.data">
                <td>
                    <a *ngIf="data.type==0" href="#" routerLink="/general-servicing-single" [queryParams]="{ order_id: data.id}">#{{ data.id }}</a>
                    <a *ngIf="data.type==1" href="#" routerLink="/accidental-claim-single" [queryParams]="{ order_id: data.id}">#{{ data.id }}</a>
                </td>
                <td>{{data.booking_date | date}}</td>
                <td>${{data.amount | number: '1.2-2'}}</td>
                <td>
                    <span *ngIf="data.status==0">Service Scheduled</span>
                    <span *ngIf="data.status==1">Quote Created</span>
                    <span *ngIf="data.status==2">Quotation Approved Internally</span>
                    <span *ngIf="data.status==3">Vehicle in Workshop</span>
                    <span *ngIf="data.status==4">Material Requested</span>
                    <span *ngIf="data.status==5">Material Collected</span>
                    <span *ngIf="data.status==6">Repair Complete</span>
                    <span *ngIf="data.status==7">Vehicle in Quality Control</span>
                    <span *ngIf="data.status==8">Vehicle Quality Control Complete</span>
                    <span *ngIf="data.status==9">Vehicle in Wash queue</span>
                    <span *ngIf="data.status==10">Wash Completed</span>
                    <span *ngIf="data.status==11">Invoice & Discharge Voucher Generated</span>
                    <span *ngIf="data.status==12">Delivered</span>
                </td>
              </tr>
            </tbody>
          </nz-table>
    </ng-container>
</nz-modal>

<nz-modal [nzWidth]="920" [(nzVisible)]="open_servicing_orders_visible" (nzOnCancel)="open_servicing_orders_visible=false">
    <ng-container *nzModalContent>
        <nz-table #openServicingOrdersTable [nzData]="open_servicing_orders_data">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Booking Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of openServicingOrdersTable.data">
                <td>
                    <a *ngIf="data.type==0" href="#" routerLink="/general-servicing-single" [queryParams]="{ order_id: data.id}">#{{ data.id }}</a>
                    <a *ngIf="data.type==1" href="#" routerLink="/accidental-claim-single" [queryParams]="{ order_id: data.id}">#{{ data.id }}</a>
                </td>
                <td>{{data.booking_date | date}}</td>
                <td>${{data.amount | number: '1.2-2'}}</td>
                <td>
                    <span *ngIf="data.status==0">Service Scheduled</span>
                    <span *ngIf="data.status==1">Quote Created</span>
                    <span *ngIf="data.status==2">Quotation Approved Internally</span>
                    <span *ngIf="data.status==3">Vehicle in Workshop</span>
                    <span *ngIf="data.status==4">Material Requested</span>
                    <span *ngIf="data.status==5">Material Collected</span>
                    <span *ngIf="data.status==6">Repair Complete</span>
                    <span *ngIf="data.status==7">Vehicle in Quality Control</span>
                    <span *ngIf="data.status==8">Vehicle Quality Control Complete</span>
                    <span *ngIf="data.status==9">Vehicle in Wash queue</span>
                    <span *ngIf="data.status==10">Wash Completed</span>
                    <span *ngIf="data.status==11">Invoice & Discharge Voucher Generated</span>
                    <span *ngIf="data.status==12">Delivered</span>
                </td>
              </tr>
            </tbody>
          </nz-table>
    </ng-container>
</nz-modal>

<nz-modal [nzWidth]="920" [(nzVisible)]="total_amount_visible" (nzOnCancel)="total_amount_visible=false">
    <ng-container *nzModalContent>
        <!-- <nz-table #totalAmountTable [nzData]="total_amount_data">
            <thead>
              <tr>
                <th>Customer Id</th>
                <th>Customer Name</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of totalAmountTable.data">
                <td>
                    <a href="#" routerLink="/customer-single" [queryParams]="{ customer_id: data.id}">
                        #{{data.id}}
                    </a>
                </td>
                <td>{{data.customer_name}}</td>
                <td>${{data.balance | number: '1.2-2'}}</td>
              </tr>
            </tbody>
          </nz-table> -->
    </ng-container>
</nz-modal>