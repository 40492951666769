import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  listOfRemarks = [];
  check_in_date = new Date();
  remarks = [];
  all_customers = [];
  customer_id = -1;
  customer_name = '';
  phone = '';
  customer_vehicles = [];
  vehicle_id = -1;
  make = '';
  model = '';
  mfg_date = null;
  order_id = 0;
  isEdit = false;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  name:'';
  email:'';
  labour_day_price:'';
  max_booking:'';
  openOtpModal=false;
  inputValue='';
  otp='';

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;

  constructor(private msg: NzMessageService, private route: ActivatedRoute, private router: Router, private themeService: ThemeConstantService ) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    
    this.route.queryParams
    .subscribe(params => {
        if(params.order_id) {
          this.order_id = params.order_id;
          this.isEdit = true;
          this.getOrderSingle();
        }
        this.getGeneralServicingPrefs();
      }
    );
    
  }

  sendApprovalOtp() {
    var url = '/api/send-approval-otp'

    var data = {
    
    };

    axios.post(url, data, {}).then(response => {
      console.log(response.data)
    })
  }

  approveRequest() {
    var url = '/api/verify-approval-otp'

    var data = {
      otp: this.otp
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success) {
        this.msg.success("");
      } else {
        this.msg.error("")
      }
    })
    
  }

  getOrderSingle() {
    var url = '/api/get-order-single';

    var data = {
      order_id: this.order_id
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.order) {
        this.customer_id = response.data.order.customer_id;
        this.vehicle_id = response.data.order.vehicle_id;
        this.remarks = JSON.parse(response.data.order.remarks);
        this.listOfRemarks = JSON.parse(response.data.order.remarks);
      }
      if(response.data.customer) {
        this.customer_name = response.data.customer.customer_name;
        this.phone = response.data.customer.phone;
      }
      if(response.data.vehicle) {
        this.make = response.data.vehicle.make;
        this.model = response.data.vehicle.model;
        this.mfg_date = response.data.vehicle.mfg_date;
      }
      this.loadCustomerDetails(response.data.order.customer_id);
      setTimeout(() => {
        this.loadVehicleDetails(response.data.order.vehicle_id);
      }, 500);
    })
  }

  getGeneralServicingPrefs() {
    var url = '/api/all-customers-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.all_customers = response.data.all_customers;
    })
  }

  loadVehicleDetails(e) {
    const index = this.customer_vehicles.findIndex((el)=>el.id==e);
    const element = this.customer_vehicles[index];

    this.make = element.make;
    this.model = element.model;
    this.mfg_date = element.mfg_date;
  }

  createNewOrder() {
    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return false;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return false;
    }

    if(this.listOfRemarks.length==0) {
      this.msg.error("Please enter customer remarks!");
      return false;
    }

    let empty_remark_count = 0;

    for (let index = 0; index < this.listOfRemarks.length; index++) {
      const element = this.listOfRemarks[index];
      if(element.text=="") {
        empty_remark_count++;
      }
    }

    if(empty_remark_count!=0) {
      this.msg.error("Please enter value in all the remarks!");
      return false;
    }

    var url = '/api/new-order';

    var data = {
      type: 0,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      booking_date: moment(this.check_in_date).format('YYYY-MM-DD'),
      status: 0,
      remarks: JSON.stringify(this.listOfRemarks),
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success("Order created successfully!")
      this.router.navigate(['/general-servicing-single'], { queryParams: { order_id: response.data }});
    })
  }

  updateOrder() {
    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return false;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return false;
    }

    if(this.listOfRemarks.length==0) {
      this.msg.error("Please enter customer remarks!");
      return false;
    }

    let empty_remark_count = 0;

    for (let index = 0; index < this.listOfRemarks.length; index++) {
      const element = this.listOfRemarks[index];
      if(element.text=="") {
        empty_remark_count++;
      }
    }

    if(empty_remark_count!=0) {
      this.msg.error("Please enter value in all the remarks!");
      return false;
    }

    var url = '/api/update-order';

    var data = {
      order_id: this.order_id,
      type: 0,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      booking_date: moment(this.check_in_date).format('YYYY-MM-DD'),
      remarks: JSON.stringify(this.listOfRemarks),
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success("Order updated successfully!")
    })
  }


  loadCustomerDetails(e) {
    var url = '/api/get-customer-single';

    var data = {
      customer_id:e
    };

    axios.post(url, data, {}).then(response => {
      this.customer_id = response.data.id;
      this.customer_name = response.data.customer_name;
      this.phone = response.data.phone;
    })

    var url = '/api/customer-vehicles';

    var data2 = {
      customer_id:e
    };

    axios.post(url, data2, {}).then(response => {
      this.customer_vehicles = response.data.customer_vehicles;
    })
  }

  removeRemarks(i) {
    this.listOfRemarks.splice(i, 1);
  }

  addRemarks() {
    this.listOfRemarks.push({text:""});
    console.log(this.listOfRemarks);
  }

}
