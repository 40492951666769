import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-invoice-single',
  templateUrl: './invoice-single.component.html',
  styleUrls: ['./invoice-single.component.css']
})
export class InvoiceSingleComponent implements OnInit {

  activeTab = 0;

  isLoadingInvoice = false;

  invoice_id = 0;
  invoice_details = {
    order_id: null,
    invoice_date: null,
    due_date: null,
    total: null,
    type: null,
    attn_to: null,
    customer_name: null,
    vehicle_number: null,
    status: null,
    balance: null,
    service_advisor: null,
  };

  payment_id = 0;

  invoice_payments = [];
  isPaymentEdit = false;
  addNewPaymentModal = false;
  openEditInvoiceModal=false;
  recordPaymentModal=false;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  payment_search = '';

  payment_date= new Date();
  payment_method = '';
  payment_amount = 0;
  payment_remarks = '';
  openPaymentModal = false;

  constructor(private msg: NzMessageService, private route: ActivatedRoute, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    
    this.route.queryParams
      .subscribe(params => {
        this.invoice_id = params.invoice_id;
        this.getInvoiceSingle();
        this.getPayments();
      }
    );
  }

  getPayments() {
    var url = '/api/invoice-payments';

    var data = {
      invoice_id: this.invoice_id,
      search: this.payment_search
    };

    axios.post(url, data, {}).then(response => {
      this.invoice_payments = response.data.invoice_payments;
    })
  }

  deletePayment(data) {
    let data2 = {
      payment_id: data.id,
    };

    let url = '/api/delete-payment'; 

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.getPayments();
        this.getInvoiceSingle()
        this.msg.success("Payment Deleted Successfully!");
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  getInvoiceSingle() {
    this.isLoadingInvoice = true;

    var url = '/api/get-invoice-single';

    var data = {
      invoice_id: this.invoice_id
    };

    axios.post(url, data, {}).then(response => {
      this.invoice_details = response.data;
    })
  }

  newCustomer() {

  }

  newPayment() {
    let url = '';

    if(!this.isPaymentEdit) {
      url = '/api/new-payment';
    } else {
      url = '/api/update-payment';
    }

    let data = {
      payment_id: this.payment_id,
      invoice_id: this.invoice_id,
      payment_date: moment(this.payment_date).format("YYYY-MM-DD"),
      payment_method: this.payment_method,
      remarks: this.payment_remarks,
      amount: this.payment_amount,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.openPaymentModal = false;
        this.getPayments();
        this.getInvoiceSingle()
      } else {
        if(response.data.msg) {
          this.msg.error(response.data.msg);
        } else {
          this.msg.error("Something Went Wrong!");
        }
      }
    })
  }

  editPayment(data) {
    this.payment_id = data.id
    this.payment_date= new Date(data.payment_date);
    this.payment_method = data.payment_method;
    this.payment_amount = Number(data.amount);
    this.payment_remarks = data.remarks;
    this.isPaymentEdit = true;
    this.openPaymentModal=true;
  }

  clearPreviousData() {
    this.payment_id = 0;
    this.payment_date= new Date();
    this.payment_method = '';
    this.payment_amount = 0;
    this.payment_remarks = '';
    this.openPaymentModal = false;
    this.isPaymentEdit = false;
  }

}
