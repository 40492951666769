import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-dashboard-customer',
  templateUrl: './dashboard-customer.component.html',
  styleUrls: ['./dashboard-customer.component.css']
})
export class DashboardCustomerComponent implements OnInit {


  selectedValue = null

  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  
  constructor(private msg: NzMessageService, private themeService: ThemeConstantService) { }

  activeTab = 0;
  date = [moment().subtract(1, 'M').toDate(), moment().toDate()];
  revenueChartFormat: string = 'revenueMonth';

  revenueChartData: Array<any> = [{ 
      data: [30, 60, 40, 50, 40, 55, 85, 65, 75, 50, 70],
      label: 'Series A' 
  }];
  currentrevenueChartLabelsIdx = 1;
  revenueChartLabels:Array<any> = ["16th", "17th", "18th", "19th", "20th", "21th", "22th", "23th", "24th", "25th", "26th"];
  revenueChartOptions: any = {
      maintainAspectRatio: false,
      responsive: true,
      hover: {
          mode: 'nearest',
          intersect: true
      },
      tooltips: {
          mode: 'index'
      },
      scales: {
          xAxes: [{ 
              gridLines: [{
                  display: false,
              }],
              ticks: {
                  display: true,
                  fontColor: '#77838f',
                  fontSize: 13,
                  padding: 10
              }
          }],
          yAxes: [{
              gridLines: {
                  drawBorder: false,
                  drawTicks: false,
                  borderDash: [3, 4],
                  zeroLineWidth: 1,
                  zeroLineBorderDash: [3, 4]  
              },
              ticks: {
                  display: true,
                  max: 100,                            
                  stepSize: 20,
                  fontColor: '#77838f',
                  fontSize: 13,
                  padding: 10
              }  
          }],
      }
  };
  revenueChartColors: Array<any> = [
      { 
          backgroundColor: 'rgba(255, 255, 255, 0)',
          borderColor: "#05c9a7",
          pointBackgroundColor: "#05c9a7",
          pointBorderColor: '#ffffff',
          pointHoverBackgroundColor: 'rgba(0, 201, 167, 0.1)',
          pointHoverBorderColor: 'rgba(0, 201, 167, 0.1)'
      }
  ];
  revenueChartType = 'line';
  
  ordersList = [
    {
        id: 5331,
        name: 'Erin Gonzales',
        avatar: 'assets/images/avatars/thumb-1.jpg',
        date: '8 May 2019',
        amount: 137,
        status: 'In Stock',
        checked : false
    },
    {
        id: 5375,
        name: 'Darryl Day',
        avatar: 'assets/images/avatars/thumb-2.jpg',
        date: '6 May 2019',
        amount: 322,
        status: 'Out of Stock',
        checked : false
    },
    {
        id: 5762,
        name: 'Marshall Nichols',
        avatar: 'assets/images/avatars/thumb-3.jpg',
        date: '1 May 2019',
        amount: 543,
        status: 'Out of Stock',
        checked : false
    },
    {
        id: 5865,
        name: 'Virgil Gonzales',
        avatar: 'assets/images/avatars/thumb-4.jpg',
        date: '28 April 2019',
        amount: 876,
        status: 'In Stock',
        checked : false
    },
    {
        id: 5213,
        name: 'Nicole Wyne',
        avatar: 'assets/images/avatars/thumb-5.jpg',
        date: '28 April 2019',
        amount: 241,
        status: 'In Stock',
        checked : false
    }
    ]    

  intro = {
    total_customers: 0,
    total_vehicles: 0,
    total_departments: 0,
    upcoming_bookings: 0
  }
  low_on_stock_products = []
  most_used_products = []
  customer_id = null;
  customers = [];

  upcoming_service_bookings = [];
  lapsed_service_bookings = [];
  upcoming_renewals = [];

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);   
    this.getDashboardCustomer(); 
    this.getCustomers();
  }

  getDashboardCustomer() {

    let url = '/api/get-dashboard-customer';

    let data = {
        customer_id: this.customer_id || 0,
    };

    axios.post(url, data, {}).then(response => {

        this.intro = response.data.intro;
        this.upcoming_renewals = response.data.upcoming_renewals;
        this.upcoming_service_bookings = response.data.upcoming_bookings;
        this.lapsed_service_bookings = response.data.lapsed_bookings;
    })
  }

  getCustomers() {
    var url = '/api/all-customers-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.customers = response.data.all_customers;
    })
  }

  customerChange(e) {
    this.getDashboardCustomer();
  }

  onOk(result: Date | Date[] | null): void {
    this.getDashboardCustomer()
  }
}
