<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
    <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
          <nz-card>
            <div nz-row>
              <div nz-col nzSpan="8">
                  <nz-button-group>
                  <button
                    nz-button
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                  >
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70679 0.293031C5.89426 0.480558 5.99957 0.734866 5.99957 1.00003C5.99957 1.26519 5.89426 1.5195 5.70679 1.70703L2.41379 5.00003L5.70679 8.29303C5.88894 8.48163 5.98974 8.73423 5.98746 8.99643C5.98518 9.25863 5.88001 9.50944 5.6946 9.69485C5.5092 9.88026 5.25838 9.98543 4.99619 9.9877C4.73399 9.98998 4.48139 9.88919 4.29279 9.70703L0.292787 5.70703C0.105316 5.5195 0 5.26519 0 5.00003C0 4.73487 0.105316 4.48056 0.292787 4.29303L4.29279 0.293031C4.48031 0.10556 4.73462 0.000244141 4.99979 0.000244141C5.26495 0.000244141 5.51926 0.10556 5.70679 0.293031Z" fill="#18181B"/>
                      </svg>                            
                  </button>
                  <button
                    nz-button
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                  >
                    Today
                  </button>
                  <button
                    nz-button
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                  >
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292787 9.70692C0.105316 9.51939 0 9.26508 0 8.99992C0 8.73475 0.105316 8.48045 0.292787 8.29292L3.58579 4.99992L0.292787 1.70692C0.110629 1.51832 0.00983372 1.26571 0.0121121 1.00352C0.0143906 0.741321 0.11956 0.490509 0.304968 0.305101C0.490376 0.119692 0.741189 0.0145233 1.00339 0.0122448C1.26558 0.00996641 1.51818 0.110761 1.70679 0.292919L5.70679 4.29292C5.89426 4.48045 5.99957 4.73475 5.99957 4.99992C5.99957 5.26508 5.89426 5.51939 5.70679 5.70692L1.70679 9.70692C1.51926 9.89439 1.26495 9.99971 0.999786 9.99971C0.734622 9.99971 0.480314 9.89439 0.292787 9.70692Z" fill="#18181B"/>
                      </svg>                            
                  </button>
                </nz-button-group>
              </div>
              <div nz-col nzSpan="8" style="display: flex;justify-content: center;">
                  <nz-button-group>
                      <button
                        nz-button
                        (click)="setView(CalendarView.Month)"
                        [class.active]="view === CalendarView.Month"
                      >
                        Month
                      </button>
                      <button
                        nz-button
                        (click)="setView(CalendarView.Week)"
                        [class.active]="view === CalendarView.Week"
                      >
                        Week
                      </button>
                      <button
                        nz-button
                        (click)="setView(CalendarView.Day)"
                        [class.active]="view === CalendarView.Day"
                      >
                        Day
                      </button>
                    </nz-button-group>
              </div>
              <div nz-col nzSpan="8" style="display: flex;justify-content: flex-end;">

                <ng-template #footerTpl>
                  <div style="float: right">
                    <button nz-button style="margin-right: 8px;" (click)="filterDrawerVisible=false">Cancel</button>
                    <button nz-button nzType="primary" (click)="filterMethodJobs();filterDrawerVisible=false;">Filter</button>
                  </div>
                </ng-template>

                <nz-drawer
                  [nzClosable]="false"
                  [nzWidth]="420"
                  [nzVisible]="filterDrawerVisible"
                  nzPlacement="right"
                  nzTitle="Filter"
                  (nzOnClose)="filterDrawerVisible=false"
                  [nzFooter]="footerTpl"
                >
                  <ng-container *nzDrawerContent>

                    <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="role" (ngModelChange)="filterByRole($event)">
                      <nz-option [nzValue]="-1" nzLabel="All"></nz-option>
                      <nz-option [nzValue]="0" nzLabel="Service Advisor"></nz-option>
                      <nz-option [nzValue]="1" nzLabel="Mechanic"></nz-option>
                      <nz-option [nzValue]="2" nzLabel="Washer"></nz-option>
                      <nz-option [nzValue]="3" nzLabel="QC"></nz-option>
                      <nz-option [nzValue]="4" nzLabel="Supervisior"></nz-option>
                    </nz-select>

                    <div class="d-flex mt-3 justify-content-between" style="letter-spacing: 0.6px;font-weight:600;font-size: 12px;">
                      <div style="color: rgba(0, 74, 127, 1);cursor: pointer;" (click)="selectAll()">Select All</div>
                      <div style="color: rgba(140, 140, 140, 1);cursor: pointer;font-weight:500;" (click)="clearSelections()">Clear Selection</div>
                    </div>

                    <div *ngFor="let item of filterUsers" class="my-3">
                      <div class="d-flex mt-3 justify-content-between align-items-center" style="letter-spacing: 0.6px;">
                        <div style="color: rgba(0, 74, 127, 1);">
                          <label nz-checkbox [(ngModel)]="item.is_selected" ></label>
                        </div>
                        <nz-avatar style="background-color: #f56a00" [nzText]="item.name.charAt(0)"></nz-avatar>
                        <div style="width: calc(100% - 85px);">
                          <div style="color: rgba(140, 140, 140, 1);">{{item.name}}</div>
                          <div style="color: rgba(140, 140, 140, 1);font-size: 12px;">
                            {{item.role == 0 ? "Service Advisor" : item.role == 1 ? "Mechanic" : item.role == 2 ? "Washer" : item.role == 3 ? "QC" : "Supervisior"}}
                          </div>
                        </div>
                      </div>
                    </div>

                  </ng-container>
                </nz-drawer>


                <button (click)="filterDrawerVisible=true;" nz-button style="display: flex;align-items: center;justify-content: center;margin-left: 10px;">
                  <svg style="margin-right: 7px;" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 10.5C6 10.3674 6.05268 10.2402 6.14645 10.1464C6.24021 10.0527 6.36739 10 6.5 10H9.5C9.63261 10 9.75979 10.0527 9.85355 10.1464C9.94732 10.2402 10 10.3674 10 10.5C10 10.6326 9.94732 10.7598 9.85355 10.8536C9.75979 10.9473 9.63261 11 9.5 11H6.5C6.36739 11 6.24021 10.9473 6.14645 10.8536C6.05268 10.7598 6 10.6326 6 10.5ZM4 7.5C4 7.36739 4.05268 7.24021 4.14645 7.14645C4.24021 7.05268 4.36739 7 4.5 7H11.5C11.6326 7 11.7598 7.05268 11.8536 7.14645C11.9473 7.24021 12 7.36739 12 7.5C12 7.63261 11.9473 7.75979 11.8536 7.85355C11.7598 7.94732 11.6326 8 11.5 8H4.5C4.36739 8 4.24021 7.94732 4.14645 7.85355C4.05268 7.75979 4 7.63261 4 7.5ZM2 4.5C2 4.36739 2.05268 4.24021 2.14645 4.14645C2.24021 4.05268 2.36739 4 2.5 4H13.5C13.6326 4 13.7598 4.05268 13.8536 4.14645C13.9473 4.24021 14 4.36739 14 4.5C14 4.63261 13.9473 4.75979 13.8536 4.85355C13.7598 4.94732 13.6326 5 13.5 5H2.5C2.36739 5 2.24021 4.94732 2.14645 4.85355C2.05268 4.75979 2 4.63261 2 4.5Z" fill="black"/>
                  </svg>
                  Filter
              </button>
              </div>
            </div>
            <br />
            <div [ngSwitch]="view">
              <mwl-calendar-month-view
                *ngSwitchCase="CalendarView.Month"
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"
              >
              </mwl-calendar-month-view>
              <mwl-calendar-week-view
                *ngSwitchCase="CalendarView.Week"
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"
              >
              </mwl-calendar-week-view>
              <mwl-calendar-day-view
                *ngSwitchCase="CalendarView.Day"
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"
              >
              </mwl-calendar-day-view>
            </div>
          </nz-card>
        </div>
      </div>
  </div>

  <nz-modal [nzWidth]="960" [(nzVisible)]="showJobDetailModal" nzTitle="Job Details" (nzOnCancel)="showJobDetailModal = false" (nzOnOk)="showJobDetailModal = false">
    <ng-container *nzModalContent>

      <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
        <nz-descriptions-item nzTitle="Title">{{modelContent.title}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Start">{{modelContent.start | date :'medium'}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="End">{{modelContent.end | date :'medium'}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Type">{{modelContent.type == 1 ? 'Mechanic Job' : modelContent.type == 2 ? 'Washer Job' : modelContent.type == 3 ? 'QC Job' : 'Other Job'}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Order Id">#{{modelContent.order_id}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Order Type">{{modelContent.order_type == 0 ? 'General Servicing' : 'Accidental Claim'}}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Order Link">
          <a *ngIf="modelContent.order_type == 0" href="#" routerLink="/general-servicing-single" [queryParams]="{ order_id: modelContent.order_id }">View Order</a>
          <a *ngIf="modelContent.order_type == 1" href="#" routerLink="/accidental-claim-single" [queryParams]="{ order_id: modelContent.order_id }">View Order</a>
        </nz-descriptions-item>
      </nz-descriptions>
        

    </ng-container>
  </nz-modal>


<!-- <div class="all-products-main"> 

    <app-primary-header></app-primary-header>

    <div style="display: flex; flex-direction: row; justify-content: center;">

        <div class="mini-calendar-container" style="width: 300px; background-color:#18375F; padding-left: 10px;">

        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 15px; margin-top: 15px;">

            <div style="display: flex; flex-direction: row;">

            <div style="font-size: 26px; color: white; font-weight: 400; margin-right: 10px; align-items: center;">November</div>
            <div style="font-size: 26px; color: rgb(243, 71, 71); font-weight: 400;">2021</div>
            </div>
 
            <div style="display: flex; flex-direction: row; margin-right:10px;">

                <div style="cursor: pointer;">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2485 6.35159C15.4735 6.57662 15.5999 6.88179 15.5999 7.19999C15.5999 7.51818 15.4735 7.82335 15.2485 8.04839L11.2969 12L15.2485 15.9516C15.4671 16.1779 15.5881 16.481 15.5853 16.7957C15.5826 17.1103 15.4564 17.4113 15.2339 17.6338C15.0114 17.8563 14.7104 17.9825 14.3958 17.9852C14.0812 17.9879 13.7781 17.867 13.5517 17.6484L8.75173 12.8484C8.52677 12.6234 8.40039 12.3182 8.40039 12C8.40039 11.6818 8.52677 11.3766 8.75173 11.1516L13.5517 6.35159C13.7768 6.12662 14.0819 6.00024 14.4001 6.00024C14.7183 6.00024 15.0235 6.12662 15.2485 6.35159Z" fill="white"/>
                        </svg>
                        
                </div>

                <div style="cursor: pointer;">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75173 17.6483C8.52677 17.4233 8.40039 17.1181 8.40039 16.7999C8.40039 16.4817 8.52677 16.1765 8.75173 15.9515L12.7033 11.9999L8.75173 8.0483C8.53314 7.82198 8.41219 7.51886 8.41493 7.20422C8.41766 6.88959 8.54386 6.58861 8.76635 6.36612C8.98884 6.14363 9.28982 6.01743 9.60445 6.01469C9.91909 6.01196 10.2222 6.13291 10.4485 6.3515L15.2485 11.1515C15.4735 11.3765 15.5999 11.6817 15.5999 11.9999C15.5999 12.3181 15.4735 12.6233 15.2485 12.8483L10.4485 17.6483C10.2235 17.8733 9.91833 17.9996 9.60013 17.9996C9.28194 17.9996 8.97677 17.8733 8.75173 17.6483Z" fill="white"/>
                        </svg>
                        
                </div>

            </div>

        </div>

            <div class="all-jobs-calendar"  [ngStyle]="{ width: '280px'}">
                <nz-calendar [nzFullscreen]="false"></nz-calendar>
            </div>

        </div> 

        <div class="full-calendar-container" style="width: calc(100vw - 300px); padding: 20px;">

            <nz-card style="width:100%;">
                <div nz-row>
                    <div nz-col nzSpan="8">
                        <nz-button-group>
                        <button
                          nz-button
                          mwlCalendarPreviousView
                          [view]="view"
                          [(viewDate)]="viewDate"
                          (viewDateChange)="closeOpenMonthViewDay()"
                        >
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.70679 0.293031C5.89426 0.480558 5.99957 0.734866 5.99957 1.00003C5.99957 1.26519 5.89426 1.5195 5.70679 1.70703L2.41379 5.00003L5.70679 8.29303C5.88894 8.48163 5.98974 8.73423 5.98746 8.99643C5.98518 9.25863 5.88001 9.50944 5.6946 9.69485C5.5092 9.88026 5.25838 9.98543 4.99619 9.9877C4.73399 9.98998 4.48139 9.88919 4.29279 9.70703L0.292787 5.70703C0.105316 5.5195 0 5.26519 0 5.00003C0 4.73487 0.105316 4.48056 0.292787 4.29303L4.29279 0.293031C4.48031 0.10556 4.73462 0.000244141 4.99979 0.000244141C5.26495 0.000244141 5.51926 0.10556 5.70679 0.293031Z" fill="#18181B"/>
                            </svg>                            
                        </button>
                        <button
                          nz-button
                          mwlCalendarToday
                          [(viewDate)]="viewDate"
                        >
                          Today
                        </button>
                        <button
                          nz-button
                          mwlCalendarNextView
                          [view]="view"
                          [(viewDate)]="viewDate"
                          (viewDateChange)="closeOpenMonthViewDay()"
                        >
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292787 9.70692C0.105316 9.51939 0 9.26508 0 8.99992C0 8.73475 0.105316 8.48045 0.292787 8.29292L3.58579 4.99992L0.292787 1.70692C0.110629 1.51832 0.00983372 1.26571 0.0121121 1.00352C0.0143906 0.741321 0.11956 0.490509 0.304968 0.305101C0.490376 0.119692 0.741189 0.0145233 1.00339 0.0122448C1.26558 0.00996641 1.51818 0.110761 1.70679 0.292919L5.70679 4.29292C5.89426 4.48045 5.99957 4.73475 5.99957 4.99992C5.99957 5.26508 5.89426 5.51939 5.70679 5.70692L1.70679 9.70692C1.51926 9.89439 1.26495 9.99971 0.999786 9.99971C0.734622 9.99971 0.480314 9.89439 0.292787 9.70692Z" fill="#18181B"/>
                            </svg>                            
                        </button>
                      </nz-button-group>
                    </div>
                    <div nz-col nzSpan="8" style="display: flex;justify-content: center;">
                        <nz-button-group>
                            <button
                              nz-button
                              (click)="setView(CalendarView.Month)"
                              [class.active]="view === CalendarView.Month"
                            >
                              Month
                            </button>
                            <button
                              nz-button
                              (click)="setView(CalendarView.Week)"
                              [class.active]="view === CalendarView.Week"
                            >
                              Week
                            </button>
                            <button
                              nz-button
                              (click)="setView(CalendarView.Day)"
                              [class.active]="view === CalendarView.Day"
                            >
                              Day
                            </button>
                          </nz-button-group>
                    </div>
                    <div nz-col nzSpan="8" style="display: flex;justify-content: flex-end;">
                      <button nz-button style="display: flex;align-items: center;justify-content: center;margin-left: 10px;">
                        <svg style="margin-right: 7px;" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 10.5C6 10.3674 6.05268 10.2402 6.14645 10.1464C6.24021 10.0527 6.36739 10 6.5 10H9.5C9.63261 10 9.75979 10.0527 9.85355 10.1464C9.94732 10.2402 10 10.3674 10 10.5C10 10.6326 9.94732 10.7598 9.85355 10.8536C9.75979 10.9473 9.63261 11 9.5 11H6.5C6.36739 11 6.24021 10.9473 6.14645 10.8536C6.05268 10.7598 6 10.6326 6 10.5ZM4 7.5C4 7.36739 4.05268 7.24021 4.14645 7.14645C4.24021 7.05268 4.36739 7 4.5 7H11.5C11.6326 7 11.7598 7.05268 11.8536 7.14645C11.9473 7.24021 12 7.36739 12 7.5C12 7.63261 11.9473 7.75979 11.8536 7.85355C11.7598 7.94732 11.6326 8 11.5 8H4.5C4.36739 8 4.24021 7.94732 4.14645 7.85355C4.05268 7.75979 4 7.63261 4 7.5ZM2 4.5C2 4.36739 2.05268 4.24021 2.14645 4.14645C2.24021 4.05268 2.36739 4 2.5 4H13.5C13.6326 4 13.7598 4.05268 13.8536 4.14645C13.9473 4.24021 14 4.36739 14 4.5C14 4.63261 13.9473 4.75979 13.8536 4.85355C13.7598 4.94732 13.6326 5 13.5 5H2.5C2.36739 5 2.24021 4.94732 2.14645 4.85355C2.05268 4.75979 2 4.63261 2 4.5Z" fill="black"/>
                        </svg>
                        Filter
                    </button>
                    </div>
                  </div>
                  <br />
                  <div [ngSwitch]="view">
                    <mwl-calendar-month-view
                      *ngSwitchCase="CalendarView.Month"
                      [viewDate]="viewDate"
                      [events]="events"
                      [refresh]="refresh"
                      [activeDayIsOpen]="activeDayIsOpen"
                      (dayClicked)="dayClicked($event.day)"
                      (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)"
                    >
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view
                      *ngSwitchCase="CalendarView.Week"
                      [viewDate]="viewDate"
                      [events]="events"
                      [refresh]="refresh"
                      (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)"
                    >
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view
                      *ngSwitchCase="CalendarView.Day"
                      [viewDate]="viewDate"
                      [events]="events"
                      [refresh]="refresh"
                      (eventClicked)="handleEvent('Clicked', $event.event)"
                      (eventTimesChanged)="eventTimesChanged($event)"
                    >
                    </mwl-calendar-day-view>
                  </div>
            </nz-card>
          
        </div>
    </div>

</div> -->
