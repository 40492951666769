import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-vehicle-categories',
  templateUrl: './vehicle-categories.component.html',
  styleUrls: ['./vehicle-categories.component.css']
})
export class VehicleCategoriesComponent implements OnInit {

  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  listOfVehicleCategories = [];
  isLoadingVehicleCategories = false;
  openAddVehicleCategoriesModal = false;
  isVehicleCategoriesEdit = false;

  vehicle_category = {
    id: 0,
    name: '',
    image: '',
    status: 1,
  }

  search = '';

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);  
    this.getVehicleCategories();
  }

  getVehicleCategories() {
    this.isLoadingVehicleCategories = true;

    const data = {
      search: this.search,
    }

    axios.post('/api/vehicle-categories', data)
    .then(res => {
      this.isLoadingVehicleCategories = false;
      this.listOfVehicleCategories = res.data.vehicle_categories;
    })
    .catch(err => {
      this.isLoadingVehicleCategories = false;
      console.log(err);
    })
  }

  downloadCsvVehicleCategories() {

  }

  newVehicleCategories() {
    if(this.vehicle_category.name == '') {
      this.msg.error('Please enter vehicle category name');
      return;
    }

    if(this.vehicle_category.image == '') {
      this.msg.error('Please upload vehicle image');
      return;
    }

    const data = {
      id: this.vehicle_category.id,
      name: this.vehicle_category.name,
      image: this.vehicle_category.image,
      status: this.vehicle_category.status,
    }

    let url = '';

    if(this.isVehicleCategoriesEdit) {
      url = '/api/update-vehicle-category';
    } else {
      url = '/api/new-vehicle-category';
    }

    axios.post(url, data).then(res => {
      if(res.data.success) {
        this.msg.success('Vehicle category added successfully');
        this.getVehicleCategories();
        this.openAddVehicleCategoriesModal = false;
        this.clearPreviousData();
      } else {
        this.msg.error(res.data.message);
      }
    }).catch(err => {
      console.log(err);
    });

  }

  editVehicleCategory(data:any) {
    this.vehicle_category = {
      id: data.id,
      name: data.name,
      image: data.image,
      status: data.status,
    };

    this.isVehicleCategoriesEdit = true;
    this.openAddVehicleCategoriesModal = true;
  }

  deleteVehicleCategory(vehicle_category_id: any) {
    const data = {
      id: vehicle_category_id,
    }

    axios.post('/api/delete-vehicle-category', data).then(res => {
      if(res.data.success) {
        this.msg.success('Vehicle category deleted successfully');
        this.getVehicleCategories();
      } else {
        this.msg.error(res.data.message);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  clearPreviousData() {
    this.vehicle_category = {
      id: 0,
      name: '',
      image: '',
      status: 1,
    }
    this.isVehicleCategoriesEdit = false;
  }

  handleChange(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        // Component will show file.url as link
        this.vehicle_category.image = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

}
