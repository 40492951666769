import { Component, OnInit, ChangeDetectionStrategy,  ViewChild,  TemplateRef, } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
// import bootstrap from '@fullcalendar/bootstrap';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/list';
import moment from 'moment';
// import 'bootstrap/dist/css/bootstrap.css';
// import '@fortawesome/fontawesome-free/css/all.css';
import { Router } from '@angular/router';

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import axios from 'axios';
import { ThemeConstantService } from '../services/theme-constant.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-all-washing-register',
  templateUrl: './all-washing-register.component.html',
  styleUrls: ['./all-washing-register.component.css']
})
export class AllWashingRegisterComponent implements OnInit {

  openNewOrderModal=false;
  listOfOrders = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  isLoadingOrders = false;
  customer_search = '';
  sort_by_field = '';
  sort_by_type = ''; 
  
  compareId = (a, b) => a.id - b.id;
  compareName = (a, b) => a.customer_name.localeCompare(b.customer_name);
  compareVehicleNumber = (a, b) => a.vehicle_number.localeCompare(b.vehicle_number);
  compareCategory = (a, b) => a.category.localeCompare(b.category);
  compareBookingDate = (a, b) => a.booking_date - b.booking_date;
  compareCreatedBy = (a, b) => a.created_by - b.created_by;
  compareAmount = (a, b) => a.amount - b.amount;
  compareStatus = (a, b) => a.status - b.status;

  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  filterDrawerVisible = false;
  product_filters = [{
    label: 'Customer Name',
    key: 'customer_name',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Vehicle Number',
    key: 'vehicle_number',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Vehicles Type',
    key: 'vehicles.category',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Reporting Date',
    key: 'booking_date',
    type: "date",
    value: null,
    condition: "Equals",
  },{
    label: 'Created By',
    key: 'orders.created_by',
    type: "options",
    value: null,
    condition: "Equals",
    options: []
  }]
  
  pictures = [];
  pictureModal = false;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  isLoadingJobs = false;
  job_search = '';

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;

  role = -1;
  users = [];
  filterUsers = [];

  modelContent = {
    title: '',
    start: '',
    end: '',
    type: '',
    order_id: '',
    order_type: '',
    order_link: '',
  }

  showJobDetailModal = false;

  booking_search = '';
  listOfBookings = [];

  service_booking_vehicle_id = 0;
  service_booking_make = '';
  service_booking_model = '';
  service_booking_department = '';
  service_booking_vehicle_type = '';
  service_booking_due_date = null;
  service_booking_due_mileage = '';
  service_booking_id = 0;
  service_booking_address = 'BigFoot';
  isBookingEdit = false;
  openBookingModal = false; 

  is_recurring_booking = false;
  recurring_in = 30;

  last_recurring_date = moment().add(5, 'years').toDate();

  customer_vehicles = [];

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);     
    this.filterMethodOrders();
    this.getVehicles();
    this.getBookings();
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  getVehicles() {
    var url = '/api/customer-vehicles';

    var data = {
      customer_id: 1,
      search: '',
      show_scrapped: false,
    };

    axios.post(url, data, {}).then(response => {
      this.customer_vehicles = response.data.customer_vehicles;
    })
  }

  bookingVehicleChanged(vehicle_id: number) {
    this.service_booking_vehicle_id = vehicle_id;
    const vehicle = this.customer_vehicles.find(vehicle => vehicle.id == vehicle_id);
    this.service_booking_make = vehicle.make;
    this.service_booking_model = vehicle.model;
    this.service_booking_department = vehicle.vehicle_department;
    this.service_booking_vehicle_type = vehicle.vehicle_type;
  }

  filterMethodOrders(page_index=1, page_size=10) {
    this.isLoadingOrders = true;

    var url = '/api/washing-register';

    var data = {
      type: 2,
      search: this.customer_search,
      page_index: page_index,
      page_size: page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };

    axios.post(url, data, {}).then(response => {
      this.listOfOrders = response.data.washing_register;

      this.isLoadingOrders = false;
    })
  }

  clearFilters() {
    this.product_filters.forEach(element => {
      element.value = null;
    });
    this.filterMethodOrders();
  }

  downloadCsvOrders() {
    const { Parser } = require('json2csv');
    const data = this.listOfOrders.data;
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(data);
    require("downloadjs")(csv, 'orders.csv');
  }

  viewOrder(data) {
    this.pictures = data.pictures;
    this.pictureModal = true;
  }

  onOrdersChange($event) {
    this.filterMethodOrders($event.pageIndex, $event.pageSize);
  }

  deleteOrder(data) {
    var url = '/api/delete-order';

    var data2 = {
       order_id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodOrders();
        this.msg.success("Order deleted successfully!")
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  handleEvent(action: string, event): void {
    console.log({ event, action });
    this.editBooking(event.data);
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getBookings() {
    var url = '/api/all-service-bookings';

    var data = {
      customer_id: 1,
      search: this.booking_search,
      type: 'WASH'
    };

    axios.post(url, data, {}).then(response => {
      this.listOfBookings = response.data.service_bookings;
      let mechanic_color = {primary:'#e93b3b', secondary:'#ffffff'};
      let washer_color = {primary:'#3498db', secondary:'#ffffff'};
      let events = [];
      const service_bookings = response.data.service_bookings;
      for (let index = 0; index < service_bookings.length; index++) {
        const element = service_bookings[index];      

        events.push({
          type: element.type,
          start: new Date(element.due_date),
          end: new Date(element.due_date),
          title: element.vehicle_number + ' - ' + element.vehicle_department,
          color: element.wash_register_id ? mechanic_color : washer_color,
          data: element,
          resizable: {
            beforeStart: false,
            afterEnd: false,
          },
          draggable: false,
        });

        if(element.is_recurring_booking) {
          let recurring_date = moment(element.due_date).add(element.recurring_in, 'days').toDate();
          let last_recurring_date = moment(element.last_recurring_date).toDate();

          if(moment(last_recurring_date).diff(moment(), 'months') > 2) {
            last_recurring_date = moment().add(2, 'months').toDate();
          }

          while(recurring_date < last_recurring_date) {
            events.push({
              type: element.type,
              start: new Date(recurring_date),
              end: new Date(recurring_date),
              title: element.vehicle_number + ' - ' + element.vehicle_department,
              color: mechanic_color,
              data: element,
              resizable: {
                beforeStart: false,
                afterEnd: false,
              },
              draggable: false,
            });
            recurring_date = moment(recurring_date).add(element.recurring_in, 'days').toDate();
          }
        }
      }

      console.log(events);
      
      this.events = events;
    })
  }

  downloadCsvRegister() {
    const { parse } = require('json2csv');
    // const data = this.listOfOrders.data;

    const fields = [{
      label: 'Id',
      value: 'id'
    }, {
      label: 'Customer',
      value: 'customer_name'
    }, {
      label: 'Vehicle No.',
      value: 'vehicle_number'
    }, {
      label: 'Vehicle Department',
      value: 'vehicle_department'
    }, {
      label: 'Reporting Date',
      value: row => moment(row.time).format('DD-MM-YYYY')
    }, {
      label: 'Start Date',
      value: 'start_time'
    }, {
      label: 'End Date',
      value: 'end_time'
    }, {
      label: 'Driver Name',
      value: 'driver_name'
    }, {
      label: 'Washer Name',
      value: 'washer_name'
    }, {
      label: 'Created By',
      value: 'created_by_name'
    }];

    var url = '/api/washing-register';

    var data = {
      type: 2,
      search: this.customer_search,
      page_index: 1,
      page_size: this.listOfOrders.meta.total,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };
    
    axios.post(url, data, {}).then(response => {
      // this.listOfOrders = response.data.washing_register;
      let listOfOrders = response.data.washing_register;
      const data = listOfOrders.data;

      const csv = parse(data, { fields });
      require("downloadjs")(csv, 'Wash Register.csv');
    })
    // const csv = parse(data, { fields });
    // require("downloadjs")(csv, 'Wash Register.csv');
  }

  newBooking() {
    if(this.service_booking_due_date==null) {
      this.msg.error("Please Select Due Date!");
      return;
    }

    if(this.service_booking_vehicle_id==0) {
      this.msg.error("Please Select Vehicle!");
      return;
    }

    if(this.is_recurring_booking) {
      if(!this.recurring_in) {
        this.msg.error("Please enter recurring in days!");
        return;
      }
    }

    let url: string;

    if(!this.isBookingEdit) {
      url = '/api/new-service-booking';
    } else {
      url = '/api/update-service-booking';
    }

    let data = {
      service_booking_id: this.service_booking_id,
      due_date: moment(this.service_booking_due_date).format("YYYY-MM-DD"),
      due_mileage: this.service_booking_due_mileage,
      vehicle_id: this.service_booking_vehicle_id,
      customer_id: 1,
      type: 'WASH',
      is_recurring_booking: this.is_recurring_booking,
      recurring_in: this.recurring_in,
      last_recurring_date: moment(this.last_recurring_date).format("YYYY-MM-DD"),
      service_booking_address: this.service_booking_address,
    }

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.openBookingModal = false;
        this.getBookings();
        this.clearPreviousData();
      } else {
        if(response.data.message) {
          this.msg.error(response.data.message);
        } else {
          this.msg.error("Something Went Wrong!");
        }
      }
    });
  }

  editBooking(data: any) {
    this.service_booking_id = data.id;
    this.service_booking_due_date = new Date(data.due_date);
    this.service_booking_due_mileage = data.due_mileage;
    this.service_booking_vehicle_id = data.vehicle_id;
    this.bookingVehicleChanged(data.vehicle_id);
    this.service_booking_make = data.make;
    this.service_booking_model = data.model;
    this.is_recurring_booking = data.is_recurring_booking == 1 ? true : false;
    this.recurring_in = data.recurring_in;
    this.last_recurring_date = new Date(data.last_recurring_date);
    this.service_booking_address = data.service_booking_address;
    this.isBookingEdit = true;
    this.openBookingModal=true;
  }

  deleteBooking(service_booking_id: number) {
    let data = {
      service_booking_id: service_booking_id,
    };

    let url = '/api/delete-service-booking';

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.getBookings()
        this.msg.success("Booking Deleted Successfully!");
      } else {
        if(response.data.message) {
          this.msg.error(response.data.message);
        } else {
          this.msg.error("Something Went Wrong!");
        }
      }
    })
  }

  clearPreviousData() {
    this.service_booking_id = 0;
    this.service_booking_due_date =  null;
    this.service_booking_due_mileage = '';
    this.service_booking_vehicle_id = 0;
    this.service_booking_address = '';
    this.openBookingModal = false;
    this.isBookingEdit = false;
    this.service_booking_make = '';
    this.service_booking_model = '';
    this.is_recurring_booking = false;
    this.recurring_in = 30;
  }
}
