import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-reporting-only-new',
  templateUrl: './reporting-only-new.component.html',
  styleUrls: ['./reporting-only-new.component.css']
})
export class ReportingOnlyNewComponent implements OnInit {

  listOfRemarks = [];
  check_in_date = new Date();
  remarks = [];
  all_customers = [];
  customer_id = -1;
  customer_name = '';
  phone = '';
  customer_vehicles = [];
  vehicle_id = -1;
  make = '';
  model = '';
  mfg_date = null;
  order_id = 0;
  isEdit = false;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  

  settlement_type = '';

  gia_report = '';
  quotation_report = '';
  pte_settlement_form = '';

  driver = '';
  accident_date = null;
  accident_time = null;
  details_of_accident = '';
  location = '';
  claim_type = '';
  damage_status = '';
  third_party_vehicle_number = '';
  cost_of_repair = '';
  received_amount = '';
  nric_number = '';
  ep_number = '';
  sp_number = '';
  wp_number = '';
  ci_file = '';
  pictures = [];

  is_incident_only = false;
  
  incident_number = '';
  employee_name = '';
  dept_name = '';
  event_type = '';
  date = null;
  recorded_by = '';
  details_of_incident = '';
  status = '';


  constructor(private msg: NzMessageService, private route: ActivatedRoute, private router: Router, private themeService: ThemeConstantService ) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.route.queryParams
    .subscribe(params => {
        if(params.order_id) {
          this.order_id = params.order_id;
          this.isEdit = true;
          this.getOrderSingle();
        }
        this.getGeneralServicingPrefs();
      }
    );
    
  }

  getOrderSingle() {
    var url = '/api/get-order-single';

    var data = {
      order_id: this.order_id
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.order) {
        this.customer_id = response.data.order.customer_id;
        this.vehicle_id = response.data.order.vehicle_id;
        this.remarks = JSON.parse(response.data.order.remarks);
        this.listOfRemarks = JSON.parse(response.data.order.remarks);
        this.settlement_type = response.data.order.settlement_type;
        this.gia_report = response.data.order.gia_report;
        this.quotation_report = response.data.order.quotation_report;
        this.pte_settlement_form = response.data.order.pte_settlement_form;
        this.is_incident_only = response.data.order.is_incident_only == 1 ? true : false;

        if(response.data.order.other_details) {
          const other_details = JSON.parse(response.data.order.other_details);
          this.driver = other_details.driver;
          const accident_date = other_details.accident_date;
          this.accident_date = accident_date ? moment(accident_date, 'YYYY-MM-DD').toDate() : null;
          const accident_time = other_details.accident_time;
          this.accident_time = accident_time ? moment(accident_time, 'HH:mm:ss').toDate() : null;
          this.details_of_accident = other_details.details_of_accident;
          this.location = other_details.location;
          this.claim_type = other_details.claim_type;
          this.damage_status = other_details.damage_status;
          this.third_party_vehicle_number = other_details.third_party_vehicle_number;
          this.cost_of_repair = other_details.cost_of_repair;
          this.received_amount = other_details.received_amount;
          this.nric_number = other_details.nric_number;
          this.ep_number = other_details.ep_number;
          this.sp_number = other_details.sp_number;
          this.wp_number = other_details.wp_number;
          this.ci_file = other_details.ci_file;
          this.pictures = other_details.pictures;

          this.incident_number = other_details.incident_number;
          this.employee_name = other_details.employee_name;
          this.dept_name = other_details.dept_name;
          this.event_type = other_details.event_type;
          this.date = other_details.date ? moment(other_details.date, 'YYYY-MM-DD').toDate() : null;
          this.recorded_by = other_details.recorded_by;
          this.details_of_incident = other_details.details_of_incident;
          this.status = other_details.status;
        }
      }
      if(response.data.customer) {
        this.customer_name = response.data.customer.customer_name;
        this.phone = response.data.customer.phone;
      }
      if(response.data.vehicle) {
        this.make = response.data.vehicle.make;
        this.model = response.data.vehicle.model;
        this.mfg_date = response.data.vehicle.mfg_date;
      }
      this.loadCustomerDetails(response.data.order.customer_id);
      setTimeout(() => {
        this.loadVehicleDetails(response.data.order.vehicle_id);
      }, 500);
    })
  }

  getGeneralServicingPrefs() {
    var url = '/api/all-customers-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.all_customers = response.data.all_customers;
    })
  }

  loadVehicleDetails(e) {
    const index = this.customer_vehicles.findIndex((el)=>el.id==e);
    const element = this.customer_vehicles[index];

    this.make = element.make;
    this.model = element.model;
    this.mfg_date = element.mfg_date;
    if(!this.isEdit) {
      // this.checkOrderExists(e);
    }
  }

  createNewOrder() {

    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return;
    }

    if(this.listOfRemarks.length==0) {
      this.msg.error("Please enter customer remarks!");
      return;
    }

    if(this.settlement_type=='') {
      this.msg.error("Please select settlement type!");
      return;
    }

    let empty_remark_count = 0;

    for (let index = 0; index < this.listOfRemarks.length; index++) {
      const element = this.listOfRemarks[index];
      if(element.text=="") {
        empty_remark_count++;
      }
    }

    if(empty_remark_count!=0) {
      this.msg.error("Please enter value in all the remarks!");
      return false;
    }

    var url = '/api/new-order';

    const other_details = {
      driver: this.driver,
      accident_date: this.accident_date ? moment(this.accident_date).format('YYYY-MM-DD') : null,
      accident_time: this.accident_time ? moment(this.accident_time).format('HH:mm:ss') : null,
      details_of_accident: this.details_of_accident,
      location: this.location,
      claim_type: this.claim_type,
      damage_status: this.damage_status,
      third_party_vehicle_number: this.third_party_vehicle_number,
      cost_of_repair: this.cost_of_repair,
      received_amount: this.received_amount,
      nric_number: this.nric_number,
      ep_number: this.ep_number,
      sp_number: this.sp_number,
      wp_number: this.wp_number,
      ci_file: this.ci_file,
      pictures: this.pictures,
      incident_number: this.incident_number,
      employee_name: this.employee_name,
      dept_name: this.dept_name,
      event_type: this.event_type,
      date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
      recorded_by: this.recorded_by,
      details_of_incident: this.details_of_incident,
      status: this.status,
    }

    var data = {
      type: 2,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      settlement_type_report: this.settlement_type,
      gia_report: this.gia_report,
      quotation_report: this.quotation_report,
      pte_settlement_form: this.pte_settlement_form,
      booking_date: moment(this.check_in_date).format('YYYY-MM-DD'),
      status: 12,
      remarks: JSON.stringify(this.listOfRemarks),
      other_details: JSON.stringify(other_details),
      is_incident_only: this.is_incident_only,
    };

    axios.post(url, data, {}).then(response => {

      if(response.data.success==false) {
        this.msg.error(response.data.message);
      } else {
        this.msg.success("Order created successfully!")
        this.router.navigate(['/all-reporting-only']);
      }

    })
  }

  updateOrder() {
    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return false;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return false;
    }

    if(this.listOfRemarks.length==0) {
      this.msg.error("Please enter customer remarks!");
      return false;
    }

    if(this.settlement_type=='') {
      this.msg.error("Please select settlement type!");
      return;
    }

    let empty_remark_count = 0;

    for (let index = 0; index < this.listOfRemarks.length; index++) {
      const element = this.listOfRemarks[index];
      if(element.text=="") {
        empty_remark_count++;
      }
    }

    if(empty_remark_count!=0) {
      this.msg.error("Please enter value in all the remarks!");
      return false;
    }

    var url = '/api/update-order';

    const other_details = {
      driver: this.driver,
      accident_date: this.accident_date ? moment(this.accident_date).format('YYYY-MM-DD') : null,
      accident_time: this.accident_time ? moment(this.accident_time).format('HH:mm:ss') : null,
      details_of_accident: this.details_of_accident,
      location: this.location,
      claim_type: this.claim_type,
      damage_status: this.damage_status,
      third_party_vehicle_number: this.third_party_vehicle_number,
      cost_of_repair: this.cost_of_repair,
      received_amount: this.received_amount,
      nric_number: this.nric_number,
      ep_number: this.ep_number,
      sp_number: this.sp_number,
      wp_number: this.wp_number,
      ci_file: this.ci_file,
      pictures: this.pictures,
      incident_number: this.incident_number,
      employee_name: this.employee_name,
      dept_name: this.dept_name,
      event_type: this.event_type,
      date: this.date ? moment(this.date).format('YYYY-MM-DD') : null,
      recorded_by: this.recorded_by,
      details_of_incident: this.details_of_incident,
      status: this.status,
    }

    var data = {
      order_id: this.order_id,
      type: 2,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      settlement_type_report: this.settlement_type,
      gia_report: this.gia_report,
      quotation_report: this.quotation_report,
      pte_settlement_form: this.pte_settlement_form,
      booking_date: moment(this.check_in_date).format('YYYY-MM-DD'),
      remarks: JSON.stringify(this.listOfRemarks),
      other_details: JSON.stringify(other_details),
      is_incident_only: this.is_incident_only,
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success("Order updated successfully!")
    })
  }

  checkOrderExists(vehicle_id: number) {
    var url = '/api/check-order-exists';
    var data = {
      vehicle_id: vehicle_id,
    }

    axios.post(url, data, {}).then(response => {
      if(response.data.success==false) {
        this.msg.error(response.data.message);
      } 
    })
  }

  loadCustomerDetails(e) {
    var url = '/api/get-customer-single';

    var data = {
      customer_id:e
    };

    axios.post(url, data, {}).then(response => {
      this.customer_id = response.data.id;
      this.customer_name = response.data.customer_name;
      this.phone = response.data.phone;
    })

    var url = '/api/customer-vehicles';

    var data2 = {
      customer_id:e
    };

    axios.post(url, data2, {}).then(response => {
      this.customer_vehicles = response.data.customer_vehicles;
    })
  }

  removeRemarks(i) {
    this.listOfRemarks.splice(i, 1);
  }

  addRemarks() {
    this.listOfRemarks.push({text:""});
    console.log(this.listOfRemarks);
  }

  handleChangeGIAReport(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      this.gia_report = '';
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        this.gia_report = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  handleChangeCIFile(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      this.ci_file = '';
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        this.ci_file = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  handleChangeQuotationReporting(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      this.quotation_report = '';
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        this.quotation_report = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  handleChangePTE(info: NzUploadChangeParam, isNric = false) {
    if (info.file.status !== 'uploading') {
      if(isNric) {
        this.nric_number = '';
      } else {
        this.pte_settlement_form = '';
      }
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        if(isNric) {
          this.nric_number = info.file.response.url;
        } else {
          this.pte_settlement_form = info.file.response.url;
        }
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

}
