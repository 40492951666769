<div class="common-layout is-default" [class.is-folded]="isFolded" [class.is-side-nav-dark]="isFolded"
    [class.is-expand]="isFolded">
    <app-primary-header></app-primary-header>

    <!-- <div class="sidebar-and-main-container"> -->
    <app-sidebar-dashboard></app-sidebar-dashboard>
    <div class="page-container">
        <div class="main-content" id="section-to-print">
            <nz-card *ngIf="form_type == 'check_in'">
                <div class="breadcrumbs-add-btn-bar">
                    <div class="title-breadrumbs-container">
                        <div class="title">CHECK IN MARKINGS</div>
                    </div>
                    <div class="doc-search-btn">
                        <div class="icon-download">
                            <div class="download-text">
                                <div
                                    style="margin-right: 10px; display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                    <div style="margin-right: 10px;">
                                        <button nz-button nzType="primary"
                                            (click)="insertOrUpdateCheckInMarkings()">SAVE</button>
                                        <button nz-button nzType="secondary"
                                            (click)="printCheckinForm()" class="ml-2">EXPORT PDF</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="width: 100%;margin-top: 55px;" id="print-section-checkin">

                    <style>
                       
                    </style>

                    <nz-card>

                        <div style="padding:0 8px">
                            <div>
                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    class="label-text"
                                                    style="font-family: Poppins;font-size: 13px;color: #de1b4b;font-weight: 500; margin-bottom:5px; margin-top: 10px;width: 100%;">
                                                    Check In Date
                                                </div>

                                                <div>
                                                    <nz-date-picker [nzDisabledDate]="disabledDate" style="width: 100%;"
                                                        [nzFormat]="'dd-MM-yyyy'" nzPlaceHolder="Select Date"
                                                        [(ngModel)]="check_in_date"></nz-date-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Driver Name
                                                </div>
                                                <div>
                                                    <input nz-input [(ngModel)]="driver_name" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </nz-card>

                    <nz-card>

                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Exterior
                        </div>
                        <div style="padding:0 8px">
                            <div>
                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Road Tax
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_road_tax"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Valid" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Expired" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Radio Antenna
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_radio_antenna" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Speed Limit Label
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_speed_limit_label" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Door Alarm
                                                </div>

                                                <div>


                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_door_alarm" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Centre Locking
                                                </div>

                                                <div>


                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_centre_locking" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>

                    </nz-card>

                    <nz-card>
                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Interior
                        </div>

                        <div>
                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Vehicle IU
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_vehicle_iu" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Fix Accessories
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_fix_accessories" style="width: 100%; ">
                                                        <nz-option nzLabel="Listed in Remarks" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    No.of Car mats / carpets
                                                </div>

                                                <div>
                                                    <nz-input-number nz-input placeholder="No.of Car mats / carpets"
                                                        [nzMin]="0" [(ngModel)]="check_in_carpets" style="width: 100%;">
                                                    </nz-input-number>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Solar Films
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_solar_film" style="width: 100%; ">
                                                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Seat leather/panels
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_seat_leather" style="width: 100%; ">
                                                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="Torn" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Safety Belts
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_safety_belts" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Jam" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="Torn" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Dashboard
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_dashboard" style="width: 100%; ">
                                                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="Crack" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Radio/CD/DVD player
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_radio_player" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Ash Tray/Cigarette Lighter
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_ash_tray"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Warning Light
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_warning_light" style="width: 100%; ">
                                                        <nz-option nzLabel="No Warning" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Light On" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Indicate light on
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_indicate_light_on" style="width: 100%; ">
                                                        <nz-option nzLabel="Yes" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="No" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Air Con
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_air_con"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Horn
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_horn"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Real Mirror/ Clip On Mirror
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_real_mirror" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Damage" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Right wing mirror folding motor/ Auto left
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_right_wing_mirror" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="Not Tested" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="3"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Power Windows
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_power_window" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Rear booth carpet / Mat
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_rear_booth_carpet" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Damage" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Spare Tyre Supporting board
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_spare_tyre_supporting_board"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Spare Tyres
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_spare_tyre" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Jack Tools/ Kit
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_tool_kit"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Breakdown Sign
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_breakdown_sign" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Rear boot remote /Latch
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_rear_boot_remote" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">

                            </div>


                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">

                            </div>


                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">


                            </div>

                        </div>

                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 13px;line-height: 15px;color: #231F20;margin-top: 20px; margin-bottom: 20px;">
                            Add Remarks
                        </div>

                        <div>

                            <textarea rows="4" nz-input [(ngModel)]="check_in_remarks" style="width: 100%;"></textarea>

                        </div>
                    </nz-card>

                    <nz-card>
                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Other Details
                        </div>
                        <div style="padding:0 8px">
                            <div>

                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Mileage
                                                </div>

                                                <div>

                                                    <input nz-input placeholder="Mileage"
                                                        [(ngModel)]="check_in_mileage" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Gasoline Level
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_in_gasoline_level" style="width: 100%; ">
                                                        <nz-option nzLabel="E" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="1/8" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="1/4" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="1/2" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="5/8" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="3/4" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="7/8" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="F" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="N/A" nzValue="8"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </nz-card>

                    <nz-card>
                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Condition of body work/wheels/tyres
                        </div>

                        <div style="display: flex;">
                            <div class="w-100" id="check_in_marking_canvas_container">

                            </div>

                            <div id="check_in_marking_canvas_markings" style="width: 300px;min-width: 300px;">
                                <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;"
                                    *ngFor="let check_in_marking of check_in_markings;let i = index;">
                                    <nz-input-group nzAddOnBefore="{{i+1}}">
                                        <input nz-input placeholder="Text..." [(ngModel)]="check_in_marking.text" />
                                    </nz-input-group>
                                    <div (click)="removePointCheckIn(i)" style="margin-left: 5px;">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.9189 1H3.91895C2.81438 1 1.91895 1.89543 1.91895 3V17C1.91895 18.1046 2.81438 19 3.91895 19H17.9189C19.0235 19 19.9189 18.1046 19.9189 17V3C19.9189 1.89543 19.0235 1 17.9189 1Z"
                                                stroke="#FF3A29" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M7.91895 7L13.9189 13" stroke="#FF3A29" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.9189 7L7.91895 13" stroke="#FF3A29" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <button *ngIf="check_in_markings.length<20" nz-button (click)="addCheckInPoint()">Add
                                    Point</button>
                            </div>
                        </div>

                    </nz-card>

                    <nz-card>
                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Photos
                        </div>
                        <div style="padding:0 8px">
                            <div>

                                <div class="clearfix">
                                    <nz-upload [nzAccept]="'image/*'"
                                        nzAction="https://bigfoot.reddotapps.com.sg/api/upload-order-images"
                                        nzListType="picture-card" [(nzFileList)]="check_in_photos"
                                        [nzShowButton]="check_in_photos.length < 20" [nzPreview]="handlePreview"
                                        >
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                    <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                                        (nzOnCancel)="previewVisible = false">
                                        <ng-template #modalContent>
                                            <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                                        </ng-template>
                                    </nz-modal>
                                </div>

                            </div>
                        </div>

                    </nz-card>

                </div>
            </nz-card>

            <nz-card *ngIf="form_type == 'check_out'">
                <div class="breadcrumbs-add-btn-bar">
                    <div class="title-breadrumbs-container">
                        <div class="title">CHECK OUT MARKINGS</div>
                    </div>
                    <div class="doc-search-btn">
                        <div class="icon-download">
                            <div class="download-text">
                                <div
                                    style="margin-right: 10px; display: flex; flex-direction: row; justify-content: center; align-items: center;">
                                    <div style="margin-right: 10px;">
                                        <button nz-button nzType="secondary"
                                            (click)="getCheckinFormData()">FETCH FROM CHECK-IN</button>
                                        <button nz-button nzType="primary"
                                            (click)="insertOrUpdateCheckOutMarkings()" class="ml-2">SAVE</button>
                                        <button nz-button nzType="secondary"
                                            (click)="printCheckoutForm()" class="ml-2">EXPORT PDF</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="width: 100%;margin-top: 55px;"  id="print-section-checkout">

                    <nz-card>

                        <div style="padding:0 8px">
                            <div>
                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Check Out Date
                                                </div>

                                                <div>
                                                    <nz-date-picker [nzDisabledDate]="disabledDate" style="width: 100%;"
                                                        [nzFormat]="'dd-MM-yyyy'" nzPlaceHolder="Select Date"
                                                        [(ngModel)]="check_in_date"></nz-date-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Driver Name
                                                </div>
                                                <div>
                                                    <input nz-input [(ngModel)]="driver_name" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </nz-card>

                    <nz-card>
                        <div class="d-flex justify-content-between">
                            <div
                                style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                                Exterior
                            </div>
                            <!-- <div style="color: #1e5799;font-size: 11px;text-transform: uppercase;letter-spacing: 0.8px;font-weight: 600;cursor: pointer;display: flex;height: 18px;"
                                (click)="cloneCheckInMarkingToCheckOutMarking()">
                                FETCH DATA FROM CHECK-IN
                            </div> -->
                        </div>
                        <div style="padding:0 8px">
                            <div>
                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Road Tax
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_road_tax" style="width: 100%; ">
                                                        <nz-option nzLabel="Valid" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Expired" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Radio Antenna
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_radio_antenna" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Speed Limit Label
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_speed_limit_label" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Door Alarm
                                                </div>

                                                <div>


                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_door_alarm" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Centre Locking
                                                </div>

                                                <div>


                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_centre_locking" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>

                    </nz-card>

                    <nz-card>
                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Interior
                        </div>

                        <div>
                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Vehicle IU
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_vehicle_iu" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Fix Accessories
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_fix_accessories" style="width: 100%; ">
                                                        <nz-option nzLabel="Listed in Remarks" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    No.of Car mats / carpets
                                                </div>

                                                <div>

                                                    <nz-input-number nz-input placeholder="No.of Car mats / carpets"
                                                        [nzMin]="0" [(ngModel)]="check_out_carpets"
                                                        style="width: 100%;"></nz-input-number>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Solar Films
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_solar_film" style="width: 100%; ">
                                                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Seat leather/panels
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_seat_leather" style="width: 100%; ">
                                                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="Torn" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Safety Belts
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_safety_belts" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Jam" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="Torn" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Dashboard
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_dashboard" style="width: 100%; ">
                                                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="Crack" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Radio/CD/DVD player
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_radio_player" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Ash Tray/Cigarette Lighter
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_ash_tray" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Warning Light
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_warning_light" style="width: 100%; ">
                                                        <nz-option nzLabel="No Warning" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Light On" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Indicate light on
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_indicate_light_on" style="width: 100%; ">
                                                        <nz-option nzLabel="Yes" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="No" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Air Con
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_air_con"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Horn
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_horn"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Real Mirror/ Clip On Mirror
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_real_mirror" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Damage" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Right wing mirror folding motor/ Auto left
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_right_wing_mirror" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="Not Tested" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="3"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Power Windows
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_power_window" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Rear booth carpet / Mat
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_rear_booth_carpet" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Damage" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Spare Tyre Supporting board
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_spare_tyre_supporting_board"
                                                        style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Spare Tyres
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_spare_tyre" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Jack Tools/ Kit
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_tool_kit" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Breakdown Sign
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_breakdown_sign" style="width: 100%; ">
                                                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Rear boot remote /Latch
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_rear_boot_remote" style="width: 100%; ">
                                                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">

                            </div>


                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">

                            </div>


                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">


                            </div>

                        </div>

                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 13px;line-height: 15px;color: #231F20;margin-top: 20px; margin-bottom: 20px;">
                            Add Remarks
                        </div>

                        <div>

                            <textarea rows="4" nz-input [(ngModel)]="check_out_remarks" style="width: 100%;"></textarea>

                        </div>
                    </nz-card>

                    <nz-card>
                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Other Details
                        </div>
                        <div style="padding:0 8px">
                            <div>

                                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Mileage
                                                </div>

                                                <div>

                                                    <input nz-input placeholder="Mileage"
                                                        [(ngModel)]="check_out_mileage" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                                        <div class="inner-box">
                                            <div
                                                style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                                                <div
                                                    style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                                                    Gasoline Level
                                                </div>

                                                <div>

                                                    <nz-select nzShowSearch nzAllowClear
                                                        [(ngModel)]="check_out_gasoline_level" style="width: 100%; ">
                                                        <nz-option nzLabel="E" nzValue="0"></nz-option>
                                                        <nz-option nzLabel="1/8" nzValue="1"></nz-option>
                                                        <nz-option nzLabel="1/4" nzValue="2"></nz-option>
                                                        <nz-option nzLabel="1/2" nzValue="3"></nz-option>
                                                        <nz-option nzLabel="5/8" nzValue="4"></nz-option>
                                                        <nz-option nzLabel="3/4" nzValue="5"></nz-option>
                                                        <nz-option nzLabel="7/8" nzValue="6"></nz-option>
                                                        <nz-option nzLabel="F" nzValue="7"></nz-option>
                                                        <nz-option nzLabel="N/A" nzValue="8"></nz-option>
                                                    </nz-select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </nz-card>

                    <nz-card>
                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Condition of body work/wheels/tyres
                        </div>

                        <div style="display: flex;">
                            <div id="check_out_marking_canvas_container" class="w-100">

                            </div>

                            <div id="check_out_marking_canvas_markings">
                                <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;"
                                    *ngFor="let check_out_marking of check_out_markings;let i = index;">
                                    <nz-input-group nzAddOnBefore="{{i+1}}">
                                        <input nz-input placeholder="Text..." [(ngModel)]="check_out_marking.text" />
                                    </nz-input-group>
                                    <div (click)="removePointCheckOut(i)" style="margin-left: 5px;">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.9189 1H3.91895C2.81438 1 1.91895 1.89543 1.91895 3V17C1.91895 18.1046 2.81438 19 3.91895 19H17.9189C19.0235 19 19.9189 18.1046 19.9189 17V3C19.9189 1.89543 19.0235 1 17.9189 1Z"
                                                stroke="#FF3A29" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M7.91895 7L13.9189 13" stroke="#FF3A29" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.9189 7L7.91895 13" stroke="#FF3A29" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <button *ngIf="check_out_markings.length<20" nz-button (click)="addCheckOutPoint()">Add
                                    Point</button>
                            </div>
                        </div>

                    </nz-card>

                    <nz-card>
                        <div
                            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
                            Photos
                        </div>
                        <div style="padding:0 8px">
                            <div>

                                <div class="clearfix">
                                    <nz-upload [nzAccept]="'image/*'"
                                        nzAction="https://bigfoot.reddotapps.com.sg/api/upload-order-images"
                                        nzListType="picture-card" [(nzFileList)]="check_out_photos"
                                        [nzShowButton]="check_out_photos.length < 20" [nzPreview]="handlePreview"
                                       >
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                    <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                                        (nzOnCancel)="previewVisible = false">
                                        <ng-template #modalContent>
                                            <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                                        </ng-template>
                                    </nz-modal>
                                </div>

                            </div>
                        </div>

                    </nz-card>

                </div>
            </nz-card>
        </div>
    </div>
</div>