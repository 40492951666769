import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { ActivatedRoute } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';


@Component({
  selector: 'app-primary-header',
  templateUrl: './primary-header.component.html',
  styleUrls: ['./primary-header.component.css']
})
export class PrimaryHeaderComponent implements OnInit {


  searchVisible : boolean = false;
  quickViewVisible : boolean = false;
  isFolded : boolean;
  isExpand : boolean;

  name: string = localStorage.getItem('name');
  email: string = localStorage.getItem('email');
  profile_pic: string = localStorage.getItem('profile_pic');
  
  notificationList = [];
  unreadMessages = 0;

  search_all = '';
  options = {
    customers: [],
    products: [],
    vendors: [],
    vehicles: [],
  };
  isLoading = false;

  role = +localStorage.getItem('role');

  constructor(private message: NzMessageService, private route: ActivatedRoute, private router: Router, private themeService: ThemeConstantService) {}

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getNotifications();
  }

  onSearch(event) {
    const data = {
        search: event
    }

    this.isLoading = true;

    axios.post('/api/all-search', data)
    .then(response => {
        this.options = response.data.options;
        this.isLoading = false;
    })
  }

  onChange(event) {
    const type = event.split('_')[0];
    const id = event.split('_')[1];
    
    if (type == 'customer') {
        this.router.navigate(['/customer-single'], { queryParams: { customer_id: id } });
    } else if (type == 'product') {
        this.router.navigate(['/product-single'], { queryParams: { product_id: id } });
    } else if (type == 'vendor') {
        this.router.navigate(['/masterdata'], { queryParams: { vendor_id: id } });
    } else if (type == 'vehicle') {
        this.router.navigate(['/all-vehicles'], { queryParams: { vehicle_id: id } });
    }
      
  } 
  
  toggleFold() {
      console.log(this.isFolded);
      this.isFolded = !this.isFolded;
      this.themeService.toggleFold(this.isFolded)
  }

  toggleExpand() {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
  }

  searchToggle(): void {
      this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
      this.quickViewVisible = !this.quickViewVisible;
  }

  createSuccessMessage(msg: string): void {
    this.message.create('success', msg);
  }

  createErrorMessage(msg: string): void {
    this.message.create('error', msg);
  }

  logout() {

      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("name");
      localStorage.removeItem("id");
      localStorage.removeItem("role");
      localStorage.removeItem("status");
      localStorage.removeItem("username");
      this.createSuccessMessage(`Logged Out Successfully!`);
      this.router.navigate(['/']);
        
  }

  getNotifications() {
    const data = {
        limit: 50,
        app_name: 'vmo'
    }

    axios.post('/api/get-notifications', data)
    .then(response => {
        this.notificationList = response.data?.notifications;
        this.unreadMessages = this.notificationList.filter(notification => notification.read == false).length;
    })
  }

  markAllAsRead() {
    const data = {

    }

    axios.post('/api/read-all-notifications', data)
    .then(response => {
        this.getNotifications();
    })
  }

}
