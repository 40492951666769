import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { registerLocaleData, PathLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NzAffixModule } from 'ng-zorro-antd/affix'; 
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { NzTransButtonModule } from 'ng-zorro-antd/core/trans-button';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzI18nModule } from 'ng-zorro-antd/i18n';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzResizableModule } from 'ng-zorro-antd/resizable';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { SignupPageComponent } from './signup-page/signup-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { PasswordResetPageComponent } from './password-reset-page/password-reset-page.component';
import { Error404PageComponent } from './error404-page/error404-page.component';
import { CustomheaderComponent } from './customheader/customheader.component';
import { CustomfooterComponent } from './customfooter/customfooter.component';
import { AllComponentsComponent } from './all-components/all-components.component';
import { AllProductsComponent } from './all-products/all-products.component';
import { ProductSingleComponent } from './product-single/product-single.component';
import { PrimaryHeaderComponent } from './primary-header/primary-header.component';
import { InventoryNavbarComponent } from './inventory-navbar/inventory-navbar.component';
import { OrdersNavbarComponent } from './orders-navbar/orders-navbar.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { OrderSingleComponent } from './order-single/order-single.component';
import { MasterdataComponent } from './masterdata/masterdata.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { AllGeneralServicingComponent } from './all-general-servicing/all-general-servicing.component';
import { GeneralServicingNewComponent } from './general-servicing-new/general-servicing-new.component';
import { GeneralServicingSingleComponent } from './general-servicing-single/general-servicing-single.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { InventoryTransactionsComponent } from './inventory-transactions/inventory-transactions.component';
import { AllCustomersComponent } from './all-customers/all-customers.component';
import { CustomerSingleComponent } from './customer-single/customer-single.component';
import { InvoiceSingleComponent } from './invoice-single/invoice-single.component';
import { AllInvoiceComponent } from './all-invoice/all-invoice.component';
import { AllAccidentalClaimsComponent } from './all-accidental-claims/all-accidental-claims.component';
import { AccidentalClaimSingleComponent } from './accidental-claim-single/accidental-claim-single.component';
import { AccidentalClaimComponent } from './accidental-claim/accidental-claim.component';
import { AllJobsComponent } from './all-jobs/all-jobs.component';
import { SortByComponentComponent } from './sort-by-component/sort-by-component.component';
import { DashboardComponent } from './dashboard/dashboard.component'; 
import { NgChartjsModule } from 'ng-chartjs';
import { AllReportingOnlyComponent } from './all-reporting-only/all-reporting-only.component';
import { ReportingOnlyNewComponent } from './reporting-only-new/reporting-only-new.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { SidebarDashboardComponent } from './sidebar-dashboard/sidebar-dashboard.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { DashboardInventoryComponent } from './dashboard-inventory/dashboard-inventory.component';
import { DashboardFinanceComponent } from './dashboard-finance/dashboard-finance.component';
import { DashboardLabourComponent } from './dashboard-labour/dashboard-labour.component';
import { DashboardWorkshopComponent } from './dashboard-workshop/dashboard-workshop.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { AllSurveyorsComponent } from './all-surveyors/all-surveyors.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { AllVehiclesComponent } from './all-vehicles/all-vehicles.component';
import { AllUpcomingBookingsComponent } from './all-upcoming-bookings/all-upcoming-bookings.component';
import { VehicleCategoriesComponent } from './vehicle-categories/vehicle-categories.component';
import { OrderMastersComponent } from './order-masters/order-masters.component';
import { ApprovePendingApprovalComponent } from './approve-pending-approval/approve-pending-approval.component';
import { DashboardCustomerComponent } from './dashboard-customer/dashboard-customer.component';
import { DisposableProductsComponent } from './disposable-products/disposable-products.component';
import { DashboardGpsComponent } from './dashboard-gps/dashboard-gps.component';
import { AgmCoreModule } from '@agm/core';
import { DashboardFuelConsumptionComponent } from './dashboard-fuel-consumption/dashboard-fuel-consumption.component';
import { DashboardCombinedComponent } from './dashboard-combined/dashboard-combined.component';

import { initializeApp } from "firebase/app";
import { environment } from "../environments/environment";
import { AllWashingRegisterComponent } from './all-washing-register/all-washing-register.component';
import { WashingFormComponent } from './washing-form/washing-form.component';
import { VehicleCheckHistoryComponent } from './vehicle-check-history/vehicle-check-history.component';
import { VehicleCheckHistorySingleComponent } from './vehicle-check-history-single/vehicle-check-history-single.component';
import { VpcMasterComponent } from './vpc-master/vpc-master.component';

initializeApp(environment.firebase);

export function momentAdapterFactory() {
  return adapterFactory(moment);
};


registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent,
        CustomheaderComponent,
        CustomfooterComponent,
        SignupPageComponent,
        LoginPageComponent,
        PasswordResetPageComponent,
        Error404PageComponent,
        AllComponentsComponent,
        AllProductsComponent,
        ProductSingleComponent,
        PrimaryHeaderComponent,
        InventoryNavbarComponent,
        OrdersNavbarComponent,
        AllOrdersComponent,
        OrderSingleComponent,
        MasterdataComponent,
        TransactionsComponent,
        PurchaseOrdersComponent,
        AllGeneralServicingComponent,
        GeneralServicingNewComponent,
        GeneralServicingSingleComponent,
        InventoryTransactionsComponent,
        AllCustomersComponent,
        CustomerSingleComponent,
        InvoiceSingleComponent,
        AllInvoiceComponent,
        AllAccidentalClaimsComponent,
        AccidentalClaimSingleComponent,
        AccidentalClaimComponent,
        AllJobsComponent,
        SortByComponentComponent,
        DashboardComponent,
        AllReportingOnlyComponent,
        ReportingOnlyNewComponent,
        SidebarDashboardComponent,
        DashboardInventoryComponent,
        DashboardFinanceComponent,
        DashboardLabourComponent,
        DashboardWorkshopComponent,
        ProfileComponent,
        SettingsComponent,
        AllSurveyorsComponent,
        ApprovalsComponent,
        AllVehiclesComponent,
        AllUpcomingBookingsComponent,
        VehicleCategoriesComponent,
        OrderMastersComponent,
        ApprovePendingApprovalComponent,
        DashboardCustomerComponent,
        DisposableProductsComponent,
        DashboardGpsComponent,
        DashboardFuelConsumptionComponent,
        DashboardCombinedComponent,
        AllWashingRegisterComponent,
        WashingFormComponent,
        VehicleCheckHistoryComponent,
        VehicleCheckHistorySingleComponent,
        VpcMasterComponent,
    ],
    imports: [
        // NgxGoogleAnalyticsModule.forRoot('G-2873YXBFV5'),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NzBreadCrumbModule,
        FormsModule,
        HttpClientModule,
        NzAffixModule,
        NzAlertModule,
        NzAnchorModule,
        NzAutocompleteModule,
        NzAvatarModule,
        NzBackTopModule,
        NzBadgeModule,
        NzButtonModule,
        NzBreadCrumbModule,
        NzCalendarModule,
        NzCardModule,
        NzCarouselModule,
        NzCascaderModule,
        NzCheckboxModule,
        NzCollapseModule,
        NzCommentModule,
        NzDatePickerModule,
        NzDescriptionsModule,
        NzDividerModule,
        NzDrawerModule,
        NzDropDownModule,
        NzEmptyModule,
        NzFormModule,
        NzGridModule,
        NzI18nModule,
        NzImageModule,
        NzInputModule,
        NzInputNumberModule,
        NzLayoutModule,
        NzListModule,
        NzMentionModule,
        NzMenuModule,
        NzMessageModule,
        NzModalModule,
        NzDrawerModule,
        NzNoAnimationModule,
        NzNotificationModule,
        NzPageHeaderModule,
        NzPaginationModule,
        NzPopconfirmModule,
        NzPopoverModule,
        NzProgressModule,
        NzRadioModule,
        NzRateModule,
        NzResultModule,
        NzSelectModule,
        NzSkeletonModule,
        NzSliderModule,
        NzSpinModule,
        NzStatisticModule,
        NzStepsModule,
        NzSwitchModule,
        NzTableModule,
        NzTabsModule,
        NzTagModule,
        NzTimePickerModule,
        NzTimelineModule,
        NzToolTipModule,
        NzTransButtonModule,
        NzTransferModule,
        NzTreeModule,
        NzTreeViewModule,
        NzTreeSelectModule,
        NzTypographyModule,
        NzUploadModule,
        NzWaveModule,
        NzResizableModule,
        NzPipesModule,
        NgChartjsModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }), 
        PerfectScrollbarModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBoU1nrGyraL_EDKoD40rM8Dc74rkJoekc',
            libraries: ['places']
        })
    ],
    providers: [{ 
        provide: NZ_I18N,
        useValue: en_US, 
    },{
            provide: LocationStrategy, 
            useClass: PathLocationStrategy
    },
    ThemeConstantService],
    bootstrap: [AppComponent]
})
export class AppModule { }
