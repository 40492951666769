<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">

            <div class="row" style="width: 100%">
                <div class="col-md-6 col-lg-3">
                    <nz-card>
                        <div class="media align-items-center">
                           <div>
                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="33" cy="33" r="33" fill="#3F87F5" fill-opacity="0.19"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.2356 31.3499C53.3739 31.3533 53.8979 31.6659 54.1136 32.3759C54.4832 33.5892 53.555 35.0001 52.3071 35.0001H50.4683C49.158 35.0001 48.8241 34.1023 49.0342 33.4667C49.3674 32.4469 51.0979 31.3441 52.2356 31.3499ZM41.6918 20.0001C39.6219 20.0001 37.2511 19.9938 35.2039 20.1942C35.4174 20.6797 35.6535 21.2288 35.825 21.6554C37.6076 21.5478 39.8104 21.4853 41.6913 21.4819C43.7964 21.4848 46.0735 21.5598 47.9981 21.6909C48.7254 21.7447 49.3992 22.1375 49.8489 22.8739C50.8129 24.4525 51.6701 25.9343 52.2112 27.5834C52.2112 27.5834 46.174 28.192 41.6907 28.192C39.6741 28.192 37.8824 28.0667 35.9658 27.9321C35.3839 29.4294 34.3307 30.8494 33 31.935L33.335 32.0719H47.306C47.7852 32.0719 47.8914 32.6302 47.67 33.1667C47.2084 34.3021 46.0202 35.0659 44.8137 35.0659H40.2924C41.1116 35.6981 41.6555 36.593 42.0047 37.4279L43.0339 40.5318H50.4404V43.0638C50.4404 43.582 50.8543 44 51.3692 44H54.0035C54.5184 44 54.9351 43.582 54.9351 43.0638V40.4007C55.6919 40.103 56.3453 39.3008 56.3453 38.3497V32.6657C56.3453 31.97 56.0677 31.1981 55.6862 30.4646L56.1744 30.3473C57.4518 30.0495 57.7181 29.6166 57.9509 28.74C58.1615 27.9435 57.6761 27.3057 56.8597 27.3057H53.9297C53.2734 25.4539 52.5217 23.6262 51.3567 21.8959C50.843 21.1355 50.1299 20.4799 49.0211 20.3236C46.6327 19.9875 44.137 20.0001 41.6918 20.0001Z" fill="#3F87F5"/>
                                <path d="M31.3194 33L27.4277 36.25C27.1672 36.4676 26.8231 36.4676 26.5627 36.25L22.6709 33.0025L21 33.7348C21.67 35.5481 22.2677 38.1709 22.2677 39.6112V41H31.7289V39.6112C31.7289 38.1709 32.3306 35.5481 33 33.7348L31.3194 33Z" fill="#3F87F5"/>
                                <path d="M18.0569 34L15.345 35.1201C14.2513 35.7298 13.8407 36.2623 13.4447 37.2245L12.0737 41.4528C11.7873 42.7196 12.3902 43.1983 12.9427 43.3708C15.2911 43.9168 21.9447 45 26.5002 45C31.0556 45 37.7087 43.9162 40.0598 43.3708C40.6101 43.1983 41.213 42.719 40.926 41.4528L39.555 37.2245C39.1596 36.2623 38.7518 35.7298 37.6559 35.1201L34.9468 34C34.3024 35.5684 33.8146 37.4467 33.8146 38.503V43.0422C31.3659 43.3597 28.6925 43.6151 26.5002 43.6151C24.3078 43.6151 21.6373 43.3591 19.188 43.0422V38.503C19.188 37.4467 18.7007 35.5684 18.0569 34Z" fill="#3F87F5"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1031 17.5888C20.5017 19.9794 20.0114 22.3808 20.0001 24.9272C19.9807 28.8338 23.1331 32 26.9978 32H27.0027C30.8695 32 34.0198 28.8343 33.9999 24.9272C33.9892 22.3808 33.4989 19.9794 31.9006 17.5888C30.7997 15.9446 28.952 15 27.0005 15C25.0512 15 23.2003 15.9446 22.1031 17.5888ZM27 16.7653C28.3461 16.7653 29.5783 17.4584 30.5223 18.6752C31.0539 19.365 31.477 20.2222 31.7485 21.1847C30.4352 20.0929 28.7638 19.459 27 19.4248C25.2362 19.459 23.5675 20.0929 22.2536 21.1847C22.5251 20.2222 22.945 19.365 23.4799 18.6752C24.4239 17.4584 25.656 16.7653 27 16.7653ZM22.3294 24.4487H31.6727C31.6899 24.6073 31.6974 24.7691 31.6974 24.9272C31.6974 27.5497 29.5982 29.6741 27.0021 29.6741H27C24.4067 29.6741 22.3015 27.5497 22.3015 24.9272C22.3042 24.7691 22.3128 24.6073 22.3294 24.4487Z" fill="#3F87F5"/>
                                </svg>
                                
                           </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">10</h2>
                                <p class="m-b-0 text-muted">Service Mechanics</p>
                            </div>
                        </div>
                    </nz-card>
                </div>

                <div class="col-md-6 col-lg-3">
                    <nz-card>
                        <div class="media align-items-center">
                            <div>
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33" cy="33" r="33" fill="#CBFFF6"/>
                                    <path d="M38.3101 33C38.2712 34.6821 35.686 36.0404 32.4995 36.0404C29.314 36.0404 26.7283 34.6821 26.6904 33C21.817 33.4291 18 33 18 42.1913C18 46.2899 20.7711 46.913 24.6047 47V46.2609C24.6047 45.4075 25.1635 44.6755 25.9646 44.4047C25.5909 43.6678 25.7343 42.7659 26.3661 42.161L27.4689 41.0989C27.8454 40.7349 28.3727 40.5257 28.9148 40.5257C29.2205 40.5257 29.5207 40.5913 29.7918 40.7153C30.067 39.9413 30.8283 39.4003 31.7192 39.4003H33.2808C34.1666 39.4003 34.9265 39.9405 35.2068 40.7135C35.4774 40.5886 35.7795 40.5221 36.0894 40.5221C36.638 40.5221 37.1514 40.7282 37.5293 41.0967L38.6348 42.161C39.2615 42.765 39.4026 43.6643 39.0335 44.4016C39.8356 44.6679 40.3957 45.4013 40.3957 46.2609V47C44.2293 46.913 47 46.2899 47 42.1913C47.0009 33 43.183 33.4287 38.3101 33Z" fill="#00C9A7"/>
                                    <path d="M32.4996 34C36.2782 34 39.4092 28.44 40 24H25C25.5903 28.4414 28.7231 34 32.4996 34Z" fill="#00C9A7"/>
                                    <path d="M24.175 23H40.8255C41.4744 23 42 22.4748 42 21.8266C42 21.177 41.4744 20.6523 40.8255 20.6523H40.5062C40.5062 17.5301 38.7191 14.8282 36.1148 13.5069L35.4518 17.2701C35.4 17.5678 35.1414 17.777 34.8499 17.777C34.8141 17.777 34.7779 17.7737 34.7407 17.7671C34.4068 17.7082 34.184 17.3907 34.2438 17.0567L35.0001 12.7584C34.7167 12.3034 34.2128 12 33.6363 12H31.3637C30.7882 12 30.2843 12.3034 30.0008 12.7579L30.7576 17.0563C30.8169 17.3902 30.5946 17.7077 30.2603 17.7666C30.2235 17.7732 30.1873 17.7765 30.151 17.7765C29.859 17.7765 29.6005 17.5674 29.5492 17.2697L28.8856 13.5064C26.2805 14.8277 24.4947 17.5297 24.4947 20.6518H24.1745C23.5256 20.6518 23 21.1766 23 21.8261C23.0009 22.4743 23.526 23 24.175 23Z" fill="#00C9A7"/>
                                    <path d="M38.4666 46.1117L37.486 45.9469C37.3934 45.6444 37.2705 45.3507 37.1239 45.0705L37.6931 44.2728C37.8918 44.0299 37.8732 43.6799 37.6522 43.4588L36.5398 42.3488C36.4258 42.2329 36.2731 42.1705 36.1112 42.1705C35.9706 42.1705 35.8338 42.2185 35.7374 42.2985L34.9281 42.8761C34.6479 42.7281 34.3542 42.6062 34.0517 42.5121L33.8907 41.5464C33.859 41.235 33.5993 41 33.2847 41H31.7134C31.4002 41 31.1401 41.235 31.1107 41.5329L30.9478 42.5121C30.6439 42.6062 30.3507 42.7281 30.0701 42.8761L29.2733 42.3055C29.1648 42.2175 29.0285 42.1742 28.8916 42.1742C28.7334 42.1742 28.5747 42.2329 28.4597 42.3483L27.3483 43.4583C27.1249 43.6799 27.1077 44.0303 27.298 44.2621L27.8761 45.0701C27.7295 45.3502 27.6062 45.6435 27.5131 45.9464L26.5469 46.1084C26.235 46.1401 26 46.3998 26 46.7144V48.2852C26 48.6002 26.235 48.8595 26.5334 48.8883L27.5135 49.0526C27.6062 49.3533 27.73 49.6484 27.8766 49.9295L27.3087 50.7263C27.1082 50.9687 27.1268 51.3187 27.3488 51.5412L28.4597 52.6522C28.5747 52.7662 28.7269 52.8309 28.8893 52.8309C29.0299 52.8309 29.1653 52.782 29.2621 52.7015L30.0705 52.1244C30.3516 52.2724 30.6444 52.3943 30.9483 52.4883L31.1084 53.4541C31.1401 53.7645 31.4002 54 31.7134 54H33.2847C33.5993 54 33.8585 53.7645 33.8869 53.4671L34.0517 52.4879C34.3538 52.3938 34.6479 52.2719 34.9281 52.1239L35.7253 52.6922C35.8342 52.7811 35.9711 52.8248 36.1079 52.8248C36.2671 52.8248 36.4258 52.7662 36.5398 52.6512L37.6508 51.5403C37.8732 51.3187 37.8918 50.9687 37.7015 50.7374L37.1239 49.9295C37.2705 49.6493 37.3934 49.3551 37.486 49.0517L38.4508 48.8883C38.7645 48.8595 39 48.6002 39 48.2852V46.7144C39 46.4002 38.7645 46.1405 38.4666 46.1117ZM32.4991 50.0993C31.0656 50.0993 29.8988 48.9344 29.8988 47.5C29.8988 46.0656 31.0656 44.8997 32.4991 44.8997C33.933 44.8997 35.1003 46.0656 35.1003 47.5C35.1003 48.9344 33.933 50.0993 32.4991 50.0993Z" fill="#00C9A7"/>
                                    </svg>
                                    
                            </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">5</h2>
                                <p class="m-b-0 text-muted">Service Advisors</p>
                            </div>
                        </div>
                    </nz-card>
                </div>

                <div class="col-md-6 col-lg-3">
                    <nz-card>
                        <div class="media align-items-center">
                            <div>
                                <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="33" cy="33" r="33" fill="#FFF9E6"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.0814 34.8126C41.5462 34.8703 42.0196 34.9 42.5 34.9C44.341 34.9 46.0801 34.4636 47.6194 33.6886C47.9734 33.9736 48.2 34.4105 48.2 34.9V36.4545L45.0909 36.973V50.4455C45.0909 51.3043 44.3953 52 43.5364 52H41.2045L40.3495 47.3364H21.8505L20.9955 52H18.6636C17.8055 52 17.1091 51.3043 17.1091 50.4455V36.973L14 36.4545V34.9C14 34.0419 14.6964 33.3455 15.5545 33.3455H18.1654L20.0526 27.7887C20.5734 25.7064 22.735 24.0182 24.8818 24.0182H31.1116C31.16 25.1007 31.3594 26.1439 31.6892 27.1273H24.2002C23.7703 27.1273 23.3654 27.4701 23.2954 27.8937L21.0911 34.9H41.1089L41.0814 34.8126ZM20.9955 42.6727C19.7083 42.6727 18.6636 41.6288 18.6636 40.3409C18.6636 39.053 19.7083 38.0091 20.9955 38.0091C22.2826 38.0091 23.3273 39.053 23.3273 40.3409C23.3273 41.6288 22.2826 42.6727 20.9955 42.6727ZM35.7636 42.6727C35.7636 43.5316 34.9288 44.2273 33.8982 44.2273H28.3018C27.2721 44.2273 26.4364 43.5316 26.4364 42.6727V41.1182H35.7636V42.6727ZM40.955 24.7672L39.5868 23.399C39.128 22.9401 38.3723 22.934 37.91 23.3963C37.4444 23.8618 37.4489 24.6093 37.9127 25.0731L40.8995 28.06L40.955 28.0045L41.0105 28.06L47.3484 21.7221C47.8098 21.2607 47.8107 20.5102 47.3484 20.0479C46.8829 19.5824 46.1365 19.5856 45.6742 20.048L40.955 24.7672ZM41.2045 42.6727C39.9174 42.6727 38.8727 41.6288 38.8727 40.3409C38.8727 39.053 39.9174 38.0091 41.2045 38.0091C42.4917 38.0091 43.5364 39.053 43.5364 40.3409C43.5364 41.6288 42.4917 42.6727 41.2045 42.6727ZM42.5 33C47.7467 33 52 28.7467 52 23.5C52 18.2533 47.7467 14 42.5 14C37.2533 14 33 18.2533 33 23.5C33 28.7467 37.2533 33 42.5 33Z" fill="#FFC107"/>
                                    </svg>
                                        
                                </div>

                            <div class="m-l-15">
                                <h2 class="m-b-0">24</h2>
                                <p class="m-b-0 text-muted">Quality Control</p>
                            </div>
                        </div>
                    </nz-card>
                </div>
                <div class="col-md-6 col-lg-3">
                    <nz-card>
                        <div class="media align-items-center">
                           <div>
                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="33" cy="33" r="33" fill="#F3F0FF"/>
                                <path d="M49.0202 24.0031C49.0202 24.3958 48.7186 24.717 48.3499 24.717C48.1689 24.717 48.0013 24.7955 47.8739 24.9312C47.7466 25.0668 47.6795 25.2382 47.6795 25.4309C47.6795 25.8236 47.3778 26.1449 47.0091 26.1449C46.6337 26.1449 46.3388 25.8236 46.3388 25.4309C46.3388 25.0383 46.0371 24.717 45.6684 24.717C45.293 24.717 44.998 24.3958 44.998 24.0031C44.998 23.6104 45.293 23.2892 45.6684 23.2892C45.8494 23.2892 46.017 23.2178 46.1444 23.0821C46.265 22.9536 46.3388 22.768 46.3388 22.5753C46.3388 22.1826 46.6337 21.8613 47.0091 21.8613C47.3778 21.8613 47.6795 22.1826 47.6795 22.5753C47.6795 22.768 47.7466 22.9465 47.8739 23.0821C48.0013 23.2178 48.1689 23.2892 48.3499 23.2892C48.7186 23.2892 49.0202 23.6104 49.0202 24.0031Z" fill="#886CFF"/>
                                <path d="M25.9577 18.3844C25.9577 18.7771 25.6561 19.0984 25.2874 19.0984C25.1131 19.0984 24.9455 19.1698 24.8114 19.3125C24.6841 19.4482 24.617 19.6195 24.617 19.8123C24.617 20.2049 24.3153 20.5262 23.9466 20.5262C23.5712 20.5262 23.2763 20.2049 23.2763 19.8123C23.2763 19.4196 22.9746 19.0984 22.6059 19.0984C22.2305 19.0984 21.9355 18.7771 21.9355 18.3844C21.9355 17.9918 22.2305 17.6705 22.6059 17.6705C22.7869 17.6705 22.9612 17.5991 23.0819 17.4635C23.2025 17.3278 23.2763 17.1494 23.2763 16.9566C23.2763 16.5639 23.5712 16.2427 23.9466 16.2427C24.3153 16.2427 24.617 16.5639 24.617 16.9566C24.617 17.1993 24.7243 17.3707 24.8114 17.4635C24.9388 17.5991 25.1064 17.6705 25.2874 17.6705C25.6561 17.6705 25.9577 17.9918 25.9577 18.3844Z" fill="#886CFF"/>
                                <path d="M39.0845 19.5696C39.0845 19.9623 38.7829 20.2835 38.4142 20.2835C37.8779 20.2835 37.3684 20.5048 36.9863 20.9118C36.6109 21.3187 36.4031 21.8542 36.4031 22.4253C36.4031 22.818 36.1014 23.1392 35.7327 23.1392C35.3573 23.1392 35.0623 22.818 35.0623 22.4253C35.0623 21.2473 34.1573 20.2835 33.0512 20.2835C32.6758 20.2835 32.3809 19.9623 32.3809 19.5696C32.3809 19.177 32.6758 18.8557 33.0512 18.8557C33.5808 18.8557 34.0903 18.6344 34.4724 18.2274C34.8478 17.8205 35.0623 17.2851 35.0623 16.7139C35.0623 16.3213 35.3573 16 35.7327 16C36.1014 16 36.4031 16.3213 36.4031 16.7139C36.4031 17.2851 36.6109 17.8205 36.993 18.2346C37.3684 18.6344 37.8779 18.8557 38.4142 18.8557C38.7829 18.8557 39.0845 19.177 39.0845 19.5696Z" fill="#886CFF"/>
                                <path d="M55.2112 37.9389C54.99 36.6253 54.695 35.3117 54.1654 34.1123C54.1587 34.098 54.152 34.0766 54.1453 34.0695C53.542 32.7273 52.6504 31.5422 51.2292 30.6783C50.4248 30.1857 49.4863 29.9287 48.5142 29.9287H43.0641C38.9414 25.8522 34.3427 23.9389 28.9127 24.0531C26.2849 23.7461 22.7319 25.0669 19.3197 26.4519L18.0125 26.9874C15.807 27.8869 13.7289 28.7365 11.9993 29.1862C11.7379 29.2505 11.5435 29.4789 11.4966 29.7574C11.2083 31.4993 10.9536 33.327 11.0072 35.0047C11.0005 35.0118 11.0005 35.0261 11.0005 35.0333C11.0005 35.0547 11.0072 35.0761 11.0072 35.0904C11.0809 37.1607 11.6306 38.9884 13.1524 40.1235C13.2663 40.2021 13.4004 40.252 13.5412 40.252H16.6785C16.9265 42.3581 18.6159 44.0001 20.6672 44.0001C22.7118 44.0001 24.4078 42.3581 24.6492 40.252H39.5782C39.8263 42.3581 41.5223 44.0001 43.5669 44.0001C45.6182 44.0001 47.3076 42.3581 47.5556 40.252H53.4548C53.9777 40.252 54.4671 40.0022 54.809 39.5667C55.1643 39.1169 55.3117 38.5243 55.2112 37.9389ZM13.1658 34.9119H11.7647C11.7781 34.0695 11.8384 33.1628 11.9189 32.2204H13.1658C13.8697 32.2204 14.4328 32.8201 14.4328 33.5697C14.4328 34.3122 13.8697 34.9119 13.1658 34.9119ZM41.0195 29.9287H31.574L29.878 25.481C34.0879 25.5881 37.6811 27.0159 41.0195 29.9287ZM28.4233 25.4667L30.1261 29.9287H21.8671L20.5398 27.4942C23.3151 26.3877 26.2849 25.3525 28.4233 25.4667ZM20.6672 42.5723C19.1857 42.5723 17.9857 41.2944 17.9857 39.7166C17.9857 38.1388 19.1857 36.8609 20.6672 36.8609C22.142 36.8609 23.3486 38.1388 23.3486 39.7166C23.3486 41.2944 22.142 42.5723 20.6672 42.5723ZM24.8838 38.0317L22.5911 31.6635L25.7821 37.018L37.1917 38.7528L24.8838 38.0317ZM43.5669 42.5723C42.0921 42.5723 40.8854 41.2944 40.8854 39.7166C40.8854 38.1388 42.0921 36.8609 43.5669 36.8609C45.0484 36.8609 46.2484 38.1388 46.2484 39.7166C46.2484 41.2944 45.0484 42.5723 43.5669 42.5723ZM48.1723 30.8925C49.7343 30.2 53.3677 32.3489 53.1867 34.2836C50.2974 34.1837 48.836 32.249 48.1723 30.8925Z" fill="#886CFF"/>
                                </svg>
                                
                           </div>
                            <div class="m-l-15">
                                <h2 class="m-b-0">12</h2>
                                <p class="m-b-0 text-muted">Vehicles Washed</p>
                            </div>
                        </div>
                    </nz-card>

                   
        
                </div>

                <div class="row" style="width: 100%">
                    <div class="col-12">
                        <nz-card>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 style="color:#004A7F;">Labour Ranking</h5>      
                            </div>
    
                            <div class="d-flex justify-content-between align-items-center" style="margin-bottom: -20px; margin-top: 20px;">
                                <h6 style="color:#000000;">Hours</h6>
                                <div>
                                    <div style="display: flex; flex-direction: row; justify-content: center; align-items: center; position: relative; top: 4px;">
                                        <div style="margin-right: 15px;">
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <ellipse cx="6" cy="6.5" rx="6" ry="6.5" fill="#FF6060"/>
                                                </svg>   
                                        </div>
                                        <div>Labours</div>
                                    </div>
                                    
                                </div>
                            </div>
                
                            <div class="m-t-50" style="height: 290px">
                                <canvas ngChartjs 
                                    #myCanvas 
                                    [datasets]="revenueChartData" 
                                    [labels]="revenueChartLabels"
                                    [options]="revenueChartOptions" 
                                    [colors]="revenueChartColors" 
                                    [legend]="false"
                                    [chartType]="revenueChartType">
                                </canvas>
                            </div>
                        </nz-card>
                    </div>
                </div>

                <div class="row" style="width: 100%">
                    <div class="col-12">
                        <nz-card>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 style="color:#004A7F;">Labour Rankings</h5>

                                <div>
                                    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Department" [(ngModel)]="selectedValue">
                                        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                                        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                                        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                                      </nz-select>
                                </div>
                            </div>
                            <div class="m-t-30">
                                <nz-table #ordersListTable [nzData]="ordersList"  [nzShowPagination]="false" class="no-border-last">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Picture</th>
                                            <th>Name</th>
                                            <th>Hours Worked</th>
                                            <th>Weekly Average Working</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of ordersListTable.data">
                                            <td>#{{item.id}}</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                  <img src="https://i.ibb.co/74QqCnn/dp.png" alt="">
                                                </div>
                                            </td>
                                            <td>Joe Cole</td>
                                            <td>12 Hours</td>
                                            <td>12 Hours</td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </div>
                        </nz-card>
                    </div>
                </div>


            </div>
            
        </div>
    </div>
</div>