<div class="common-layout is-default" [class.is-folded]="isFolded" [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded">
  <app-primary-header></app-primary-header>

  <!-- <div class="sidebar-and-main-container"> -->
  <app-sidebar-dashboard></app-sidebar-dashboard>
  <div class="page-container">
    <div class="main-content">
      <nz-card>
        <div class="breadcrumbs-add-btn-bar">
          <div class="title-breadrumbs-container">
            <div class="title">Check In/Check Out</div>
            <div class="sub-title">Vehicle Number - {{vehicle?.vehicle_number}}</div>
          </div>

          <div class="doc-search-btn">

            <div class="add-product-btn">
              <button class="mr-2" (click)="checkIn()" nz-button nzType="primary" [disabled]="loadingVehicleCheckHistory || checkedIn">
                Check-In
              </button>
              <button (click)="checkOut()" nz-button nzType="primary" [disabled]="loadingVehicleCheckHistory || !checkedIn">
                Check-Out
              </button>
            </div>
          </div>
        </div>

        <div class="stats__table">
          <nz-table #vehicleCategoryTable [nzData]="listOfVehicleCheckHistory" [nzFrontPagination]="false"
            [nzLoading]="loadingVehicleCheckHistory">
            <thead>
              <tr>
                <th>Driver Name</th>
                <th>Check In/Check Out</th>
                <th>Date</th>
                <th>Filled By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of vehicleCategoryTable.data">
                <td>{{ data.driver_name }}</td>
                <td>{{ data.type == 'check_in' ? 'Check In' : 'Check Out' }}</td>
                <td>{{ data.check_in_date | date: 'dd-MM-yyyy' }}</td>
                <td>{{ data.check_in_by_name }}</td>
                <td>
                  <button nz-button nzSize="small" routerLink="/vehicle-check-history/{{vehicle?.id}}/{{data.type}}/edit/{{data.id}}">
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
    </div>
  </div>
</div>

<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzWidth]="1024" [nzVisible]="openCheckInModal" nzTitle="Check In Form"
  (nzOnClose)="openCheckInModal=false;">
  <div *nzDrawerContent class="pb-5">

    <div class="breadcrumbs-add-btn-bar">
      <div class="title-breadrumbs-container">
        <div class="title">CHECK IN MARKINGS</div>
      </div>
      <div class="doc-search-btn">
        <div class="icon-download">
          <div class="download-text">
            <div
              style="margin-right: 10px; display: flex; flex-direction: row; justify-content: center; align-items: center;">
              <div style="margin-right: 10px;">
                <button nz-button nzType="primary" (click)="insertOrUpdateCheckInMarkings()">SAVE</button>
                <button nz-button nzType="secondary" (click)="printCheckForm()">Print</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%;margin-top: 55px;" id="print-section">

      <nz-card>

        <div style="padding:0 8px">
          <div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Check In Date
                    </div>

                    <div>
                      <nz-date-picker [nzDisabledDate]="disabledDate" style="width: 100%;" [nzFormat]="'dd-MM-yyyy'"
                        nzPlaceHolder="Select Date" [(ngModel)]="check_in_date"></nz-date-picker>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
              <div class="inner-box">
              <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
        
                <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                    Check In By
                </div>
        
                  <div>
                    <nz-select nzShowSearch [(ngModel)]="check_in_check_in_by" style="width: 100%; ">
                      <nz-option *ngFor="let supervisor of supervisors;" [nzLabel]="supervisor.name" [nzValue]="supervisor.id"></nz-option>
                    </nz-select>
                  </div>
              </div>
              </div>
            </div> -->
            </div>

          </div>
        </div>

      </nz-card>

      <nz-card>

        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Exterior
        </div>
        <div style="padding:0 8px">
          <div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Road Tax
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_road_tax" style="width: 100%; ">
                        <nz-option nzLabel="Valid" nzValue="0"></nz-option>
                        <nz-option nzLabel="Expired" nzValue="1"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Radio Antenna
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_radio_antenna" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Speed Limit Label
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_speed_limit_label"
                        style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Door Alarm
                    </div>

                    <div>


                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_door_alarm" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Centre Locking
                    </div>

                    <div>


                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_centre_locking" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>

      </nz-card>

      <nz-card>
        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Interior
        </div>

        <div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Vehicle IU
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_vehicle_iu" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Fix Accessories
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_fix_accessories"
                        style="width: 100%; ">
                        <nz-option nzLabel="Listed in Remarks" nzValue="0"></nz-option>
                        <nz-option nzLabel="NA" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      No.of Car mats / carpets
                    </div>

                    <div>
                      <nz-input-number nz-input placeholder="No.of Car mats / carpets" [nzMin]="0"
                        [(ngModel)]="check_in_carpets" style="width: 100%;"></nz-input-number>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Solar Films
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_solar_film" style="width: 100%; ">
                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Seat leather/panels
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_seat_leather" style="width: 100%; ">
                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                        <nz-option nzLabel="Torn" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Safety Belts
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_safety_belts" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Jam" nzValue="1"></nz-option>
                        <nz-option nzLabel="Torn" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Dashboard
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_dashboard" style="width: 100%; ">
                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                        <nz-option nzLabel="Crack" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Radio/CD/DVD player
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_radio_player" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Ash Tray/Cigarette Lighter
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_ash_tray" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="NA" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Warning Light
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_warning_light" style="width: 100%; ">
                        <nz-option nzLabel="No Warning" nzValue="0"></nz-option>
                        <nz-option nzLabel="Light On" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Indicate light on
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_indicate_light_on"
                        style="width: 100%; ">
                        <nz-option nzLabel="Yes" nzValue="0"></nz-option>
                        <nz-option nzLabel="No" nzValue="1"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Air Con
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_air_con" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Horn
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_horn" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Real Mirror/ Clip On Mirror
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_real_mirror" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="Damage" nzValue="1"></nz-option>
                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Right wing mirror folding motor/ Auto left
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_right_wing_mirror"
                        style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                        <nz-option nzLabel="Not Tested" nzValue="2"></nz-option>
                        <nz-option nzLabel="NA" nzValue="3"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Power Windows
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_power_window" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Rear booth carpet / Mat
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_rear_booth_carpet"
                        style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="Damage" nzValue="1"></nz-option>
                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Spare Tyre Supporting board
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_spare_tyre_supporting_board"
                        style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Spare Tyres
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_spare_tyre" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Jack Tools/ Kit
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_tool_kit" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Breakdown Sign
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_breakdown_sign" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Rear boot remote /Latch
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_rear_boot_remote"
                        style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">

          </div>


          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">

          </div>


          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">


          </div>

        </div>

        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 13px;line-height: 15px;color: #231F20;margin-top: 20px; margin-bottom: 20px;">
          Add Remarks
        </div>

        <div>

          <textarea rows="4" nz-input [(ngModel)]="check_in_remarks" style="width: 100%;"></textarea>

        </div>
      </nz-card>

      <nz-card>
        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Other Details
        </div>
        <div style="padding:0 8px">
          <div>

            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Mileage
                    </div>

                    <div>

                      <input nz-input placeholder="Mileage" [(ngModel)]="check_in_mileage" />

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Check in By
                    </div>

                    <div>
                      <nz-select nzShowSearch [(ngModel)]="check_in_check_in_by" style="width: 100%; ">
                        <nz-option *ngFor="let supervisor of supervisors;" [nzLabel]="supervisor.name"
                          [nzValue]="supervisor.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Gasoline Level
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_in_gasoline_level" style="width: 100%; ">
                        <nz-option nzLabel="E" nzValue="0"></nz-option>
                        <nz-option nzLabel="1/8" nzValue="1"></nz-option>
                        <nz-option nzLabel="1/4" nzValue="2"></nz-option>
                        <nz-option nzLabel="1/2" nzValue="3"></nz-option>
                        <nz-option nzLabel="5/8" nzValue="4"></nz-option>
                        <nz-option nzLabel="3/4" nzValue="5"></nz-option>
                        <nz-option nzLabel="7/8" nzValue="6"></nz-option>
                        <nz-option nzLabel="F" nzValue="7"></nz-option>
                        <nz-option nzLabel="N/A" nzValue="8"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </nz-card>

      <nz-card>
        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Condition of body work/wheels/tyres
        </div>

        <div style="display: flex;">
          <div id="check_in_marking_canvas_container">

          </div>

          <div id="check_in_marking_canvas_markings">
            <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;"
              *ngFor="let check_in_marking of check_in_markings;let i = index;">
              <nz-input-group nzAddOnBefore="{{i+1}}">
                <input nz-input placeholder="Text..." [(ngModel)]="check_in_marking.text" />
              </nz-input-group>
              <div (click)="removePointCheckIn(i)" style="margin-left: 5px;">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.9189 1H3.91895C2.81438 1 1.91895 1.89543 1.91895 3V17C1.91895 18.1046 2.81438 19 3.91895 19H17.9189C19.0235 19 19.9189 18.1046 19.9189 17V3C19.9189 1.89543 19.0235 1 17.9189 1Z"
                    stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.91895 7L13.9189 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M13.9189 7L7.91895 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <button *ngIf="check_in_markings.length<20" nz-button (click)="addCheckInPoint()">Add Point</button>
          </div>
        </div>

      </nz-card>

      <nz-card>
        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Photos
        </div>
        <div style="padding:0 8px">
          <div>

            <div class="clearfix">
              <nz-upload [nzAccept]="'image/*'" nzAction="https://bigfoot.reddotapps.com.sg/api/upload-order-images"
                nzListType="picture-card" [(nzFileList)]="check_in_images" [nzShowButton]="check_in_images.length < 20"
                [nzPreview]="handlePreview" (nzChange)="allImagesSave($event)">
                <div>
                  <i nz-icon nzType="plus"></i>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </nz-upload>
              <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                (nzOnCancel)="previewVisible = false">
                <ng-template #modalContent>
                  <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                </ng-template>
              </nz-modal>
            </div>

          </div>
        </div>

      </nz-card>

    </div>

  </div>
</nz-drawer>

<nz-drawer [nzBodyStyle]="{ overflow: 'auto' }" [nzWidth]="1024" [nzVisible]="openCheckOutModal"
  nzTitle="Check Out Form" (nzOnClose)="openCheckOutModal=false;">
  <div *nzDrawerContent class="pb-5">

    <div class="breadcrumbs-add-btn-bar">
      <div class="title-breadrumbs-container">
        <div class="title">CHECK OUT MARKINGS</div>
      </div>
      <div
        style="margin-right: 10px; display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <div style="margin-right: 10px;">
          <button nz-button (click)="printDischargeVoucher()">Discharge Voucher</button>
        </div>
        <div style="margin-right: 10px;">

          <button nz-button (click)="openAddInvoiceModal=true" *ngIf="invoice_id==-1">Generate Invoice</button>
          <nz-modal [nzWidth]="800" [(nzVisible)]="openAddInvoiceModal" nzTitle="Add New Invoice"
            (nzOnCancel)="openAddInvoiceModal=false" style="justify-content: center; align-items: center;">

            <div nz-row [nzGutter]="[12,36]">
              <div nz-col nzSpan="12">

                <div class="input-group">
                  <div class="input-label">Invoice Date</div>
                  <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="'dd-MM-yyyy'" style="width: 100%;"
                    [(ngModel)]="invoice_date"></nz-date-picker>
                </div>

              </div>

              <div nz-col nzSpan="12">

                <div class="input-group">
                  <div class="input-label">Due Date</div>
                  <nz-date-picker [nzDisabledDate]="disabledDate" [nzFormat]="'dd-MM-yyyy'" style="width: 100%;"
                    [(ngModel)]="due_date"></nz-date-picker>
                </div>

              </div>
            </div>

            <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary"
              (click)="generateInvoice()">SAVE</button>

          </nz-modal>

          <button nz-button (click)="printInvoice()" *ngIf="invoice_id!=-1">Print Invoice</button>
        </div>
        <div style="margin-right: 10px;">
          <button nz-button nzType="primary" (click)="insertOrUpdateCheckOutMarkings()">SAVE</button>
        </div>
      </div>
    </div>

    <div style="width: 100%;margin-top: 55px;">

      <nz-card>
        <div class="d-flex justify-content-between">
          <div
            style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
            Exterior
          </div>
          <div
            style="color: #1e5799;font-size: 11px;text-transform: uppercase;letter-spacing: 0.8px;font-weight: 600;cursor: pointer;display: flex;height: 18px;"
            (click)="cloneCheckInMarkingToCheckOutMarking()">
            FETCH DATA FROM CHECK-IN
          </div>
        </div>
        <div style="padding:0 8px">
          <div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Road Tax
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_road_tax" style="width: 100%; ">
                        <nz-option nzLabel="Valid" nzValue="0"></nz-option>
                        <nz-option nzLabel="Expired" nzValue="1"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Radio Antenna
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_radio_antenna" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Speed Limit Label
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_speed_limit_label"
                        style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Door Alarm
                    </div>

                    <div>


                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_door_alarm" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Centre Locking
                    </div>

                    <div>


                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_centre_locking" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>

      </nz-card>

      <nz-card>
        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Interior
        </div>

        <div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Vehicle IU
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_vehicle_iu" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Fix Accessories
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_fix_accessories"
                        style="width: 100%; ">
                        <nz-option nzLabel="Listed in Remarks" nzValue="0"></nz-option>
                        <nz-option nzLabel="NA" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      No.of Car mats / carpets
                    </div>

                    <div>

                      <nz-input-number nz-input placeholder="No.of Car mats / carpets" [nzMin]="0"
                        [(ngModel)]="check_out_carpets" style="width: 100%;"></nz-input-number>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Solar Films
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_solar_film" style="width: 100%; ">
                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Seat leather/panels
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_seat_leather" style="width: 100%; ">
                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                        <nz-option nzLabel="Torn" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Safety Belts
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_safety_belts" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Jam" nzValue="1"></nz-option>
                        <nz-option nzLabel="Torn" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Dashboard
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_dashboard" style="width: 100%; ">
                        <nz-option nzLabel="OK" nzValue="0"></nz-option>
                        <nz-option nzLabel="Scratches" nzValue="1"></nz-option>
                        <nz-option nzLabel="Crack" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Radio/CD/DVD player
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_radio_player" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Ash Tray/Cigarette Lighter
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_ash_tray" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="NA" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Warning Light
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_warning_light" style="width: 100%; ">
                        <nz-option nzLabel="No Warning" nzValue="0"></nz-option>
                        <nz-option nzLabel="Light On" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Indicate light on
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_indicate_light_on"
                        style="width: 100%; ">
                        <nz-option nzLabel="Yes" nzValue="0"></nz-option>
                        <nz-option nzLabel="No" nzValue="1"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Air Con
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_air_con" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>

                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Horn
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_horn" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Real Mirror/ Clip On Mirror
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_real_mirror" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="Damage" nzValue="1"></nz-option>
                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Right wing mirror folding motor/ Auto left
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_right_wing_mirror"
                        style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                        <nz-option nzLabel="Not Tested" nzValue="2"></nz-option>
                        <nz-option nzLabel="NA" nzValue="3"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>


              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Power Windows
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_power_window" style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Rear booth carpet / Mat
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_rear_booth_carpet"
                        style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="Damage" nzValue="1"></nz-option>
                        <nz-option nzLabel="NA" nzValue="2"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Spare Tyre Supporting board
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_spare_tyre_supporting_board"
                        style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Spare Tyres
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_spare_tyre" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Jack Tools/ Kit
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_tool_kit" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Breakdown Sign
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_breakdown_sign" style="width: 100%; ">
                        <nz-option nzLabel="Intact" nzValue="0"></nz-option>
                        <nz-option nzLabel="N.A" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>

              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='24'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Rear boot remote /Latch
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_rear_boot_remote"
                        style="width: 100%; ">
                        <nz-option nzLabel="Working" nzValue="0"></nz-option>
                        <nz-option nzLabel="Not Working" nzValue="1"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">

          </div>


          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">

          </div>


          <div
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 10px;">


          </div>

        </div>

        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 13px;line-height: 15px;color: #231F20;margin-top: 20px; margin-bottom: 20px;">
          Add Remarks
        </div>

        <div>

          <textarea rows="4" nz-input [(ngModel)]="check_out_remarks" style="width: 100%;"></textarea>

        </div>
      </nz-card>

      <nz-card>
        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Other Details
        </div>
        <div style="padding:0 8px">
          <div>

            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Mileage
                    </div>

                    <div>

                      <input nz-input placeholder="Mileage" [(ngModel)]="check_out_mileage" />

                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Check Out By
                    </div>

                    <div>
                      <nz-select nzShowSearch [(ngModel)]="check_out_check_in_by" style="width: 100%; ">
                        <nz-option *ngFor="let supervisor of supervisors;" [nzLabel]="supervisor.name"
                          [nzValue]="supervisor.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" [nzLg]="8" [nzXs]='12'>
                <div class="inner-box">
                  <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    <div
                      style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px; margin-top: 10px;">
                      Gasoline Level
                    </div>

                    <div>

                      <nz-select nzShowSearch nzAllowClear [(ngModel)]="check_out_gasoline_level" style="width: 100%; ">
                        <nz-option nzLabel="E" nzValue="0"></nz-option>
                        <nz-option nzLabel="1/8" nzValue="1"></nz-option>
                        <nz-option nzLabel="1/4" nzValue="2"></nz-option>
                        <nz-option nzLabel="1/2" nzValue="3"></nz-option>
                        <nz-option nzLabel="5/8" nzValue="4"></nz-option>
                        <nz-option nzLabel="3/4" nzValue="5"></nz-option>
                        <nz-option nzLabel="7/8" nzValue="6"></nz-option>
                        <nz-option nzLabel="F" nzValue="7"></nz-option>
                        <nz-option nzLabel="N/A" nzValue="8"></nz-option>
                      </nz-select>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </nz-card>

      <nz-card>
        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Condition of body work/wheels/tyres
        </div>

        <div style="display: flex;">
          <div id="check_out_marking_canvas_container">

          </div>

          <div id="check_out_marking_canvas_markings">
            <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 5px;"
              *ngFor="let check_out_marking of check_out_markings;let i = index;">
              <nz-input-group nzAddOnBefore="{{i+1}}">
                <input nz-input placeholder="Text..." [(ngModel)]="check_out_marking.text" />
              </nz-input-group>
              <div (click)="removePointCheckOut(i)" style="margin-left: 5px;">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.9189 1H3.91895C2.81438 1 1.91895 1.89543 1.91895 3V17C1.91895 18.1046 2.81438 19 3.91895 19H17.9189C19.0235 19 19.9189 18.1046 19.9189 17V3C19.9189 1.89543 19.0235 1 17.9189 1Z"
                    stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.91895 7L13.9189 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M13.9189 7L7.91895 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <button *ngIf="check_out_markings.length<20" nz-button (click)="addCheckOutPoint()">Add Point</button>
          </div>
        </div>

      </nz-card>

      <nz-card>
        <div
          style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 18px;line-height: 15px;color: #004A7F; padding-top: 15px; padding-bottom: 15px;">
          Photos
        </div>
        <div style="padding:0 8px">
          <div>

            <div class="clearfix">
              <nz-upload [nzAccept]="'image/*'" nzAction="https://bigfoot.reddotapps.com.sg/api/upload-order-images"
                nzListType="picture-card" [(nzFileList)]="check_out_images"
                [nzShowButton]="check_out_images.length < 20" [nzPreview]="handlePreview"
                (nzChange)="allImagesSave($event)">
                <div>
                  <i nz-icon nzType="plus"></i>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </nz-upload>
              <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                (nzOnCancel)="previewVisible = false">
                <ng-template #modalContent>
                  <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                </ng-template>
              </nz-modal>
            </div>

          </div>
        </div>

      </nz-card>

    </div>

  </div>
</nz-drawer>