<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>

    <app-primary-header></app-primary-header>

    <!-- <div class="sidebar-and-main-container"> -->
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">

          <nz-card>
            <div class="item-detail-navbar-container">
              <div class="item-detail-navbar" style="width: 100%;">

                <div class="detail-container">
                  <div class="item-name">{{invoice_details.order_id}}</div>

                  <div class="item-detail-text">Order ID</div>
                </div>

                <div class="detail-container"> 
                  <div class="item-name">{{invoice_details.invoice_date | date: 'mediumDate'}}</div>

                  <div class="item-detail-text">Invoice Date</div>
                </div>

                <div class="detail-container">
                  <div class="item-name">{{invoice_details.due_date | date: 'mediumDate'}}</div>

                  <div class="item-detail-text">Due Date</div>
                </div>

                <div class="detail-container">
                  <div class="item-name">{{invoice_details.total | number : '1.2-2'}}</div>

                  <div class="item-detail-text">Amount</div>
                </div>

                <div class="detail-container">
                  <div class="item-name">
                    {{invoice_details.type==0 ? "General Servicing" : ''}}
                    {{invoice_details.type==1 ? "Accidental Claim" : ''}}
                  </div>

                  <div class="item-detail-text">Order Type</div>
                </div>

              </div>

              <div class="item-detail-navbar" style="width: 100%;margin-top:20px;">

                  <div class="detail-container">
                    <div class="item-name">{{invoice_details.customer_name}}</div>
            
                    <div class="item-detail-text">Attn. to</div>
                  </div>
            
                  <div class="detail-container"> 
                    <div class="item-name">{{invoice_details.vehicle_number}}</div>
            
                    <div class="item-detail-text">Vehicle Number</div>
                  </div>
            
                  <div class="detail-container">
                    <div class="item-name">
                      {{ invoice_details.balance==0 ? 'Paid' : invoice_details.balance==invoice_details.total ? 'Unpaid' : 'Partially Paid' }}
                    </div>
            
                    <div class="item-detail-text">Status</div>
                  </div>
            
                  <div class="detail-container">
                    <div class="item-name">{{invoice_details.total - invoice_details.balance | number : '1.2-2'}}</div>
            
                    <div class="item-detail-text">Amount Collected</div>
                  </div>
            
                  <div class="detail-container">
                    <div class="item-name">{{invoice_details.service_advisor}}</div>
            
                    <div class="item-detail-text">Service Advisor</div>
                  </div>
            
              </div>
            </div>
          </nz-card>

          <nz-card>
            <div class="breadcrumbs-add-btn-bar">
                <div class="title-breadrumbs-container">
                  <div class="title">Invoice #{{invoice_id}}</div>
                </div>
                <div class="doc-search-btn">
        
                    <div class="search-box">
                        <nz-input-group [nzPrefix]="prefixIconSearch" >
                            <input type="text" nz-input placeholder="Search..." [(ngModel)]="payment_search" 
                            (ngModelChange)="getPayments()" />
                          </nz-input-group>

                          <ng-template #prefixIconSearch>
                            <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right:5px;">
                                <path d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                          </ng-template>
                    </div>

                    <button nz-button nzType="primary" (click)="openPaymentModal=true;payment_amount=invoice_details.balance;">
                      New Payment
                    </button>

                    <nz-modal [nzWidth]="800" [(nzVisible)]="openPaymentModal" nzTitle="Payment" 
                    (nzOnCancel)="openPaymentModal=false;clearPreviousData();" style="justify-content: center; align-items: center;">
                      
                      <div nz-row [nzGutter]="[12,36]">
            
                        <div nz-col nzSpan="8">
                          <div class="input-group">
                            <div class="input-label">Payment Date</div>
            
                            <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;" [(ngModel)]="payment_date"></nz-date-picker>
                          </div>
                    
                        </div>
            
                        <div nz-col nzSpan="8">
            
                          <div class="input-group">
                            <div class="input-label">Payment Method</div>
                            <nz-select nzShowSearch [(ngModel)]="payment_method" style="width: 100%;">
                              <nz-option nzValue="Cash" nzLabel="Cash"></nz-option>
                              <nz-option nzValue="Bank" nzLabel="Bank"></nz-option>
                            </nz-select>
                          </div>
            
                        
                        </div>
            
                        <div nz-col nzSpan="8">
                          
                          <div class="input-group">
                            <div class="input-label">Amount Collected</div>
                            <nz-input-group nzAddOnBefore="$">
                              <nz-input-number nz-input [nzMin]="0" [nzStep]="100" [(ngModel)]="payment_amount" style="width: 100%;"></nz-input-number>
                            </nz-input-group>
                          </div>
                          
                        
                        </div>
            
                        <div style="width: 100%; margin-bottom: 15px;">
                          <div class="input-label">Remarks</div>
            
                          <textarea rows="4" nz-input style="width: 100%;" [(ngModel)]="payment_remarks"></textarea>
                        </div>
            
                      </div>
            
                      <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary" (click)="newPayment()" >SAVE</button>
                      
                    </nz-modal>


                </div>
            </div>

            <div class="main">
                <div class='stats__table'>
                    <nz-table #sortTable [nzData]="invoice_payments" nzTableLayout="fixed" [nzShowPagination]="false">
                      <thead>
                        <tr>
                          <th>Payment ID</th>
                          <th>Payment Date</th>
                          <th>Mode</th>
                          <th>Amt. Paid</th>
                          <th>Remarks</th>
                          <th>
                          
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let data of sortTable.data">
                          <td>{{ data.id }}</td>
                          <td>{{ data.payment_date | date: 'mediumDate'}}</td>
                          <td>{{ data.payment_method }}</td>
                          <td>{{ data.amount | number : '1.2-2'}}</td>
                          <td>{{ data.remarks}}</td>
                          <td>
                            <div style="display: flex; justify-content: center; align-items: center;">

                              <div style="margin-right: 15px;cursor: pointer;" (click)="editPayment(data)">
                                <svg width="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              </div>


                              <div
                                nz-popconfirm
                                nzPopconfirmTitle="Are you sure delete this payment?"
                                nzPopconfirmPlacement="bottomRight"
                                (nzOnConfirm)="deletePayment(data)"
                                  style="cursor: pointer; justify-content: space-around; align-items: center; margin-right: 25px;" 
                                >
                                  <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7 7L13 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13 7L7 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>                         
                                </div>

                                <div style="cursor: pointer;">
                                    <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.875 12.5005V10.938H17.1875V18.7505H18.75V15.6255H21.0938V14.063H18.75V12.5005H21.875Z" fill="#AE282E"/>
                                        <path d="M13.2812 18.7505H10.1562V10.938H13.2812C13.9027 10.9386 14.4984 11.1857 14.9378 11.6251C15.3773 12.0645 15.6244 12.6603 15.625 13.2817V16.4067C15.6244 17.0281 15.3773 17.6239 14.9378 18.0633C14.4984 18.5027 13.9027 18.7499 13.2812 18.7505ZM11.7188 17.188H13.2812C13.4884 17.1878 13.687 17.1054 13.8334 16.9589C13.9799 16.8125 14.0623 16.6139 14.0625 16.4067V13.2817C14.0623 13.0746 13.9799 12.876 13.8334 12.7295C13.687 12.5831 13.4884 12.5007 13.2812 12.5005H11.7188V17.188Z" fill="#AE282E"/>
                                        <path d="M7.03125 10.938H3.125V18.7505H4.6875V16.4067H7.03125C7.44546 16.4061 7.84253 16.2413 8.13542 15.9484C8.42831 15.6555 8.59313 15.2584 8.59375 14.8442V12.5005C8.59334 12.0862 8.42858 11.689 8.13565 11.3961C7.84271 11.1032 7.44552 10.9384 7.03125 10.938ZM4.6875 14.8442V12.5005H7.03125L7.03203 14.8442H4.6875Z" fill="#AE282E"/>
                                        <path d="M15.625 9.37527V6.25027C15.6278 6.14759 15.6082 6.04554 15.5678 5.95113C15.5273 5.85672 15.4669 5.7722 15.3906 5.70339L9.92187 0.234641C9.85309 0.158347 9.76858 0.0978832 9.67417 0.0574196C9.57975 0.0169561 9.47768 -0.00254362 9.375 0.000265657H1.5625C1.14848 0.00150216 0.75177 0.166519 0.459012 0.459277C0.166254 0.752035 0.00123651 1.14875 0 1.56277V20.3128C0 20.7272 0.16462 21.1246 0.457646 21.4176C0.750671 21.7106 1.1481 21.8753 1.5625 21.8753H14.0625V20.3128H1.5625V1.56277H7.8125V6.25027C7.81374 6.66429 7.97875 7.061 8.27151 7.35375C8.56427 7.64651 8.96098 7.81153 9.375 7.81277H14.0625V9.37527H15.625ZM9.375 6.25027V1.87527L13.75 6.25027H9.375Z" fill="#AE282E"/>
                                      </svg>
                                </div>
                              </div>

                          </td>
                          
                        </tr>
                      </tbody>
                    </nz-table>
                </div>
            </div>
          </nz-card>

        </div>
    </div>
  </div>
    



