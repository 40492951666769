<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">

          <div class="row mb-4" style="width: 100%">
              <div class="col-12 d-flex justify-content-end">
                  <nz-select nzShowSearch nzAllowClear [(ngModel)]="department_id" style="width: 300px;" nzPlaceHolder="Department" (ngModelChange)="departmentChange($event)">
                      <nz-option *ngFor="let department of departments" [nzValue]="department.id" [nzLabel]="department.name">
                      </nz-option>
                  </nz-select>
              </div> 
          </div>

          <div class="row mb-4" style="width: 100%">
            <div class="col-12">
              <div style="position: relative; width: 100%;">
                <canvas ngChartjs [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [legend]="lineChartLegend"
                 [chartType]="lineChartType" [inlinePlugins]="inlinePlugin"></canvas>
              </div>
            </div>
          </div>


        </div>
    </div>
</div>