<div class="common-layout is-default" [class.is-folded]="isFolded" [class.is-side-nav-dark]="isFolded"
    [class.is-expand]="isFolded">
    <app-primary-header></app-primary-header>
    <app-sidebar-dashboard></app-sidebar-dashboard>
    <div class="page-container">
        <div class="main-content">
            <nz-card>

                <div class="breadcrumbs-add-btn-bar" style="padding-left: 0px;padding-right: 0px;">

                    <div class="title-breadrumbs-container">

                        <div class="title">ACCIDENTAL CLAIMS</div>

                        <!-- <div class="breadcrumbs">
                        <div class="breadcrumbs-text" style="margin-right: 10px;">Orders</div>

                        <div>
                            <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 10px;">
                                <path d="M5.97304 4.14618L0.690235 0.0200133C0.676431 0.00914556 0.659841 0.00239175 0.642372 0.00052741C0.624902 -0.00133693 0.607261 0.00176383 0.591474 0.00947365C0.575687 0.0171835 0.562396 0.0291897 0.553125 0.0441133C0.543854 0.0590369 0.53898 0.0762728 0.539064 0.0938414V0.999701C0.539064 1.05712 0.566017 1.1122 0.610548 1.14736L4.82929 4.4415L0.610548 7.73564C0.564845 7.77079 0.539064 7.82587 0.539064 7.88329V8.78915C0.539064 8.86767 0.629298 8.91103 0.690235 8.86298L5.97304 4.73681C6.01794 4.70179 6.05427 4.65698 6.07925 4.60581C6.10423 4.55464 6.11722 4.49844 6.11722 4.4415C6.11722 4.38455 6.10423 4.32836 6.07925 4.27718C6.05427 4.22601 6.01794 4.18121 5.97304 4.14618Z" fill="#979797"/>
                                </svg>
                                
                        </div>

                        <div class="breadcrumbs-text" style="margin-right: 10px;">New Order</div>

                    </div>  -->

                    </div>

                    <div class="doc-search-btn">
                        <button *ngIf="!isEdit" nz-button nzType="primary" (click)="createNewOrder()">CREATE</button>
                        <button *ngIf="isEdit" nz-button nzType="primary" (click)="updateOrder()">SAVE</button>
                    </div>

                </div>

                <div class="customer-details-container"
                    style="padding-left: 0px;padding-right: 0px;padding-bottom: 20px;">

                    <div
                        style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;">
                        Customer Details</div>
                    <nz-divider></nz-divider>

                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer ID
                            </div>
                            <div>
                                <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="customer_id"
                                    (ngModelChange)="loadCustomerDetails($event)">
                                    <nz-option *ngFor="let customer of all_customers;" [nzValue]="customer.id"
                                        [nzLabel]="customer.customer_name+' #'+customer.id"></nz-option>
                                </nz-select>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer Name
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="customer_name" readonly>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Reg. No.
                            </div>
                            <div>
                                <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="vehicle_id"
                                    (ngModelChange)="loadVehicleDetails($event)">
                                    <nz-option *ngFor="let vehicle of customer_vehicles;" [nzValue]="vehicle.id"
                                        [nzLabel]="vehicle.vehicle_number"></nz-option>
                                </nz-select>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer Contact No.
                            </div>
                            <div>
                                <input nz-input type="text" [(ngModel)]="phone" readonly />
                            </div>
                        </div>

                    </div>

                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top: 15px;">

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Make
                            </div>
                            <div>
                                <input nz-input type="text" [(ngModel)]="make" readonly />
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Model
                            </div>
                            <div>
                                <input nz-input type="text" [(ngModel)]="model" readonly />
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Registration Year
                            </div>
                            <div>
                                <nz-input-group>
                                    <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;"
                                        [(ngModel)]="mfg_date" readonly></nz-date-picker>
                                </nz-input-group>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Check in Date
                            </div>
                            <div>
                                <nz-date-picker [nzFormat]="'dd-MM-yyyy'" [nzDisabledDate]="disabledDate"
                                    style="width: 100%;" [(ngModel)]="check_in_date"></nz-date-picker>
                            </div>
                        </div>

                    </div>

                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top: 15px;">


                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Settlement Type
                            </div>
                            <div>
                                <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="settlement_type">
                                    <nz-option [nzValue]="'Own Damage'" [nzLabel]="'Own Damage'"></nz-option>
                                    <nz-option [nzValue]="'Third Party'" [nzLabel]="'Third Party Claims'"></nz-option>
                                    <nz-option [nzValue]="'Reporting Only'" [nzLabel]="'Reporting Only'"></nz-option>
                                    <nz-option [nzValue]="'Private Settlement – At Fault'"
                                        [nzLabel]="'Private Settlement – At Fault'"></nz-option>
                                    <nz-option [nzValue]="'Private Settlement – Not At Fault'"
                                        [nzLabel]="'Private Settlement – Not At Fault'"></nz-option>
                                </nz-select>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">

                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Insurancing Company
                            </div>
                            <div>
                                <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="insurance_company_id"
                                    (ngModelChange)="insuranceCompanySelected($event)"
                                    [nzDropdownRender]="renderTemplateCompany">
                                    <nz-option *ngFor="let insurance_company of all_insurance_companies;"
                                        [nzValue]="insurance_company.id" [nzLabel]="insurance_company.company_name">
                                    </nz-option>
                                </nz-select>
                                <ng-template #renderTemplateCompany>
                                    <nz-divider style="margin-top: 0px;margin-bottom: 4px;"></nz-divider>
                                    <a style="text-transform: uppercase;font-size: 12px;letter-spacing: 0.6px;margin: 10px;font-weight: 600;"
                                        href="#" routerLink="/all-surveyors">
                                        Add New
                                    </a>
                                </ng-template>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                SIR Date
                            </div>
                            <div>
                                <nz-date-picker class="w-100" [nzFormat]="'dd-MM-yyyy'" [(ngModel)]="str_date">
                                </nz-date-picker>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                SIR Number
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="sir_status" />
                            </div>
                        </div>

                    </div>

                </div>


                <div class="customer-details-container"
                    style="padding-left: 0px;padding-right: 0px;padding-bottom: 20px;">

                    <div
                        style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;">
                        Accident Details</div>
                    <nz-divider></nz-divider>

                    <div
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">


                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Accident Date
                            </div>
                            <div>
                                <nz-date-picker class="w-100" [nzFormat]="'dd-MM-yyyy'" [(ngModel)]="accident_date">
                                </nz-date-picker>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Accident Time
                            </div>
                            <div>
                                <nz-time-picker [(ngModel)]="accident_time" class="w-100" [nzFormat]="'HH:mm:ss'">
                                </nz-time-picker>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Driver
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="driver" />
                            </div>
                        </div>


                    </div>

                    <div class="mt-3"
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Type of Accident
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="type_of_accident" />
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Road Condition
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="area_of_focus" />
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Location
                            </div>
                            <div>
                                <input nz-input [(ngModel)]="location" />
                            </div>
                        </div>

                        <!-- <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Claim Type
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="claim_type" />
                        </div>
                    </div> -->


                    </div>

                    <!-- <div class="mt-3" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Cost of Repair
                        </div>
                        <div>
                            <nz-input-number [(ngModel)]="cor" class="w-100" [nzMin]="0" [nzStep]="100"></nz-input-number>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Deduction
                        </div>
                        <div>
                            <nz-input-number [(ngModel)]="deduction" class="w-100" [nzMin]="0" [nzStep]="100"></nz-input-number>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Vehicle Repaired Status
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="vehicle_repaired_status" />
                        </div>
                    </div>


                </div> -->

                    <!-- <div class="mt-3" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">


                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Accident Remarks
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="accident_remarks" />
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                    </div>

                </div> -->

                    <div class="mt-3"
                        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            <div
                                style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Details of Accident
                            </div>
                            <div>
                                <textarea nz-input [(ngModel)]="details_of_accident"></textarea>
                            </div>
                        </div>
                    </div>

                </div>


                <div
                    style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px; justify-content: flex-start; align-items: center; margin-left: 0px;">
                    Customer Remarks</div>
                <nz-divider></nz-divider>

                <div class='stats__table' style="margin-left: 0px;margin-right: 0px;">
                    <nz-table #productTable [nzData]="listOfRemarks" [nzNoResult]="null" [nzShowPagination]="false">
                        <thead>
                            <tr>
                                <th style="max-width:50px">ID</th>
                                <th style="width:100%">Remarks</th>
                                <th style="max-width:50px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of productTable.data;let i = index;">
                                <td>{{ i+1 }}</td>
                                <td>
                                    <input placeholder="Customer Remark.." nz-input [(ngModel)]="data.text" />
                                </td>
                                <td>
                                    <div
                                        style="display: flex; flex-direction: row; justify-content: center; align-items: center;cursor: pointer;">
                                        <div (click)="removeRemarks(i)">
                                            <svg width="16" height="16" viewBox="0 0 21 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17.5286 1H3.06607C1.92501 1 1 1.89543 1 3V17C1 18.1046 1.92501 19 3.06607 19H17.5286C18.6696 19 19.5947 18.1046 19.5947 17V3C19.5947 1.89543 18.6696 1 17.5286 1Z"
                                                    stroke="#FF3A29" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path d="M7.19824 7L13.3965 13" stroke="#FF3A29" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.3965 7L7.19824 13" stroke="#FF3A29" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </nz-table>
                </div>

                <button nz-button style="margin-left: 0px; margin-bottom: 25px;" (click)="addRemarks()">Add
                    Remark</button>


                <div
                    style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px; justify-content: flex-start; align-items: center; margin-left: 0px;">
                    Third Party Details</div>
                <nz-divider></nz-divider>

                <div class='stats__table' style="margin-left: 0px;margin-right: 0px;">
                    <nz-table #listOfThirdPartyTable [nzData]="listOfThirdParty" [nzShowPagination]="false">
                        <thead>
                            <tr>
                                <th style="max-width:50px">ID</th>
                                <th >Name</th>
                                <th >NRIC</th>
                                <th >Contact</th>
                                <th >Vehicle No.</th>
                                <th >Company</th>
                                <th >PIC Name</th>
                                <th >PIC Contact</th>
                                <th style="max-width:50px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of listOfThirdPartyTable.data;let i = index;">
                                <td>{{ i+1 }}</td>
                                <td>
                                    {{ data.name }}
                                </td>
                                <td>
                                    {{ data.nric }}
                                </td>
                                <td>
                                    {{ data.contact }}
                                </td>
                                <td>
                                    {{ data.vehicle_no }}
                                </td>
                                <td>
                                    {{ data.company }}
                                </td>
                                <td>
                                    {{ data.pic_name }}
                                </td>
                                <td>
                                    {{ data.pic_contact }}
                                </td>
                                <td>
                                    <div
                                        style="display: flex; flex-direction: row; justify-content: center; align-items: center;cursor: pointer;">
                                        <div (click)="removeThirdParty(i)">
                                            <svg width="16" height="16" viewBox="0 0 21 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M17.5286 1H3.06607C1.92501 1 1 1.89543 1 3V17C1 18.1046 1.92501 19 3.06607 19H17.5286C18.6696 19 19.5947 18.1046 19.5947 17V3C19.5947 1.89543 18.6696 1 17.5286 1Z"
                                                    stroke="#FF3A29" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path d="M7.19824 7L13.3965 13" stroke="#FF3A29" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M13.3965 7L7.19824 13" stroke="#FF3A29" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>

                <button nz-button style="margin-left: 0px; margin-bottom: 25px;" (click)="third_party_modal = true">Add
                    Third Party</button>

                <nz-modal [(nzVisible)]="third_party_modal" nzTitle="Add Third Party" (nzOnCancel)="clearThirdParty()"
                    [nzWidth]="960">
                    <div nz-row [nzGutter]="[16,16]">
                        <div nz-col nzSpan="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="8" [nzXs]="24"> Name </nz-form-label>
                                <nz-form-control [nzSm]="16" [nzXs]="24">
                                    <input nz-input [(ngModel)]="third_party.name" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="8" [nzXs]="24"> NRIC </nz-form-label>
                                <nz-form-control [nzSm]="16" [nzXs]="24">
                                    <input nz-input [(ngModel)]="third_party.nric" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="8" [nzXs]="24"> Contact </nz-form-label>
                                <nz-form-control [nzSm]="16" [nzXs]="24">
                                    <input nz-input [(ngModel)]="third_party.contact" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="8" [nzXs]="24"> Vehicle No. </nz-form-label>
                                <nz-form-control [nzSm]="16" [nzXs]="24">
                                    <input nz-input [(ngModel)]="third_party.vehicle_no" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="8" [nzXs]="24"> Company </nz-form-label>
                                <nz-form-control [nzSm]="16" [nzXs]="24">
                                    <input nz-input [(ngModel)]="third_party.company" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="8" [nzXs]="24"> PIC Name </nz-form-label>
                                <nz-form-control [nzSm]="16" [nzXs]="24">
                                    <input nz-input [(ngModel)]="third_party.pic_name" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col nzSpan="12">
                            <nz-form-item>
                                <nz-form-label [nzSm]="8" [nzXs]="24"> PIC Contact </nz-form-label>
                                <nz-form-control [nzSm]="16" [nzXs]="24">
                                    <input nz-input [(ngModel)]="third_party.pic_contact" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col nzSpan="24">
                            <button nz-button nzType="primary" (click)="addThirdParty()" class="mr-2">Save</button>
                            <button nz-button nzType="default" (click)="clearThirdParty()">Cancel</button>
                        </div>
                    </div>
                </nz-modal>

                <div
                    style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px; justify-content: flex-start; align-items: center; margin-left: 0px;">
                    Gears Report</div>
                <nz-divider></nz-divider>

                <div class="mt-3"
                    style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div
                            style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Gears Report Date
                        </div>
                        <div>
                            <nz-date-picker [(ngModel)]="gears_report_date" nzFormat="dd/MM/yyyy"
                                nzPlaceHolder="Select date"></nz-date-picker>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div
                            style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Gears Report ID
                        </div>
                        <div>
                            <input nz-input [(ngModel)]="gears_report_id" />
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                        <div
                            style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Attach Document
                        </div>
                        <div class="d-flex flex-column " [class.reporting-only-uploads]="gears_report__document != ''">
                            <a *ngIf="gears_report__document!=''" class="mb-2 uppercase" [href]="gears_report__document"
                                target="_blank">
                                Download
                            </a>
                            <nz-upload [nzAccept]="'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                            text/plain, application/pdf, image/*, video/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload_file"
                                (nzChange)="handleChange($event, 'gears_report__document')">
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896"
                                        focusable="false" fill="currentColor" width="1em" height="1em"
                                        data-icon="upload" aria-hidden="true">
                                        <path
                                            d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z">
                                        </path>
                                    </svg>
                                    {{gears_report__document=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div>

                </div>

                <div class="mt-4"
                    style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px; justify-content: flex-start; align-items: center; margin-left: 0px;">
                    Private Settlement Details</div>

                <nz-divider></nz-divider>

                <div class="mt-3"
                    style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div
                            style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Pre Payment Required
                        </div>
                        <div>
                            <nz-select [(ngModel)]="pre_payment_required" nzPlaceHolder="Select" class="w-100">
                                <nz-option nzLabel="Yes" nzValue="Yes"></nz-option>
                                <nz-option nzLabel="No" nzValue="No"></nz-option>
                            </nz-select>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                        <div
                            style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Private Settlement Form
                        </div>
                        <div class="d-flex flex-column " [class.reporting-only-uploads]="private_settlement_form != ''">
                            <a *ngIf="private_settlement_form!=''" class="mb-2 uppercase" [href]="private_settlement_form"
                                target="_blank">
                                Download
                            </a>
                            <nz-upload [nzAccept]="'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                text/plain, application/pdf, image/*, video/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload_file"
                                (nzChange)="handleChange($event, 'private_settlement_form')">
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896"
                                        focusable="false" fill="currentColor" width="1em" height="1em"
                                        data-icon="upload" aria-hidden="true">
                                        <path
                                            d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z">
                                        </path>
                                    </svg>
                                    {{private_settlement_form=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                        <div
                            style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            Private Settlement Invoice 
                        </div>
                        <div class="d-flex flex-column " [class.reporting-only-uploads]="private_settlement_invoice != ''">
                            <a *ngIf="private_settlement_invoice!=''" class="mb-2 uppercase" [href]="private_settlement_invoice"
                                target="_blank">
                                Download
                            </a>
                            <nz-upload [nzAccept]="'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                text/plain, application/pdf, image/*, video/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload_file"
                                (nzChange)="handleChange($event, 'private_settlement_invoice')">
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896"
                                        focusable="false" fill="currentColor" width="1em" height="1em"
                                        data-icon="upload" aria-hidden="true">
                                        <path
                                            d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z">
                                        </path>
                                    </svg>
                                    {{private_settlement_invoice=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                        <div
                            style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                            HR Deduction Form 
                        </div>
                        <div class="d-flex flex-column " [class.reporting-only-uploads]="hr_deduction_form != ''">
                            <a *ngIf="hr_deduction_form!=''" class="mb-2 uppercase" [href]="hr_deduction_form"
                                target="_blank">
                                Download
                            </a>
                            <nz-upload [nzAccept]="'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                text/plain, application/pdf, image/*, video/*'"
                                nzAction="https://bigfoot.reddotapps.com.sg/api/upload_file"
                                (nzChange)="handleChange($event, 'hr_deduction_form')">
                                <button nz-button>
                                    <svg style="position: relative;top:2px" class="mr-2" viewBox="64 64 896 896"
                                        focusable="false" fill="currentColor" width="1em" height="1em"
                                        data-icon="upload" aria-hidden="true">
                                        <path
                                            d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z">
                                        </path>
                                    </svg>
                                    {{hr_deduction_form=='' ? 'Upload' : 'Replace'}}
                                </button>
                            </nz-upload>
                        </div>
                    </div>

                </div>

            </nz-card>
        </div>
    </div>
</div>