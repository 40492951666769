import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-all-upcoming-bookings',
  templateUrl: './all-upcoming-bookings.component.html',
  styleUrls: ['./all-upcoming-bookings.component.css']
})
export class AllUpcomingBookingsComponent implements OnInit {
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  search = '';
  isLoadingUpcomingBookings = true;

  listOfUpcomingBookings = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };

  page_index = 1;
  page_size = 10;
  sort_by_field = 'created_at';
  sort_by_type = 'desc';

  filterDrawerVisible = false;

  product_filters = [{
    label: 'Customer Name',
    key: 'customer_name',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Vehicle Number',
    key: 'vehicle_number',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Vehicles Type',
    key: 'vehicles.category',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Due Date',
    key: 'due_date',
    type: "date",
    value: null,
    condition: "Equals",
  }]

  constructor(private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);  
    this.filterMethodUpcomingBookings()
  }

  clearFilters() {
    this.product_filters.forEach(element => {
      element.value = null;
    });
    this.filterMethodUpcomingBookings();
  }

  filterMethodUpcomingBookings() {
    this.isLoadingUpcomingBookings = true;

    var url = '/api/all-upcoming-service-bookings';

    var data = {
      search: this.search,
      page_index: this.page_index,
      page_size: this.page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      this.listOfUpcomingBookings = response.data.service_bookings;
      this.isLoadingUpcomingBookings = false;
      this.filterDrawerVisible = false;
    })
  }

  onParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(item => item.value !== null);
    this.sort_by_field = (currentSort && currentSort.key) || 'created_at';
    this.sort_by_type = (currentSort && currentSort.value) || 'desc';
    this.page_index = pageIndex;
    this.page_size = pageSize;
    this.filterMethodUpcomingBookings();
  }

  downloadCsvUpcomingBookings(): void {

    const { parse } = require('json2csv');
    // const UpcomingBookings = this.listOfUpcomingBookings.data;
    const fields = [{
      label: 'Booking ID',
      value: (row:any) => row.id,
    }, {
      label: 'Vehicle Number',
      value: (row:any) => row.vehicle_number,
    }, {
      label: 'Due Date',
      value: (row:any) => row.due_date ? moment(row.due_date).format('DD/MM/YYYY') : '',
    }, {
      label: 'Due Mileage',
      value: (row:any) => row.due_mileage,
    }];

    var url = '/api/all-upcoming-service-bookings';

    var data = {
      search: this.search,
      page_index: this.page_index,
      page_size: this.page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
    };

    axios.post(url, data, {}).then(response => {
      // this.listOfUpcomingBookings = response.data.service_bookings;
      let listOfUpcomingBookings = response.data.service_bookings;
      const UpcomingBookings = listOfUpcomingBookings.data;
      const csv = parse(UpcomingBookings, { fields });
      require("downloadjs")(csv, 'UpcomingBookings.csv');
    })

    // const csv = parse(UpcomingBookings, { fields });
    // require("downloadjs")(csv, 'UpcomingBookings.csv');
  }

}
