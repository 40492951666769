import { Component, OnInit } from '@angular/core';
import axios from 'axios';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  constructor() { }

  listOfTransactions = [];
  isLoadingTransactions = false;
  transactions_sort_by_field = 'created_at';
  transactions_sort_by_type = 'desc';
  transaction_search = '';


  ngOnInit(): void {
  }

  filterMethodTransactions() {
    this.isLoadingTransactions = true;

    var url = '/api/all_transactions';

    var data = {
      search: this.transaction_search,
      sort_by_field: this.transactions_sort_by_field,
      sort_by_type: this.transactions_sort_by_type,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfTransactions = response.data.all_transactions;
    })
  }

  downloadCsvTransactions() {
    const { Parser } = require('json2csv');
    const myCars = this.listOfTransactions;
    const json2csvParser = new Parser();
    const csv = json2csvParser.parse(myCars);
    require("downloadjs")(csv, 'transactions.csv');
  }


}
