<div
  class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
  <app-primary-header></app-primary-header>
  <app-sidebar-dashboard></app-sidebar-dashboard>
  <div class="page-container">
    <div class="main-content">
      <nz-card>
        <div class="breadcrumbs-add-btn-bar">
          <div class="title-breadrumbs-container d-flex flex-row">
            <div class="title">All Vehicles</div>
            <div class="d-flex ml-2">
              <nz-switch class="mr-2" nzSize="small" 
              [(ngModel)]="show_scrapped" 
              (ngModelChange)="filterMethodVehicles()"
              ></nz-switch>
              SHOW SCRAPPED
            </div>
          </div>

          <div *ngIf="setOfCheckedId.length==0" class="doc-search-btn">



            <div class="icon-download">
              <div style="margin-right: 10px">
                <svg
                  height="16"
                  style="margin-right: 10px"
                  viewBox="0 0 24 24"
                  fill="#1e5799"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z"
                  />
                  <path
                    d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z"
                  />
                  <path
                    d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z"
                  />
                  <path
                    d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z"
                  />
                </svg>
              </div>
              <div class="download-text" (click)="downloadCsvVehicles()">
                Download CSV
              </div>
            </div>

            <div class="search-box">
              <nz-input-group [nzPrefix]="prefixIconSearch">
                <input
                  type="text"
                  nz-input
                  placeholder="Search..."
                  [(ngModel)]="vehicle_search"
                  (ngModelChange)="filterMethodVehicles()"
                />
              </nz-input-group>

              <ng-template #prefixIconSearch>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 5px"
                >
                  <path
                    d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z"
                    stroke="#979797"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.0001 17L13.0977 13.0975"
                    stroke="#979797"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-template>
            </div>
            <button
              nz-button
              class="filter-btn"
              (click)="filterDrawerVisible = true"
            >
              <svg
                class="icon"
                style="
                  width: 1em;
                  height: 1em;
                  vertical-align: middle;
                  fill: currentColor;
                  overflow: hidden;
                "
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="841"
              >
                <path
                  d="M912.83888 168.576q9.728 23.424-8 40l-281.728 281.728 0 424q0 24-22.272 33.728-7.424 2.848-14.272 2.848-15.424 0-25.728-10.848l-146.272-146.272q-10.848-10.848-10.848-25.728l0-277.728-281.728-281.728q-17.728-16.576-8-40 9.728-22.272 33.728-22.272l731.424 0q24 0 33.728 22.272z"
                  p-id="842"
                ></path>
              </svg>
            </button>

            <div class="add-product-btn">
              <nz-upload
                class="vehicle-refill"
                [nzAccept]="'.csv'"
                [nzAction]="base_url+'/api/upload-topup-csv'"
                (nzChange)="handleChange($event)"
                [nzFileListRender]="null"
              >
                <button
                  
                  nz-button
                  nzType="primary"
                  [nzLoading]="uploadingFile"
                >
                  Upload Refill CSV
                </button>
              </nz-upload>
            </div>
          </div>

          <div *ngIf="setOfCheckedId.length>0" class="doc-search-btn">
            <div class="add-product-btn">
                <button
                  nz-button
                  nzType="primary"
                  (click)="printSelected()"
                >
                  Download Details
                </button>
            </div>
          </div>

        </div>

        <nz-modal [nzWidth]="1024" [(nzVisible)]="showPrintModal" nzTitle="Vehicle Details" 
        (nzOnCancel)="showPrintModal=false;"
        >

          <div class="d-flex justify-content-end mb-3">
            <button nz-button nzType="primary" (click)="finalPrint()">Download</button>
          </div>

          <div id="print-section">
            <nz-descriptions 
            *ngFor="let vehicle of printVehiclesData"
            style="margin-bottom: 150px;"
            [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }"
            nzTitle="" nzBordered>

              <nz-descriptions-item nzTitle="Vehicle Number">{{vehicle.vehicle_number}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Category">{{vehicle.category}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Vehicle Department">{{vehicle.selected_vehicle_department}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Make">{{vehicle.make}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Model">{{vehicle.model}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Effective Date Of Ownership">{{vehicle.effectiveDateOfOwnership | date}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Road Tax Expiry Date">{{vehicle.road_tax_expiry_date | date}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="VPC Start Date">{{vehicle.vpc_start_date | date}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="VPC Expiry Date">{{vehicle.vpc_expiry_date | date}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Inspection Date">{{vehicle.inspection_date | date}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Mfg Date">{{vehicle.mfg_date | date}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Vehicle Type">{{vehicle.vehicle_type}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Propellant">{{vehicle.propellant}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="Engine Capacity/Power Rating">{{vehicle.engineCapacityOrPowerRating}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Identification No">{{vehicle.identificationNo}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Engine No">{{vehicle.engineNo}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Chassis No">{{vehicle.chassisNo}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="Max Laden Weight">{{vehicle.maxLadenWeight}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Unladen Weight">{{vehicle.unLadenWeight}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="COE No">{{vehicle.COENo}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="COE Price">{{vehicle.COEPrice}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="COE Category">{{vehicle.COECategory}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="COE Expiry Date">{{vehicle.COEExpiryDate | date}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="Vehicle Life Span Exp Date">{{vehicle.vehicleLifeSpanExpDate | date}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="Tonnage Capacity">{{vehicle.tonnageCapacity}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Is GPS Enabled">{{vehicle.isGPSEnabled ? 'Yes' : 'No'}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Is Season Parking">{{vehicle.isSeasonParking ? 'Yes' : 'No'}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Max Speed">{{vehicle.maxSpeed}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Status">{{vehicle.status}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="Parking Places">{{vehicle.parkingPlaces}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Reefer Measurement">{{vehicle.reeferMeasurement}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="Reefer Model">{{vehicle.reeferModel}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="Is Vehicle On Lease">{{vehicle.is_vehicle_on_lease ? 'Yes' : 'No'}}</nz-descriptions-item>

              <nz-descriptions-item *ngIf="is_vehicle_on_lease" nzTitle="Lease Start Date">{{vehicle.lease_start_date | date}}</nz-descriptions-item>
              <nz-descriptions-item *ngIf="is_vehicle_on_lease" nzTitle="Lease End Date">{{vehicle.lease_end_date | date}}</nz-descriptions-item>

              <nz-descriptions-item nzTitle="Insurance Company">{{vehicle.insurance_company}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Insurance Start Date">{{vehicle.insurance_start_date | date}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Insurance End Date">{{vehicle.insurance_expiry_date | date}}</nz-descriptions-item>
              <nz-descriptions-item nzTitle="Logcard">
                <a href="{{vehicle.logcard}}" target="_blank">
                  {{vehicle.logcard}}
                </a>
              </nz-descriptions-item>

            </nz-descriptions>
          </div>
        </nz-modal>

        <div class="stats__table">
          <nz-table
            #vehiclesTable
            [nzData]="listOfVehicles.data"
            nzTableLayout="fixed"
            nzShowSizeChanger
            [nzPageSizeOptions]="[10, 50, 100, 1000]"
            [nzLoading]="isLoadingVehicles"
            [nzFrontPagination]="false"
            [nzTotal]="listOfVehicles.meta.total"
            [nzPageSize]="listOfVehicles.meta.per_page"
            [nzPageIndex]="listOfVehicles.meta.current_page"
            (nzQueryParams)="onParamsChange($event)"
          >
            <thead>
              <tr>
                <th ></th>
                <th>Customer</th>
                <th>Vehicle Number</th>
                <th>Category</th>
                <th>Make</th>
                <th>Model</th>
                <th>Mfg Date</th>
                <th>Last Serviced On</th>
                <th>Last Service ID</th>
                <th>Mileage</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of vehiclesTable.data">
                <td
                  [nzChecked]="setOfCheckedId.includes(data.id)"
                  (nzCheckedChange)="onItemChecked(data.id, $event)"
                ></td>
                <td>
                    <a href="#" class="font-weight-semibold" routerLink="/customer-single" [queryParams]="{ customer_id: data.customer_id}">
                        {{ data.customer_name }}
                    </a>
                </td>
                <td>
                  <a href="#" class="font-weight-semibold" routerLink="/customer-single" [queryParams]="{ 
                    customer_id: data.customer_id,
                    vehicle_number: data.vehicle_number
                  }">
                    {{ data.vehicle_number }}
                  </a>
                  
                </td>
                <td>{{ data.category }}</td>
                <td>{{ data.make }}</td>
                <td>{{ data.model }}</td>
                <td>{{ data.mfg_date | date: "mediumDate" }}</td>
                <td>{{ data.last_service_date | date: "mediumDate" }}</td>
                <td>
                    <a class="font-weight-semibold" *ngIf="data.order_type==0" href="#" routerLink="/general-servicing-single" [queryParams]="{ order_id: data.last_service_id}">#{{ data.last_service_id }}</a>
                    <a class="font-weight-semibold" *ngIf="data.order_type==1" href="#" routerLink="/accidental-claim-single" [queryParams]="{ order_id: data.last_service_id}">#{{ data.last_service_id }}</a>
                    <a class="font-weight-semibold" *ngIf="data.order_type==2" href="#" routerLink="/reporting-only-new" [queryParams]="{ order_id: data.last_service_id}">#{{ data.last_service_id }}</a>
                </td>
                <td>{{ data.calculated_mileage | number : '1.2-2' }}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
    </div>
  </div>
</div>

<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzWidth]="550"
  [nzVisible]="filterDrawerVisible"
  nzTitle="Apply Filters"
  [nzFooter]="footerTpl"
  (nzOnClose)="filterDrawerVisible = false"
>
  <div *nzDrawerContent class="pb-5">
    <div class="input-group" *ngFor="let product_filter of product_filters">
      <div class="input-label">{{ product_filter.label }}</div>

      <div class="d-flex">
        <nz-select
          *ngIf="product_filter.type == 'string'"
          class="mr-3 w-50"
          nzShowSearch
          nzPlaceHolder="Operators"
          [(ngModel)]="product_filter.condition"
        >
          <nz-option nzLabel="Contains" nzValue="Contains"></nz-option>
          <nz-option nzLabel="Equals" nzValue="Equals"></nz-option>
          <nz-option nzLabel="Starts With" nzValue="Starts With"></nz-option>
          <nz-option nzLabel="End With" nzValue="End With"></nz-option>
        </nz-select>

        <nz-select
          *ngIf="product_filter.type == 'number'"
          class="mr-3 w-50"
          nzShowSearch
          nzPlaceHolder="Operators"
          [(ngModel)]="product_filter.condition"
        >
          <nz-option nzLabel="Equals" nzValue="Equals"></nz-option>
          <nz-option nzLabel="More Than" nzValue="More Than"></nz-option>
          <nz-option nzLabel="Less Than" nzValue="Less Than"></nz-option>
        </nz-select>

        <nz-select
          *ngIf="product_filter.type == 'date'"
          class="mr-3 w-50"
          nzShowSearch
          nzPlaceHolder="Operators"
          [(ngModel)]="product_filter.condition"
        >
          <nz-option nzLabel="Equals" nzValue="Equals"></nz-option>
          <nz-option nzLabel="More Than" nzValue="More Than"></nz-option>
          <nz-option nzLabel="Less Than" nzValue="Less Than"></nz-option>
          <nz-option nzLabel="Range" nzValue="Range"></nz-option>
        </nz-select>

        <nz-select
          nzAllowClear
          *ngIf="product_filter.type == 'options'"
          class="w-100"
          nzShowSearch
          nzPlaceHolder="Options"
          [(ngModel)]="product_filter.value"
        >
          <nz-option
            *ngFor="let option of product_filter.options"
            [nzLabel]="option.label"
            [nzValue]="option.value"
          ></nz-option>
        </nz-select>

        <nz-date-picker
          [nzFormat]="'dd-MM-yyyy'"
          class="w-100"
          *ngIf="product_filter.type == 'date' && product_filter.condition != 'Range'"
          [(ngModel)]="product_filter.value"
        ></nz-date-picker>

        <nz-range-picker
          [nzFormat]="'dd-MM-yyyy'"
          class="w-100"
          *ngIf="product_filter.type == 'date' && product_filter.condition == 'Range'"
          [(ngModel)]="product_filter.value"
        ></nz-range-picker>

        <input
          nz-input
          *ngIf="product_filter.type == 'string'"
          [placeholder]="product_filter.label"
          [(ngModel)]="product_filter.value"
          (ngModelChange)="
            product_filter.value == '' ? (product_filter.value = null) : null
          "
        />

        <nz-input-number
          class="w-100"
          *ngIf="product_filter.type == 'number'"
          [nzPlaceHolder]="product_filter.label"
          [(ngModel)]="product_filter.value"
          (ngModelChange)="
            product_filter.value === '' ? (product_filter.value = null) : null
          "
          [nzStep]="100"
        ></nz-input-number>
      </div>
    </div>
  </div>

  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="clearFilters()">
        Clear
      </button>
      <button nz-button nzType="primary" (click)="filterMethodVehicles()">
        Apply
      </button>
    </div>
  </ng-template>
</nz-drawer>

<nz-modal 
[nzWidth]="800" 
[(nzVisible)]="displayRefillPreview" 
[nzTitle]="'Review Data'" 
(nzOnCancel)="displayRefillPreview=false;" style="justify-content: center; align-items: center;">

    <nz-table
      #topUpTable
      [nzData]="vehicle_refills"
      nzTableLayout="fixed"
      nzShowSizeChanger
      [nzFrontPagination]="false"
    >
      <thead>
        <tr>
          <th>Vehicle</th>
          <th>Date</th>
          <th>Topup Qty</th>
          <th>Mileage Run</th>
          <th>Driver Name</th>
          <th>Amount Paid</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of topUpTable.data">
          <td>{{ data.vehicle_number }}</td>
          <td>{{ data.date }}</td>
          <td>{{ data.topup_qty }}</td>
          <td>{{ data.mileage_run }}</td>
          <td>{{ data.driver_name }}</td>
          <td>{{ data.amount_paid }}</td>
        </tr>
      </tbody>
    </nz-table>

    <button nz-button (click)="uploadVehicleRefills()" [nzLoading]="uploadingRefillData" nzType="primary">Upload</button>
    
</nz-modal>
