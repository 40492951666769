<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
          <nz-card>
            <nz-tabset (nzSelectChange)="clearSearches()">

              <nz-tab nzTitle="VENDORS">

                <div class="breadcrumbs-add-btn-bar">
                  <div class="title-breadrumbs-container">
                    <div class="title">VENDORS</div>
                  </div>
                  <div class="doc-search-btn">
                    <div class="icon-download">
                        <div style="margin-right: 10px;">
                          <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                            <path d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                            <path d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                            <path d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                            </svg>
                                
                        </div>
                        <div  class="download-text" (click)="downloadCsvVendor()">
                            Download CSV
                        </div>
                    </div>
        
                    <div class="search-box" *ngIf="activeTab==0">
                        <nz-input-group [nzPrefix]="prefixIconSearch" style="min-height: 42px;">
                            <input type="text" nz-input (ngModelChange)="changeVendorsFn()" [(ngModel)]="vendor_search" placeholder="Search..." />
                          </nz-input-group>
        
                          <ng-template #prefixIconSearch>
                            <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right:5px;">
                                <path d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                
                          </ng-template>
                    </div>
        
                    <button (click)="openAddVendorModal=true" nz-button nzType="primary" *ngIf="activeTab==0">Add Vendor</button>
        
                    <nz-modal [nzWidth]="720" [(nzVisible)]="openAddVendorModal" nzTitle="Add New Vendor" 
                    (nzOnCancel)="openAddVendorModal=false;clearPreviousData()" style="justify-content: center; align-items: center;">
                      
                    <div nz-row [nzGutter]="[12,12]">

                      <div nz-col nzSpan="12">
                        <div class="input-group">
                            <div class="input-label">Company Name</div>
                            <input nz-input placeholder="Company Name" [(ngModel)]="vendor_name" />
                        </div>
                      </div>

                      <div nz-col nzSpan="12">
                          <div class="input-group">
                            <div class="input-label">Contact Person</div>
                            <input nz-input placeholder="Contact Person" [(ngModel)]="contact_person" />
                        </div>
                      </div>

                      <div nz-col nzSpan="12">
                        <div class="input-group">
                            <div class="input-label">Contact Number</div>
                            <input nz-input placeholder="Contact Number" [(ngModel)]="contact_number" />
                        </div>
                      </div>

                      <div nz-col nzSpan="12">
                        <div class="input-group">
                            <div class="input-label">Whatsapp Number</div>
                            <input nz-input placeholder="Whatsapp Number" [(ngModel)]="whatsapp_number" />
                        </div>
                      </div>

                      <div nz-col nzSpan="12">
                        <div class="input-group">
                            <div class="input-label">UEN Number</div>
                            <input nz-input placeholder="UEN Number" [(ngModel)]="uen_number" />
                        </div>
                      </div>

                      <div nz-col nzSpan="12">
                          <div class="input-group">
                            <div class="input-label">Email</div>
                            <input nz-input placeholder="Email" [(ngModel)]="email" />
                        </div>
                      </div>
                      
                      <div nz-col nzSpan="12">
                        <div class="input-group">
                          <div class="input-label">Address</div>
                          <textarea nz-input placeholder="Address" [(ngModel)]="address"></textarea>
                        </div>
                      </div>

                    </div>
        
                    <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary" (click)="newVendor()" >SAVE</button>
                    
                    </nz-modal>
        
                </div>
                </div>

                <div class='stats__table'>
                  <nz-table  #vendorsTable 
                  [nzData]="listOfVendors.data" 
                  nzTableLayout="fixed"
                  nzShowSizeChanger
                  [nzLoading]="isLoadingVendors"
                  [nzFrontPagination]="false"
                  [nzTotal]="listOfVendors.meta.total"
                  [nzPageSize]="listOfVendors.meta.per_page"
                  [nzPageIndex]="listOfVendors.meta.current_page"
                  (nzQueryParams)="onVendorsChange($event)"
                  >
                    <thead>
                      <tr>
                        <th nzLeft>Id</th>
                        <th nzLeft>Company Name</th>
                        <th>Contact person</th>
                        <th>Contact</th>
                        <th>Address</th>
                        <th>Total Business</th>
                        <th>Created On</th>
                        <th>Created By</th>
                        <th nzRight></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of vendorsTable.data">
                        <td nzLeft>{{ data.id }}</td>
                        <td nzLeft>{{ data.vendor_name }}</td>
                        <td>{{ data.contact_person }}</td>
                        <td>{{ data.contact_number }}</td>
                        <td>{{ data.address }}</td>
                        <td>${{ data.total_purchasses | number : '1.2-2' }}</td>
                        <td>{{ data.created_at | date: 'mediumDate'}}</td>
                        <td>{{ data.created_by_name }}</td>
                        <td nzRight>
                          <div style="display: flex;">
                            <div style="margin-right: 15px;cursor: pointer;" (click)="editVendor(data)">
                              <svg width="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
              
                            <div style="cursor: pointer;" 
                            nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this vendor?"
                            (nzOnConfirm)="deleteVendor(data)">
                              <svg style="position: relative;top: 2px;" width="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7 7L13 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13 7L7 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </div>

              </nz-tab>

              <nz-tab nzTitle="CATEGORIES">

                <div class="breadcrumbs-add-btn-bar">
                  <div class="title-breadrumbs-container">
                      <div class="title">CATEGORIES</div>
                  </div>
                  <div class="doc-search-btn">

                    <div class="icon-download">
                        <div style="margin-right: 10px;">
                          <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                            <path d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                            <path d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                            <path d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                            </svg>
                                
                        </div>
                        <div class="download-text" (click)="downloadCsvCategories()">
                          Download CSV
                      </div>
                    </div>
        
                    <div class="search-box">
                      <nz-input-group [nzPrefix]="prefixIconSearch" style="min-height: 42px;">
                          <input type="text" nz-input (ngModelChange)="changeCategoriesFn()" [(ngModel)]="category_search" placeholder="Search..." />
                        </nz-input-group>
        
                        <ng-template #prefixIconSearch>
                          <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right:5px;">
                              <path d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              
                        </ng-template>
                    </div>
      
                    <button (click)="openAddCategoryModal=true" nz-button nzType="primary">Add category</button>
        
                    <nz-modal [(nzVisible)]="openAddCategoryModal" nzTitle="Add New Category" 
                    (nzOnCancel)="openAddCategoryModal=false;clearPreviousData()" style="justify-content: center; align-items: center;">
        
                      <div class="input-group">
                          <div class="input-label">Category Name</div>
                          <input nz-input placeholder="Category Name" [(ngModel)]="category_name" />
                      </div>
        
                      <div class="input-group">
                          <div class="input-label">Category Description</div>
                          <input nz-input placeholder="Description" [(ngModel)]="description" />
                      </div>
        
                      <button style="margin-top: 45px;min-height: auto;" nz-button nzType="primary" (click)="newCategory()" >SAVE</button>
                        
                    </nz-modal>
        
                </div>
                </div>

                <div class='stats__table'>
                  <nz-table  #sortTableCategories 
                  [nzData]="listOfCategories.data" 
                  nzTableLayout="fixed"
                  nzShowSizeChanger
                  [nzLoading]="isLoadingCategories"
                  [nzFrontPagination]="false"
                  [nzTotal]="listOfCategories.meta.total"
                  [nzPageSize]="listOfCategories.meta.per_page"
                  [nzPageIndex]="listOfCategories.meta.current_page"
                  (nzQueryParams)="onCategoriesChange($event)"
                  >
                    <thead>
                      <tr>
                        <th nzLeft>Sr. No</th>
                        <th >Category</th>
                        <th style="width: 50%;">Description</th>
                        <th>Created On</th>
                        <th>Created By</th>
                        <th nzRight></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of sortTableCategories.data;let i=index;">
                        <td nzLeft>{{ i+1 }}</td>
                        <td>{{ data.category_name }}</td>
                        <td style="width: 50%;">{{ data.description }}</td>
                        <td>{{ data.created_at | date: 'mediumDate'}}</td>
                        <td>{{ data.created_by_name }}</td>
                        <td nzRight>
                          <div style="display: flex;">
                            <div style="margin-right: 15px;cursor: pointer;" (click)="editCategory(data)">
                              <svg width="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>   
                            </div>
              
                            <div style="cursor: pointer;" 
                            nz-popconfirm
                            nzPopconfirmTitle="Are you sure delete this category?"
                            (nzOnConfirm)="deleteCategory(data)">
                              <svg style="position: relative;top: 2px;" width="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7 7L13 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13 7L7 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </div>

              </nz-tab>

              <nz-tab nzTitle="LABOUR PRICES FOR GENERAL SERVICING">

                <div class="breadcrumbs-add-btn-bar mt-3">
                  <div class="title-breadrumbs-container">
                      <div class="title">Labour Prices</div>
                  </div>
                  <div class="doc-search-btn">
        
                    <nz-modal [(nzVisible)]="openAddCategoryModal" nzTitle="Add New Category" 
                    (nzOnCancel)="openAddCategoryModal=false;clearPreviousData()" style="justify-content: center; align-items: center;">
        
                      <div class="input-group">
                          <div class="input-label">Category Name</div>
                          <input nz-input placeholder="Category Name" [(ngModel)]="category_name" />
                      </div>
        
                      <div class="input-group">
                          <div class="input-label">Category Description</div>
                          <input nz-input placeholder="Description" [(ngModel)]="description" />
                      </div>
        
                      <button style="margin-top: 45px;min-height: auto;" nz-button nzType="primary" (click)="newCategory()" >SAVE</button>
                        
                    </nz-modal>
        
                </div>
                </div>

                <div class='stats__table'>
                  <nz-table  #labourPricesTable
                  [nzData]="labour_prices" 
                  nzTableLayout="fixed"
                  [nzLoading]="isLoadingLabourPrices"
                  >
                    <thead>
                      <tr>
                        <th nzLeft>Id</th>
                        <th>Vehicle Type</th>
                        <th>Cost/Day</th>
                        <th>Updated By</th>
                        <th>Updated On</th>
                        <th nzRight></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let data of labourPricesTable.data">
                        <td nzLeft>{{ data.id }}</td>
                        <td>{{ data.vehicle_type }}</td>
                        <td>{{ data.cost_per_day }}</td>
                        <td>{{ data.updated_by_name}}</td>
                        <td>{{ data.updated_at  | date }}</td>
                        <td nzRight>
                          <div style="display: flex;">
                            <div style="margin-right: 15px;cursor: pointer;" (click)="editLabourPrice(data)">
                              <svg width="15" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>   
                            </div>
              
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
 

                  <nz-modal [(nzVisible)]="editLabourPriceModal" nzTitle="Edit Labour Price" 
                  (nzOnCancel)="editLabourPriceModal=false;clearPreviousData()" style="justify-content: center; align-items: center;">
      
                    <div class="input-group">
                        <div class="input-label">Vehicle Type</div>
                        <input nz-input placeholder="Vehicle Type" [(ngModel)]="vehicle_type" disabled />
                    </div>
      
                    <div class="input-group">
                        <div class="input-label">Labour Price</div>
                        <nz-input-number style="width: 100%;" [(ngModel)]="cost_per_day" [nzMin]="0" [nzStep]="100"></nz-input-number> 
                    </div>
      
                    <button style="margin-top: 45px;min-height: auto;" nz-button nzType="primary" (click)="saveLabourPrice()" >SAVE</button>
                      
                  </nz-modal>

                </div> 

              </nz-tab>
              
            </nz-tabset>
          </nz-card>
        </div>
      </div>
  </div>

