import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import axios from 'axios';
import {Router} from "@angular/router"
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss']
})
export class SignupPageComponent implements OnInit {

  name = '';
  email = '';
  password = '';
  role = "-1";
  course_id = '1';
  board_id = '-1';
  reference_code = '';

  page = 1;
  remember_password=false;

  courses = [];
  teacherDetailsModal=false;

  hash = '';
  phone_number = '';
  city = '';
  state = '';
  country = '';
  profile_pic = '';
  status = '';
  avatarselector=false;
  id_proof = '';
  address = '';
  listOfCourseSubjects: string[] = [];
  listOfSelectedCourse = [];
  org_list = [];

  constructor(private message: NzMessageService, private router: Router) { }


  ngOnInit(): void {


    var token = localStorage.getItem("token"); 
    var role = +localStorage.getItem("role"); 
    if(token) {
      this.router.navigate(['/all-products']);
      // if(role==0) {
      //   this.router.navigate(['/admin/dashboard']);
      // } else if(role==1) {
      //   this.router.navigate(['/teacher/dashboard']);
      // } else if(role==2) {
      //   this.router.navigate(['/student/dashboard']);
      // } else if(role==3) {
      //   this.router.navigate(['/parent/dashboard']);
      // }
    }

  }


  createSuccessMessage(msg: string): void {
    this.message.create('success', msg);
  }

  createErrorMessage(msg: string): void {
    this.message.create('error', msg);
  }

  signupFn() {
  

    if(this.name==""){
      this.createSuccessMessage(`Please enter your name!`);
      return false;
    }

    if(this.email==""){
      this.createSuccessMessage(`Please enter your email!`);
      return false;
    }

    if(this.password==""){
      this.createErrorMessage(`Please enter your password!`);
      return false;
    }

    var url = '/api/register';

    var data = {
      "name": this.name,	
      "email": this.email,	
      "password": this.password,	
    };


    axios.post(url, data, {}).then(response => {

      axios.defaults.headers.common['Authorization'] = 'Bearer '+ response.data.token.token;
        
      this.createSuccessMessage(`Account Created Successfully!`);
      localStorage.setItem("token", response.data.token.token);
      localStorage.setItem("id", response.data.user.id);
      localStorage.setItem("email", response.data.user.email);
      localStorage.setItem("role", response.data.user.role);
      localStorage.setItem("status", response.data.user.status);
      localStorage.setItem("name", response.data.user.name);

      this.router.navigate(['/all-products']);
      // if(response.data.user.role==0) {
      //   this.router.navigate(['/admin/dashboard']);
      // } else if(response.data.user.role==1) {
      //   this.router.navigate(['/teacher/dashboard']);
      // } else if(response.data.user.role==2) {
      //   this.router.navigate(['/student/dashboard']);
      // } else if(response.data.user.role==3) {
      //   this.router.navigate(['/parent/dashboard']);
      // }

    })
    .catch((error) => { 

      if(error.response) {

        if(error.response.data.email=="The email has already been taken.") {

          this.createErrorMessage(`Email Address Already Taken!`);
  
        } else if(error.response.data.email) {

          this.createErrorMessage(`Invalid Email Address!`);

        }  else if(error.response.data.password) {

          this.createErrorMessage(`Please use strong password!`);

        }  else if(error.response.data) {

            this.createErrorMessage(`Something went wrong!`);

        }
      
      } 
    });
  }

}
