<div
  class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
  <app-primary-header></app-primary-header>
  <app-sidebar-dashboard></app-sidebar-dashboard>
  <div class="page-container">
    <div class="main-content">
      <nz-card>
        <div class="breadcrumbs-add-btn-bar">
          <div class="title-breadrumbs-container d-flex">
            <div class="d-flex">
              <div class="title mr-3">WASH ENTRY</div>
            </div>
          </div>

          <div class="doc-search-btn">
            <button
              *ngIf="!isEdit"
              nz-button
              nzType="primary"
              (click)="createNewOrder()"
            >
              SUBMIT
            </button>
            <button
              *ngIf="isEdit"
              nz-button
              nzType="primary"
              (click)="createNewOrder()"
            >
              SAVE
            </button>
          </div>
        </div>

        <div class="customer-details-container" style="padding-bottom: 30px;">
          <div
            style="
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 15px;
              color: #004a7f;
              padding-top: 15px;
            "
          >
            Customer Details
          </div>
          <nz-divider></nz-divider>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 20px;
              "
            >
              <div
                style="
                  font-family: Poppins;
                  font-size: 13px;
                  color: #231f20;
                  font-weight: 500;
                  margin-bottom: 5px;
                "
              >
                Customer ID
              </div>
              <div>
                <nz-select
                  nzShowSearch
                  style="width: 100%;"
                  [(ngModel)]="customer_id"
                  (ngModelChange)="loadCustomerDetails($event)"
                >
                  <nz-option
                    *ngFor="let customer of all_customers"
                    [nzValue]="customer.id"
                    [nzLabel]="customer.customer_name + ' #' + customer.id"
                  ></nz-option>
                </nz-select>
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 20px;
              "
            >
              <div
                style="
                  font-family: Poppins;
                  font-size: 13px;
                  color: #231f20;
                  font-weight: 500;
                  margin-bottom: 5px;
                "
              >
                Customer Name
              </div>
              <div>
                <input nz-input [(ngModel)]="customer_name" disabled />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 20px;
              "
            >
              <div
                style="
                  font-family: Poppins;
                  font-size: 13px;
                  color: #231f20;
                  font-weight: 500;
                  margin-bottom: 5px;
                "
              >
                Vehicle Reg. No.
              </div>
              <div>
                <nz-select
                  nzShowSearch
                  style="width: 100%;"
                  [(ngModel)]="vehicle_id"
                  (ngModelChange)="loadVehicleDetails($event)"
                >
                  <nz-option
                    *ngFor="let vehicle of customer_vehicles"
                    [nzValue]="vehicle.id"
                    [nzLabel]="vehicle.vehicle_number"
                  ></nz-option>
                </nz-select>
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 20px;
              "
            ></div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-top: 15px;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 20px;
              "
            >
              <div
                style="
                  font-family: Poppins;
                  font-size: 13px;
                  color: #231f20;
                  font-weight: 500;
                  margin-bottom: 5px;
                "
              >
                Vehicle Department
              </div>
              <div>
                <input
                  nz-input
                  type="text"
                  disabled
                  [(ngModel)]="vehicle_department"
                />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 20px;
              "
            >
              <div
                style="
                  font-family: Poppins;
                  font-size: 13px;
                  color: #231f20;
                  font-weight: 500;
                  margin-bottom: 5px;
                "
              >
                Vehicle Type
              </div>
              <div>
                <input
                  nz-input
                  type="text"
                  disabled
                  [(ngModel)]="vehicle_type"
                />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 20px;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  margin-right: 20px;
                "
              >
                <div
                  style="
                    font-family: Poppins;
                    font-size: 13px;
                    color: #231f20;
                    font-weight: 500;
                    margin-bottom: 5px;
                  "
                >
                  Driver Name
                </div>
                <div>
                  <input nz-input type="text" [(ngModel)]="driver_name" />
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-right: 20px;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  margin-right: 20px;
                "
              >
                <div
                  style="
                    font-family: Poppins;
                    font-size: 13px;
                    color: #231f20;
                    font-weight: 500;
                    margin-bottom: 5px;
                  "
                >
                  Washer Name
                </div>
                <div>
                  <input nz-input type="text" [(ngModel)]="washer_name" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 15px;
            color: #004a7f;
            padding-top: 15px;
            justify-content: flex-start;
            align-items: center;
          "
        >
          Details
        </div>
        <nz-divider></nz-divider>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-right: 20px;
            "
          >
            <div
              style="
                font-family: Poppins;
                font-size: 13px;
                color: #231f20;
                font-weight: 500;
                margin-bottom: 5px;
              "
            >
              Wash Date
            </div>
            <div>
              <nz-input-group>
                <nz-date-picker
                  style="width: 100%;"
                  [nzFormat]="'dd-MM-yyyy'"
                  [(ngModel)]="wash_date_time"
                ></nz-date-picker>
              </nz-input-group>
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-right: 20px;
            "
          >
            <div
              style="
                font-family: Poppins;
                font-size: 13px;
                color: #231f20;
                font-weight: 500;
                margin-bottom: 5px;
              "
            >
              Wash Time
            </div>
            <div>
              <nz-input-group>
                <nz-time-picker
                  style="width: 100%;"
                  [nzFormat]="'HH:mm'"
                  [(ngModel)]="wash_date_time"
                ></nz-time-picker>
              </nz-input-group>
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-right: 20px;
            "
          >
            <div
              style="
                font-family: Poppins;
                font-size: 13px;
                color: #231f20;
                font-weight: 500;
                margin-bottom: 5px;
              "
            >
              Wash End Time
            </div>
            <div>
              <nz-input-group>
                <nz-time-picker
                  style="width: 100%;"
                  [nzFormat]="'HH:mm'"
                  [(ngModel)]="wash_end_time"
                ></nz-time-picker>
              </nz-input-group>
            </div>
          </div>

          <div
            style="
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-right: 20px;
            "
          >
            <div
              style="
                font-family: Poppins;
                font-size: 13px;
                color: #231f20;
                font-weight: 500;
                margin-bottom: 5px;
              "
            >
              Link Booking (If Any)
            </div>
            <nz-select
              style="width: 100%;"
              [(ngModel)]="service_booking_id"
              nzShowSearch
              nzAllowClear
            >
              <nz-option
                *ngFor="let booking of listOfBookings"
                [nzValue]="booking.id"
                nzLabel="ID#{{ booking.id }}, Due Date: {{
                  booking.due_date | date
                }} "
              ></nz-option>
            </nz-select>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-right: 20px;
            "
          ></div>
        </div>

        <div
          style="
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 15px;
            color: #004a7f;
            padding-top: 25px;
            justify-content: flex-start;
            align-items: center;
          "
        >
          Pictures
        </div>
        <nz-divider></nz-divider>

        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;
          "
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-right: 20px;
            "
          >
            <nz-upload
              [nzAccept]="'image/*, video/*'"
              nzAction="https://bigfoot.reddotapps.com.sg/api/upload-order-images"
              nzListType="picture-card"
              [(nzFileList)]="pictures"
              [nzShowButton]="pictures.length < 20"
              [nzPreview]="handlePreview"
              (nzChange)="allImagesSave($event)"
            >
              <div>
                <i nz-icon nzType="plus"></i>
                <div style="margin-top: 8px;">Upload</div>
              </div>
            </nz-upload>
            <nz-modal
              [nzVisible]="previewVisible"
              [nzContent]="modalContent"
              [nzFooter]="null"
              (nzOnCancel)="previewVisible = false"
            >
              <ng-template #modalContent>
                <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
              </ng-template>
            </nz-modal>
          </div>
        </div>
      </nz-card>
    </div>
  </div>
</div>
