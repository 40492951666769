<nz-layout>
    <nz-content>

      <div class="login-bg">

           <div class="login-card" [ngClass]="setClassesForSignUp()">
            <div nz-row>
                <div nz-col nzXs="24" nzLg="24" style="display: flex;justify-content: space-between;border-bottom: 1px solid rgb(0 0 0 / 8%);margin-bottom: 15px;">
                    <h2>Password Reset Request</h2>
                </div>

                <div nz-col nzXs="24" nzLg="24" style="margin-bottom: 15px;">
                    <span class="form-label">Password</span>
                    <input nz-input placeholder="Password" type="password" [(ngModel)]="password" />
                </div>

                <div nz-col nzXs="24" nzLg="24" style="margin-bottom: 15px;">
                    <span class="form-label">Confirm Password</span>
                    <input nz-input placeholder="Password" type="password" [(ngModel)]="password_confirmation" />
                </div>

                <div nz-col nzXs="24" nzLg="24" style="margin-top: 15px;margin-bottom: 10px;">

                    <button class="button button--white button--block" style="max-width: 150px;margin-left: 0px; margin-top: 30px;background-color: #ff9223;color: white;box-shadow: 0 6px 14px 0 rgb(238 157 75 / 34%);border: none;outline: none; cursor:pointer;" (click)="resetPassword()">
                        <div class="outline" style="box-shadow: 0 0 0 2px #ff922357;">
                        </div>
                        <div class="background" style="background-color: #ff9223;">
                        </div>
                        <span style="font-family: 'Poppins';FONT-WEIGHT: 600;">
                          Submit
                        </span>
                    </button>

                </div>
            </div>
          </div>


      </div>
    </nz-content>
</nz-layout>