import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { ThemeConstantService } from '../services/theme-constant.service';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';

interface DataItem {
  id: string;
  productName: string;
  contact: number;
  address: string;
  totalBusiness: number;
  createdOn: string;
  createdBy: string;
  contactPerson: string;
}

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.css'],
})
export class AllProductsComponent implements OnInit {
  isClicked: boolean = false;
  options: string[] = [
    'products',
    'purchase orders',
    'stock out',
    'dashboard',
    'master data',
    'transactions',
  ];

  listOfColumn = [
    {
      title: 'ID',
    },
    {
      title: 'VENDOR NAME',
    },
    {
      title: 'CONTACT',
    },
    {
      title: 'ADDRESS',
    },
    {
      title: 'TOTAL BUSINESS',
    },
    {
      title: 'CREATED ON',
    },
    {
      title: 'CREATED BY',
    },
    {
      title: 'CONTACT PERSON',
    },
  ];

  listOfData: DataItem[] = [];

  listOfProducts = {
    data: [],
    meta: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      first_page: 0,
    },
  };

  isLoadingProducts = false;

  openAddProductModal = false;

  product_id = 0;
  product_name = '';
  product_image = '';
  make = '';
  model = '';
  category_id = 0;
  type = '';
  recyclable_amount = 0;
  price = 0;
  selling_price = 0;
  initial_stock = 0;
  labour_charges = 0;
  location = '';
  total_purchasses = 0;
  status = 0;
  reorder_level = 0;

  isProductEdit = false;

  product_search = '';
  sort_by_field = 'created_at';
  sort_by_type = 'desc';
  categories = [];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;

  page_index = 1;
  page_size = 10;
  filterDrawerVisible = false;
  product_filters = [
    {
      label: 'Name',
      key: 'product_name',
      type: 'string',
      value: null,
      condition: 'Contains',
    },
    {
      label: 'Make',
      key: 'make',
      type: 'string',
      value: null,
      condition: 'Contains',
    },
    {
      label: 'Model',
      key: 'model',
      type: 'string',
      value: null,
      condition: 'Contains',
    },
    {
      label: 'Category',
      key: 'category_id',
      type: 'options',
      value: null,
      condition: 'Equals',
      options: [],
    },
    {
      label: 'Price',
      key: 'price',
      type: 'number',
      value: null,
      condition: 'Equals',
    },
    {
      label: 'Quantity',
      key: 'stock_in_hand',
      type: 'number',
      value: null,
      condition: 'Equals',
    },
    {
      label: 'Location',
      key: 'location',
      type: 'string',
      value: null,
      condition: 'Contains',
    },
    {
      label: 'Status',
      key: 'status',
      type: 'options',
      value: null,
      condition: 'Equals',
      options: [
        {
          value: 1,
          label: 'Inactive',
        },
        {
          value: 0,
          label: 'Active',
        },
      ],
    },
  ];

  show_expired_quotes_products = false;
  quotes_start_date = '';
  quotes_end_date = '';

  isLoadingProduct = false;

  intro = {
    open_po: 0,
    expired_quotes: 0,
    po_generated: 0,
    po_rejected: 0,
  }

  date = [moment().subtract(1, 'M').toDate(), moment().toDate()];

  constructor(
    private msg: NzMessageService,
    private themeService: ThemeConstantService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.filterMethodProducts();
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.route.queryParams.subscribe((params) => {
      if (
        params.show_expired_quotes_products &&
        params.start_date &&
        params.end_date
      ) {
        this.show_expired_quotes_products = true;
        this.quotes_start_date = moment(params.start_date).format('YYYY-MM-DD');
        this.quotes_end_date = moment(params.end_date).format('YYYY-MM-DD');
        this.filterMethodProducts();
      }
    });
  }

  getDashboardInventory() {
    let url = '/api/get-dashboard-inventory';

    let data = {
        start_date: moment(this.date[0]).format('YYYY-MM-DD'),
        end_date: moment(this.date[1]).format('YYYY-MM-DD')
    };

    axios.post(url, data, {}).then(response => {
        this.intro = response.data.intro;
    })

  }

  getCategories() {
    var url = '/api/categories-dropdown';

    var data = {};

    axios.post(url, data, {}).then((response) => {
      this.categories = response.data.categories;

      const index = this.product_filters.findIndex(
        (item) => item.key == 'category_id'
      );

      this.product_filters[index].options = this.categories.map((item) => {
        return {
          value: item.id,
          label: item.category_name,
        };
      });
    });
  }

  filterMethodProducts() {
    console.log(this.product_filters);

    this.isLoadingProducts = true;

    var url = '/api/all-products';

    var data = {
      search: this.product_search,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      page_index: this.page_index,
      page_size: this.page_size,
      filters: [],
      show_expired_quotes_products: this.show_expired_quotes_products,
      quotes_start_date: this.quotes_start_date,
      quotes_end_date: this.quotes_end_date,
    };

    const filters = this.product_filters
      .map((element) => {
        if (element.value != null) {
          if (element.type === 'number') {
            return {
              key: element.key,
              value: Number(element.value),
              condition: element.condition,
            };
          } else if (element.type === 'date') {
            return {
              key: element.key,
              value: moment(element.value).format('YYYY-MM-DD'),
              condition: element.condition,
            };
          } else {
            return {
              key: element.key,
              value: element.value,
              condition: element.condition,
            };
          }
        }
      })
      .filter((element) => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then((response) => {
      this.listOfProducts = response.data.all_products;
      this.isLoadingProducts = false;
      this.filterDrawerVisible = false;
    });
  }

  clearFilters() {
    this.product_filters.forEach((element) => {
      element.value = null;
    });
    this.filterMethodProducts();
  }

  onProductParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find((item) => item.value !== null);
    this.sort_by_field = (currentSort && currentSort.key) || null;
    this.sort_by_type = (currentSort && currentSort.value) || null;
    this.page_index = pageIndex;
    this.page_size = pageSize;
    this.filterMethodProducts();
  }

  // onProductChange($event) {
  //   this.filterMethodProducts($event.pageIndex, $event.pageSize);
  // }

  downloadCsvProduct() {
    const { parse } = require('json2csv');
    // let Products = [...this.listOfProducts.data];

    // Products.forEach((_, index) => {
    //   Products[index].sr_no = index + 1;
    // });

    const fields = [
      {
        label: 'Sr. No.',
        value: 'sr_no',
      },
      {
        label: 'Name',
        value: 'product_name',
      },
      {
        label: 'Make',
        value: 'make',
      },
      {
        label: 'Model',
        value: 'model',
      },
      {
        label: 'Category',
        value: 'category_name',
      },
      {
        label: 'Price',
        value: 'price',
      },
      {
        label: 'Quantity',
        value: 'stock_in_hand',
      },
      {
        label: 'Location',
        value: 'location',
      },
      {
        label: 'Status',
        value: (row: any) => {
          if (row.status == 0) {
            return 'Active';
          } else {
            return 'In Active';
          }
        },
      },
    ];

    var url = '/api/all-products';

    var data = {
      search: this.product_search,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      page_index: 1,
      page_size: this.listOfProducts.meta.total,
      filters: [],
      show_expired_quotes_products: this.show_expired_quotes_products,
      quotes_start_date: this.quotes_start_date,
      quotes_end_date: this.quotes_end_date,
    };

    const filters = this.product_filters
      .map((element) => {
        if (element.value != null) {
          if (element.type === 'number') {
            return {
              key: element.key,
              value: Number(element.value),
              condition: element.condition,
            };
          } else if (element.type === 'date') {
            return {
              key: element.key,
              value: moment(element.value).format('YYYY-MM-DD'),
              condition: element.condition,
            };
          } else {
            return {
              key: element.key,
              value: element.value,
              condition: element.condition,
            };
          }
        }
      })
      .filter((element) => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then((response) => {
      // this.listOfProducts = response.data.all_products;
      let listOfProducts = response.data.all_products;
      let Products = [...listOfProducts.data];
      Products.forEach((_, index) => {
        Products[index].sr_no = index + 1;
      });
      const csv = parse(Products, { fields });
      require('downloadjs')(csv, 'Products.csv');
    });

    // const csv = parse(Products, { fields });
    // require('downloadjs')(csv, 'Products.csv');
  }

  newProduct() {
    // if (this.product_name == '') {
    //   this.msg.error('Please enter product name!');
    //   return false;
    // }

    // if (this.product_image == '') {
    //   this.msg.error('Please upload product image!');
    //   return false;
    // }

    // if (this.make == '') {
    //   this.msg.error('Please enter product make!');
    //   return false;
    // }

    // if (this.model == '') {
    //   this.msg.error('Please enter product model!');
    //   return false;
    // }

    // if (this.location == '') {
    //   this.msg.error('Please enter location!');
    //   return false;
    // }

    // if (this.type == '') {
    //   this.msg.error('Please select product type!');
    //   return false;
    // }

    // if(!this.selling_price) {
    //   this.msg.error('Please enter selling price!');
    //   return false;
    // }

    // if(this.initial_stock<0) {
    //   this.msg.error('Please enter valid initial stock!');
    //   return false;
    // }

    // if(isNaN(this.initial_stock)) {
    //   this.msg.error('Please enter valid initial stock!');
    //   return false;
    // }

    let url = '';

    if (this.isProductEdit) {
      url = '/api/update-product';
    } else {
      url = '/api/new-product';
    }

    let data = {
      product_name: this.product_name,
      product_image: this.product_image,
      make: this.make,
      model: this.model,
      product_id: this.product_id,
      category_id: this.category_id,
      price: this.price,
      selling_price: this.selling_price,
      initial_stock: this.initial_stock,
      labour_charges: this.labour_charges,
      location: this.location,
      status: this.status,
      reorder_level: this.reorder_level,
      type: this.type,
      recyclable_amount: this.recyclable_amount,
    };

    axios.post(url, data, {}).then((response) => {
      if (response.data.success == true) {
        this.filterMethodProducts();
        this.openAddProductModal = false;
        this.clearPreviousData();
      } else if (response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error('Something Went Wrong!');
      }
    });
  }

  editProduct(data) {
    this.product_id = data.id;
    this.product_name = data.product_name;
    this.product_image = data.product_image;
    this.make = data.make;
    this.model = data.model;
    this.product_name = data.product_name;
    this.category_id = data.category_id;
    this.price = data.price;
    this.selling_price = data.selling_price;
    this.initial_stock = data.initial_stock;
    this.labour_charges = data.labour_charges;
    this.location = data.location;
    this.status = data.status;
    this.reorder_level = data.reorder_level;
    this.type = data.type;
    this.recyclable_amount = data.recyclable_amount;

    this.isProductEdit = true;
    this.openAddProductModal = true;
  }

  deleteProduct(data) {
    var url = '/api/delete-product';

    var data2 = {
      product_id: data.id,
    };

    axios.post(url, data2, {}).then((response) => {
      if (response.data.success == true) {
        this.filterMethodProducts();
        this.openAddProductModal = false;
        this.msg.success('Product deleted successfully!');
      } else if (response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error('Something Went Wrong!');
      }
    });
  }

  handleChange(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        // Component will show file.url as link
        this.product_image = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  GenerateProductName() {
    if (this.categories.filter((el) => el.id == this.category_id).length > 0) {
      const category_name = this.categories.filter(
        (el) => el.id == this.category_id
      )[0].category_name;
      this.product_name = category_name + '-' + this.make + '-' + this.model;
    } else {
      this.product_name = '' + '-' + this.make + '-' + this.model;
    }
  }

  getProductSingle(product_id) {
    this.isLoadingProduct = true;
    this.openAddProductModal = true;
    var url = '/api/get-product-single';

    var data = {
      product_id: product_id,
    };

    this.product_id = product_id;

    axios.post(url, data, {}).then((response) => {
      this.isProductEdit = true;
      this.product_name = response.data.product_name;
      this.product_image = response.data.product_image;
      this.make = response.data.make;
      this.model = response.data.model;
      this.category_id = response.data.category_id;
      this.price = response.data.price;
      this.selling_price = response.data.selling_price;
      this.initial_stock = response.data.initial_stock;
      this.reorder_level = response.data.reorder_level;
      this.labour_charges = response.data.labour_charges;
      this.location = response.data.location;
      this.status = response.data.status;
      this.type = response.data.type;
      this.recyclable_amount = response.data.recyclable_amount;
      this.isLoadingProduct = false;
    });
  }

  clearPreviousData() {
    this.product_id = 0;
    this.product_name = '';
    this.product_image = '';
    this.make = '';
    this.model = '';
    this.category_id = 0;
    this.price = 0;
    this.selling_price = 0;
    this.initial_stock = 0;
    this.reorder_level = 0;
    this.labour_charges = 0;
    this.location = '';
    this.status = 0;
    this.type = '';
    this.recyclable_amount = 0;
    this.isProductEdit = false;
  }
}
