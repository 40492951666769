<div
  class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
  <app-primary-header></app-primary-header>

  <!-- <div class="sidebar-and-main-container"> -->
  <app-sidebar-dashboard></app-sidebar-dashboard>
  <div class="page-container">
    <div class="main-content">
      <nz-card>
        <div class="breadcrumbs-add-btn-bar">
          <div class="title-breadrumbs-container">
            <div class="title">All Vehicle Categories</div>
          </div>

          <div class="doc-search-btn">

            <div class="search-box" style="margin-right: 10px">
              <nz-input-group [nzPrefix]="prefixIconSearch">
                <input
                  type="text"
                  nz-input
                  placeholder="Search..."
                  [(ngModel)]="search"
                  (ngModelChange)="getVehicleCategories()"
                />
              </nz-input-group>

              <ng-template #prefixIconSearch>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 5px"
                >
                  <path
                    d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z"
                    stroke="#979797"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.0001 17L13.0977 13.0975"
                    stroke="#979797"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ng-template>
            </div>

            <div class="add-product-btn">
              <button
                (click)="openAddVehicleCategoriesModal = true"
                nz-button
                nzType="primary"
              >
                Add Vehicle Category
              </button>

              <nz-modal
                [nzWidth]="800"
                [(nzVisible)]="openAddVehicleCategoriesModal"
                [nzTitle]="
                  isVehicleCategoriesEdit
                    ? 'Update Vehicle Category'
                    : 'New Vehicle Category'
                "
                (nzOnCancel)="
                  openAddVehicleCategoriesModal = false; clearPreviousData()
                "
                style="justify-content: center; align-items: center"
              >
                <div nz-row [nzGutter]="[12, 36]">
                  <div nz-col nzSpan="12">
                    <div class="input-group">
                      <div class="input-label">Name</div>
                      <input
                        nz-input
                        placeholder="Name"
                        [(ngModel)]="vehicle_category.name"
                      />
                    </div>
                  </div>

                  <div nz-col nzSpan="12">
                    <div class="input-group">
                      <div class="input-label">Image</div>
                      <nz-upload
                        [nzAccept]="'image/*'"
                        style="width: 100%"
                        nzAction="https://bigfoot.reddotapps.com.sg/api/upload-product-image"
                        (nzChange)="handleChange($event)"
                        [nzShowUploadList]="false"
                      >
                        <ng-container *ngIf="!vehicle_category.image">
                          <button nz-button>Upload</button>
                        </ng-container>
                        <img
                          class="pointer w-100"
                          *ngIf="vehicle_category.image"
                          [src]="vehicle_category.image"
                        />
                      </nz-upload>
                    </div>
                  </div>
                </div>

                <button
                  style="margin-top: 25px; min-height: auto"
                  nz-button
                  nzType="primary"
                  (click)="newVehicleCategories()"
                >
                  SAVE
                </button>
              </nz-modal>
            </div>
          </div>
        </div>

        <div class="stats__table">
          <nz-table
            #vehicleCategoryTable
            [nzData]="listOfVehicleCategories"
            [nzFrontPagination]="false"
            [nzLoading]="isLoadingVehicleCategories"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Image</th>
                <th>Name</th>
                <th>Created At</th>
                <th nzRight></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of vehicleCategoryTable.data">
                <td>#{{ data.id }}</td>
                <td>
                  <img
                    *ngIf="data.image"
                    [src]="data.image"
                    style="max-height: 30px"
                  />
                </td>
                <td>{{ data.name }}</td>
                <td>{{ data.created_at | date }}</td>

                <td nzRight>
                  <div style="display: flex">
                    <div
                      style="margin-right: 15px; cursor: pointer"
                      (click)="editVehicleCategory(data)"
                    >
                      <svg
                        width="15"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213"
                          stroke="#2672AB"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z"
                          stroke="#2672AB"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      style="cursor: pointer"
                      nz-popconfirm
                      nzPopconfirmTitle="Are you sure delete this vehicle category?"
                      (nzOnConfirm)="deleteVehicleCategory(data.id)"
                    >
                      <svg
                        style="position: relative; top: 2px"
                        width="14"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 1H3C1.89543 1 1 1.89543 1 3V17C1 18.1046 1.89543 19 3 19H17C18.1046 19 19 18.1046 19 17V3C19 1.89543 18.1046 1 17 1Z"
                          stroke="#FF3A29"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7 7L13 13"
                          stroke="#FF3A29"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13 7L7 13"
                          stroke="#FF3A29"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-card>
    </div>
  </div>
</div>
