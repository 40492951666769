<div nz-row class="main-login-signup-container">

    <div class="main-login-container" nz-col="24">

      <div class="blur-filter">
        
      </div>  
      
      <img src="assets/BigFoot/image_720.png" style="width:100vw; object-fit: cover; height:100vh; position: absolute; z-index:1;" alt="">
      
      <div class="signup-details-container">

        <div class="new-here-lang-container">

          <div class="new-here-sign-up-text">

            <div class="logo-container">
              <img src="assets/BigFoot/image_480.png" alt="" style="width:185px; height:auto;">
            </div>

            <div class="sign-up-text">Login</div>

          </div>

        </div>
    
        <div class="sign-up-sub-text">Enter your credentials to get access</div>
    
      
    
        <div class="full-name-details">
          <div class="full-name-text">
           EMPLOYEE ID
          </div>
    
          <div>
            <nz-input-group [nzPrefix]="prefixIconEmail"> 
              <input style="margin-left: 15px;" type="text" [(ngModel)]="email" nz-input />
            </nz-input-group>
    
            <ng-template #prefixIconEmail>
              <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.49994 12H16.5C17.3272 12 18 11.3273 18 10.5V1.5C18 0.672752 17.3272 0 16.5 0H1.49994C0.672691 0 -6.10352e-05 0.672752 -6.10352e-05 1.5V10.5C-6.10352e-05 11.3273 0.672691 12 1.49994 12ZM16.5 0.750002C16.9136 0.750002 17.25 1.08638 17.25 1.5V10.5C17.25 10.9137 16.9136 11.25 16.5 11.25H1.49994C1.08632 11.25 0.749943 10.9137 0.749943 10.5V1.5C0.749943 1.08638 1.08632 0.750002 1.49994 0.750002H16.5Z" fill="#575757"/>
                  <path d="M8.99994 7.94663L15.5786 3.68999C15.7526 3.57712 15.8025 3.34537 15.69 3.17136C15.5775 2.99736 15.3453 2.94786 15.1713 3.05999L8.99994 7.05338L2.82855 3.05999C2.65455 2.94786 2.42242 2.99736 2.30992 3.17136C2.19742 3.34537 2.2473 3.57712 2.4213 3.68999L8.99994 7.94663Z" fill="#575757"/>
                  <path d="M15.7497 9.75023C15.8708 9.75023 15.99 9.69173 16.062 9.58335C16.1772 9.41085 16.1307 9.17835 15.9582 9.06322L12.5831 6.81322C12.4106 6.69809 12.1778 6.74459 12.063 6.91709C11.9479 7.08959 11.9944 7.32209 12.1669 7.43722L15.5419 9.68723C15.606 9.7296 15.6784 9.75023 15.7497 9.75023Z" fill="#575757"/>
                  <path d="M2.25027 9.75023C2.32152 9.75023 2.3939 9.7296 2.45802 9.68723L5.83303 7.43722C6.00553 7.32209 6.05203 7.08959 5.93691 6.91709C5.82216 6.74459 5.58966 6.69809 5.41678 6.81322L2.04177 9.06322C1.86927 9.17835 1.82277 9.41085 1.93789 9.58335C2.00989 9.69173 2.12914 9.75023 2.25027 9.75023Z" fill="#575757"/>
                  </svg>
            </ng-template>
    
          </div>
        </div>  
    
        <div class="full-name-details">

          <div style="display: flex; flex-direction: row;">
            <div class="full-name-text">
              NRIC/FIN
            </div>

         </div>
        <div>
            <nz-input-group [nzPrefix]="prefixIconPassword"> 
              <input style="margin-left: 15px;" type="password" [(ngModel)]="password" nz-input />
            </nz-input-group>
    
            <ng-template #prefixIconPassword>
              <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 6.25H9.9375V2.75C9.9375 1.64531 9.04219 0.75 7.9375 0.75H4.0625C2.95781 0.75 2.0625 1.64531 2.0625 2.75V6.25H1C0.723438 6.25 0.5 6.47344 0.5 6.75V12.75C0.5 13.0266 0.723438 13.25 1 13.25H11C11.2766 13.25 11.5 13.0266 11.5 12.75V6.75C11.5 6.47344 11.2766 6.25 11 6.25ZM3.1875 2.75C3.1875 2.26719 3.57969 1.875 4.0625 1.875H7.9375C8.42031 1.875 8.8125 2.26719 8.8125 2.75V6.25H3.1875V2.75ZM10.375 12.125H1.625V7.375H10.375V12.125ZM5.5625 9.95313V10.7813C5.5625 10.85 5.61875 10.9063 5.6875 10.9063H6.3125C6.38125 10.9063 6.4375 10.85 6.4375 10.7813V9.95313C6.56648 9.86052 6.66275 9.72939 6.71245 9.57859C6.76216 9.42779 6.76273 9.26511 6.71409 9.11396C6.66545 8.96282 6.57011 8.831 6.44179 8.73749C6.31346 8.64398 6.15878 8.5936 6 8.5936C5.84122 8.5936 5.68654 8.64398 5.55821 8.73749C5.42989 8.831 5.33455 8.96282 5.28591 9.11396C5.23727 9.26511 5.23784 9.42779 5.28755 9.57859C5.33725 9.72939 5.43352 9.86052 5.5625 9.95313Z" fill="#575757"/>
              </svg>
            </ng-template>
    
          </div>
        </div> 
  
        <div class="remember-me-forgot-pw">
            <div class="remember-me-text-icon">
  
                <div style="position: relative;top: 4px;">
                  <label nz-checkbox [(ngModel)]="checked"></label> 
                </div>
                <div>Remember me</div>
            </div>
  
            <div class="forgot-pw-text" style="cursor: pointer;" (click)="forgetPassModal=true;">
                <!-- Forgot Password? -->
            </div>
        </div>
        
        <nz-modal [(nzVisible)]="forgetPassModal" [nzFooter]="null" (nzOnCancel)="forgetPassModal=false">
          <div class="input-group">
              <div class="input-label" style="color: #2a2a2a;
              font-weight: 500; font-size: 16px;">Email</div>
              <input nz-input [(ngModel)]="forget_password_email" placeholder="email address" />
          </div>
         <div style="display: flex; flex-direction: row; align-items: center;">
          <div>
              <button nz-button nzType="primary" (click)="forgetPass()" style="margin-top: 15px; margin-right: 10px;">
                  RESET PASSWORD
              </button>
  
          </div>
          <div>
              <button nz-button nzType="secondary" nzDanger  (click)="forgetPassModal=false" style="margin-top: 13px;">
                  CANCEL
              </button> 
          </div>
  
         </div>
        </nz-modal>
    
        <button nz-button nzType="primary" nzDanger style="margin-top: 25px; width: 100%;" (click)="loginFn()">
          Login
       </button>
    
       <!-- <div class="already-member">
        
        <div class="already-member-text">Dont have an account?</div>
        <div class="sign-in-text" routerLink="/sign-up" style="cursor: pointer;">SIGN UP</div>
    
       </div> -->
    
        
      </div>
   
    </div>
  

  
  </div> 

  <nz-modal [(nzVisible)]="otpSubmitModal" [nzFooter]="null" (nzOnCancel)="otpSubmitModal=false">
    <div class="input-group">
      <div class="input-label" style="color: #2a2a2a;
      font-weight: 500; font-size: 16px;">OTP</div>
      <input nz-input [(ngModel)]="forget_password_otp" placeholder="OTP" />
  </div>
   <div style="display: flex; flex-direction: row; align-items: center;">
    <div>
        <button nz-button nzType="primary" (click)="submitOtp()" style="margin-top: 15px; margin-right: 10px;">
            Submit
        </button>

    </div>
    <div>
        <button nz-button nzType="secondary" nzDanger  (click)="otpSubmitModal=false" style="margin-top: 13px;">
            CANCEL
        </button> 
    </div>

   </div>
  </nz-modal>