import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-inventory-transactions',
  templateUrl: './inventory-transactions.component.html',
  styleUrls: ['./inventory-transactions.component.css']
})
export class InventoryTransactionsComponent implements OnInit {


  createNewPOModal = false;
  listOfData = [];

  constructor(private themeService: ThemeConstantService) { }

  listOfTransactions = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  isLoadingTransactions = false;
  transactions_sort_by_field = 'created_at';
  transactions_sort_by_type = 'desc';
  transaction_search = '';
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);   
    this.filterMethodTransactions();
  }

  filterMethodTransactions(page_index=1, page_size=10) {
    this.isLoadingTransactions = true;

    var url = '/api/all_transactions';

    var data = {
      search: this.transaction_search,
      page_index: page_index,
      page_size: page_size,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfTransactions = response.data.all_transactions;
      this.isLoadingTransactions = false;
    })
  }

  onTransactionsChange($event) {
    this.filterMethodTransactions($event.pageIndex, $event.pageSize);
  }

  downloadCsvTransactions() {
    const { parse } = require('json2csv');
    // let Products = [...this.listOfTransactions.data];

    const fields = [
      { label: 'ID', value: 'id' },
      { label: 'Product', value: 'product_name' },
      { label: 'Type', value: 'type' },
      { label: 'Order ID', value: 'order_id' },
      { label: 'PO Id', value: 'purchase_order_id' },
      { label: 'Quantity', value: 'quantity_adjusted' },
      { label: 'Date', value: (row:any) => moment(row.date).format('DD/MM/YYYY'), },
      { label: 'Qty in Hand', value: 'quantity_in_hand' },
    ];

    var url = '/api/all_transactions';

    var data = {
      search: this.transaction_search,
      page_index: 1,
      page_size: this.listOfTransactions.meta.total,
    };

    axios.post(url, data, {}).then(response => {
      // this.listOfTransactions = response.data.all_transactions;
      let listOfTransactions = response.data.all_transactions;
      let Products = [...listOfTransactions.data];

      const csv = parse(Products, { fields });
      require("downloadjs")(csv, 'transactions.csv');
    })

    // const csv = parse(Products, { fields });
    // require("downloadjs")(csv, 'transactions.csv');
  }

}
