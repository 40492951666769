import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error404-page',
  templateUrl: './error404-page.component.html',
  styleUrls: ['./error404-page.component.scss']
})
export class Error404PageComponent implements OnInit {

  constructor() { }

    changeLang(lang) {

    let tag = `googtrans(${lang})`;

    let url = window.location.href;
    let final_url = url+"#"+tag;

    window.location.href = final_url;
    window.location.reload();

  }

  ngOnInit(): void {
  }

}
