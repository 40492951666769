<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">


            <div class="row mb-4" style="width: 100%">
                <div class="col-12">
                    <agm-map 
                        [latitude]='lat' 
                        [longitude]='long'
                    >
                        <agm-marker 
                            *ngFor="let device of devices"
                            [latitude]="device.lat" 
                            [longitude]="device.long"
                            [iconUrl]="device.icon"
                            >    
                        </agm-marker>
                    
                    </agm-map>
                </div> 
            </div>


            
        </div>
    </div>
</div>