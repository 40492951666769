import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-general-servicing-new',
  templateUrl: './general-servicing-new.component.html',
  styleUrls: ['./general-servicing-new.component.css']
})
export class GeneralServicingNewComponent implements OnInit {
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  listOfRemarks = [];
  check_in_date = new Date();
  remarks = [];
  all_customers = [];
  customer_id = -1;
  customer_name = '';
  phone = '';
  customer_vehicles = [];
  vehicle_id = -1;
  make = '';
  model = '';
  mfg_date = null;
  order_id = 0;
  isEdit = false;

  service_booking_id = -1;
  vehicle_service_bookings = [];
  loadVehiclesInitially = false;

  order_sub_type = [];
  order_sub_types = [];

  disabledDate = (current: Date): boolean => differenceInCalendarDays(current, new Date()) < 0;

  constructor(private msg: NzMessageService, private route: ActivatedRoute, private router: Router, private themeService: ThemeConstantService ) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    this.getGeneralServicingPrefs();
    this.loadOrderSubType();
    this.route.queryParams
    .subscribe(params => {
        if(params.order_id) {
          this.order_id = +params.order_id;
          this.isEdit = true;
          this.getOrderSingle();
        }
        if(params.service_booking_id) {
          this.service_booking_id = +params.service_booking_id;
          this.customer_id = +params.customer_id;
          this.vehicle_id = +params.vehicle_id;
          this.loadVehiclesInitially = true;
          this.loadCustomerDetails(+params.customer_id)
        }
      }
    );
    
  }

  loadOrderSubType() {
    var url = '/api/all-order-sub-types';

    var data = {
      search: '',
    };

    axios.post(url, data, {}).then(response => {
      this.order_sub_types = response.data.all_order_sub_types;
    })
  }

  getOrderSingle() {
    var url = '/api/get-order-single';

    var data = {
      order_id: this.order_id
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.order) {
        this.customer_id = response.data.order.customer_id;
        this.vehicle_id = response.data.order.vehicle_id;
        this.remarks = JSON.parse(response.data.order.remarks);
        this.listOfRemarks = JSON.parse(response.data.order.remarks);
        this.check_in_date = new Date(response.data.order.check_in_date);
        this.order_sub_type = [];

        try {
          this.order_sub_type = JSON.parse(response.data.order.order_sub_type);
        } catch {
          this.order_sub_type = [];
        }
      }
      if(response.data.customer) {
        this.customer_name = response.data.customer.customer_name;
        this.phone = response.data.customer.phone;
      }
      if(response.data.vehicle) {
        this.make = response.data.vehicle.make;
        this.model = response.data.vehicle.model;
        this.mfg_date = response.data.vehicle.mfg_date;
      }
      this.loadCustomerDetails(response.data.order.customer_id);
      setTimeout(() => {
        this.loadVehicleDetails(response.data.order.vehicle_id);
      }, 500);
    })
  }

  getGeneralServicingPrefs() {
    var url = '/api/all-customers-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.all_customers = response.data.all_customers;
    })
  }

  loadVehicleDetails(e) {
    const index = this.customer_vehicles.findIndex((el)=>el.id==e);
    const element = this.customer_vehicles[index];

    this.make = element.make;
    this.model = element.model;
    this.mfg_date = element.mfg_date;
    this.checkOrderExists(e);
    var url = '/api/vehicle-service-bookings';

    var data2 = {
      vehicle_id:e
    };

    axios.post(url, data2, {}).then(response => {
      this.vehicle_service_bookings = response.data.vehicle_service_bookings.filter(el => el.service_booking_address == "BigFoot");
    })
  }

  checkOrderExists(vehicle_id: number) {
    if(!this.isEdit) {
      var url = '/api/check-order-exists';
      var data = {
        vehicle_id: vehicle_id,
      }
  
      axios.post(url, data, {}).then(response => {
        if(response.data.success==false) {
          this.msg.error(response.data.message);
        } 
      })
    }
  }

  createNewOrder() {
    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return false;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return false;
    }

    if(this.listOfRemarks.length==0) {
      this.msg.error("Please enter customer remarks!");
      return false;
    }

    if(this.order_sub_type.length==0) {
      this.msg.error("Please select order sub type!");
      return false;
    }

    let empty_remark_count = 0;

    for (let index = 0; index < this.listOfRemarks.length; index++) {
      const element = this.listOfRemarks[index];
      if(element.text=="") {
        empty_remark_count++;
      }
    }

    if(empty_remark_count!=0) {
      this.msg.error("Please enter value in all the remarks!");
      return false;
    }

    var url = '/api/new-order';

    var data = {
      type: 0,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      booking_date: moment().format('YYYY-MM-DD'),
      check_in_date: moment(this.check_in_date).format('YYYY-MM-DD'),
      status: 0,
      remarks: JSON.stringify(this.listOfRemarks),
      service_booking_id: this.service_booking_id ?? -1,
      order_sub_type: JSON.stringify(this.order_sub_type),
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==false) {
        this.msg.error(response.data.message);
      } else {
        this.msg.success("Order created successfully!")
        this.router.navigate(['/general-servicing-single'], { queryParams: { order_id: response.data }});
      }
    })
  }

  updateOrder() {
    if(this.customer_id==-1) {
      this.msg.error("Please select customer!");
      return false;
    }

    if(this.vehicle_id==-1) {
      this.msg.error("Please select vehicle!");
      return false;
    }

    if(this.listOfRemarks.length==0) {
      this.msg.error("Please enter customer remarks!");
      return false;
    }

    if(this.order_sub_type.length==0) {
      this.msg.error("Please select order sub type!");
      return false;
    }

    let empty_remark_count = 0;

    for (let index = 0; index < this.listOfRemarks.length; index++) {
      const element = this.listOfRemarks[index];
      if(element.text=="") {
        empty_remark_count++;
      }
    }

    if(empty_remark_count!=0) {
      this.msg.error("Please enter value in all the remarks!");
      return false;
    }

    var url = '/api/update-order';

    var data = {
      order_id: this.order_id,
      type: 0,
      vehicle_id: this.vehicle_id,
      customer_id: this.customer_id,
      booking_date: moment().format('YYYY-MM-DD'),
      check_in_date: moment(this.check_in_date).format('YYYY-MM-DD'),
      remarks: JSON.stringify(this.listOfRemarks),
      service_booking_id: this.service_booking_id,
      order_sub_type: JSON.stringify(this.order_sub_type),
    };

    axios.post(url, data, {}).then(response => {
      this.msg.success("Order updated successfully!");
      this.router.navigate(['/all-general-servicing']);
    })
  }


  loadCustomerDetails(e) {
    var url = '/api/get-customer-single';

    var data = {
      customer_id:e
    };

    axios.post(url, data, {}).then(response => {
      this.customer_id = response.data.id;
      this.customer_name = response.data.customer_name;
      this.phone = response.data.phone;
    })

    var url = '/api/customer-vehicles';

    var data2 = {
      customer_id:e
    };

    axios.post(url, data2, {}).then(response => {
      this.customer_vehicles = response.data.customer_vehicles;
      if(this.loadVehiclesInitially) {
        this.loadVehicleDetails(this.vehicle_id);
      }
    })

  }
  
  removeRemarks(i) {
    const listOfRemarks = [...this.listOfRemarks];
    listOfRemarks.splice(i, 1);
    this.listOfRemarks = listOfRemarks;
  }

  addRemarks() {
    const listOfRemarks = [...this.listOfRemarks];
    listOfRemarks.push({text:""});
    this.listOfRemarks = listOfRemarks;
  }
  

}
