<div class="common-layout is-default" [class.is-folded]="isFolded" [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded">

  <app-primary-header></app-primary-header>

  <!-- <div class="sidebar-and-main-container"> -->
  <app-sidebar-dashboard></app-sidebar-dashboard>
  <div *ngIf="fullLoader"
    style="display: flex;justify-content: center;align-items: center;position: fixed;top: 0;left: 0;z-index: 99999;width: 100vw;height: 100vh;background: rgba(0, 0, 0, 0.75);">
    <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
  </div>
  <div class="page-container">
    <div class="main-content">

      <nz-card>
        <div class="item-detail-navbar">

          <div class="img-container">
            <img *ngIf="product_image" [src]="product_image" style="max-height: 60px;border-radius: 4px;" />
          </div>

          <div class="detail-container">
            <div class="item-name">{{ product_name }}</div>

            <div class="item-detail-text">Product Name</div>
          </div>

          <div class="detail-container">
            <div class="item-name">{{ category_name }}</div>

            <div class="item-detail-text">Category</div>
          </div>

          <div class="detail-container">
            <div class="item-name">${{price | number : '1.2-2'}}</div>

            <div class="item-detail-text">Price</div>
          </div>

          <div class="detail-container">
            <div class="item-name">{{ stock_in_hand }}</div>

            <div class="item-detail-text">Stock</div>
          </div>

          <div class="detail-container">
            <div class="item-name">{{selling_price}}</div>

            <div class="item-detail-text">Selling Price</div>
          </div>

          <div class="detail-container">
            <div class="item-name" *ngIf="status==0"><nz-badge nzStatus="success"></nz-badge>Active</div>
            <div class="item-name" *ngIf="status==1"><nz-badge nzStatus="error"></nz-badge>In Active</div>

            <div class="item-detail-text">Status</div>
          </div>

          <div class="detail-container" style="margin-right: 25px;" (click)="openAddProductModal=true">

            <svg width="31" height="35" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg"
              style="cursor:pointer">
              <rect x="0.233887" width="30.5319" height="35" rx="5" fill="#ECF7FF" />
              <path
                d="M15.8723 10.5161H10.6595C10.2645 10.5161 9.8857 10.673 9.60639 10.9523C9.32708 11.2316 9.17017 11.6105 9.17017 12.0055V22.431C9.17017 22.826 9.32708 23.2048 9.60639 23.4841C9.8857 23.7635 10.2645 23.9204 10.6595 23.9204H21.0851C21.4801 23.9204 21.8589 23.7635 22.1382 23.4841C22.4175 23.2048 22.5744 22.826 22.5744 22.431V17.2182"
                stroke="#2672AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M21.4573 9.39896C21.7536 9.10271 22.1554 8.93628 22.5744 8.93628C22.9933 8.93628 23.3951 9.10271 23.6914 9.39896C23.9876 9.69522 24.1541 10.097 24.1541 10.516C24.1541 10.935 23.9876 11.3368 23.6914 11.633L16.6169 18.7075L13.6382 19.4522L14.3829 16.4734L21.4573 9.39896Z"
                stroke="#2672AB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>


          </div>

        </div>
        <nz-modal [nzWidth]="800" [(nzVisible)]="openAddProductModal" nzTitle="Edit Product"
          (nzOnCancel)="openAddProductModal=false;clearPreviousData()"
          style="justify-content: center; align-items: center;">

          <div nz-row [nzGutter]="[12,12]">
            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Product Name</div>
                <input nz-input placeholder="Product Name" [(ngModel)]="product_name" />
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Category</div>
                <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="category_id"
                  (ngModelChange)="GenerateProductName()">
                  <nz-option *ngFor="let category of categories;" [nzValue]="category.id"
                    [nzLabel]="category.category_name"></nz-option>
                </nz-select>
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Make</div>
                <input nz-input placeholder="Make" [(ngModel)]="make" (ngModelChange)="GenerateProductName()" />
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Model</div>
                <input nz-input placeholder="model" [(ngModel)]="model" (ngModelChange)="GenerateProductName()" />
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Location</div>
                <input nz-input placeholder="Location Name" [(ngModel)]="location" />
              </div>
            </div>


            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Status</div>
                <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="status">
                  <nz-option [nzValue]="0" nzLabel="Active"></nz-option>
                  <nz-option [nzValue]="1" nzLabel="In Active"></nz-option>
                </nz-select>
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Initial Stock</div>
                <nz-input-number [nzMin]="0" style="width:100%;" [nzStep]="100" nz-input placeholder="0"
                  [(ngModel)]="initial_stock" style="width: 100%;"></nz-input-number>
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Cost Price</div>
                <nz-input-number [nzMin]="0" style="width:100%;" [nzStep]="100" nz-input placeholder="0"
                  [(ngModel)]="price" [nzFormatter]="formatterDollar" [nzParser]="parserDollar"></nz-input-number>
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Labour Charges</div>
                <nz-input-number [nzMin]="0" style="width:100%;" [nzStep]="100" nz-input placeholder="0"
                  [(ngModel)]="labour_charges" [nzFormatter]="formatterDollar"
                  [nzParser]="parserDollar"></nz-input-number>
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Selling Price</div>
                <nz-input-number [nzMin]="0" style="width:100%;" [nzStep]="100" nz-input placeholder="0"
                  [(ngModel)]="selling_price" style="width: 100%;" [nzFormatter]="formatterDollar"
                  [nzParser]="parserDollar"></nz-input-number>
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Min Stock Level</div>
                <nz-input-number [nzPrecision]="0" [nzMin]="0" style="width:100%;" [nzStep]="100" nz-input
                  placeholder="0" [(ngModel)]="reorder_level"></nz-input-number>
              </div>
            </div>


            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Image</div>
                <nz-upload [nzAccept]="'image/*'" style="width: 100%;"
                  nzAction="https://bigfoot.reddotapps.com.sg/api/upload-product-image"
                  (nzChange)="handleChange($event)" [nzShowUploadList]="false">
                  <ng-container *ngIf="!product_image">
                    <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
                    <button nz-button>Upload</button>
                  </ng-container>
                  <img class="pointer w-100" *ngIf="product_image" [src]="product_image" />
                </nz-upload>
              </div>
            </div>

            <div nz-col nzSpan="12">
              <div class="input-group">
                <div class="input-label">Product Type</div>
                <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="type">
                  <nz-option nzValue="Reusable" nzLabel="Reusable"></nz-option>
                  <nz-option nzValue="Recyclable" nzLabel="Recyclable"></nz-option>
                  <nz-option nzValue="Disposable" nzLabel="Consumable"></nz-option>
                </nz-select>
              </div>
            </div>

            <div nz-col nzSpan="12" *ngIf="type=='Recyclable'">
              <div class="input-group">
                <div class="input-label">Recyclable Amount</div>
                <nz-input-number [nzMin]="0" style="width:100%;" [nzStep]="100" nz-input placeholder="0"
                  [(ngModel)]="recyclable_amount"></nz-input-number>
              </div>
            </div>

          </div>

          <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary"
            (click)="newProduct()">SAVE</button>

        </nz-modal>
      </nz-card>

      <nz-card>
        <nz-tabset>
          <nz-tab nzTitle="Price Chart">

            <div class="breadcrumbs-add-btn-bar">

              <div class="title-breadrumbs-container">

                <div class="title">Price Chart</div>

              </div>

              <div class="doc-search-btn">
                <div class="icon-download" (click)="downloadCsvPriceList()">
                  <div style="margin-right: 10px;">
                    <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                      <path
                        d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                      <path
                        d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                      <path
                        d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                    </svg>
                  </div>
                  <div class="download-text">
                    Download CSV
                  </div>
                </div>

                <div class="search-box">
                  <!-- <nz-input-group [nzPrefix]="prefixIconSearch">
                    <input type="text" nz-input placeholder="Search..." [(ngModel)]="price_chart_search" (ngModelChange)="filterMethodPriceList()">
                  </nz-input-group> -->

                  <ng-template #prefixIconSearch>
                    <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                      style="margin-right:5px;">
                      <path
                        d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z"
                        stroke="#979797" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>

                  </ng-template>
                </div>
              </div>

            </div>

            <div class='stats__table'>
              <nz-table #sortTablePL [nzData]="listOfPriceList.data" nzTableLayout="fixed" nzShowSizeChanger
                [nzLoading]="isLoadingPriceList" [nzFrontPagination]="false" [nzTotal]="listOfPriceList.meta.total"
                [nzPageSize]="listOfPriceList.meta.per_page" [nzPageIndex]="listOfPriceList.meta.current_page"
                (nzQueryParams)="onPriceListChange($event)">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Vendor Name</th>
                    <th>PO Number</th>
                    <th>Cost/Unit</th>
                    <th>QTY</th>
                    <th>Date</th>
                    <th>Price Difference</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of sortTablePL.data">
                    <td>{{ data.id }}</td>
                    <td>{{ data.vendor_name }}</td>
                    <td>{{ data.id }}</td>
                    <td>${{ data.price | number : '1.2-2'}}</td>
                    <td>{{ data.quantity }}</td>
                    <td>{{ data.date | date: 'mediumDate' }}</td>
                    <td>
                      <div *ngIf="data.price_change>=0" style="color: rgb(38, 163, 34);font-weight: 500;"> + {{
                        data.price_change }}</div>
                      <div *ngIf="data.price_change<0" style="color: rgb(163, 34, 34);font-weight: 500;"> {{
                        data.price_change }}</div>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </nz-tab>
          <nz-tab nzTitle="Transactions">

            <div class="breadcrumbs-add-btn-bar">

              <div class="title-breadrumbs-container" style="margin-top: 5px;margin-bottom: 5px;">
                <div class="title">Transactions</div>
              </div>

              <div class="doc-search-btn">

                <div class="icon-download" (click)="downloadCsvTransactions()">
                  <div style="margin-right: 10px;">
                    <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                      <path
                        d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                      <path
                        d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                      <path
                        d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                    </svg>
                  </div>
                  <div class="download-text">
                    Download CSV
                  </div>
                </div>

              </div>

            </div>


            <div class='stats__table'>
              <nz-table #sortTableTR [nzData]="listOfTransactions.data" nzTableLayout="fixed" nzShowSizeChanger
                [nzLoading]="isLoadingTransactions" [nzFrontPagination]="false"
                [nzTotal]="listOfTransactions.meta.total" [nzPageSize]="listOfTransactions.meta.per_page"
                [nzPageIndex]="listOfTransactions.meta.current_page" (nzQueryParams)="onTransactionsChange($event)">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Type</th>
                    <th>Order Id</th>
                    <th>PO Id</th>
                    <th>QTY</th>
                    <th>Date</th>
                    <th>Qty in Hand</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of sortTableTR.data">
                    <td>{{ data.id }}</td>
                    <td>
                      <div *ngIf="data.purchase_order_id">Purchase</div>
                      <div *ngIf="data.order_id">Sale</div>
                    </td>
                    <td>{{ data.order_id }}</td>
                    <td>{{ data.purchase_order_id }}</td>
                    <td>
                      <div *ngIf="data.quantity_adjusted>=0" style="color: rgb(38, 163, 34);font-weight: 500;"> + {{
                        data.quantity_adjusted }}</div>
                      <div *ngIf="data.quantity_adjusted<0" style="color: rgb(163, 34, 34);font-weight: 500;"> {{
                        data.quantity_adjusted }}</div>
                    </td>
                    <td>{{ data.date | date: 'mediumDate' }}</td>
                    <td>{{ data.quantity_in_hand }}</td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </nz-tab>
          <nz-tab nzTitle="Purchase Orders">

            <div class="breadcrumbs-add-btn-bar">

              <div class="title-breadrumbs-container">

                <div class="title">Purchase Orders</div>

              </div>

              <div class="doc-search-btn">

                <div class="icon-download" (click)="downloadCsvPurchaseOrders()">
                  <div style="margin-right: 10px;">
                    <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                      <path
                        d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                      <path
                        d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                      <path
                        d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                    </svg>
                  </div>
                  <div class="download-text">
                    Download CSV
                  </div>
                </div>

                <div class="search-box">
                  <nz-input-group [nzPrefix]="prefixIconSearch">
                    <input type="text" nz-input placeholder="Search..." [(ngModel)]="purchase_order_search"
                      (ngModelChange)="filterMethodPurchaseOrders()" />
                  </nz-input-group>

                  <ng-template #prefixIconSearch>
                    <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                      style="margin-right:5px;">
                      <path
                        d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z"
                        stroke="#979797" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>

                  </ng-template>

                </div>
                <button nz-button nzType="primary" (click)="createNewPOModal=true">Add New PO</button>
              </div>

            </div>

            <div class='stats__table'>
              <nz-table #sortTablePO [nzData]="listOfPurchaseOrders.data" nzTableLayout="fixed" nzShowSizeChanger
                [nzLoading]="isLoadingPurchaseOrders" [nzFrontPagination]="false"
                [nzTotal]="listOfPurchaseOrders.meta.total" [nzPageSize]="listOfPurchaseOrders.meta.per_page"
                [nzPageIndex]="listOfPurchaseOrders.meta.current_page" (nzQueryParams)="onPurchaseOrdersChange($event)">
                <thead>
                  <tr>
                    <th style="width:60px;">PO #</th>
                    <th>Vendor Name</th>
                    <th>Cost/Unit</th>
                    <th>QTY</th>
                    <th>Total Cost</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Quotation</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of sortTablePO.data">
                    <td>{{data.id}}</td>
                    <td>{{data.vendor_name}}</td>
                    <td>${{data.price | number : '1.2-2'}} </td>
                    <td>{{data.quantity}}</td>
                    <td>${{data.price * data.quantity | number : '1.2-2'}} </td>
                    <td>{{ data.date | date: 'mediumDate'}}</td>
                    <td>
                      <nz-badge *ngIf="data.status==-1" nzStatus="warning"></nz-badge>
                      <nz-badge *ngIf="data.status==0" nzStatus="processing"></nz-badge>
                      <nz-badge *ngIf="data.status==1" nzStatus="success"></nz-badge>
                      <nz-badge *ngIf="data.status==2" nzStatus="error"></nz-badge>
                      <nz-badge *ngIf="data.status==3" nzStatus="default"></nz-badge>
                      {{data.status==3 ? 'Approved' : data.status==-1 ? 'Pending Approval' : data.status==0 ? 'Sent' :
                      data.status==1 ? 'Stock Received' : 'Rejected'}}
                    </td>
                    <td> {{ data.created_by_name }}</td>
                    <td>{{ data.quotation_id }}</td>
                    <td>
                      <div style="display: flex;">

                        <div style="margin-right: 10px;cursor: pointer;" (click)="editPurchaseOrder(data)">
                          <svg width="20" height="20" viewBox="0 0 23 23" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10 3.12134H3C2.46957 3.12134 1.96086 3.33205 1.58579 3.70712C1.21071 4.0822 1 4.5909 1 5.12134V19.1213C1 19.6518 1.21071 20.1605 1.58579 20.5356C1.96086 20.9106 2.46957 21.1213 3 21.1213H17C17.5304 21.1213 18.0391 20.9106 18.4142 20.5356C18.7893 20.1605 19 19.6518 19 19.1213V12.1213"
                              stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z"
                              stroke="#2672AB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>

                        <div style="margin-right: 10px;cursor: pointer;" (click)="printPO(data.id)">
                          <!-- <a target="_blank" [href]="data.po_doc"> -->
                          <svg width="20" height="20" viewBox="0 0 32 32" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M30 18V16H24V26H26V22H29V20H26V18H30Z" fill="#FF0000" />
                            <path
                              d="M19 26H15V16H19C19.7954 16.0008 20.558 16.3171 21.1204 16.8796C21.6829 17.442 21.9992 18.2046 22 19V23C21.9992 23.7954 21.6829 24.558 21.1204 25.1204C20.558 25.6829 19.7954 25.9992 19 26ZM17 24H19C19.2651 23.9997 19.5193 23.8943 19.7068 23.7068C19.8943 23.5193 19.9997 23.2651 20 23V19C19.9997 18.7349 19.8943 18.4807 19.7068 18.2932C19.5193 18.1057 19.2651 18.0003 19 18H17V24Z"
                              fill="#FF0000" />
                            <path
                              d="M11 16H6V26H8V23H11C11.5302 22.9992 12.0384 22.7882 12.4133 22.4133C12.7882 22.0384 12.9992 21.5302 13 21V18C12.9995 17.4697 12.7886 16.9613 12.4136 16.5864C12.0387 16.2114 11.5303 16.0005 11 16ZM8 21V18H11L11.001 21H8Z"
                              fill="#FF0000" />
                            <path
                              d="M22 14V9.99997C22.0035 9.86855 21.9785 9.73792 21.9268 9.61708C21.875 9.49624 21.7976 9.38806 21.7 9.29997L14.7 2.29997C14.612 2.20232 14.5038 2.12492 14.3829 2.07313C14.2621 2.02134 14.1314 1.99638 14 1.99997H4C3.47005 2.00156 2.96227 2.21278 2.58753 2.58751C2.2128 2.96224 2.00158 3.47003 2 3.99997V28C2 28.5304 2.21071 29.0391 2.58579 29.4142C2.96086 29.7893 3.46957 30 4 30H20V28H4V3.99997H12V9.99997C12.0016 10.5299 12.2128 11.0377 12.5875 11.4124C12.9623 11.7872 13.4701 11.9984 14 12H20V14H22ZM14 9.99997V4.39997L19.6 9.99997H14Z"
                              fill="#FF0000" />
                          </svg>
                          <!-- </a> -->
                        </div>

                      </div>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </nz-tab>
          <nz-tab nzTitle="Quotations">

            <div class="breadcrumbs-add-btn-bar">
              <div class="title-breadrumbs-container">
                <div class="title">Quotations</div>
              </div>

              <div class="doc-search-btn">
                <div class="icon-download" (click)="downloadCsvQuotations()">
                  <div style="margin-right: 10px;">
                    <svg height="16" style="margin-right: 10px;" viewBox="0 0 24 24" fill="#1e5799"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.9607 20.0457H4.00172V4.00367H10.9807V8.98367H15.9607V9.99967H18.0117V8.01367L15.9617 5.96167L14.0017 4.00367L12.0117 2.01367H4.01172C3.48278 2.01553 2.97618 2.22715 2.60309 2.60211C2.23001 2.97707 2.02092 3.48473 2.02172 4.01367L2.01172 20.0137C2.01092 20.5426 2.22001 21.0503 2.59309 21.4252C2.96618 21.8002 3.47278 22.0118 4.00172 22.0137H18.0117V20.0137L15.9607 20.0457Z" />
                      <path
                        d="M21.2617 18.0137L23.0117 12.0137H21.5117L20.5117 15.4437L19.5117 12.0137H18.0117L19.7617 18.0137H21.2617Z" />
                      <path
                        d="M10.0117 12.0137H7.01172C6.74675 12.0145 6.49285 12.1201 6.30549 12.3074C6.11812 12.4948 6.01251 12.7487 6.01172 13.0137V17.0137C6.01251 17.2786 6.11812 17.5325 6.30549 17.7199C6.49285 17.9073 6.74675 18.0129 7.01172 18.0137H10.0117C10.2767 18.0129 10.5306 17.9073 10.718 17.7199C10.9053 17.5325 11.0109 17.2786 11.0117 17.0137V16.0137H9.51172V16.5137H7.51172V13.5137H9.51172V14.0137H11.0117V13.0137C11.0109 12.7487 10.9053 12.4948 10.718 12.3074C10.5306 12.1201 10.2767 12.0145 10.0117 12.0137V12.0137Z" />
                      <path
                        d="M17.0117 13.5197V12.0137H13.0117C12.7465 12.0137 12.4921 12.119 12.3046 12.3066C12.1171 12.4941 12.0117 12.7485 12.0117 13.0137V14.7707C12.0117 15.0359 12.1171 15.2902 12.3046 15.4778C12.4921 15.6653 12.7465 15.7707 13.0117 15.7707H15.5217V16.5137H12.0117V18.0207H16.0117C16.2769 18.0207 16.5313 17.9153 16.7188 17.7278C16.9064 17.5402 17.0117 17.2859 17.0117 17.0207V15.2637C17.0117 14.9985 16.9064 14.7441 16.7188 14.5566C16.5313 14.369 16.2769 14.2637 16.0117 14.2637H13.5017V13.5207L17.0117 13.5197Z" />
                    </svg>
                  </div>
                  <div class="download-text">
                    Download CSV
                  </div>
                </div>

                <div class="search-box">
                  <nz-input-group [nzPrefix]="prefixIconSearch">
                    <input type="text" nz-input placeholder="Search..." [(ngModel)]="quotation_search"
                      (ngModelChange)="filterMethodQuotations()" />
                  </nz-input-group>

                  <ng-template #prefixIconSearch>
                    <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                      style="margin-right:5px;">
                      <path
                        d="M8.02439 15.0488C11.9039 15.0488 15.0488 11.9039 15.0488 8.02439C15.0488 4.14493 11.9039 1 8.02439 1C4.14493 1 1 4.14493 1 8.02439C1 11.9039 4.14493 15.0488 8.02439 15.0488Z"
                        stroke="#979797" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.0001 17L13.0977 13.0975" stroke="#979797" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </ng-template>
                </div>

                <button nz-button nzType="primary" (click)="newQuoteModal=true">New Quote</button>

                <nz-modal [nzWidth]="800" [(nzVisible)]="newQuoteModal" nzTitle="New Quote"
                  (nzOnCancel)="newQuoteModal=false;clearPreviousData()"
                  style="justify-content: center; align-items: center;">

                  <div nz-row [nzGutter]="[12,36]">
                    <div nz-col nzSpan="12">
                      <div class="input-group">
                        <div class="input-label">Date</div>
                        <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;"
                          [(ngModel)]="quotation_date"></nz-date-picker>
                      </div>

                      <div class="input-group">
                        <div class="input-label">Expiry Date</div>
                        <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;"
                          [(ngModel)]="quotation_expiry_date"></nz-date-picker>
                      </div>

                      <div class="input-group">
                        <div class="input-label">Price/Unit</div>
                        <nz-input-number [nzMin]="0" style="width: 100%;" [(ngModel)]="quote_price" (nzBlur)="calcGSTQuotePrice()" [nzStep]="100"></nz-input-number>
                      </div>

                      <div class="input-group">
                        <div class="input-label">GST</div>
                        <input nz-input placeholder="GST" [(ngModel)]="GSTQuotePrice" readonly />
                      </div>

                    </div>

                    <div nz-col nzSpan="12">

                      <div class="input-group">
                        <div class="input-label">Vendor Name</div>
                        <nz-select nzShowSearch [(ngModel)]="quotation_vendor_id" style="width: 100%;">
                          <nz-option *ngFor="let vendor of vendors" [nzValue]="vendor.id"
                            [nzLabel]="vendor.vendor_name">
                          </nz-option>
                        </nz-select>
                      </div>



                      <div class="input-group">
                        <div class="input-label">Vendor Ref No</div>
                        <input nz-input placeholder="Vendor Ref No" [(ngModel)]="ref_no" />
                      </div>

                      <div class="input-group">
                        <div class="input-label">Attach Document</div>
                        <nz-upload [nzAccept]="'application/pdf, image/*'" style="width: 100%;"
                          nzAction="https://bigfoot.reddotapps.com.sg/api/upload-quotation-doc"
                          (nzChange)="handleChangeQuatation($event)">
                          <button style="width: 100%;" nz-button>
                            <i nz-icon nzType="upload"></i>
                            Click to Upload
                          </button>
                        </nz-upload>
                      </div>

                    </div>
                  </div>

                  <button style="margin-top: 25px;min-height: auto;" nz-button nzType="primary"
                    (click)="newQuote()">SAVE</button>

                </nz-modal>

              </div>

            </div>

            <div class='stats__table'>
              <nz-table #sortTableQuotation [nzData]="listOfQuotations.data" nzTableLayout="fixed" nzShowSizeChanger
                [nzLoading]="isLoadingQuotation" [nzFrontPagination]="false" [nzTotal]="listOfQuotations.meta.total"
                [nzPageSize]="listOfQuotations.meta.per_page" [nzPageIndex]="listOfQuotations.meta.current_page"
                (nzQueryParams)="onQuotationsChange($event)">
                <thead>
                  <tr>
                    <th>Quote ID</th>
                    <th>Date</th>
                    <th>Vendor Name</th>
                    <th>Vendor Ref #</th>
                    <th>Cost/Unit</th>
                    <th>Expiry Date</th>
                    <th>Created By</th>
                    <th>Status</th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of sortTableQuotation.data">
                    <td>{{ data.id }}</td>
                    <td>{{ data.date | date: 'mediumDate' }}</td>
                    <td>{{ data.vendor_name }}</td>
                    <td>{{ data.ref_no }}</td>
                    <td>${{ data.price | number : '1.2-2'}}</td>
                    <td>{{ data.expiry_date | date: 'mediumDate' }}</td>
                    <td>{{data.created_by_name}}</td>
                    <td>{{ data.status }}</td>
                    <td>
                      <div style="display: flex;">
                        <div *ngIf="compareDates(data.expiry_date)" style="margin-right: 10px;cursor: pointer;"
                          (click)="newPurchaseOrderByQuote(data)">
                          <svg width="15" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6667 1L19.6667 9V25H1V1H11.6667Z" stroke="#004A7F" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11 1V8.33333H19.6667" stroke="#004A7F" stroke-linecap="round"
                              stroke-linejoin="round" />
                            <path d="M10.3333 11.6665V19.6665" stroke="#004A7F" stroke-width="2"
                              stroke-linecap="round" />
                            <path d="M6.33325 15.6665H14.3333" stroke="#004A7F" stroke-width="2"
                              stroke-linecap="round" />
                          </svg>
                        </div>
                        <a target="_blank" [href]="data.quotation_doc">
                          <svg width="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M30.9401 15.66C29.7639 12.6176 27.722 9.98662 25.0669 8.09209C22.4117 6.19756 19.2595 5.12257 16.0001 5C12.7406 5.12257 9.58845 6.19756 6.93326 8.09209C4.27808 9.98662 2.23622 12.6176 1.06006 15.66C0.98063 15.8797 0.98063 16.1203 1.06006 16.34C2.23622 19.3824 4.27808 22.0134 6.93326 23.9079C9.58845 25.8024 12.7406 26.8774 16.0001 27C19.2595 26.8774 22.4117 25.8024 25.0669 23.9079C27.722 22.0134 29.7639 19.3824 30.9401 16.34C31.0195 16.1203 31.0195 15.8797 30.9401 15.66ZM16.0001 25C10.7001 25 5.10006 21.07 3.07006 16C5.10006 10.93 10.7001 7 16.0001 7C21.3001 7 26.9001 10.93 28.9301 16C26.9001 21.07 21.3001 25 16.0001 25Z"
                              fill="#39424E" />
                            <path
                              d="M16 10C14.8133 10 13.6533 10.3519 12.6666 11.0112C11.6799 11.6705 10.9109 12.6075 10.4567 13.7039C10.0026 14.8003 9.88378 16.0067 10.1153 17.1705C10.3468 18.3344 10.9182 19.4035 11.7574 20.2426C12.5965 21.0818 13.6656 21.6532 14.8295 21.8847C15.9933 22.1162 17.1997 21.9974 18.2961 21.5433C19.3925 21.0892 20.3295 20.3201 20.9888 19.3334C21.6481 18.3467 22 17.1867 22 16C22 14.4087 21.3679 12.8826 20.2426 11.7574C19.1174 10.6321 17.5913 10 16 10ZM16 20C15.2089 20 14.4355 19.7654 13.7777 19.3259C13.1199 18.8864 12.6072 18.2616 12.3045 17.5307C12.0017 16.7998 11.9225 15.9956 12.0769 15.2196C12.2312 14.4437 12.6122 13.731 13.1716 13.1716C13.731 12.6122 14.4437 12.2312 15.2196 12.0769C15.9956 11.9225 16.7998 12.0017 17.5307 12.3045C18.2616 12.6072 18.8864 13.1199 19.3259 13.7777C19.7654 14.4355 20 15.2089 20 16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20Z"
                              fill="#39424E" />
                          </svg>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </nz-tab>
        </nz-tabset>
      </nz-card>
    </div>
  </div>
</div>


<nz-modal [nzWidth]="800" [(nzVisible)]="createNewPOModal" [nzTitle]="poTitle"
  (nzOnCancel)="createNewPOModal=false;clearPreviousData()" style="justify-content: center; align-items: center;">

  <ng-template #poTitle>
    <div class="d-flex align-items-end">
      Purchase Order
      <div *ngIf="last_purchase_date!=''" style="font-size:13px;margin-left: 10px">
        Last Purchase Date - {{last_purchase_date | date: 'mediumDate'}}
      </div>
    </div>
  </ng-template>

  <div nz-row [nzGutter]="[12,12]">

    <div nz-col nzSpan="8">
      <div class="label-text" style="margin-bottom: 5px;">Date</div>
      <div>
        <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;" [(ngModel)]="date"></nz-date-picker>
      </div>
    </div>

    <div nz-col nzSpan="8">
      <div class="label-text" style="margin-bottom: 5px;">Vendor Name</div>
      <div>
        <nz-select nzShowSearch nzAllowClear [(ngModel)]="vendor_id" style="width: 100%;"
          (ngModelChange)="filterQuotations($event)">
          <nz-option *ngFor="let vendor of vendors" [nzValue]="vendor.id" [nzLabel]="vendor.vendor_name">
          </nz-option>
        </nz-select>
      </div>

    </div>

    <div nz-col nzSpan="8">
      <div class="label-text" style="margin-bottom: 5px;">Quotation ID</div>
      <nz-select nzShowSearch (ngModelChange)="quoteSelected($event)" [(ngModel)]="quotation_id" style="width: 100%;"
        [disabled]="isPurchaseOrderEdit">
        <nz-option *ngFor="let quotation of quotations" [nzValue]="quotation.id" [nzLabel]="quotation.ref_no">
        </nz-option>
      </nz-select>
      <div>
      </div>
    </div>


    <div nz-col nzSpan="8">
      <div class="label-text" style="margin-bottom: 5px;">Product Name</div>
      <div>
        <nz-select nzShowSearch [(ngModel)]="product_id" style="width: 100%;" disabled>
          <nz-option *ngFor="let product of products" [nzValue]="product.id" [nzLabel]="product.product_name">
          </nz-option>
        </nz-select>
      </div>
    </div>

    <div nz-col nzSpan="8">
      <div class="label-text" style="margin-bottom: 5px;">Price</div>
      <div>
        <nz-input-number [nzMin]="0" style="width: 100%;" disabled [(ngModel)]="quote_price" (nzBlur)="calcGSTQuotePrice()" [nzStep]="1"></nz-input-number>
      </div>
    </div>

    <div nz-col nzSpan="8">
      <div class="label-text" style="margin-bottom: 5px;">GST</div>
      <div>
        <input nz-input placeholder="GST" [(ngModel)]="GSTQuotePrice" readonly />
      </div>
    </div>

    <div nz-col nzSpan="8">
      <div class="label-text" style="margin-bottom: 5px;">Qty Requested</div>
      <div>
        <nz-input-number [nzMin]="0" [nzPrecision]="0" style="width: 100%;" [(ngModel)]="quantity" [nzStep]="1"
          [disabled]="old_po_status==-1||old_po_status==0||old_po_status==1||old_po_status==2||old_po_status==3"></nz-input-number>
      </div>
    </div>

    <div nz-col nzSpan="24"> 
      <div class="label-text" style="margin-bottom: 5px;">Petty Cash Purchase</div>
      <div>
        <nz-switch [nzSize]="'small'" [(ngModel)]="petty_cash_purchase" [nzDisabled]="old_po_status==-1||old_po_status==0||old_po_status==1||old_po_status==2||old_po_status==3"></nz-switch>
      </div> 
    </div>

  </div>

  <div nz-row [nzGutter]="[12,12]">

    <div nz-col nzSpan="8" *ngIf="old_po_status>=0">
      <div class="label-text" style="margin-bottom: 5px;">Status</div>
      <div>
        <nz-select nzShowSearch [(ngModel)]="purchase_order_status" style="width: 100%;"
          [disabled]="old_po_status==1||old_po_status==2">
          <nz-option [nzValue]="0" [nzLabel]="'Sent'"></nz-option>
          <nz-option [nzValue]="1" [nzLabel]="'Stock Received'" [nzDisabled]="old_po_status==3"></nz-option>
          <nz-option [nzValue]="2" [nzLabel]="'Rejected'" [nzDisabled]="old_po_status==3"></nz-option>
        </nz-select>
      </div>
    </div>

  </div>

  <div style="display: flex;justify-content: space-between;align-items: center;flex-direction: row;margin-top: 25px;">
    <ng-template [ngIf]="old_po_status==3||old_po_status==-2||old_po_status==0" [ngIfElse]="none">
      <div>
        <button *ngIf="!isPurchaseOrderEdit" (click)="newPurchaseOrder()" style="margin-right: 15px;" nz-button
          nzType="primary">Generate PO</button>

        <button *ngIf="isPurchaseOrderEdit" (click)="newPurchaseOrder()" style="margin-right: 15px;" nz-button
          nzType="primary">Update PO</button>

        <button *ngIf="isPurchaseOrderEdit" (click)="deletePurchaseOrder()" nz-button nzType="default" nzDanger>Delete
          PO</button>
      </div>
    </ng-template>

    <ng-template #none>
      <div>
        <button *ngIf="![1,2].includes(old_po_status)" (click)="deletePurchaseOrder()" nz-button nzType="default"
          nzDanger>Delete PO</button>
      </div>
    </ng-template>

    <div
      style="font-family: Poppins; font-style: normal; font-weight: 500; font-size: 13px;color: #383838; margin-top: 15px;letter-spacing: 0.6px;">
      Total PO Amount ${{ quote_price*quantity | number : '1.2-2' }}
    </div>

  </div>

</nz-modal>