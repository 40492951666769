import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-all-invoice',
  templateUrl: './all-invoice.component.html',
  styleUrls: ['./all-invoice.component.css']
})
export class AllInvoiceComponent implements OnInit { 


  log(): void {
    console.log('click dropdown button');
  }

  isClicked:boolean=false;
  options: string[] = [
    'products',
    'purchase orders',
    'stock out',
    'dashboard',
    'master data',
    'transactions',
  ];

  listOfColumn = [
    {
      title: 'ID',
  
    },
    {
      title: 'VENDOR NAME',
    },
    {
      title: 'CONTACT',
    },
    {
      title: 'ADDRESS',
    },
    {
      title:'TOTAL BUSINESS'
    },
    {
      title:'CREATED ON'
    },
    {
      title:'CREATED BY'
    },
    {
      title:'CONTACT PERSON'
    }
  ];

  listOfData=[];

  listOfInvoices = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  isLoadingInvoices = false;

  openEditInvoiceModal = false;

  product_id = 0;
  product_name = '';
  product_image = '';
  make = '';
  model = '';
  category_id = 0;
  price = 0;
  location = '';
  total_purchasses = 0;
  status = 0;
  openPaymentModal=false;
  inputValue='';

  name='';
  type='';
  contact='';
  phone='';
  created_by='';
  credit_limit='';
  credit_term='';
  created_on='';
 
  email = '';

  isInvoiceEdit = false;

  invoice_search = '';
  sort_by_field = 'created_at';
  sort_by_type = 'desc';
  categories = [];

  selectedValue = null;
  openAddInvoiceModal=false;

  new_invoice_order_id = -1;
  invoice_date= null;
  due_date= null;
  order_type='';
  vehicle_number='';
  customer_id='';
  vehicle_id='';
  sub_total=0;
  tax_amount=0;
  total=0;
  attn_to='';
  due_amount='';
  amount_paid='';

  payment_date= new Date();
  payment_method = '';
  payment_amount = 0;
  payment_remarks = '';

  orders = [];
  invoice_id = 0;
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  page_index = 1;
  page_size = 10;
  filterDrawerVisible = false;

  product_filters = [
    {
      label: 'Invoice Id',
      key: 'invoices.id',
      type: 'number',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Order Type',
      key: 'orders.type',
      type: 'options',
      value: null,
      condition: "Equals",
      options: [
        {
          label: 'General Servicing',
          value: 0
        },{
          label: 'Accidental Claims',
          value: 1
        }
      ]
    },
    {
      label: 'Order ID',
      key: 'orders.id',
      type: 'number',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Vehicle',
      key: 'vehicle_number',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Customer Name',
      key: 'customer_name',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Invoice Date',
      key: 'invoice_date',
      type: 'date',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Due Date',
      key: 'due_date',
      type: 'date',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Due Amt',
      key: 'invoices.balance',
      type: 'number',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Amt Paid',
      key: 'amount_paid',
      type: 'number',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Status',
      key: 'invoices.status',
      type: "options",
      value: null,
      condition: "Equals",
      options: [{
          value: 'Paid',
          label: 'Paid'
        },
        {
          value: 'Partially Paid',
          label: 'Partially Paid'
        },
        {
          value: 'Unpaid',
          label: 'Unpaid'
        }
      ]
  }];
  
  constructor(private msg: NzMessageService, private themeService: ThemeConstantService) { }

  ngOnInit(): void {  
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);     
    this.filterMethodInvoices();
  }


  filterMethodInvoices() {
    this.isLoadingInvoices = true;

    var url = '/api/all-invoices';

    var data = {
      search: this.invoice_search,
      page_index: this.page_index,
      page_size: this.page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      this.listOfInvoices = response.data.all_invoices;
      this.orders = response.data.orders;
      this.isLoadingInvoices = false;
      this.filterDrawerVisible=false;
      this.clearPrevData();
    })
  }

  clearFilters() {
    this.product_filters.forEach(element => {
      element.value = null;
    });
    this.filterMethodInvoices();
  }

  onParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(item => item.value !== null);
    this.sort_by_field = (currentSort && currentSort.key) || null;
    this.sort_by_type = (currentSort && currentSort.value) || null;
    this.page_index = pageIndex;
    this.page_size = pageSize;
    this.filterMethodInvoices();
  }

  downloadCsvInvoices() {

    const { parse } = require('json2csv');
    // const Invoices = this.listOfInvoices.data;
    const fields = [{
      label: 'Invoice Id',
      value: (row:any) => row.id,
    }, {
      label: 'Order Type',
      value: (row:any) => row.type==0 ? "General Servicing" : row.type==1 ? "Accidental Claim" : '',
    }, {
      label: 'Order ID',
      value: (row:any) => row.order_id,
    }, {
      label: 'Vehicle #',
      value: (row:any) => row.vehicle_number,
    }, {
      label: 'Customer Name',
      value: (row:any) => row.customer_name,
    }, {
      label: 'Invoice Date',
      value: (row:any) => moment(row.invoice_date).format('DD/MM/YYYY'),
    }, {
      label: 'Due Date',
      value: (row:any) => moment(row.due_date).format('DD/MM/YYYY'),
    }, {
      label: 'Due Amt.',
      value: (row:any) => row.balance,
    }, {
      label: 'Status',
      value: (row:any) => row.balance==0 ? 'Paid' : row.balance==row.total ? 'Unpaid' : 'Partially Paid'
    }, {
      label: 'Amt. Paid',
      value: (row:any) => row.total - row.balance,
    }];

    var url = '/api/all-invoices';

    var data = {
      search: this.invoice_search,
      page_index: 1,
      page_size: this.listOfInvoices.meta.total,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      // this.listOfInvoices = response.data.all_invoices;
      let listOfInvoices = response.data.all_invoices;
      const Invoices = listOfInvoices.data;
      const csv = parse(Invoices, { fields });
    require("downloadjs")(csv, 'Invoices.csv');
    })
    // const csv = parse(Invoices, { fields });
    // require("downloadjs")(csv, 'Invoices.csv');
  }

  loadOrderDetail(e) {
    const orderIndex = this.orders.findIndex((el)=>el.id==e);
    const order = this.orders[orderIndex];
    this.vehicle_number = order.vehicle.vehicle_number;
    this.customer_id = order.vehicle.customer_id;
    this.vehicle_id = order.vehicle.id;
    this.sub_total = order.customer_quote.price;
    this.tax_amount = order.customer_quote.tax_amount;
    this.total = order.customer_quote.total;
  }

  newInvoice() {
    let url = '';

    if(this.invoice_date == null) {
      this.msg.error('Please select invoice date');
      return;
    }

    if(this.due_date == null) {
      this.msg.error('Please select due date');
      return;
    }

    if(this.isInvoiceEdit) {
      url = '/api/update-invoice';
    } else {
      url = '/api/new-invoice';
    }

    let data = {
      invoice_id: this.invoice_id,
      order_id: this.new_invoice_order_id,
      customer_id: this.customer_id,
      vehicle_id: this.vehicle_id,
      invoice_date: moment(this.invoice_date).format("YYYY-MM-DD"),
      due_date: moment(this.due_date).format("YYYY-MM-DD"),
      status: this.status,
      sub_total: this.sub_total,
      tax_amount: this.tax_amount,
      total: this.total,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodInvoices();
        this.openAddInvoiceModal = false;
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  newPayment() {
    let url = '/api/new-payment';

    let data = {
      invoice_id: this.invoice_id,
      payment_date: moment(this.payment_date).format("YYYY-MM-DD"),
      payment_method: this.payment_method,
      remarks: this.payment_remarks,
      amount: this.payment_amount,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodInvoices();
        this.openPaymentModal = false;
      } else {
        if(response.data.msg) {
          this.msg.error(response.data.msg);
        } else {
          this.msg.error("Something Went Wrong!");
        }
      }
    })
  }

  editInvoice(data) {
    this.invoice_id = data.id;
    this.new_invoice_order_id = data.order_id;
    this.invoice_date = data.invoice_date;
    this.due_date = data.due_date;
    this.vehicle_number = data.vehicle_number;
    this.customer_id = data.customer_id;
    this.vehicle_id = data.vehicle_id;

    this.isInvoiceEdit = true;
    this.openAddInvoiceModal=true;
  }

  deleteInvoice(data) {
    var url = '/api/delete-invoice';

    var data2 = {
       invoice_id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodInvoices();
        this.msg.success("Invoice deleted successfully!")
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  handleChange(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        // Component will show file.url as link
        this.product_image = info.file.response.url;
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  printPDF(id) {
    var url = '/api/generate-invoice';

    var data = {
       invoice_id: id,
    };

    axios.post(url, data, {}).then(response => {
      const printJS = require('print-js')
      printJS({ printable: response.data.url})
    })
  }

  downloadPDF(id) {
    var url = '/api/generate-invoice';

    var data = {
       invoice_id: id,
    };

    axios.post(url, data, {}).then(response => {
      var a = document.createElement('a')
      a.href = response.data.url
      a.download = "Invoice.pdf"
      a.click()
      a.remove()
    })
  }

  clearPrevData() {
    this.new_invoice_order_id = -1;
    this.invoice_date= null;
    this.due_date= null;
    this.order_type='';
    this.vehicle_number='';
    this.customer_id='';
    this.vehicle_id='';
    this.sub_total=0;
    this.tax_amount=0;
    this.total=0;
    this.attn_to='';
    this.due_amount='';
    this.amount_paid='';
    this.payment_date= new Date();
    this.payment_method = '';
    this.payment_amount = 0;
    this.payment_remarks = '';
    this.isInvoiceEdit = false;
  }

}
