import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-customer-single',
  templateUrl: './customer-single.component.html',
  styleUrls: ['./customer-single.component.css']
})
export class CustomerSingleComponent implements OnInit {

  isClicked:boolean=false;
  options: string[] = [
    'products',
    'purchase orders',
    'stock out',
    'dashboard',
    'master data',
    'transactions',
  ];

  createNewQuote = false;
  editPOModal=false;
  activeTab = 0;

  po_sort_by_field = 'vendor_name';
  po_sort_by_type = 'desc';

  createNewPOModal = false;
  listOfPurchaseOrders = [];

  vendor_id = 0;
  product_id = 0;
  quotation_id = 0;
  price = 0;
  quantity = 0;

  vendors = [];
  quotations = [];
  products = [];
  vehicle_type="";


  openAddCustomerModal = false;

  isCustomerEdit = true;
  isLoadingCustomers = false;

  age='';
  

  isLoadingPriceList = false;
  listOfPriceList = [];
  pl_sort_by_field = 'created_at';
  pl_sort_by_type = 'desc';

  listOfTransactions = [];
  isLoadingTransactions = false;
  transactions_sort_by_field = 'created_at';
  transactions_sort_by_type = 'desc';

  customer_id = 0;
  customer_name='';
  contact='';
  phone='';
  credit_term='';
  credit_limit= null;
  created_on='';
  created_by='';
  email='';
  type='';
  customer_portal_access = false;

  vehicle_id = 0;
  vehicle_number = '';
  category = '';
  selected_vehicle_department = '';
  make = '';
  model = '';
  mfg_date = new Date();
  last_serviced_on = '';
  order_id = -1;

  customer_vehicles = [];
  customer_payments = [];
  isVehicleEdit = false;
  addNewVehicleModal = false;
  viewVehicleModal = false;

  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  truckNo = "Truck";
  engineCapacityOrPowerRating = "";
  identificationNo = "";
  vehicleMake="";
  maxLadenWeight = "";
  propellant = "";
  reeferModel = "";
  COENo = "";
  COECategory = "";
  vehicleLifeSpanExpDate = null;
  isGPSEnabled = true;
  maxSpeed = null;
  status = "";
  accessories = [];
  IULabel = null;
  engineNo = "";
  chassisNo = "";
  vehicleModel = "";
  unLadenWeight = "";
  yearOfManufacture = null;
  reeferMeasurement = "";
  effectiveDateOfOwnership = null;
  COEPrice = "";
  COEExpiryDate = null;
  tonnageCapacity = null;
  owner = "";
  isSeasonParking = true;
  boxSize = "";
  parkingPlaces = "";
  isGPS = "";
  isFM = false;
  isTailgat = false;
  iu_number = '';
  is_vehicle_on_lease = false;
  lease_start_date = null;
  lease_end_date = null;
  insurance_company = '';
  insurance_start_date = null;
  insurance_expiry_date = null;
  road_tax_expiry_date = null;
  vpc_start_date = null;
  vpc_expiry_date = null;
  inspection_date = null;
  logcard = '';
  logcards = [];

  tailgate = false;
  tailgate_price = 0;
  tailgate_lifespan = 0;
  warranty_of_tailgate = null;
  model_number_of_tailgate = '';
  price_of_vehicle = 0;
  box_price = '';
  refrigerator = false;
  manufacturer_of_refrigerator = '';
  documents_attachments = [];

  is_scrapped = false;

  vehicle_search = '';
  payment_search = '';

  invoice_id = 0;
  payment_date= new Date();
  payment_method = '';
  payment_amount = 0;
  payment_remarks = '';
  openPaymentModal = false;
  payment_id = 0;
  isPaymentEdit = false;

  booking_search = '';
  listOfBookings = [];

  service_booking_vehicle_id = 0;
  service_booking_make = '';
  service_booking_model = '';
  service_booking_due_date = null;
  service_booking_due_mileage = '';
  service_booking_id = 0;
  service_booking_address = 'BigFoot';
  isBookingEdit = false;
  openBookingModal = false; 

  is_recurring_booking = false;
  recurring_in = 30;

  last_recurring_date = moment().add(5, 'years').toDate();

  vehicle_categories = [];
  vehicle_departments = [];

  listOfVehicleDepartments = [];
  isLoadingVehicleDepartments = false;
  openAddVehicleDepartmentsModal = false;
  isVehicleDepartmentsEdit = false;

  vehicle_department = {
    id: 0,
    name: '',
    pic_name: '',
    pic_phone: '',
    pic_email: '',
    status: 1,
    customer_id: 0,
  }

  search = '';

  viewVehicleDepartmentModal = false;
  vehicle_refills = [];

  show_scrapped = false;





  constructor(private msg: NzMessageService, private route: ActivatedRoute, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    
    this.route.queryParams
      .subscribe(params => {
        this.customer_id = params.customer_id;
        if(params.vehicle_number) {
          this.vehicle_search = params.vehicle_number;
        }
        this.getCustomerSingle();
        this.getVehicles();
        this.getPayments();
        this.getBookings();
        this.getVehicleCategories();
        this.getVehicleDepartments();
      }
    );
  }

  getVehicles() {
    var url = '/api/customer-vehicles';

    var data = {
      customer_id: this.customer_id,
      search: this.vehicle_search,
      show_scrapped: this.show_scrapped,
    };

    axios.post(url, data, {}).then(response => {
      this.customer_vehicles = response.data.customer_vehicles;
    })
  }

  getVehicleCategories() {
    var url = '/api/vehicle-categories';

    axios.post(url, {}).then(response => {
      this.vehicle_categories = response.data.vehicle_categories;
    })
  }

  deleteVehicle(data) {
    let data2 = {
      vehicle_id: data.id,
    };

    let url = '/api/delete-vehicle'; 

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.getVehicles()
        this.msg.success("Vehicle Deleted Successfully!");
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  getCustomerSingle() {
    this.isLoadingCustomers = true;

    var url = '/api/get-customer-single';

    var data = {
      customer_id: this.customer_id
    };

    axios.post(url, data, {}).then(response => {
      this.customer_id = response.data.id;
      this.customer_name = response.data.customer_name;
      this.type = response.data.type;
      this.contact = response.data.contact;
      this.phone = response.data.phone;
      this.email = response.data.email;
      this.credit_term = response.data.credit_term;
      this.credit_limit = response.data.credit_limit;
      this.created_on = response.data.created_on;
      this.created_by = response.data.created_by;
    })
  }

  newCustomer() {
    if(this.customer_name=='') {
      this.msg.error("Please enter customer name!");
      return false;
    }

    if(this.type=='') {
      this.msg.error("Please enter type!");
      return false;
    }
    
    if(this.contact=='') {
      this.msg.error("Please enter contact name!");
      return false;
    }

    // if(!this.contact.match(/^[A-Za-z]+$/)) {
    //   this.msg.error("Please enter only alphabetic characters!");
    //   return false;
    // }


    if(this.phone=='') {
      this.msg.error("Please enter phone!");
      return false;
    }

    if(!this.phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
      this.msg.error("Please enter valid singapore mobile number!");
      return false;
    }

    if(this.email=='') {
      this.msg.error("Please enter email!");
      return false;
    }

    if(this.email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )==null) {
      this.msg.error('Please enter a valid email');
      return;
    }

    if(this.credit_limit==null) {
      this.msg.error("Please enter credit limit!");
      return false;
    }

    if(this.credit_term=='') {
      this.msg.error("Please enter credit term!");
      return false;
    }

    let url = '';

    if(this.isCustomerEdit) {
      url = '/api/update-customer';
    } else {
      url = '/api/new-customer';
    }

    let data = {
      customer_id: this.customer_id,
      customer_name: this.customer_name,
      type: this.type,
      contact: this.contact,
      phone: this.phone,
      email: this.email,
      credit_limit: this.credit_limit,
      credit_term: this.credit_term,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.openAddCustomerModal = false;
        this.getCustomerSingle()
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  newVehicle() {

    if(this.vehicle_number=='') {
      this.msg.error("Please enter vehicle number!");
      return false;
    }

    if(this.category=='') {
      this.msg.error("Please enter category!");
      return false;
    }
   
    // if(this.make=='') {
    //   this.msg.error("Please enter make!");
    //   return false;
    // }


    if(this.model=='') {
      this.msg.error("Please enter model!");
      return false;
    }

    // if(this.model==this.make) {
    //   this.msg.error("Make and model can't be same!");
    //   return false;
    // }
  
    // if(!this.vehicle_number.match(/^[A-Za-z]{3}[\d]{4}[A-Za-z]{1}$/)) {
    //   this.msg.error("Please enter valid vehicle number!");
    //   return false;
    // }

    let url = '';

    if(this.isVehicleEdit) {
      url = '/api/update-vehicle';
    } else {
      url = '/api/new-vehicle';
    }

    let data = {
      customer_id: this.customer_id,
      vehicle_id: this.vehicle_id,
      vehicle_number: this.vehicle_number,
      category: this.category,
      vehicle_department: this.selected_vehicle_department,
      make: this.make,
      model: this.model,
      truckNo: this.truckNo,
      vehicle_type: this.vehicle_type,
      engineCapacityOrPowerRating: this.engineCapacityOrPowerRating,
      identificationNo: this.identificationNo,
      vehicleMake: this.vehicleMake,
      maxLadenWeight: this.maxLadenWeight,
      propellant: this.propellant,
      reeferModel: this.reeferModel,
      COENo: this.COENo,
      COECategory: this.COECategory,
      vehicleLifeSpanExpDate: this.vehicleLifeSpanExpDate ? moment(this.vehicleLifeSpanExpDate).format("YYYY-MM-DD"): null,
      isGPSEnabled: this.isGPSEnabled,
      maxSpeed: this.maxSpeed,
      status: this.status,
      IULabel: this.IULabel,
      engineNo: this.engineNo,
      chassisNo: this.chassisNo,
      vehicleModel: this.vehicleModel,
      unLadenWeight: this.unLadenWeight,
      yearOfManufacture: this.yearOfManufacture ? moment(this.yearOfManufacture).format("YYYY-MM-DD"): null,
      reeferMeasurement: this.reeferMeasurement,
      effectiveDateOfOwnership: this.effectiveDateOfOwnership,
      COEPrice: this.COEPrice,
      COEExpiryDate: this.COEExpiryDate ? moment(this.COEExpiryDate).format("YYYY-MM-DD"): null,
      tonnageCapacity: this.tonnageCapacity,
      owner: this.owner,
      isSeasonParking: this.isSeasonParking,
      boxSize: this.boxSize,
      parkingPlaces: this.parkingPlaces,
      isGPS: this.isGPS,
      isFM: this.isFM ? 'true' : 'false',
      isTailgat: this.isTailgat ? 'true' : 'false',
      mfg_date: moment(this.mfg_date).format("YYYY-MM-DD"),
      iu_number: this.iu_number,
      is_vehicle_on_lease: this.is_vehicle_on_lease ? 1 : 0,
      lease_start_date: this.lease_start_date ? moment(this.lease_start_date).format("YYYY-MM-DD"): null,
      lease_end_date: this.lease_end_date ? moment(this.lease_end_date).format("YYYY-MM-DD"): null,
      insurance_company: this.insurance_company,
      insurance_start_date: this.insurance_start_date ? moment(this.insurance_start_date).format("YYYY-MM-DD"): null,
      insurance_expiry_date: this.insurance_expiry_date ? moment(this.insurance_expiry_date).format("YYYY-MM-DD"): null,
      road_tax_expiry_date: this.road_tax_expiry_date ? moment(this.road_tax_expiry_date).format("YYYY-MM-DD"): null,
      vpc_start_date: this.vpc_start_date ? moment(this.vpc_start_date).format("YYYY-MM-DD"): null,
      vpc_expiry_date: this.vpc_expiry_date ? moment(this.vpc_expiry_date).format("YYYY-MM-DD"): null,
      inspection_date: this.inspection_date ? moment(this.inspection_date).format("YYYY-MM-DD"): null,
      logcard: this.logcard,
      logcards: JSON.stringify(this.logcards),
      is_scrapped: this.is_scrapped ? 1 : 0,

      tailgate: this.tailgate ? 1 : 0,
      tailgate_price: this.tailgate_price,
      tailgate_lifespan: this.tailgate_lifespan,
      warranty_of_tailgate: this.warranty_of_tailgate ? moment(this.warranty_of_tailgate).format("YYYY-MM-DD"): null,
      model_number_of_tailgate: this.model_number_of_tailgate,
      price_of_vehicle: this.price_of_vehicle,
      box_price: this.box_price,
      refrigerator: this.refrigerator ? 1 : 0,
      manufacturer_of_refrigerator: this.manufacturer_of_refrigerator,
      documents_attachments: JSON.stringify(this.documents_attachments),
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.getVehicles()
        this.addNewVehicleModal = false;
      } else {
          this.msg.error(response.data.message);
      }
    })
  }

  editVehicle(data, edit=false) {

    this.vehicle_id = data.id
    this.vehicle_number = data.vehicle_number
    this.category = data.category
    this.selected_vehicle_department = data.vehicle_department ? data.vehicle_department : ''
    this.make = data.make
    this.model = data.model
    this.mfg_date = data.mfg_date
    this.truckNo = data.truckNo
    this.vehicle_type = data.vehicle_type
    this.engineCapacityOrPowerRating = data.engineCapacityOrPowerRating
    this.identificationNo = data.identificationNo
    this.vehicleMake = data.vehicleMake
    this.maxLadenWeight = data.maxLadenWeight
    this.propellant = data.propellant
    this.reeferModel = data.reeferModel
    this.COENo = data.COENo
    this.COECategory = data.COECategory
    this.vehicleLifeSpanExpDate = data.vehicleLifeSpanExpDate ? new Date(data.vehicleLifeSpanExpDate) : null
    this.isGPSEnabled = data.isGPSEnabled ? true : false
    this.maxSpeed = data.maxSpeed
    this.status = data.status
    this.IULabel = data.IULabel
    this.engineNo = data.engineNo
    this.chassisNo = data.chassisNo
    this.vehicleModel = data.vehicleModel
    this.unLadenWeight = data.unLadenWeight
    this.yearOfManufacture = data.yearOfManufacture ? new Date(data.yearOfManufacture) : null,
    this.reeferMeasurement = data.reeferMeasurement
    this.effectiveDateOfOwnership = data.effectiveDateOfOwnership
    this.COEPrice = data.COEPrice
    this.COEExpiryDate = data.COEExpiryDate ? new Date(data.COEExpiryDate) : null
    this.tonnageCapacity = data.tonnageCapacity
    this.owner = data.owner
    this.isSeasonParking = data.isSeasonParking
    this.boxSize = data.boxSize
    this.parkingPlaces = data.parkingPlaces
    this.isGPS = data.isGPS;
    this.isFM = data.isFM=='true' ? true : false
    this.isTailgat = data.isTailgat=='true' ? true : false
    this.mfg_date = data.mfg_date ? new Date(data.mfg_date) : null
    this.iu_number = data.iu_number
    this.is_vehicle_on_lease = data.is_vehicle_on_lease ? true : false
    this.lease_start_date = data.lease_start_date ? new Date(data.lease_start_date) : null
    this.lease_end_date = data.lease_end_date ? new Date(data.lease_end_date) : null
    this.insurance_company = data.insurance_company
    this.insurance_start_date = data.insurance_start_date ? new Date(data.insurance_start_date) : null
    this.insurance_expiry_date = data.insurance_expiry_date ? new Date(data.insurance_expiry_date) : null
    this.road_tax_expiry_date = data.road_tax_expiry_date ? new Date(data.road_tax_expiry_date) : null
    this.vpc_start_date = data.vpc_start_date ? new Date(data.vpc_start_date) : null
    this.vpc_expiry_date = data.vpc_expiry_date ? new Date(data.vpc_expiry_date) : null
    this.inspection_date = data.inspection_date ? new Date(data.inspection_date) : null
    this.logcard = data.logcard;
    this.logcards = [];

    this.is_scrapped = data.is_scrapped ? true : false;

    if(data.logcards) {
      this.logcards = JSON.parse(data.logcards);
    }

    this.tailgate = data.tailgate ? true : false;
    this.tailgate_price = data.tailgate_price;
    this.tailgate_lifespan = data.tailgate_lifespan;
    this.warranty_of_tailgate = data.warranty_of_tailgate ? new Date(data.warranty_of_tailgate) : null;
    this.model_number_of_tailgate = data.model_number_of_tailgate;
    this.price_of_vehicle = data.price_of_vehicle;
    this.box_price = data.box_price;
    this.refrigerator = data.refrigerator ? true : false;
    this.manufacturer_of_refrigerator = data.manufacturer_of_refrigerator;
    this.documents_attachments = [];

    if(data.documents_attachments) {
      this.documents_attachments = JSON.parse(data.documents_attachments);
    }

    if(this.logcard) {
      const result = this.logcards.filter(logcard => logcard.url == this.logcard);
      if(result.length == 0) {
        this.logcards = [...this.logcards, {
          url: this.logcard,
          name: this.logcard,
        }]
      }
    }

    if(edit) {
      this.isVehicleEdit = true;
      this.addNewVehicleModal=true;
    } else {
      this.viewVehicleModal = true;
      this.getVehicleRefills(data.id)
    }
  }

  getPayments() {
    var url = '/api/customer-payments';

    var data = {
      customer_id: this.customer_id,
      search: this.payment_search
    };

    axios.post(url, data, {}).then(response => {
      this.customer_payments = response.data.customer_payments;
    })
  }

  newPayment() {
    let url = '';

    if(!this.isPaymentEdit) {
      url = '/api/new-payment';
    } else {
      url = '/api/update-payment';
    }

    let data = {
      payment_id: this.payment_id,
      invoice_id: this.invoice_id,
      payment_date: moment(this.payment_date).format("YYYY-MM-DD"),
      payment_method: this.payment_method,
      remarks: this.payment_remarks,
      amount: this.payment_amount,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.openPaymentModal = false;
        this.getPayments();
        this.getCustomerSingle();
      } else {
        if(response.data.msg) {
          this.msg.error(response.data.msg);
        } else {
          this.msg.error("Something Went Wrong!");
        }
      }
    })
  }

  editPayment(data) {
    this.payment_id = data.id;
    this.invoice_id = data.invoice_id;
    this.payment_date= new Date(data.payment_date);
    this.payment_method = data.payment_method;
    this.payment_amount = Number(data.amount);
    this.payment_remarks = data.remarks;
    this.isPaymentEdit = true;
    this.openPaymentModal=true;
  }

  deletePayment(data) {
    let data2 = {
      payment_id: data.id,
    };

    let url = '/api/delete-payment'; 

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.getPayments()
        this.msg.success("Payment Deleted Successfully!");
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  getBookings() {
    var url = '/api/all-service-bookings';

    var data = {
      customer_id: this.customer_id,
      search: this.booking_search
    };

    axios.post(url, data, {}).then(response => {
      this.listOfBookings = response.data.service_bookings;
    })
  }

  newBooking() {

    if(this.service_booking_due_date==null) {
      this.msg.error("Please Select Due Date!");
      return;
    }

    if(this.service_booking_due_mileage=='') {
      this.msg.error("Please Enter Due Mileage!");
      return;
    }

    if(this.service_booking_vehicle_id==0) {
      this.msg.error("Please Select Vehicle!");
      return;
    }

    if(this.service_booking_address=='') {
      this.msg.error("Please Enter Company!");
      return;
    }

    let url: string;

    if(!this.isBookingEdit) {
      url = '/api/new-service-booking';
    } else {
      url = '/api/update-service-booking';
    }

    let data = {
      service_booking_id: this.service_booking_id,
      due_date: moment(this.service_booking_due_date).format("YYYY-MM-DD"),
      due_mileage: this.service_booking_due_mileage,
      vehicle_id: this.service_booking_vehicle_id,
      customer_id: this.customer_id,
      is_recurring_booking: this.is_recurring_booking,
      recurring_in: this.recurring_in,
      last_recurring_date: moment(this.last_recurring_date).format("YYYY-MM-DD"),
      service_booking_address: this.service_booking_address,
    }

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.openBookingModal = false;
        this.getBookings();
        this.getCustomerSingle();
        this.clearPreviousData();
      } else {
        if(response.data.message) {
          this.msg.error(response.data.message);
        } else {
          this.msg.error("Something Went Wrong!");
        }
      }
    });
  }

  editBooking(data: any) {
    this.service_booking_id = data.id;
    this.service_booking_due_date = new Date(data.due_date);
    this.service_booking_due_mileage = data.due_mileage;
    this.service_booking_vehicle_id = data.vehicle_id;
    this.service_booking_make = data.make;
    this.service_booking_model = data.model;
    this.is_recurring_booking = data.is_recurring_booking == 1 ? true : false;
    this.recurring_in = data.recurring_in;
    this.last_recurring_date = new Date(data.last_recurring_date);
    this.service_booking_address = data.service_booking_address;
    this.isBookingEdit = true;
    this.openBookingModal=true;
  }

  deleteBooking(service_booking_id: number) {
    let data = {
      service_booking_id: service_booking_id,
    };

    let url = '/api/delete-service-booking';

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.getBookings()
        this.msg.success("Booking Deleted Successfully!");
      } else {
        if(response.data.message) {
          this.msg.error(response.data.message);
        } else {
          this.msg.error("Something Went Wrong!");
        }
      }
    })
  }

  bookingVehicleChanged(vehicle_id: number) {
    this.service_booking_vehicle_id = vehicle_id;
    const vehicle = this.customer_vehicles.find(vehicle => vehicle.id == vehicle_id);
    this.service_booking_make = vehicle.make;
    this.service_booking_model = vehicle.model;
  }


  getVehicleDepartments() {
    this.isLoadingVehicleDepartments = true;

    const data = {
      search: this.search,
      customer_id: this.customer_id,
    }

    axios.post('/api/vehicle-departments', data)
    .then(res => {
      this.isLoadingVehicleDepartments = false;
      this.listOfVehicleDepartments = res.data.vehicle_departments;
    })
    .catch(err => {
      this.isLoadingVehicleDepartments = false;
      console.log(err);
    })
  }

  downloadCsvVehicleDepartments() {

  }

  newVehicleDepartments() {
    if(this.vehicle_department.name == '') {
      this.msg.error('Please enter vehicle department name');
      return;
    }

    if(this.vehicle_department.pic_name == '') {
      this.msg.error('Please enter vehicle department pic name');
      return;
    }

    if(this.vehicle_department.pic_phone == '') {
      this.msg.error('Please enter vehicle department PIC phone');
      return;
    }

    if(this.vehicle_department.pic_email == '') {
      this.msg.error('Please enter vehicle department PIC email');
      return;
    }

    if(this.vehicle_department.pic_email == null) {
      this.msg.error('Please enter vehicle department PIC email');
      return;
    }

    if(this.vehicle_department.pic_email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )==null) {
      this.msg.error('Please enter a valid vehicle department PIC email');
      return;
    }

    const data = {
      id: this.vehicle_department.id,
      name: this.vehicle_department.name,
      pic_name: this.vehicle_department.pic_name,
      pic_phone: this.vehicle_department.pic_phone,
      pic_email: this.vehicle_department.pic_email,
      customer_id: this.customer_id,
      status: this.vehicle_department.status,
    }

    let url = '';

    if(this.isVehicleDepartmentsEdit) {
      url = '/api/update-vehicle-department';
    } else {
      url = '/api/new-vehicle-department';
    }

    axios.post(url, data).then(res => {
      if(res.data.success) {
        this.msg.success('Vehicle department added successfully');
        this.getVehicleDepartments();
        this.openAddVehicleDepartmentsModal = false;
        this.clearPreviousData();
      } else {
        this.msg.error(res.data.message);
      }
    }).catch(err => {
      console.log(err);
    });

  }

  editVehicleDepartment(data:any) {
    this.vehicle_department = {
      id: data.id,
      name: data.name,
      pic_name: data.pic_name,
      pic_phone: data.pic_phone,
      pic_email: data.pic_email,
      status: data.status,
      customer_id: this.customer_id
    };

    this.isVehicleDepartmentsEdit = true;
    this.openAddVehicleDepartmentsModal = true;
  }

  viewVehicleDepartment(name:string) {
    const lowerCaseName = name.toLowerCase();

    const vehicle_department = this.listOfVehicleDepartments.find(vehicle_department => vehicle_department.name.toLowerCase() == lowerCaseName);
    
    this.vehicle_department = {
      id: vehicle_department.id,
      name: vehicle_department.name,
      pic_name: vehicle_department.pic_name,
      pic_phone: vehicle_department.pic_phone,
      pic_email: vehicle_department.pic_email,
      status: vehicle_department.status,
      customer_id: this.customer_id
    };
  }

  deleteVehicleDepartment(vehicle_department_id: any) {
    const data = {
      id: vehicle_department_id,
    }

    axios.post('/api/delete-vehicle-department', data).then(res => {
      if(res.data.success) {
        this.msg.success('Vehicle department deleted successfully');
        this.getVehicleDepartments();
      } else {
        this.msg.error(res.data.message);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  vehicleLeaseChanged(e) {
    if(!e) {
      this.lease_start_date = null;
      this.lease_end_date = null;
    }
  }

  getVehicleRefills(vehicle_id) {
    const data = {
      vehicle_id: vehicle_id
    }

    axios.post('/api/get-vehicle-refills', data)
    .then(res => {
      this.vehicle_refills = res.data.vehicle_refills;
    })
    .catch(err => {
      console.log(err);
    })
  }

  handleChange(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        this.logcards = [...this.logcards, {name: info.file.response.url, url: info.file.response.url}];
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  handleChangeVehicleDocs(info: NzUploadChangeParam) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (info.file.response) {
        this.documents_attachments = [...this.documents_attachments, {name: info.file.response.url, url: info.file.response.url}];
      }
      this.msg.success(`File uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`File upload failed.`);
    }
  }

  clearPreviousData() {
    this.vehicle_id = 0;
    this.vehicle_number = '';
    this.category = '';
    this.selected_vehicle_department = '';
    this.make = '';
    this.model = '';
    this.mfg_date = new Date();
    this.last_serviced_on = '';
    this.order_id = -1;
    this.truckNo = "Truck";
    this.engineCapacityOrPowerRating = "";
    this.identificationNo = "";
    this.vehicleMake="";
    this.maxLadenWeight = "";
    this.propellant = "";
    this.reeferModel = "";
    this.COENo = "";
    this.COECategory = "";
    this.vehicleLifeSpanExpDate = null;
    this.isGPSEnabled = true;
    this.maxSpeed = null;
    this.status = "";
    this.IULabel = null;
    this.engineNo = "";
    this.chassisNo = "";
    this.vehicleModel = "";
    this.unLadenWeight = "";
    this.yearOfManufacture = null;
    this.reeferMeasurement = "";
    this.effectiveDateOfOwnership = null;
    this.COEPrice = "";
    this.COEExpiryDate = null;
    this.tonnageCapacity = null;
    this.owner = "";
    this.isSeasonParking = true;
    this.boxSize = "";
    this.parkingPlaces = "";
    this.isGPS = "";
    this.isFM = false;
    this.isTailgat = false;
    this.iu_number = "";
    this.is_vehicle_on_lease = false;
    this.lease_start_date = null;
    this.lease_end_date = null;
    this.insurance_company = "";
    this.insurance_start_date = null;
    this.insurance_expiry_date = null;
    this.road_tax_expiry_date = null;
    this.vpc_start_date = null;
    this.vpc_expiry_date = null;
    this.inspection_date = null;
    this.logcard = '';
    this.logcards = [];
    this.vehicle_refills = [];
    this.is_scrapped = false;
    this.tailgate = false;
    this.tailgate_price = 0;
    this.tailgate_lifespan = 0;
    this.warranty_of_tailgate = null;
    this.model_number_of_tailgate = '';
    this.price_of_vehicle = 0;
    this.box_price = '';
    this.refrigerator = false;
    this.manufacturer_of_refrigerator = '';
    this.documents_attachments = [];

    this.isVehicleEdit = false;
    this.payment_id = 0;
    this.invoice_id = 0;
    this.payment_date= new Date();
    this.payment_method = '';
    this.payment_amount = 0;
    this.payment_remarks = '';
    this.openPaymentModal = false;
    this.isPaymentEdit = false;
    this.service_booking_id = 0;
    this.service_booking_due_date =  null;
    this.service_booking_due_mileage = '';
    this.service_booking_vehicle_id = 0;
    this.service_booking_address = '';
    this.openBookingModal = false;
    this.isBookingEdit = false;
    this.service_booking_make = '';
    this.service_booking_model = '';
    this.is_recurring_booking = false;
    this.recurring_in = 30;

    this.vehicle_department = {
      id: 0,
      name: '',
      pic_name: '',
      pic_phone: '',
      pic_email: '',
      status: 1,
      customer_id: this.customer_id
    }
    this.isVehicleDepartmentsEdit = false;
    
  }
 
}
