import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { ThemeConstantService } from '../services/theme-constant.service';
import moment from 'moment';


@Component({
  selector: 'app-all-customers',
  templateUrl: './all-customers.component.html',
  styleUrls: ['./all-customers.component.css']
})
export class AllCustomersComponent implements OnInit {

  isClicked:boolean=false;
  options: string[] = [
    'products',
    'purchase orders',
    'stock out',
    'dashboard',
    'master data',
    'transactions',
  ];


  listOfData = [];

  listOfCustomers = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  isLoadingCustomers = false;

  openAddCustomerModal = false;

  customer_id = 0;
  customer_name='';
  type='';
  contact='';
  uen_number = '';
  phone='';
  email = '';
  credit_limit=null;
  credit_term=0;
  customer_portal_access = false;

  isCustomerEdit = false;

  customer_search = '';
  sort_by_field = 'created_at';
  sort_by_type = 'desc';
  categories = [];
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  page_index = 1;
  page_size = 10;
  filterDrawerVisible = false;
  isLoadingCustomer = false;


  product_filters = [
    {
      label: 'Id',
      key: 'customers.id',
      type: 'number',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Customer Name',
      key: 'customer_name',
      type: 'string',
      value: null,
      condition: "Contains",
    },
    {
      label: 'Type',
      key: 'customers.type',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Contact',
      key: 'customers.contact',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'UEN Number',
      key: 'customers.uen_number',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Phone',
      key: 'customers.phone',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Email',
      key: 'customers.email',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Credit Term',
      key: 'customers.credit_term',
      type: 'string',
      value: null,
      condition: "Contains"
    },
    {
      label: 'Credit Limit',
      key: 'customers.credit_limit',
      type: 'number',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Created On',
      key: 'customers.created_on',
      type: 'date',
      value: null,
      condition: "Equals"
    },
    {
      label: 'Created By',
      key: 'users.name',
      type: 'string',
      value: null,
      condition: "Contains"
    }
  ];

  intro = {
    total_customers: 0,
    total_vehicles: 0,
    total_departments: 0,
    upcoming_bookings: 0
  }

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);     
    this.filterMethodCustomers();
    this.getDashboardCustomer();
  }

  getDashboardCustomer() {
    let url = '/api/get-dashboard-customer';

    let data = {
        customer_id: this.customer_id || 0,
    };

    axios.post(url, data, {}).then(response => {
        this.intro = response.data.intro;
    })
  }

  filterMethodCustomers() {
    this.isLoadingCustomers = true;

    var url = '/api/all-customers';

    var data = {
      search: this.customer_search,
      page_index: this.page_index,
      page_size: this.page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      this.listOfCustomers = response.data.all_customers;
      this.isLoadingCustomers = false;
      this.filterDrawerVisible = false;
    })
  }

  clearFilters() {
    this.product_filters.forEach(element => {
      element.value = null;
    });
    this.filterMethodCustomers();
  }

  onParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(item => item.value !== null);
    this.sort_by_field = (currentSort && currentSort.key) || null;
    this.sort_by_type = (currentSort && currentSort.value) || null;
    this.page_index = pageIndex;
    this.page_size = pageSize;
    this.filterMethodCustomers();
  }

  downloadCsvCustomer() {

    const { parse } = require('json2csv');
    // const Customers = this.listOfCustomers.data;
    const fields = [{
      label: 'Id',
      value: (row:any) => row.id,
    }, {
      label: 'Name',
      value: (row:any) => row.customer_name,
    }, {
      label: 'Type',
      value: (row:any) => row.type,
    }, {
      label: 'Contact',
      value: (row:any) => row.contact,
    }, {
      label: 'UEN Number',
      value: (row:any) => row.uen_number,
    }, {
      label: 'Phone',
      value: (row:any) => row.phone,
    }, {
      label: 'Email',
      value: (row:any) => row.email,
    }, {
      label: 'Credit Term',
      value: (row:any) => row.credit_term,
    }, {
      label: 'Credit Limit',
      value: (row:any) => row.credit_limit,
    }, {
      label: 'Created On',
      value: (row:any) => moment(row.created_on).format('DD/MM/YYYY'),
    }, {
      label: 'Created By',
      value: (row:any) => row.created_by_name,
    }];

    var url = '/api/all-customers';

    var data = {
      search: this.customer_search,
      page_index: 1,
      page_size: this.listOfCustomers.meta.total,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters:[]
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      // this.listOfCustomers = response.data.all_customers;
      let listOfCustomers = response.data.all_customers;
      const Customers = listOfCustomers.data;
      const csv = parse(Customers, { fields });
      require("downloadjs")(csv, 'Customers.csv');
    })

    // const csv = parse(Customers, { fields });
    // require("downloadjs")(csv, 'Customers.csv');
  }

  newCustomer() {

    if(this.customer_name=='') {
      this.msg.error("Please enter customer name!");
      return false;
    }

    if(this.type=='') {
      this.msg.error("Please enter type!");
      return false;
    }
    
    if(this.contact=='') {
      this.msg.error("Please enter contact name!");
      return false;
    }

    if(this.uen_number=='') {
      this.msg.error("Please enter UEN number!");
      return false;
    }

    // if(!this.contact.match(/^[A-Za-z]+$/)) {
    //   this.msg.error("Please enter only alphabetic characters!");
    //   return false;
    // }

    if(this.phone=='') {
      this.msg.error("Please enter phone!");
      return false;
    }

    if(!this.phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
      this.msg.error("Please enter valid singapore mobile number!");
      return false;
    }

    if(this.email=='') {
      this.msg.error("Please enter email!");
      return false;
    }

    if(this.email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )==null) {
      this.msg.error('Please enter a valid email');
      return;
    }

    if(this.credit_limit==null) {
      this.msg.error("Please enter credit limit!");
      return false;
    }

    if(!(this.credit_term>=0)) {
      this.msg.error("Please enter credit term!");
      return false;
    }

    let url = '';

    if(this.isCustomerEdit) {
      url = '/api/update-customer';
    } else {
      url = '/api/new-customer';
    }

    let data = {
      customer_id: this.customer_id,
      customer_name: this.customer_name,
      type: this.type,
      contact: this.contact,
      uen_number: this.uen_number,
      phone: this.phone,
      email: this.email,
      credit_limit: this.credit_limit,
      credit_term: this.credit_term,
      customer_portal_access: this.customer_portal_access ? 1 : 0,
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success) {
        this.filterMethodCustomers();
        this.openAddCustomerModal = false;
        this.resetData()
      } else {
        if(response.data.message) { 
          this.msg.error(response.data.message);
        } else {
          this.msg.error("Something Went Wrong!");
        }
      }
    })
  }

  deleteCustomer(data) {
    var url = '/api/delete-customer';

    var data2 = {
       customer_id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodCustomers();
        this.openAddCustomerModal=false;
        this.msg.success("Customer deleted successfully!")
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }


  getCustomerSingle(customer_id) {
    this.isLoadingCustomer = true;

    var url = '/api/get-customer-single';

    var data = {
      customer_id: customer_id
    };

    this.customer_id = customer_id;
    this.openAddCustomerModal=true;

    axios.post(url, data, {}).then(response => {
      this.isLoadingCustomer = false;
      this.isCustomerEdit = true;
      this.customer_id = customer_id;
      this.customer_name = response.data.customer_name;
      this.type = response.data.type;
      this.contact = response.data.contact;
      this.uen_number = response.data.uen_number;
      this.phone = response.data.phone;
      this.email = response.data.email;
      this.credit_term = response.data.credit_term;
      this.credit_limit = response.data.credit_limit;
      this.customer_portal_access = response.data.customer_portal_access ? true : false;
    })
  }

  generateNewPassword(email: string) {
    var url = '/api/generate-new-password';

    var data = {
      email: this.email
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.msg.success("New password generated successfully!")
      } else {
        this.msg.error("Something Went Wrong!");
      }
    }).catch(error => {
      this.msg.error("Something Went Wrong!");
    })
  }

  resetData() {
    this.customer_id = 0;
    this.customer_name = '';
    this.type = '';
    this.contact = '';
    this.uen_number = '';
    this.phone = '';
    this.email = '';
    this.credit_limit = null;
    this.credit_term = 0;
    this.customer_portal_access = false;
    this.isCustomerEdit = false;
  }
  

}
