import { Component, OnInit } from '@angular/core'
import { NzMessageService } from 'ng-zorro-antd/message'
import axios from 'axios'
import { Router } from '@angular/router'

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  name = ''
  username = ''
  email = ''
  password_confirmation = ''
  password = ''
  page = 1
  remember_password = false
  showForgerPassword = false
  checked = true
  forgetPassModal = false

  forget_password_email = ''

  forget_password_otp = ''
  otpSubmitModal = false

  constructor(private message: NzMessageService, private router: Router) {}

  ngOnInit(): void {
    var token = localStorage.getItem('token')
    var role = +localStorage.getItem('role')
    if (token) {
      this.router.navigate(['/all-products'])
      // if(role==0) {
      //   this.router.navigate(['/admin/dashboard']);
      // } else if(role==1) {
      //   this.router.navigate(['/teacher/dashboard']);
      // } else if(role==2) {
      //   this.router.navigate(['/student/dashboard']);
      // } else if(role==3) {
      //   this.router.navigate(['/parent/dashboard']);
      // }
    }
  }

  createSuccessMessage(msg: string): void {
    this.message.create('success', msg)
  }

  createErrorMessage(msg: string): void {
    this.message.create('error', msg)
  }

  loginFn() {
    if (this.password == '' && this.email == '') {
      this.createErrorMessage('Please Enter Employee Id And Password Field')
      return false
    }
    if (this.email == '') {
      this.createErrorMessage('Please Enter Employee Id Field')
      return false
    }
    if (this.password == '') {
      this.createErrorMessage('Please Enter Password Field')
      return false
    }

    var url = '/api/login'

    var data = {
      email: this.email,
      password: this.password,
    }

    axios
      .post(url, data, {})
      .then((response) => {
        if (response.data.vmo_admin_access) {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + response.data.token.token
          this.createSuccessMessage(`Logged In Successfully!`)
          localStorage.setItem('token', response.data.token.token)
          localStorage.setItem('id', response.data.user.id)
          localStorage.setItem('email', response.data.user.email)
          localStorage.setItem('name', response.data.user.name)
          localStorage.setItem('role', response.data.user.role)
          localStorage.setItem('status', response.data.user.status)
          localStorage.setItem('profile_pic', response.data.user.profile_pic)
          if (response.data.user.role == 2) {
            this.router.navigate(['/wash-register'])
          } else {
            this.router.navigate(['/all-products'])
          }
        } else {
          this.createErrorMessage(`You are not a VMO user!`)
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data == 'Invalid credentials') {
              this.createErrorMessage('Invalid credentials!')
              return false
            }

            if (error.response.data.error) {
              this.createErrorMessage(error.response.data.error)
            } else {
              this.createErrorMessage('Something went wrong!')
            }
          } else {
            this.createErrorMessage('Something went wrong!')
          }
        } else {
          this.createErrorMessage('Something went wrong!')
        }
      })
  }

  forgetPass() {
    if (this.forget_password_email == '') {
      this.createErrorMessage(`Please enter your email!`)
      return false
    }

    var url = '/api/forget-password-request'

    var data = {
      email: this.forget_password_email,
    }

    axios.post(url, data, {}).then((response) => {
      if (response.data == 'No Account Found!') {
        this.createErrorMessage(`No Account Found!`)
      } else {
        this.forgetPassModal = false
        this.otpSubmitModal = true
      }
    })
  }

  submitOtp() {
    if (this.forget_password_otp == '') {
      this.createErrorMessage(`Please enter OTP!`)
      return false
    }
    var url = '/api/forget-password-verify'

    var data = {
      email: this.forget_password_email,
      otp: this.forget_password_otp,
      send_new_password: true,
    }

    axios.post(url, data, {}).then((response) => {
      if (response.data.success) {
        this.createSuccessMessage(
          `New Password sent to your email address successfully!`,
        )
        this.otpSubmitModal = false
      } else {
        this.createErrorMessage(`OTP is not valid!`)
      }
    })
  }
}
