import { Component, OnInit } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';

interface DataItem {
  id: string;
  vendorName: string;
  contact: number;
  address: string;
  totalBusiness:number;
  createdOn:string;
  createdBy:string;
  contactPerson:string;
}


@Component({
  selector: 'app-all-components',
  templateUrl: './all-components.component.html',
  styleUrls: ['./all-components.component.css']
})
export class AllComponentsComponent implements OnInit {

  isClicked:boolean=false;
  options: string[] = [
    'products',
    'purchase orders',
    'stock out',
    'dashboard',
    'master data',
    'transactions',
  ];

  sort_by_field = 'vendor_name';
  sort_by_type = 'desc';

  listOfColumn = [
    {
      title: 'ID',
  
    },
    {
      title: 'VENDOR NAME',
    },
    {
      title: 'CONTACT',
    },
    {
      title: 'ADDRESS',
    },
    {
      title:'TOTAL BUSINESS'
    },
    {
      title:'CREATED ON'
    },
    {
      title:'CREATED BY'
    },
    {
      title:'CONTACT PERSON'
    }
  ];

  listOfData: DataItem[] = [];

  

  constructor() { }

  ngOnInit(): void {
  }

  handleClick(){
    this.isClicked=true;
  }

}
