import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { ThemeConstantService } from '../services/theme-constant.service';

@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.css']
})
export class AllOrdersComponent implements OnInit {

  returnItemModal = false;
  listOfData = {
    data:[],
    meta: {
      "total": 0,
      "per_page": 0,
      "current_page": 0,
      "last_page": 0,
      "first_page": 0,
    }
  };
  returnModal=false;
  isLoadingOrders = false;
  order_search = '';
  sort_by_field = 'id';
  sort_by_type = 'descend';
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;
  isLoadingData = false;
  page_index = 1;
  page_size = 10;
  filterDrawerVisible = false;
  product_filters = [{
    label: 'Customer Name',
    key: 'customer_name',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Vehicle Number',
    key: 'vehicle_number',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Vehicles Type',
    key: 'vehicles.category',
    type: "string",
    value: null,
    condition: "Contains",
  },{
    label: 'Booking Date',
    key: 'booking_date',
    type: "date",
    value: null,
    condition: "Equals",
  },{
    label: 'Created By',
    key: 'orders.created_by',
    type: "options",
    value: null,
    condition: "Equals",
    options: []
  },{
    label: 'Amount',
    key: 'orders.amount',
    type: "number",
    value: null,
    condition: "Equals",
  }]

  requestby=''
  product=''
  qty=''
  stockinhand=''
  remarks=''

  listOfProducts = [];
  openAddProductModal = false;
  // sortFnId = (a: any, b: any): number => +a.id - +b.id;

  constructor(private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);     
    this.filterMethodOrders();
    this.getProd();
  }
  getProd(){
    axios.post('/api/all-products', {page_index: 1,page_size:500}, {}).then((response) => {
      // this.listOfProducts = response.data.all_products;
      this.listOfProducts = response.data.all_products.data
      console.log("teste",this.listOfProducts);
      
    });
  }

  filterMethodOrders() {
    this.isLoadingData = true;

    var url = '/api/all-stock-out';

    var data = {
      search: this.order_search,
      page_index: this.page_index,
      page_size: this.page_size,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters: []
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      this.listOfData = response.data.all_stock_out;
      this.isLoadingData = false;
      console.log(this.listOfData)
    })
  }

  clearFilters() {
    this.product_filters.forEach(element => {
      element.value = null;
    });
    this.filterMethodOrders();
  }
  
  orderSearch() {
    this.page_index = 1;
    this.filterMethodOrders();
  }

  onDataChange($event) {
    this.page_index = $event.pageIndex;
    this.page_size = $event.pageSize;
    this.sort_by_field = "id";
    this.sort_by_type = "descend";
    this.filterMethodOrders();
  }
  
  stochOutHand(){

  }

  downloadCsvData() {
    const { parse } = require('json2csv');
    // const PurchaseOrders = this.listOfData.data;
    // const json2csvParser = new Parser();
    var url = '/api/all-stock-out';

    var data = {
      search: this.order_search,
      page_index: 1,
      page_size: this.listOfData.meta.total,
      sort_by_field: this.sort_by_field,
      sort_by_type: this.sort_by_type === 'ascend' ? 'asc' : 'desc',
      filters: []
    };

    const filters = this.product_filters.map(element => {
      if(element.value != null) {
        if(element.type === 'number') {
          return {
            key: element.key,
            value: Number(element.value),
            condition: element.condition
          }
        } else if(element.type === 'date') {
          return {
            key: element.key,
            value: moment(element.value).format('YYYY-MM-DD'),
            condition: element.condition
          }
        } else {
          return {
            key: element.key,
            value: element.value,
            condition: element.condition
          }
        }
      }
    }).filter(element => element != null);

    data.filters = filters;

    axios.post(url, data, {}).then(response => {
      // this.listOfData = response.data.all_stock_out;
      let listOfData = response.data.all_stock_out;
      const PurchaseOrders = listOfData.data;

      const csv = parse(PurchaseOrders);
      require("downloadjs")(csv, 'stock out.csv');
    })
    // const csv = parse(PurchaseOrders);
    // require("downloadjs")(csv, 'stock out.csv');
  }

}
