import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ThemeConstantService } from '../services/theme-constant.service';
@Component({
  selector: 'app-vpc-master',
  templateUrl: './vpc-master.component.html',
  styleUrls: ['./vpc-master.component.css']
})
export class VpcMasterComponent implements OnInit {

  isClicked:boolean=false;
  activeTab=0;

  listOfSubType = [];

  listOfVpcCustomer = [];

  isLoadingVpc = false;
  isLoadingVpcCustomer = false;
  openAddSubTypeModal = false;
  openAddVpcCustomerModel = false;

  id = 0;
  iSubTypeEdit = false;
  isVpcCustomerEdit = false;

  search = '';
  vendor_search = '';
  categoriesTimer = null;
  vendorsTimer = null;
  
  isFolded : boolean ;
  isSideNavDark : boolean;
  isExpand: boolean;

  customer_id = 0;
  customer_name = '';
  name = '';
  expiry_date = '';
  start_date = '';
  all_customers: any = [];

  constructor(private msg: NzMessageService, private themeService: ThemeConstantService) { }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);  
    this.filterMethodVpcCustomer();
    this.getGeneralServicingPrefs();
  }

  getGeneralServicingPrefs() {
    var url = '/api/all-customers-for-orders';

    var data = {
    };

    axios.post(url, data, {}).then(response => {
      this.all_customers = response.data.all_customers;
    })
  }

  changeSearchFn() {
    this.categoriesTimer = setTimeout(() => {
      this.filterMethodVpcCustomer();
     clearTimeout(this.categoriesTimer); 
    }, 500);
  }

  filterMethodVpcCustomer(page_index=1, page_size=10) {
    this.isLoadingVpcCustomer = true;

    var url = '/api/get-vpc-master';

    var data = {
      search: this.search,
    };

    axios.post(url, data, {}).then(response => {
      this.listOfVpcCustomer = response.data.vpc_masters;
      this.isLoadingVpcCustomer = false;
    })

  }

  newAddVpcCustomer() {
    if(!this.customer_id) {
      this.msg.error("Please select customer!");
      return false;
    }

    if(!this.name) {
      this.msg.error("Please select location!");
      return false;
    }

    if(!this.start_date) {
      this.msg.error("Please select start date!");
      return false;
    }

    if(!this.expiry_date) {
      this.msg.error("Please select expiry date!");
      return false;
    }

    if(this.start_date > this.expiry_date) {
      this.msg.error("Expiry date should be greater than start date!");
      return false;
    }

    let url = '';

    if(this.isVpcCustomerEdit) {
      url = '/api/update-vpc-master';
    } else {
      url = '/api/new-vpc-master';
    }

    let data = {
      id: this.id,
      customer_id: this.customer_id,
      name: this.name,
      expiry_date: moment(this.expiry_date).format('YYYY-MM-DD'),
      start_date: moment(this.start_date).format('YYYY-MM-DD'),
    };

    axios.post(url, data, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodVpcCustomer();
        this.openAddSubTypeModal = false;
        this.iSubTypeEdit = false;
        this.clearPreviousData();
      } else if(response.data.success==false) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }
  
  editVpcCustomer(data) {
    this.customer_id = data.customer_id;
    this.name = data.name;
    this.start_date = data.start_date;
    this.expiry_date = data.expiry_date;
    this.id = data.id;

    this.isVpcCustomerEdit = true;
    this.openAddVpcCustomerModel=true;
  }

  deleteVpcCustomer(data) {
    var url = '/api/delete-vpc-master';

    var data2 = {
       id: data.id,
    };

    axios.post(url, data2, {}).then(response => {
      if(response.data.success==true) {
        this.filterMethodVpcCustomer();
        this.openAddVpcCustomerModel=false;
        this.msg.success("VPC Customer deleted successfully!")
      } else if(response.data.message) {
        this.msg.error(response.data.message);
      } else {
        this.msg.error("Something Went Wrong!");
      }
    })
  }

  clearPreviousData() {
    this.name = '';
    this.id = 0;
    this.customer_id = 0;
    this.start_date = '';
    this.expiry_date = '';
    this.isVpcCustomerEdit = false;
    this.openAddVpcCustomerModel = false;
  }
}
