<div class="common-layout is-default"
  [class.is-folded]="isFolded"
  [class.is-side-nav-dark]="isFolded"
  [class.is-expand]="isFolded"
>
    <app-primary-header></app-primary-header>
      <app-sidebar-dashboard></app-sidebar-dashboard>
      <div class="page-container">
        <div class="main-content">
            <nz-card>

                <div class="breadcrumbs-add-btn-bar">

                    <div class="title-breadrumbs-container">
                        <div class="title">GENERAL SERVICING AND REPAIRS</div>
                    </div>

                    <div class="doc-search-btn">
                        <button *ngIf="!isEdit" nz-button nzType="primary" (click)="createNewOrder()">CREATE</button>
                        <button *ngIf="isEdit" nz-button nzType="primary" (click)="updateOrder()">SAVE</button>
                    </div>

                </div>

                <div class="customer-details-container" style="padding-bottom: 30px;"> 

                    <div style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px;">Customer Details</div>
                    <nz-divider ></nz-divider>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                    
                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer ID
                            </div>
                                <div>
                                    <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="customer_id" (ngModelChange)="loadCustomerDetails($event)">
                                        <nz-option *ngFor="let customer of all_customers;" 
                                        [nzValue]="customer.id" [nzLabel]="customer.customer_name+' #'+customer.id"></nz-option>
                                    </nz-select>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer Name
                            </div>
                                <div>
                                    <input nz-input [(ngModel)]="customer_name" readonly>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Reg. No.
                            </div>
                                <div>
                                    <nz-select nzShowSearch style="width: 100%;" [(ngModel)]="vehicle_id" (ngModelChange)="loadVehicleDetails($event)">
                                        <nz-option *ngFor="let vehicle of customer_vehicles;" 
                                        [nzValue]="vehicle.id" [nzLabel]="vehicle.vehicle_number"></nz-option>
                                    </nz-select>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Customer Contact No.
                            </div>
                                <div>
                                    <input nz-input type="text" [(ngModel)]="phone" readonly/>
                                </div>
                        </div>
                    
                    </div>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top: 15px;">
                    
                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">

                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Make
                            </div>
                                <div>
                                    <input nz-input type="text" [(ngModel)]="make" readonly/>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px; ">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Vehicle Model
                            </div>
                                <div>
                                    <input nz-input type="text" [(ngModel)]="model" readonly/>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Registration Year
                            </div>
                                <div>
                                    <nz-input-group>
                                        <nz-date-picker [nzFormat]="'dd-MM-yyyy'" style="width: 100%;" [(ngModel)]="mfg_date" readonly></nz-date-picker>
                                    </nz-input-group>
                                </div>
                        </div>

                        <div style="display: flex; flex-direction: column; width: 100%; margin-right:20px;">
                            
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Check in Date
                            </div>
                                <div>
                                    <nz-date-picker [nzFormat]="'dd-MM-yyyy'" [nzDisabledDate]="disabledDate" style="width: 100%;" [(ngModel)]="check_in_date"></nz-date-picker>
                                </div>
                        </div>
                    
                    </div>

                    <div class="d-flex">
                        <div style="display: flex; flex-direction: column; width: 23.5%; margin-right:20px;margin-top: 15px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Order Sub Type
                            </div>
                            <nz-select nzMode="tags" style="width: 100%;" [(ngModel)]="order_sub_type" nzShowSearch nzAllowClear>
                                <nz-option *ngFor="let order_sub_type of order_sub_types;" 
                                [nzValue]="order_sub_type.name" [nzLabel]="order_sub_type.name"></nz-option>
                            </nz-select>
                        </div>
    
                        <div style="display: flex; flex-direction: column; width: 23.5%; margin-right:20px;margin-top: 15px;">
                            <div style="font-family: Poppins;font-size: 13px;color: #231F20;font-weight: 500; margin-bottom:5px;">
                                Link Booking (If Any)
                            </div>
                            <nz-select style="width: 100%;" [(ngModel)]="service_booking_id" nzShowSearch nzAllowClear>
                                <nz-option *ngFor="let booking of vehicle_service_bookings;" 
                                [nzValue]="booking.id" nzLabel="ID#{{booking.id}}, Due Date: {{booking.due_date | date}} "></nz-option>
                            </nz-select>
                        </div>
                    </div>


                </div>
                

                <div style="font-family: Poppins;font-style: normal;font-weight: 500;font-size: 16px;line-height: 15px;color: #004A7F; padding-top: 15px; justify-content: flex-start; align-items: center; ">Customer Remarks</div>
                <nz-divider ></nz-divider>
                <div class='stats__table'>
                    <nz-table #productTable [nzData]="listOfRemarks" [nzNoResult]="null" [nzShowPagination]="false">
                    <thead>
                        <tr>
                        <th style="max-width:50px">ID</th>
                        <th style="width:100%">Remarks</th>
                        <th style="max-width:50px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of listOfRemarks;let i = index;">
                        <td>{{ i+1 }}</td>
                        <td>
                            <input placeholder="Customer Remark.." nz-input [(ngModel)]="data.text" />
                        </td>
                        <td>
                            <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;cursor: pointer;">
                                <div (click)="removeRemarks(i)">
                                    <svg width="16" height="16" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5286 1H3.06607C1.92501 1 1 1.89543 1 3V17C1 18.1046 1.92501 19 3.06607 19H17.5286C18.6696 19 19.5947 18.1046 19.5947 17V3C19.5947 1.89543 18.6696 1 17.5286 1Z" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.19824 7L13.3965 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.3965 7L7.19824 13" stroke="#FF3A29" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </td>
                        
                        </tr>
                    </tbody>
                    </nz-table>
                </div>

                <button nz-button style="margin-bottom: 25px;" (click)="addRemarks()">Add Remark</button>
                
            </nz-card>
        </div>
    </div>
</div>